import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, tap, map } from 'rxjs';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FilterData, UpdateData } from '../../models/list-model';
import { DataFilter, FilterTransferModel } from '../../models/filter-models';
import { IAlert, Alert } from '../../models/alerts/alerts';
import { detailTabbedViewEnum, detailView } from '../../models/detail-view-enum';
import { AlertNotificationModel } from '../../models/alerts/alert-notification-model';
import { AlertSubscriptionModel } from '../../models/alerts/alert-subscription-model';
import { AlertSubscriptionInputModel } from '../../models/alerts/alert-subscription-input-model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private apiUrl = environment.apiUrl;

  private filterDataTransfer = new BehaviorSubject<FilterData>({});
  filterData$ = this.filterDataTransfer.asObservable();

  private statusFilterSubject = new BehaviorSubject<string | null>(null);
  statusFilterToggle$ = this.statusFilterSubject.asObservable();

  private sendFilter = new BehaviorSubject<string | null>(null);
  sendFilter$ = this.sendFilter.asObservable();

  private filterNameSaved = new BehaviorSubject('');
  filterNameSaved$ = this.filterNameSaved.asObservable();

  private sendFilterId = new BehaviorSubject<number>(-1);
  sendFilterId$ = this.sendFilterId.asObservable();

  private sendActiveFilterToFilter = new BehaviorSubject<DataFilter>(new DataFilter());
  sendActiveFilterToFilter$ = this.sendActiveFilterToFilter.asObservable();

  private sendFilterTransfer = new BehaviorSubject<FilterTransferModel>(new FilterTransferModel(-1, {}));
  sendFilterTransfer$ = this.sendFilterTransfer.asObservable();

  private sendActiveFilterToList = new BehaviorSubject<DataFilter>(new DataFilter());
  sendActiveFilterToList$ = this.sendActiveFilterToList.asObservable();

  constructor(private http: HttpClient) { }

  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private handleError<T>(message: string, result?: T): Observable<T> {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-fail']
    });
    return of(result as T);
  }

  private openSnackBar(message: string, success: boolean = true) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: success ? ['snackbar-success'] : ['snackbar-fail']
    });
  }

  getAlerts(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): Observable<Alert[]> {
    let params = new HttpParams();
    
    if (filterData && Object.keys(filterData).length > 0) {
      const filteredData = this.convertFilterDataToJsonString(filterData);
      if (filteredData) {
        params = params.set('filter', filteredData);
      }
    } else if (filterId && filterId > 0) {
      params = params.set('filterId', filterId.toString());
    }

    if (startDate) {
      params = params.set('dateFrom', startDate.toISOString());
    }

    if (endDate) {
      params = params.set('dateTo', endDate.toISOString());
    }

    return this.http.get<Alert[]>(`${this.apiUrl}/Alert/GetAlerts`, { params }).pipe(
      map(response => response.map(item => this.mapAlert(item))),
      catchError(error => this.handleError<Alert[]>('Failed to fetch alerts', []))
    );
  }

  getFilters(): Observable<DataFilter[]> {
    return this.http.get<DataFilter[]>(`${this.apiUrl}/Alert/GetFilters`).pipe(
      catchError(error => this.handleError<DataFilter[]>('Failed to fetch filters', []))
    );
  }

  saveAlertFilter(filterName: string, filterData?: FilterData): Observable<any> {
    const url = `${this.apiUrl}/Alert/SaveFilter`;
    const params = new HttpParams().set('filterName', filterName);
    const body = filterData ? this.convertFilterDataToJsonString(filterData) : {};

    return this.http.post<string>(url, body, { params, headers: { 'Content-Type': 'application/json' } }).pipe(
      catchError(error => this.handleError('Failed to save alert filter'))
    );
  }

  deleteAlertFilter(filterId: number): Observable<any> {
    return this.http.delete<string>(`${this.apiUrl}/Alert/DeleteFilter`, { params: new HttpParams().set('filterId', filterId.toString()) }).pipe(
      catchError(error => this.handleError('Failed to delete alert filter'))
    );
  }

  updateAlertStatus(alertId: number): Observable<string> {
    const url = `${this.apiUrl}/Alert/UpdateAlertToRead`;
    return this.http.put<string>(url, {}, { params: new HttpParams().set('alertId', alertId.toString()) }).pipe(
      tap(() => this.openSnackBar('Alert status updated successfully')),
      catchError(error => this.handleError<string>('Failed to update alert status', ''))
    );
  }

  subscribeToAlertGroup(expression: AlertNotificationModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}/Alert/SubscribeAlertGroup`, expression).pipe(
      tap(() => this.openSnackBar(`Subscribed to ${expression.TemplateGroup} successfully`)),
      catchError(error => this.handleError<string>(`Subscription to ${expression.TemplateGroup} failed`, ''))
    );
  }

  getUnReadAlerts(): Observable<Alert[]> {
    return this.http.get<Alert[]>(`${this.apiUrl}/Alert/GetUnreadAlerts`).pipe(
      map(response => response.map(item => this.mapAlert(item))),
      catchError(error => this.handleError<Alert[]>('Failed to fetch unread alerts', []))
    );
  }

  getAlertsForReference(templateGroup: string, reference: string): Observable<Alert[]> {
    const url = `${this.apiUrl}/Alert/GetAlertsByReference?templateGroup=${templateGroup}&reference=${reference}`;
    return this.http.get<Alert[]>(url).pipe(
      map(response => response.map(item => this.mapAlert(item))),
      catchError(error => this.handleError<Alert[]>('Failed to fetch alerts for GR Reference', []))
    );
  }

  private convertFilterDataToJsonString(filterData: FilterData): string {
    if (!filterData) return '';

    const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
      if (value && value.length > 0) {
        acc[key] = value;
      }
      return acc;
    }, {} as FilterData);

    return Object.keys(filteredData).length > 0 ? JSON.stringify(filteredData) : '';
  }

  private mapAlert(item: any): Alert {
    const detailsView = detailView[item.detailsView.toString() as keyof typeof detailView];
    const detailsTab = detailTabbedViewEnum[item.detailsTab.toString() as keyof typeof detailTabbedViewEnum];
    return new Alert(
      item.id,
      item.userId,
      item.customerCode,
      item.customer,
      item.title,
      item.templateGroup,
      item.code,
      item.alertDate,
      item.notification,
      item.isRead,
      detailsView,
      detailsTab,
      item.reference
    );
  }

  transferFilterData(filterData: FilterData) {
    this.filterDataTransfer.next(filterData);
  }

  toggleStatusFilter(statusFilterName: string) {
    this.statusFilterSubject.next(statusFilterName);
  }

  setFilterForSetup(filter: string) {
    this.sendFilter.next(filter);
  }

  filterNameSavedSend(savedFilterName: string) {
    this.filterNameSaved.next(savedFilterName);
  }

  sendingFilterId(filterId: number) {
    this.sendFilterId.next(filterId);
  }

  sendingActiveFilterToFilter(filter: DataFilter) {
    this.sendActiveFilterToFilter.next(filter);
  }

  sendingFilterTransferModel(transferModel: FilterTransferModel) {
    this.sendFilterTransfer.next(transferModel);
  }

  sendingActiveFilterToList(filter: DataFilter) {
    this.sendActiveFilterToList.next(filter);
  }

  getAlertSubscriptionTemplates(subscription: AlertNotificationModel): Observable<AlertSubscriptionModel[]> {
    const url = `${this.apiUrl}/Alert/GetSubscriptionTemplates`;
    return this.http.post<AlertSubscriptionModel[]>(url, subscription).pipe(
      catchError(error => this.handleError<AlertSubscriptionModel[]>('Failed to fetch alert subscription templates', []))
    );
  }

  updateAlertSubscription(subscriptionInput: AlertSubscriptionInputModel): Observable<string> {
    const url = `${this.apiUrl}/Alert/SubscribeAlertGroup`;
    return this.http.post<string>(url, subscriptionInput, { headers: { 'Content-Type': 'application/json' } }).pipe(
      catchError(error => this.handleError<string>('Failed to update alert subscription', ''))
    );
  }
}
