<div class="container col-md-12">
  <div class="title-wrapper">
    <div class="saved-view-wrap">
      <h1>{{editBookingTitle}}</h1>
    </div>
  </div>



  <ng-container *ngIf="!scheduleSelected">
    <h5>Search sailing schedule</h5>

    <div class="row">
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Enter an ETD date range</mat-label>
          <mat-date-range-input [rangePicker]="picker" required>
            <input matStartDate placeholder="ETD from" [formControl]="dateFrom" [min]="today">
            <input matEndDate placeholder="ETD to" [formControl]="dateTo">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker" matTooltip="Pick a date from the calendar"
                                 matTooltipPosition="above" matTooltipClass="custom-mat-tooltip"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <ng-container [ngSwitch]="true">
    <mat-error *ngSwitchCase="dateFrom.hasError('dateInPast')">ETD from can't be in the past.</mat-error>
    <mat-error *ngSwitchCase="dateTo.invalid && dateTo.touched">End date is required.</mat-error>
  </ng-container>
        
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field *ngIf="stackStatus">
          <mat-label>Stack Status</mat-label>
          <mat-select [formControl]="stackStatus">
            <mat-option>
              <ngx-mat-select-search [formControl]="stackStatusMultiFilter" placeholderLabel="Search"
                                     noEntriesFoundLabel="Stack Status Not found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let stack of filteredStackStatus | async" [value]="stack.code">
              {{stack.name}}
            </mat-option>
          </mat-select>
          <button matSuffix
                  *ngIf="stackStatus.value"
                  mat-icon-button
                  (click)="$event.stopPropagation(); stackStatus.reset()"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field *ngIf="loadPorts" style="width:30%">
          <mat-label>Load port</mat-label>
          <mat-select [formControl]="loadPorts">
            <mat-option>
              <ngx-mat-select-search [formControl]="loadPortsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Load Port Not found"></ngx-mat-select-search>
            </mat-option>

            <mat-option *ngFor="let loadPort of filteredLoadPorts | async" [value]="loadPort.code">
              {{loadPort.name}}
            </mat-option>
          </mat-select>
          <button matSuffix
                  *ngIf="loadPorts.value"
                  mat-icon-button
                  (click)="$event.stopPropagation(); loadPorts.reset()"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-md-2-schedules">
        <mat-form-field *ngIf="countries" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
          <mat-label>Destination Country</mat-label>
          <mat-select [formControl]="countries">
            <mat-option>
              <ngx-mat-select-search [formControl]="countriesMultiFilter" placeholderLabel="Search"
                                     noEntriesFoundLabel="Country Not found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of filteredCountries | async" [value]="country.code">
              {{country.name}}
            </mat-option>
          </mat-select>
          <button matSuffix
                  *ngIf="countries.value"
                  mat-icon-button
                  (click)="$event.stopPropagation(); countries.reset()"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field *ngIf="dischargePorts" style="width:30%">
          <mat-label>Discharge port</mat-label>
          <mat-select [formControl]="dischargePorts">
            <mat-option>
              <ngx-mat-select-search [formControl]="dischargePortsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Discharge Port Not found"></ngx-mat-select-search>
            </mat-option>

            <mat-option *ngFor="let dischargePort of filteredDischargePorts | async" [value]="dischargePort.code">
              {{dischargePort.name}}
            </mat-option>
          </mat-select>
          <button matSuffix
                  *ngIf="dischargePorts.value"
                  mat-icon-button
                  (click)="$event.stopPropagation(); dischargePorts.reset()"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>


      <div class="col-md-2-schedules">
        <mat-form-field *ngIf="carriers" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
          <mat-label>Carrier</mat-label>
          <mat-select [formControl]="carriers">
            <mat-option>
              <ngx-mat-select-search [formControl]="carriersMultiFilter" placeholderLabel="Search"
                                     noEntriesFoundLabel="Carrier Not found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier.code">
              {{carrier.name}}
            </mat-option>
          </mat-select>
          <button matSuffix
                  *ngIf="carriers.value"
                  mat-icon-button
                  (click)="$event.stopPropagation(); carriers.reset()"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>

      <ng-container *ngIf="!scheduleSelected">
        <div class="col-md-2">
          <button class="runReportButton" (click)="runFilter()">Search<mat-icon>arrow_forward</mat-icon></button>
        </div>
      </ng-container>
    </div>

  </ng-container>

  <ng-container *ngIf="bookingData$ | async as bookingData">
    <app-booking-voyage-search (bookingDataOutput)="subscribeToBookingVoyage($event)" [bookingData]="bookingData" [isReadOnly]="isReadOnly"></app-booking-voyage-search>
  </ng-container>

  <ng-container *ngIf="scheduleSelected">
    <ng-container *ngIf="bookingData$ | async as bookingData">
      <app-add-booking-input [isReadOnly]="isReadOnly" [bookingData]="bookingData" [isAddBooking]="isAddBooking"></app-add-booking-input>
    </ng-container>
  </ng-container>
</div>
