
export class RoadData {
  id: string = ''; 
  customerCode: string = '';
  transportBookingId: string = '';
  createdOn: Date = new Date(); // Default to the current date
  job: JobDetail = new JobDetail();
  telemetry: TelemetryData = new TelemetryData();
}

export class JobDetail {
  transportBookingId: string = '';
  bookingRef: string = '';
  clientRef: string = '';
  driverNumber: string = '';
  loadNumber: string = '';
  lastUpdated: Date = new Date();
  vesselName: string = '';
  packDate: Date = new Date();
  status: JobStatus = JobStatus.New; // Default status
  steps: JobStep[] = [];
  nextStep: JobStep = new JobStep();
}

export enum JobStatus {
  New = 0,
  Accepted = 1,
  InProgress = 2,
  Completed = 3,
  Rejected = 4,
  Cancelled = 5,
}

export class JobStep {
  order: number = 0;
  title: string = '';
  completed: boolean = false;
  type: JobStepType = JobStepType.EnRouteToEmptyDepot;
  name: string = '';
  comment: string = '';
  deleted: boolean = false;
  lastUpdated: Date = new Date();
  completedDate?: Date;
  deviceGpsLocation: string = '';
}

export enum JobStepType {
  EnRouteToEmptyDepot = 0,
  ArrivedAtEmptyDepot = 1,
  EnRouteToPackDepot = 2,
  ArrivedAtPackDepot = 3,
  EnRouteSecondPackDepot = 4,
  ArrivedSecondPackDepot = 5,
  EnRouteToTerminal = 8,
  ArrivedAtTerminal = 9,
  ContainerOffloaded = 10,
  ContainerCapture = 11,
  WeightCapture = 12
}

export class TelemetryData {
  currentLocation: Location = new Location();
  previousLocation: Location = new Location();
  speed: number = 0;
}

export class Location {
  at: Date = new Date();
  latitude: number = 0;
  longitude: number = 0;
}
