export const environment = {
  baseUrl: 'https://uat02.root2go.group',
  apiUrl: 'https://uat02.root2go.group/api',
  socketUrl: 'wss://uat02.root2go.group/ws',
  authUrl: 'https://auth.goglobal.group',
  authRealm: 'uat-goglobal',
  authClient: 'Root2Go',
  production: false,
  googleAnalyticsId: 'G-RTMTERE2XE',
  hotjarId: 5227369,
  hotjarVersion: 6,
};
