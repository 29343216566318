import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent {
  @Output() timeSelected = new EventEmitter<string>();
  @Input() currentTime: string | null = null;
  selectedHour: number = 0;
  selectedMinute: number = 0;
  formattedHour: string = '00';
  formattedMinute: string = '00';

  private incrementInterval: any;

  constructor() {
    this.updateFormattedTime();
  }

  ngOnInit() {
    this.initializeTime();
  }

  initializeTime() {
    if (this.currentTime) {
      const [hoursStr, minutesStr] = this.currentTime.split(':');
      this.selectedHour = parseInt(hoursStr, 10);
      this.selectedMinute = parseInt(minutesStr, 10);
    }
    this.updateFormattedTime();
  }

  // Start continuous increment or decrement
  startIncrement(type: 'hour' | 'minute') {
    this.increment(type);
    this.incrementInterval = setInterval(() => this.increment(type), 200); // Adjust speed if needed
  }

  stopIncrement() {
    clearInterval(this.incrementInterval);
  }

  startDecrement(type: 'hour' | 'minute') {
    this.decrement(type);
    this.incrementInterval = setInterval(() => this.decrement(type), 200); // Adjust speed if needed
  }

  stopDecrement() {
    clearInterval(this.incrementInterval);
  }

  // Increment the hour or minute based on the type
  increment(type: 'hour' | 'minute') {
    if (type === 'hour') {
      this.selectedHour = (this.selectedHour + 1) % 24;
    } else if (type === 'minute') {
      if (this.selectedMinute === 59) {
        this.selectedMinute = 0;
        this.increment('hour'); // Increment hour if minutes roll over
      } else {
        this.selectedMinute++;
      }
    }
    this.updateFormattedTime();
  }

  // Decrement the hour or minute based on the type
  decrement(type: 'hour' | 'minute') {
    if (type === 'hour') {
      this.selectedHour = (this.selectedHour - 1 + 24) % 24;
    } else if (type === 'minute') {
      if (this.selectedMinute === 0) {
        this.selectedMinute = 59;
        this.decrement('hour'); // Decrement hour if minutes roll over
      } else {
        this.selectedMinute--;
      }
    }
    this.updateFormattedTime();
  }

  updateFormattedTime() {
    this.formattedHour = this.selectedHour.toString().padStart(2, '0');
    this.formattedMinute = this.selectedMinute.toString().padStart(2, '0');
  }

  formatHour() {
    this.selectedHour = Math.max(0, Math.min(23, this.selectedHour || 0));
    this.updateFormattedTime();
  }

  formatMinute() {
    this.selectedMinute = Math.max(0, Math.min(59, this.selectedMinute || 0));
    this.updateFormattedTime();
  }

  onConfirm() {
    const selectedTime = `${this.formattedHour}:${this.formattedMinute}`;
    this.timeSelected.emit(selectedTime);
  }

  onCancel() {
    this.timeSelected.emit('');
  }
}
