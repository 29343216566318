<div class="dv-content-wrap container">

  <div class="dv-header-wrap">
    <div class="detail-header">
      <h3 class="header-name"> {{schedule.carrier}} - {{schedule.vesselName}}</h3>
    </div>
    <div class="dv-button-wrapper">
      <button class="primary-button"
              (click)="handleNewBooking(schedule.stackEnd, schedule.loadETD)"
              [disabled]="!authService.hasClaim(ClaimAction.Root2Go_AddBookings) || isTodayGreaterThanETD(schedule.loadETD)"
              [matTooltip]="
                          !authService.hasClaim(ClaimAction.Root2Go_AddBookings)
                          ? getPermissionTooltip(ClaimAction.Root2Go_AddBookings).toolTipMessage
                          : isTodayGreaterThanETD(schedule.loadETD)
                          ? 'Can not add booking: Vessel departed.'
                          : 'Create a Booking for this schedule.'
                          "
              matTooltipPosition="above"
              matTooltipClass="custom-mat-tooltip"
              [matTooltipShowDelay]="!authService.hasClaim(ClaimAction.Root2Go_AddBookings) ? getPermissionTooltip(ClaimAction.Root2Go_AddBookings).timeOut : 1000">
        <mat-icon>add_circle</mat-icon> New Booking
      </button>

      <button class="reportSchedule-button"
              (click)="reportSchedule()"
              [matTooltip]="
      'Report Incorrect Schedule Data'
      "
              matTooltipPosition="above"
              matTooltipClass="custom-mat-tooltip">
        <mat-icon svgIcon="backspace"></mat-icon>
      </button>
    </div>
  </div>


  <div class="dv-summary-list">
    

    <div class="dv-summary-column">
      <div class="dv-summary-item">
        <span class="info-label"> Load Port:</span> <span>{{schedule.loadPort}}</span>
      </div>

      <div class="dv-summary-item">
        <span class="info-label"> Stack Start:</span> <span>{{schedule.stackStart | date: 'dd MMM yyyy, HH:mm'}}</span>
      </div>
    </div>
    <div class="dv-summary-column">
      <div class="dv-summary-item">
        <span class="info-label"> Discharge Port:</span> <span>{{schedule.dischargePort}}</span>
      </div>

      <div class="dv-summary-item">
        <span class="info-label"> Stack End:</span> <span> {{schedule.stackEnd | date: 'dd MMM yyyy, HH:mm'}}</span>
      </div>
    </div>
    <div class="dv-summary-column">
      <div class="dv-summary-item">
        <span class="info-label"> Service Name:</span> <span>{{schedule.serviceName}}</span>
      </div>
      <div class="dv-summary-item">
        <span class="info-label"> Load ETD:</span> <span> {{schedule.loadETD | date: 'dd MMM yyyy'}}</span>
      </div>
    </div>
  </div>

  <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color" [hideSingleSelectionIndicator]="true"
    class="tab-menu" [(ngModel)]="selectedTab">
    <mat-button-toggle disabled="true" class="tab-menu-item" [value]="detailTabbedViewEnum.detailsTab">Projected
      Timeline</mat-button-toggle>
  </mat-button-toggle-group>

  <ng-container *ngIf="selectedTab === detailTabbedViewEnum.trackingTab">
  </ng-container>

  <ng-container *ngIf="selectedTab === detailTabbedViewEnum.detailsTab">
    <app-schedules-details-tab [scheduleRouteLines]="scheduleRouteLines"> </app-schedules-details-tab>
  </ng-container>

  <div *ngIf="isSpinnerVisible" class="spinner-overlay">
    <div class="spinner-container">
      <mat-spinner color="primary" diameter="200"></mat-spinner>
    </div>
  </div>
</div>
