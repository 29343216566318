<div class="filter-container">
  <div class="filter-field-wrapper saved-view-name">
    <div class="filter-help" *ngIf="isSavedFilterNameEmpty">
      <img class="info-icon" alt="Info Icon" src="../../assets/Info.svg">
      <div class="help-text"> Enter a unique name to save your filters </div>
    </div>
    <mat-form-field>
      <mat-label>Saved Filter View Name</mat-label>
      <input matInput [formControl]="savedFilterName">
      <mat-error *ngIf="savedFilterName.invalid && savedFilterName.touched">
        View name cannot exceed 20 characters.
      </mat-error>
    </mat-form-field>
    <div class="action-buttons">
      <button mat-button class="small-button blue" (click)="save()"
        *ngIf="!isSavedFilterNameEmpty && savedFilterName.valid">Save View</button>
    </div>
  </div>
</div>