<div #gridsterContainer *ngIf="!loading">
  <gridster [options]="gridOptions">
    <gridster-item *ngFor="let item of dashboard; let i = index" [item]="item" [ngClass]="item['cssClass']">
      <div *ngIf="item['type'] === 'cards'" class="widget-list-container card-content">
        <div class="gridster-item-header" style="display: flex; flex-direction: row; align-items: baseline;">
          <h4 class="gridster-item-header-text" style="width:70%">{{ item['cardTitle'] }}</h4>
          <div *ngIf="item['cardToggle']" style="flex-wrap: nowrap; display: flex; align-items: baseline; gap: 8px; ">
            {{ item['cardToggle'] }}
            <mat-slide-toggle [(ngModel)]="item['stackStatus']" (change)="handleToggleChangeForCard(item)">
            </mat-slide-toggle>
          </div>
          <ng-container *ngIf="!isCustUser && (item['id'] !== 1 && item['id'] !== 2 && item['id'] !== 3) " style="position: relative; top: 0px;">
            <div class="custom-select-wrapper" style="width:min-content">
              <mat-form-field *ngIf="widgetFilters[item['id']]" class="custom-form-field">
                <button mat-button class="filter-btn dashboard-filter-icon-button">
                  <div class="filter-icon-wrap">
                    <div class="filter-icon dashboard-filter-icon"></div>
                  </div>
                  <span *ngIf="widgetFilters[item['id']].customers.value.length > 0" class="badge">
                    {{ widgetFilters[item['id']].customers.value.length }}
                  </span>
                </button>

                <mat-select [id]="'mat-select-' + item['id']"
                            [formControl]="widgetFilters[item['id']].customers"
                            multiple>
                  <mat-option>
                    <ngx-mat-select-search [formControl]="widgetFilters[item['id']].customersMultiFilter"
                                           placeholderLabel="Search"
                                           noEntriesFoundLabel="Customer not found">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-select-trigger>
                    <ng-container *ngIf="widgetFilters[item['id']].customers.value.length > 0">
                      {{ widgetFilters[item['id']].customers.value[0].code }}
                      <span *ngIf="widgetFilters[item['id']].customers.value.length > 1" class="example-additional-selection">
                        (+{{ widgetFilters[item['id']].customers.value.length - 1 }} other{{ widgetFilters[item['id']].customers.value.length === 2 ? '' : 's' }})
                      </span>
                    </ng-container>
                    <ng-container *ngIf="widgetFilters[item['id']].customers.value.length === 0">
                      Select Customers
                    </ng-container>
                    <button mat-icon-button class="clear-filter" (click)="clearSelectedCustomers(item); $event.stopPropagation()">
                      <mat-icon>backspace</mat-icon>
                    </button>
                  </mat-select-trigger>

                  <mat-option *ngFor="let customer of widgetFilters[item['id']].filteredCustomers | async" [value]="customer">
                    {{ customer.name }}
                  </mat-option>
                  <div style="position: sticky; bottom: -7px; padding: 8px; text-align: center; background: #faf9fd;">
                    <button class="runReportButton" (click)="selectCustomerForWidget(item)">Done</button>
                  </div>
                </mat-select>
              </mat-form-field>

            </div>
           
            </ng-container>
          </div>
        <div *ngIf="activeCardLoading[item['id']]" class="loading-spinner">Loading...</div>
        <div class="card-container gridster-list-container">
          <div class="col-md-12 header-row" style=" display: flex; justify-content: space-between;">
            <div class="card-header-list-items" *ngFor="let heading of item['headings']">{{ heading }}</div>
          </div>

          <mat-card appearance="outlined" style="background:white">
            <mat-card-content *ngIf="!activeCardLoading[item['id']]" class="list-container" style="padding:0px">
              <div class="scrollable-container">
                <div class="col-md-12 card-widget-list card-list-container" *ngFor="let card of item['cards']">
                  <ng-container class="card-list-container" *ngIf="!card.isLoading; else loadingTemplate">
                    <div class="card-list-items">{{ card['field1'] }}</div>
                    <div class="card-list-items">{{ card['field2'] }}</div>
                    <div class="card-list-items" *ngIf="card['field3']">{{ card['field3'] }}</div>
                    <div class="card-list-items" *ngIf="card['field4']">{{ card['field4'] }}</div>
                    <div class="card-list-items" *ngIf="card['field5']">{{ card['field5'] }}</div>
                    <div class="card-list-items" *ngIf="card['field6']">{{ card['field6'] }}</div>
                  </ng-container>
                  <ng-template #loadingTemplate>
                    <div class="col-md-12">
                      <ngx-skeleton-loader appearance="line" animation="progress-dark"></ngx-skeleton-loader>
                    </div>
                  </ng-template>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div *ngIf="item['type'] === 'progress'" class="progress-card progress-content"
           (click)="$event.stopPropagation(); item['id'] === 4 ? navigateWithDetails('shipments', item['filterData'], item['dateFrom'], item['dateTo']) : openDataDialog(item, item['delays'], item['progressTitle'])">
        <div class="card-container" style="display: flex; row-gap: 12px; flex-direction: row; justify-content:center">
          <circle-progress [percent]="item['progressValue']"
                           [radius]="60"
                           [space]="-10"
                           [title]="item['progressValue']"
                           [animateTitle]="false"
                           [animationDuration]="500"
                           [showUnits]="false"
                           [showBackground]="false"
                           [clockwise]="false"
                           [startFromZero]="false"
                           [lazy]="true"
                           [outerStrokeWidth]="10"
                           [showSubtitle]="false"
                           [innerStrokeWidth]="10"
                           [outerStrokeColor]="'#35A948'"
                           [innerStrokeColor]="'#FFFFFF'"
                           [titleColor]="'#FFFFFF'">
          </circle-progress>
          <div style="right:0%; position:absolute;">
            <mat-form-field *ngIf="!isCustUser || widgetFilters[item['id']]" (click)="$event.stopPropagation()">
              <button mat-button class="filter-btn dashboard-filter-icon-button">
                <div class="filter-icon-wrap">
                  <div class="filter-icon dashboard-filter-icon"></div>
                </div>
                <span *ngIf="widgetFilters[item['id']].customers.value.length > 0" class="badge">
                  {{ widgetFilters[item['id']].customers.value.length }}
                </span>
              </button>
              <mat-select  [id]="'mat-select-' + item['id']"   [formControl]="widgetFilters[item['id']].customers" multiple (click)="$event.stopPropagation()">
                <mat-option>
                  <ngx-mat-select-search [formControl]="widgetFilters[item['id']].customersMultiFilter"
                                         placeholderLabel="Search"
                                         noEntriesFoundLabel="Customer not found"
                                         (click)="$event.stopPropagation()"></ngx-mat-select-search>
                </mat-option>

                <mat-select-trigger>
                  {{ widgetFilters[item['id']].customers.value.length > 0 ? widgetFilters[item['id']].customers.value[0].code : '' }}
                  <span *ngIf="widgetFilters[item['id']].customers.value.length > 1" class="example-additional-selection">
                    (+{{ widgetFilters[item['id']].customers.value.length - 1 }}
                    {{ widgetFilters[item['id']].customers.value.length === 2 ? 'other' : 'others' }})
                  </span>
                </mat-select-trigger>

                <mat-option *ngFor="let customer of widgetFilters[item['id']].filteredCustomers | async" [value]="customer" (click)="$event.stopPropagation()">
                  {{ customer.name }}
                </mat-option>
                <div style="position: sticky; bottom: -7px; padding: 8px; text-align: center; background: #faf9fd;">
                  <button class="runReportButton" (click)="selectCustomerForWidget(item); $event.stopPropagation()">Done</button>
                </div>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
        <div class="card-text-row gridster-item-header-text">{{ item['cardTitle'] }}</div>
      </div>

      <div *ngIf="item['type'] === 'map'" class="widget-list-container map-content">
        <div *ngIf="activeCardLoading[item['id']]" class="loading-spinner">Loading...</div>
        <div class="gridster-item-header">
          <h4 class="gridster-item-header-text">{{ item['cardTitle'] }}</h4>
          <button *ngIf="!activeCardLoading[item['id']]" mat-button class="add-booking-btn" (click)="item['onClick'] && item['onClick']()">
            Open Map
          </button>
        </div>
        <div *ngIf="!activeCardLoading[item['id']]" style="background-image: url('../../assets/MapImage.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 592px;">
        </div>

      </div>
    </gridster-item>

  </gridster>
</div>
