<div class="filter-container">

    <div class="filter-field-wrapper">
  
      <ng-container *ngIf="!isCustUser">
        <mat-form-field *ngIf="customers.value">
          <mat-label>Customer</mat-label>
          <mat-select [formControl]="customers" multiple #multiSelect>
            <mat-option>
              <ngx-mat-select-search [formControl]="customersMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Customer not found"></ngx-mat-select-search>
            </mat-option>
            <mat-select-trigger>
              {{customers.value.length > 0 ? customers.value[0].code : ''}}
              <span *ngIf="customers.value[0]  && customers.value.length > 1" class="example-additional-selection">
                (+{{(customers.value.length || 0) - 1}} {{customers.value.length === 2 ? 'other' : 'others'}})
              </span>
              <button class="clear-filter" (click)="clearLookupFilter(customers)">
                <mat-icon svgIcon="backspace"></mat-icon>
              </button>
            </mat-select-trigger>
            <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
              {{customer.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
  
      <mat-form-field>
        <mat-label>GR Ref No.</mat-label>
        <input matInput [formControl]="grRefNo">
      </mat-form-field>
  
      <mat-form-field>
        <mat-label>Client Ref.</mat-label>
        <input matInput [formControl]="clientRefNo">
      </mat-form-field>
  
      <mat-form-field *ngIf="consignees.value">
        <mat-label>All Consignees</mat-label>
        <mat-select [formControl]="consignees" multiple>
          <mat-option>
            <ngx-mat-select-search [formControl]="consigneesMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Consignee Not Found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{consignees.value.length > 0 ? consignees.value[0].code : ''}}
            <span *ngIf="consignees.value[0]  && consignees.value.length > 1" class="example-additional-selection">
              (+{{(consignees.value.length || 0) - 1}} {{consignees.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(consignees)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let consignee of filteredConsignees | async" [value]="consignee">
            {{consignee.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container>
        <mat-form-field *ngIf="documentType.value">
          <mat-label>Document Type</mat-label>
          <mat-select [formControl]="documentType" multiple #multiSelect>
            <mat-option>
              <ngx-mat-select-search [formControl]="documentTypesMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Document Type not found"></ngx-mat-select-search>
            </mat-option>
            <mat-select-trigger>
              {{documentType.value.length > 0 ? documentType.value[0].name : ''}}
              <span *ngIf="documentType.value[0]  && documentType.value.length > 1" class="example-additional-selection">
                (+{{(documentType.value.length || 0) - 1}} {{documentType.value.length === 2 ? 'other' : 'others'}})
              </span>
              <button class="clear-filter" (click)="clearLookupFilter(documentType)">
                <mat-icon svgIcon="backspace"></mat-icon>
              </button>
            </mat-select-trigger>
            <mat-option *ngFor="let documentTypes of filteredDocumentTypes | async" [value]="documentTypes">
              {{documentTypes.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
  
      <div class="action-buttons">
        <button mat-button class="small-button" (click)="applyFilters()">Apply Filters</button>
  
        <button class="small-button soft-blue" (click)="clearAllFilters()">
          Clear All Filters
        </button>
      </div>
  
    </div>
  
    <div class="filter-field-wrapper saved-view-name">
      <div class="filter-help" *ngIf="isSavedFilterNameEmpty">
        <img class="info-icon" alt="Info Icon" src="../../assets/Info.svg">
        <div class="help-text"> Enter a unique name to save your filters </div>
      </div>
      <mat-form-field>
        <mat-label>Saved Filter View Name</mat-label>
        <input matInput [formControl]="savedFilterName">
      </mat-form-field>
      <div class="action-buttons">
        <button mat-button class="small-button delete" (click)="deleteFilter()" *ngIf="datafilter.id !== 0">Delete View</button>
        <button mat-button class="small-button blue" (click)="save()" *ngIf="!isSavedFilterNameEmpty">Save View</button>
      </div>
    </div>
  </div>
  