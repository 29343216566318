export enum bookingView {
  none,
  bookingRollingPlan,
  bookingList,
  transportView,
  submittedView
}
export enum bookingTabbedViewEnum {
  none,
  listTab,
  rollingTab,
  transportTab,
  submittedTab
}

export interface BookingViewState {
  bookingView: bookingView;
  bookingTabbedViewEnum: bookingTabbedViewEnum;
}
