import { Component, ChangeDetectorRef, OnInit,OnDestroy, inject } from '@angular/core';
import { FilterData } from '../models/list-model';
import { FormControl } from '@angular/forms';
import { CommunicationService } from '../service/communication/communication.service';
import { DocumentService } from '../service/documents/document.service';
import { DataFilter } from '../models/filter-models';
import { MatDialog } from '@angular/material/dialog';
import { InformationComponent } from '../dialog/information/information.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';

@Component({
  selector: 'app-document-filter-pending-documents',
  templateUrl: './document-filter-pending-documents.component.html',
  styleUrls: ['./document-filter-pending-documents.component.css','../filter-sidenav/filter-sidenav.component.css']
})
export class DocumentFilterPendingDocumentsComponent implements OnInit, OnDestroy {
  
  private destroy$ = new Subject<void>();

  savedFilterName = new FormControl('');

  filterData: FilterData = {};
  filterName: string = '';
  datafilter: DataFilter = new DataFilter();
  savedFilters: DataFilter[] = [];


  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private communicationService: CommunicationService,
    private documentDataService: DocumentService, 
    private cdr: ChangeDetectorRef, 
    private domSanitizer: DomSanitizer, 
    private dialog: MatDialog, 
    private matIconRegistry: MatIconRegistry
  ) {
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
  }

  documentTypeList: string[] = [];

  ngOnInit(): void {

    this.loadDocumentData();
  }

  loadDocumentData(): void {

    this.documentDataService.sendActiveFilterToFilterNotApproved$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.datafilter = data;
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Filter data', error)
      });

    if (this.datafilter.id !== 0) {
      this.filterName = this.datafilter.filterName;
      this.savedFilterName.setValue(this.filterName);
    }
  }

  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
    console.log('Filter cleared:', control);
  }

  save(): void {
    if (!this.savedFilterName.value) {
      console.error('Filter name cannot be empty');
      return;
    }

    this.filterName = this.savedFilterName.value.toString();
    this.documentDataService.saveDocumentFilterNotApproved(this.filterName, this.datafilter.filter).subscribe(_ => {
      this.documentDataService.filterNameSavedSendNotApproved(this.filterName);
      this.documentDataService.getFiltersNotApproved().subscribe(result => {
        this.savedFilters = result;
        const savedFil = this.savedFilters.find(f => f.filterName === this.filterName);
        this.datafilter.id = savedFil!.id;
        this.datafilter.filterName = savedFil!.filterName;
        this.datafilter.filter = savedFil!.filter;
      });
    });
    this.closeRightDrawer();
    this.openSnackBar('Your filter ' + this.filterName + ' has been created');
  }

  get isSavedFilterNameEmpty(): boolean {
    return !this.savedFilterName.value;
  }


  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeRightDrawer(){
    this.communicationService.closeRightNav();
  }

}
