import { ChangeDetectorRef, Component, OnInit, ViewChild,inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, skip, takeUntil } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { MatSidenav } from '@angular/material/sidenav';
import { IUserInfoModel, UserInfoModel } from '../models/user-info-model';
import { ApiUserService } from '../service/user/api-user.service';
import { CommunicationService } from '../service/communication/communication.service';
import { RightDrawer } from '../models/right-drawer-enum';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router'; 
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.css',
  animations: [
    trigger('sidenavAnimation', [
      state('open', style({
        transform: 'translateX(0)',
        visibility: 'visible',
      })),
      state('closed', style({
        transform: 'translateX(100%)',
        visibility: 'hidden',
      })),
      transition('open <=> closed', [
        animate('0.3s ease-in-out')
      ]),
    ]),
  ]
})
export class SidenavComponent implements OnInit {
  @ViewChild('drawer', { static: false }) sidenav!: MatSidenav;
  @ViewChild('rightDrawer') rightDrawer!: MatSidenav;


  activeRightDrawer: RightDrawer = RightDrawer.none;
  isExpanded: boolean = false;
  //isHandset$: Observable<boolean>;
  userInfoModel: IUserInfoModel = new UserInfoModel();
  RightDrawer = RightDrawer;
  private destroy$ = new Subject<void>();

  // Add an animation state for the sidenav
  sidenavState = 'closed';

  currentClass: { [key: string]: boolean } = {
    'sidenav': true,
    'alertnav': false,
    'detailnav':false
  };
  
  private apiUserService = inject(ApiUserService)

  constructor(
    private breakpointObserver: BreakpointObserver,
    private keycloakService: KeycloakService,
    private domSanitizer: DomSanitizer,
    private communicationService: CommunicationService,
    private matIconRegistry: MatIconRegistry,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    // Register SVG icons
    this.matIconRegistry.addSvgIcon(
      'CancelIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/CancelIcon.svg')
    );

  }
  navFaqs() {
    this.router.navigate(['/faqs'])
  }
  navAbout() {
    this.router.navigate(['/about'])
  }

  navContact() {
    this.router.navigate(['/contact'])
  }

  ngOnInit(): void {
    this.apiUserService.userInfo.subscribe((userInfo) => {this.userInfoModel = userInfo});
    this.communicationService.closeRightNav$
      .pipe(
        takeUntil(this.destroy$), skip(1)
      )
      .subscribe(() => {
      this.closeRightDrawer();
      });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.handleNavigation();
      }
    });

    this.communicationService.filterToggle$
      .subscribe((filter) => {
      if(filter){
        this.openFilterDrawer();
      }
    });

     this.communicationService.detailedViewToggle$.subscribe((detailed) =>{
      if(detailed.detailView != 0){
        this.openDetailDrawer();}
      });
  }
  
  onRightDrawerClosed() {
    this.activeRightDrawer = RightDrawer.none; 
  }

  toggleDrawer() {
  
      if (this.isHandset$) {
        this.isExpanded = true;
        this.sidenav.toggle();
        this.rightDrawer.close();
        console.log('mobile', this.isExpanded);
        this.isExpanded = !this.isExpanded;
        this.sidenavState = this.isExpanded ? 'open' : 'closed';
      }
      else if (this.isTablet$) {
        this.isExpanded = true;
        this.sidenav.toggle();
        this.rightDrawer.close();
        console.log('tablet',this.isExpanded);
        this.isExpanded = !this.isExpanded;
        this.sidenavState = this.isExpanded ? 'open' : 'closed';
      }
  
  }

  private handleNavigation(): void {
    this.isHandset$.subscribe(isHandset => {
      if (isHandset && this.sidenav.opened) {
        this.sidenav.close();
      }
    });

    this.isTablet$.subscribe(isTablet => {
      if (isTablet && this.sidenav.opened) {
        this.sidenav.close();
      }
    });
  }

  openAlertDrawer() {
    this.isHandset$.subscribe(isHandset => {
      if (isHandset){
        this.sidenav.close();
      }
    });
    this.currentClass['alertnav'] = this.currentClass['alertnav']=true;
    this.currentClass['sidenav'] = this.currentClass['sidenav']=false;
    this.currentClass['detailnav'] = this.currentClass['detailnav']=false;
    this.isExpanded = true;
    this.activeRightDrawer = RightDrawer.alertSideNav;
    this.rightDrawer.open();
    this.sidenavState = 'open';

  }
 
  closeRightDrawer() {
    this.isExpanded = false;
    this.rightDrawer.close();
    this.activeRightDrawer = RightDrawer.none;
    this.currentClass['alertnav'] = this.currentClass['alertnav']=false;
    this.currentClass['detailnav'] = this.currentClass['detailnav']=false;
    this.sidenavState = 'closed';
    this.triggerCDR();

  }

  openFilterDrawer() {
    this.isExpanded = true;
    //this.isHandset$.subscribe(isHandset => {
    //  if (isHandset){
    //    this.sidenav.close();
    //  }
    //});
    this.activeRightDrawer = RightDrawer.FilterSideNav;
    this.rightDrawer.open();
    this.currentClass['sidenav'] = this.currentClass['sidenav']=false;
    this.currentClass['alertnav'] = this.currentClass['alertnav']=true;
    this.currentClass['detailnav'] = this.currentClass['detailnav']=false;
    //this.sidenavState = 'open';
  }

   openDetailDrawer() {
    this.isExpanded = true;
    //this.isHandset$.subscribe(isHandset => {
    //  if (isHandset){
    //    this.sidenav.close();
    //  }
    //});
    this.activeRightDrawer = RightDrawer.DetailViewSideNav;
    this.rightDrawer.open();
    
    this.currentClass['sidenav'] = this.currentClass['sidenav']=true;
    this.currentClass['alertnav'] = this.currentClass['alertnav']=false;
    this.currentClass['detailnav'] = this.currentClass['detailnav']=true;
    this.sidenavState = 'open';
    
  }

  logout() {
    this.keycloakService.logout();
  }

  private triggerCDR() {
    this.cdr.detectChanges();
  }


  isTablet$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Tablet)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isDesktop$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Web)
    .pipe(
      map(result => result.matches),
      shareReplay()
  );

  
  isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );
}
