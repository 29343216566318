import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-widget-data-dialog',
  templateUrl: './widget-data.component.html',
  styleUrls: ['./widget-data.component.css'], 

})
export class WidgetDataComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, widgetData: any[] }) { }
}
