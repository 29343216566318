<mat-card class="time-picker-card">
  <mat-card-header>
    <mat-card-subtitle>Choose a time</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="time-picker-content">
    <div class="time-input-group">
      <div style="width: 60px; text-align: center;">
        <button mat-icon-button
                (mousedown)="startIncrement('hour')"
                (mouseup)="stopIncrement()"
                (mouseleave)="stopIncrement()"
                aria-label="Increase Hour">
          <mat-icon>arrow_drop_up</mat-icon>
        </button>
        <mat-form-field class="time-input">
          <mat-label>Hour</mat-label>
          <input matInput
                 type="text"
                 [(ngModel)]="formattedHour"
                 placeholder="HH"
                 (input)="formatHour()"
                 required />
        </mat-form-field>
        <button mat-icon-button
                (mousedown)="startDecrement('hour')"
                (mouseup)="stopDecrement()"
                (mouseleave)="stopDecrement()"
                aria-label="Decrease Hour">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </div>

      <span class="time-separator">:</span>

      <div style="width: 60px; text-align: center;">
        <button mat-icon-button
                (mousedown)="startIncrement('minute')"
                (mouseup)="stopIncrement()"
                (mouseleave)="stopIncrement()"
                aria-label="Increase Minute">
          <mat-icon>arrow_drop_up</mat-icon>
        </button>
        <mat-form-field class="time-input">
          <mat-label>Minute</mat-label>
          <input matInput
                 type="text"
                 [(ngModel)]="formattedMinute"
                 placeholder="MM"
                 (input)="formatMinute()"
                 required />
        </mat-form-field>
        <button mat-icon-button
                (mousedown)="startDecrement('minute')"
                (mouseup)="stopDecrement()"
                (mouseleave)="stopDecrement()"
                aria-label="Decrease Minute">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions align="end" class="action-buttons">
    <button mat-button (click)="onCancel()" class="cancel-button">Cancel</button>
    <button mat-button (click)="onConfirm()" class="ok-button">OK</button>
  </mat-card-actions>
</mat-card>
