import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { CompactType, DisplayGrid, GridType, GridsterConfig, GridsterItem } from 'angular-gridster2';
import { UserActivityService } from '../service/user-activity/user-activity.service';
import { IUserInfoModel, UserInfoModel } from '../models/user-info-model';
import { Subscription } from 'rxjs';
import { ApiUserService } from '../service/user/api-user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent {

  constructor(

  ) { }

 
}
