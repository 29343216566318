import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { TransportMode } from '../enums/transport-mode.enum';
import { FilterEnum } from '../models/filter-enum';
import { DataFilter, FilterTransferModel } from '../models/filter-models';
import { FilterData } from '../models/list-model';
import { BookingService } from '../service/booking/booking.service';
import { Router } from '@angular/router';
import { CommunicationService } from '../service/communication/communication.service';
import { FormControl } from '@angular/forms';
import { ScheduleVoyageCoordinates } from '../models/schedules/schedule-voyage-coordinates';
import { RoadDataService } from '../service/roads/road-data.service';
import { JobStatus, RoadData } from '../models/road-data-model';
import { CombinedShipment } from '../models/shipment/combined-shipment-model';
import { ShipmentService } from '../service/shipment/shipment.service';
import { Subject, takeUntil } from 'rxjs';
import { ApiUserService } from '../service/user/api-user.service';
import { ShipmentTrackingHeader, ShipmentTrackingVessel } from '../models/shipment/shipment-tracking-header-model';
import { OceanTrackingViewEnum } from '../enums/ocean-tracking-view-enum';
import { LegendSetup } from '../models/legend';

@Component({
  selector: 'app-dashboard-tracking',
  templateUrl: './dashboard-tracking.component.html',
  styleUrls: ['./dashboard-tracking.component.css']
})
export class DashboardTrackingComponent implements OnInit {
  @Output() roadDataUpdated = new EventEmitter<RoadData[]>(); // Add EventEmitter to notify child components
  @Output() shipmentDataUpdated = new EventEmitter<ShipmentTrackingHeader[]>(); // Add EventEmitter to notify child components
  

  private destroy$ = new Subject<void>();
  modeTabbedViewEnum = TransportMode;
  @Input() selectedTab = TransportMode.Ocean; 
  dateFrom = new FormControl();
  dateTo = new FormControl();
  filter!: FilterData;
  filterId: number = 0;
  startDate?: Date = new Date();
  endDate?: Date = new Date();
  filterData: FilterData = {};
  filterParameter!: FilterData;
  FilterEnum = FilterEnum;
  savedFilters: DataFilter[] = [];
  selectedFilterId: number = 0;
  SavedFilterName: string = '';
  currentFilter: DataFilter = new DataFilter();
  public transportMode: TransportMode = TransportMode.Ocean;
  roadDataList: RoadData[] = [];
  activeLabel: string = 'Active shipments';
  activeCount: number = 0;
  shipmentDataList: ShipmentTrackingHeader[] = [];
  vesselDataList: ShipmentTrackingVessel[] = [];
  isLoading = false;
  errorMessage: string | null = null;
  isCustUser: boolean = false;
  private apiUserService = inject(ApiUserService);
  protected startLocation: L.LatLngExpression = [0, 0];
  protected endLocation: L.LatLngExpression = [0, 0];
  containerTrackingCoordinates: ShipmentTrackingHeader[] = [];
  public mapView: OceanTrackingViewEnum = OceanTrackingViewEnum.overview;
  legend: LegendSetup[] =[
    { iconClass: 'icon ship-icon eta', text: 'No ETA change on vessel.' },
    { iconClass: 'icon ship-icon eta-changed', text: 'ETA moved to a later date.' },
    { iconClass: 'icon ship-icon line-expected', text: 'Expected vessel route' },
    { iconClass: 'icon ship-icon line-actual', text: 'Actual route' },
  ];




  constructor(
    private bookingDataService: BookingService,
    private roadDataService: RoadDataService,
    private communicationService: CommunicationService,
    private router: Router,
    private shipmentService: ShipmentService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

    this.apiUserService.userInfo
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (_) => {
          this.isCustUser = this.apiUserService.IsCustUser;
        }
      });

    this.roadDataService.filterData$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (t) => {
          this.filter = t;
          if (this.filter) {
            this.fetchRoadData(this.filterId, this.filter, this.startDate, this.endDate);
          }
          else {
            this.fetchRoadData()
          }
        },
        error: (error) => {
          console.error('Send FilterModel Error:', error);
          window.alert('Send FilterModel Error. Please try again later.');
        }
      });

    this.shipmentService.filterData$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (t) => {
          this.filter = t;
          if (this.filter) {
            this.fetchShipmentData(this.filterId, this.filter, this.startDate, this.endDate);
          }
          else {
            this.fetchShipmentData()
          }
        },
        error: (error) => {
          console.error('Send FilterModel Error:', error);
          window.alert('Send FilterModel Error. Please try again later.');
        }
      });


    this.isCustUser = this.apiUserService.IsCustUser;
    switch (this.selectedTab) {
      case TransportMode.Road:
        this.fetchRoadData();
        break;
      case TransportMode.Ocean:
        this.fetchShipmentData();
        break;
      case TransportMode.Air:
        //this.fetchAirData();
        break;
      default:
        console.warn('Unsupported transport mode selected');
        break;
    }
    this.updateActiveCount();
  }


  private triggerCDR() {
    this.cdr.detectChanges();
    this.updateActiveCount();
  }

  updateActiveCount(): void {
    if (this.selectedTab === TransportMode.Road) {
      this.activeLabel = 'Active trucks';
      this.activeCount = this.roadDataList.filter(vehicle => vehicle.job.status === JobStatus.InProgress).length;
    } else if (this.selectedTab === TransportMode.Ocean) {
      this.activeLabel = 'Active shipments';
      this.activeCount = this.shipmentDataList.length;
    } else {
      this.activeLabel = 'Active shipments';
      this.activeCount = 0;
    }
  }

  onTabChange(): void {
    this.transportMode = this.selectedTab;
    this.updateActiveCount();
    if (this.transportMode === TransportMode.Road) {
      this.fetchRoadData(); 
      //this.shipmentDataList = []// Optionally clear the shipment data for Road tab
    } else if (this.transportMode === TransportMode.Ocean) {
      this.fetchShipmentData();
      //this.roadDataList = []; // Optionally clear the road data for Ocean tab
    }
  }

  onOpenFilter(filter: FilterEnum) {
    this.communicationService.toggleFilter(filter);
    this.sendingActiveFilter();
  }

  applyFilter(filterId: number): void {
    this.filterData = {};
    if (this.selectedFilterId === filterId) {
      this.selectedFilterId = 0;
      this.updateCurrentFilter(0, '', {});
    } else {
      this.selectedFilterId = filterId;
      this.updateCurrentFilter(this.selectedFilterId, this.getSavedFilterNameFromId(this.selectedFilterId), this.getSavedFilterFromId(this.selectedFilterId));
    }
    this.bookingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));

  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.currentFilter.id = id;
    this.currentFilter.filterName = name;
    this.currentFilter.filter = filter;
  }

  getSavedFilterNameFromId(filterId: number): string {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  getSavedFilterFromId(filterId: number): FilterData {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  getFilterIdByName(filterName: string): number {
    const filter = this.savedFilters.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  sendingActiveFilter() {
    this.bookingDataService.sendingActiveFilterToFilter(this.currentFilter);
  }

  fetchRoadData(filterId?: number, filterData?: FilterData, startDate?: Date, endDate?: Date): void {
    this.isLoading = true;

    const startDateString = startDate ? startDate.toISOString().split('T')[0] : undefined;
    const endDateString = endDate ? endDate.toISOString().split('T')[0] : undefined;

    this.roadDataService.getRoadData(filterId, filterData, startDateString, endDateString).subscribe(data => {
      this.roadDataList = data;
      this.updateActiveCount();
      this.isLoading = false;
    });

    this.filterParameter = {};
  }

  fetchShipmentData(filterId?: number, filterData?: FilterData, startDate?: Date, endDate?: Date): void {
    this.isLoading = true;
    const startDateString = startDate ? startDate.toISOString().split('T')[0] : undefined;
    const endDateString = endDate ? endDate.toISOString().split('T')[0] : undefined;


    this.shipmentService.getActiveShipmentsTracking().subscribe(data => {
      if (data !== null) {
        console.log('all sea data', data)
        this.vesselDataList = data.trackedVessels;
        console.log('vessel Data', this.vesselDataList)
        this.isLoading = false;
        this.shipmentDataList = data.trackedContainers
        this.updateActiveCount();
        console.log('shipment', this.shipmentDataList)
      }
    });
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
