export interface IShipmentTrackingHeader {
  id: string;
  bookingReference: string;
  bookingLineReference: number;
  containerNumber: string;
  currentLatitude: number;
  currentLongitude: number;
  trackContainer: boolean;
  eta: Date;
  lastGpsUpdate: Date | null;
  vesselCode: string;
  vesselName: string;
  lastModifiedDateTime: Date;
  routeInformationMediaEditLink: string;
  routeInformationMediaReadLink: string;
  consignee: string | null;
  destination: string | null;
  grReference: string | null;
}

export class ShipmentTrackingHeader implements IShipmentTrackingHeader {
  id: string = '';
  bookingReference: string = '';
  bookingLineReference: number = 0;
  containerNumber: string = '';
  currentLatitude: number = 0.0;
  currentLongitude: number = 0.0;
  trackContainer: boolean = false;
  eta: Date = new Date();
  lastGpsUpdate: Date | null = null;
  vesselCode: string = '';
  vesselName: string = '';
  lastModifiedDateTime: Date = new Date();
  routeInformationMediaEditLink: string = '';
  routeInformationMediaReadLink: string = '';
  consignee: string | null = null;
  destination: string | null = null;
  grReference: string | null = null;

}

export interface IShipmentTrackingData {
  trackedContainers: ShipmentTrackingHeader[];
  trackedVessels: ShipmentTrackingVessel[];
}

export class ShipmentTrackingData implements IShipmentTrackingData {
  trackedContainers: ShipmentTrackingHeader[] = [];
  trackedVessels: ShipmentTrackingVessel[] = [];
}

export interface IShipmentTrackingVessel {

  vesselCode: string;
  vesselName: string;
  currentLatitude: number;
  currentLongitude: number;
  noOfContainers: number;
  eta: Date;
  lastModifiedDateTime: Date;
  bookingReference: string;
  bookingLineReference: number;
  routeInformationMediaEditLink: string;
}

export class ShipmentTrackingVessel implements IShipmentTrackingVessel {

  vesselCode: string = '';
  vesselName: string = '';
  currentLatitude: number = 0;
  currentLongitude: number = 0;
  noOfContainers: number = 0;
  eta: Date = new Date();
  lastModifiedDateTime: Date = new Date();
  bookingReference: string = '';
  bookingLineReference: number = 0;
  routeInformationMediaEditLink: string = '';
}
