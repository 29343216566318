<div class="export" *ngIf="!isLoading && this.appBookingDataSource.data.length > 0">
  <button mat-icon-button color="primary" (click)="exportTable()" matTooltip="Export submitted bookings to excel."
    matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
    <mat-icon svgIcon="export"></mat-icon>
  </button>
</div>
<div class="mat-elevation-z2 custom-card-radius">
  <table mat-table class="full-width-table" [dataSource]="appBookingDataSource" matSort aria-label="Elements">
    <ng-container *ngFor="let column of displayedColumns">
      <ng-container [matColumnDef]="column.name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
          {{ column.header }}
        </th>
        <td mat-cell *matCellDef="let row"
          [attr.data-label]="(column.name !== 'actions' && column.name !== 'indicators') ? column.header : null"
          [hidden]="!column.visible" class='has_label_on_mobile'
          [ngStyle]="column.name === 'appBookingStatusColor' ? {'background-color': getBackgroundColor(row['appBookingStatus'])} : {}">

          <ng-container *ngIf="column.name !== 'actions'">
            <ng-container
              *ngIf="column.name !== 'sailDateETD' && column.name !== 'appBookingStatusColor'  && column.name !== 'indicators'">
              {{ row[column.name] | truncateText}}
            </ng-container>
            <ng-container *ngIf="column.name === 'sailDateETD'">
              <ng-container
                *ngIf="row[column.name] === '0001-01-01T00:00:00Z' || row[column.name] === '0001-01-01T00:00:00'; else showDate"></ng-container>
              <ng-template #showDate>
                {{ row[column.name] | date: 'dd MMM yyyy' }}
              </ng-template>
            </ng-container>
            <ng-container *ngIf="column.name === 'indicators'">
              <button [ngClass]="row.hasSubscriptions ? 'indicator-btn-subbed' : 'indicator-btn'"
                (click)="subscribeToAlert(row)">
                <mat-icon svgIcon="Subscribe"></mat-icon>
              </button>
              <button [ngClass]="row.hasAlerts ? 'indicator-btn-alerts-unread' : 'indicator-btn'"
                (click)="viewDetails(row, detailView.bookingDetail, detailTabbedViewEnum.alertTab)">
                <mat-icon svgIcon="ViewAlerts"></mat-icon>
              </button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="column.name === 'actions'">
            <button class="detailed-view-btn"
              (click)="viewDetails(row, detailView.bookingDetail, detailTabbedViewEnum.detailsTab)">
              View Details <mat-icon>arrow_right_alt</mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="extractedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: extractedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell center no-data-container" [attr.colspan]="displayedColumns.length">
        <div *ngIf="isLoading" class="no-data-container-content">
          <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>
        <ng-container *ngIf="!isLoading" class="no-data-container-content">
          No bookings found.
        </ng-container>
      </td>
    </tr>
  </table>

  <mat-paginator #paginator [length]="appBookingDataSource.data.length" [pageIndex]="0" [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
  </mat-paginator>
</div>