<div class="detailView-container">
  <ng-container *ngIf="detailView === detailViewEnum.scheduleDetail">
    <app-schedules-detail-view></app-schedules-detail-view>
  </ng-container>
  <ng-container *ngIf="detailView === detailViewEnum.bookingDetail">
    <app-booking-detailed-view></app-booking-detailed-view>
  </ng-container>
  <ng-container *ngIf="detailView === detailViewEnum.rollingPlan">
    <app-rolling-plan-detailed-view></app-rolling-plan-detailed-view>
  </ng-container>
  <ng-container *ngIf="detailView === detailViewEnum.documentDetail">
    <app-document-management></app-document-management>
  </ng-container>
  <!-- <ng-container *ngIf="detailView === detailViewEnum.documentDetail">
    <app-shipment-alert-tab></app-shipment-alert-tab>
  </ng-container> -->
  <ng-container *ngIf="detailView === detailViewEnum.shipmentDetail">
    <app-detailed-shipment [selectedTab]="detailTabbedView"></app-detailed-shipment>
  </ng-container>
</div>