<div class="container">

  <div class="title-wrapper">
    <h1>Shipments</h1>
    <ng-container *ngIf="selectedTab == shipmentTabbedViewEnum.oceanTab">
      <div class="saved-view-wrap">
        <div class="saved-views">
          <div *ngFor="let savedFilter of savedFilters">
            <button mat-button class="Saved-filter-btn" [ngClass]="{'selected': currentFilter.id === savedFilter.id}"
              (click)="applyFilter(savedFilter.id)">
              {{ savedFilter.filterName }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedTab == shipmentTabbedViewEnum.roadTab">
      <div class="saved-view-wrap">
        <div class="saved-views">
          <div *ngFor="let savedFilter of savedRoadFilters">
            <button mat-button class="Saved-filter-btn"
              [ngClass]="{'selected': this.currentFilter.id === savedFilter.id}"
              (click)="applyRoadFilter(savedFilter.id)">
              {{ savedFilter.filterName }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="action-buttons-wrapper">
      <div class="action-buttons">
        <div *ngIf="selectedTab == shipmentTabbedViewEnum.oceanTab">
          <button mat-button class="filter-btn" (click)="onOpenFilter(FilterEnum.shipmentFilter)"
            matTooltip="Save your current filters." matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
            <div class="filter-icon-wrap">
              <div class="filter-icon"></div>
              Save Filters
            </div>
          </button>
        </div>
        <div *ngIf="selectedTab == shipmentTabbedViewEnum.roadTab">
          <button mat-button class="filter-btn" (click)="onOpenFilter(FilterEnum.roadShipmentFilter)"
            matTooltip="Save your current filters." matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
            <div class="filter-icon-wrap">
              <div class="filter-icon"></div>
              Save Filters
            </div>
          </button>
        </div>
        <button mat-button class="filter-btn" (click)="clearCurrentFilter()" matTooltip="Clear input fields."
          matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
          <div class="filter-icon-wrap">
            <div class="filter-clear-icon"></div>
            Reset Filters
          </div>
        </button>
        <button mat-button class="filter-btn-delete" (click)="deleteFilter()" *ngIf="currentFilter.id !== 0"
          matTooltip="Delete the current saved filter view." matTooltipPosition="above"
          matTooltipClass="custom-mat-tooltip">
          <div class="filter-icon-wrap">
            <div class="filter-delete-icon"></div>
            Delete Saved View
          </div>
        </button>
      </div>
    </div>
  </div>

  <div class="filter-btn-wrapper">
    <div class="col-md-4">
      <mat-button-toggle-group name="" aria-label="Favorite Color" [hideSingleSelectionIndicator]="true"
        class="tab-menu" [(ngModel)]="selectedTab">
        <mat-button-toggle class="tab-menu-item" (click)="viewOceanToggle()" [value]="shipmentTabbedViewEnum.oceanTab"
          matTooltip="View all ocean shipments." matTooltipPosition="above"
          matTooltipClass="custom-mat-tooltip">Ocean</mat-button-toggle>
        <mat-button-toggle class="tab-menu-item" (click)="viewTrucksToggle()" [value]="shipmentTabbedViewEnum.roadTab"
          matTooltip="View all road transport loads." matTooltipPosition="above"
          matTooltipClass="custom-mat-tooltip">Road</mat-button-toggle>

      </mat-button-toggle-group>
    </div>

  </div>

  <div class="row">
    <div class="col-md-2-shipments">
      <mat-form-field>
        <mat-label>Enter a {{selectedTab == shipmentTabbedViewEnum.oceanTab ? 'ETA':'Confirmed Pack'}} date
          range</mat-label>
        <mat-date-range-input [rangePicker]="picker" required>
          <input matStartDate placeholder="ETD from" [formControl]="dateFrom">
          <input matEndDate placeholder="ETD to" [formControl]="dateTo">
        </mat-date-range-input>

        <!-- Clear button for the Start Date -->
        <ng-container *ngIf="dateFrom.value && dateTo.value">
          <button class="clear-search" (click)="clearSearchField(dateFrom,dateTo)">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </ng-container>

        <mat-datepicker-toggle matIconSuffix [for]="picker" matTooltip="Pick a date from the calendar."
          matTooltipPosition="above" matTooltipClass="custom-mat-tooltip"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="dateFrom.invalid && dateFrom.touched">Start date is required.</mat-error>
        <mat-error *ngIf="dateTo.invalid && dateTo.touched">End date is required.</mat-error>
      </mat-form-field>
    </div>
    <ng-container *ngIf="!isCustUser">
      <div class="col-md-2-shipments">
        <mat-form-field *ngIf="customers.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
          <mat-label>Customer</mat-label>
          <mat-select [formControl]="customers" multiple #multiSelect>
            <mat-option>
              <ngx-mat-select-search [formControl]="customersMultiFilter" placeholderLabel="Search"
                noEntriesFoundLabel="Customer not found"></ngx-mat-select-search>
            </mat-option>
            <mat-select-trigger>
              {{customers.value.length > 0 ? customers.value[0].code : ''}}
              <span *ngIf="customers.value[0]  && customers.value.length > 1" class="example-additional-selection">
                (+{{(customers.value.length || 0) - 1}} {{customers.value.length === 2 ? 'other' : 'others'}})
              </span>
              <button class="clear-filter" (click)="clearLookupFilter(customers)">
                <mat-icon svgIcon="backspace"></mat-icon>
              </button>
            </mat-select-trigger>
            <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
              {{customer.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
    <div class="col-md-2-shipments" *ngIf="selectedTab === shipmentTabbedViewEnum.oceanTab">
      <mat-form-field *ngIf="consignees.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>All Consignees</mat-label>
        <mat-select [formControl]="consignees" multiple>
          <mat-option>
            <ngx-mat-select-search [formControl]="consigneesMultiFilter" placeholderLabel="Search"
              noEntriesFoundLabel="Consignee Not Found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{consignees.value.length > 0 ? consignees.value[0].code : ''}}
            <span *ngIf="consignees.value[0]  && consignees.value.length > 1" class="example-additional-selection">
              (+{{(consignees.value.length || 0) - 1}} {{consignees.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(consignees)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let consignee of filteredConsignees | async" [value]="consignee">
            {{consignee.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2-shipments" *ngIf="selectedTab === shipmentTabbedViewEnum.roadTab">
      <mat-form-field *ngIf="loadPoints.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>All Load Points</mat-label>
        <mat-select [formControl]="loadPoints" multiple>
          <mat-option>
            <ngx-mat-select-search [formControl]="loadpointsMultiFilter" placeholderLabel="Search"
              noEntriesFoundLabel="Consignee Not Found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{loadPoints.value.length > 0 ? loadPoints.value[0].code : ''}}
            <span *ngIf="loadPoints.value[0]  && loadPoints.value.length > 1" class="example-additional-selection">
              (+{{(loadPoints.value.length || 0) - 1}} {{loadPoints.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(loadPoints)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let loadPoint of filteredLoadpoints | async" [value]="loadPoint">
            {{loadPoint.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2-shipments" *ngIf="selectedTab === shipmentTabbedViewEnum.oceanTab">
      <mat-form-field *ngIf="dischargePorts.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Discharge port</mat-label>
        <mat-select [formControl]="dischargePorts" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="dischargePortsMultiFilter" placeholderLabel="Search"
              noEntriesFoundLabel="Discharge Port Not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{dischargePorts.value.length > 0 ? dischargePorts.value[0].code : ''}}
            <span *ngIf="dischargePorts.value[0]  && dischargePorts.value.length > 1"
              class="example-additional-selection">
              (+{{(dischargePorts.value.length || 0) - 1}} {{dischargePorts.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(dischargePorts)" matTooltip="Clear field input."
              matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let dischargePort of filteredDischargePorts | async" [value]="dischargePort">
            {{dischargePort.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2-shipments" *ngIf="selectedTab === shipmentTabbedViewEnum.oceanTab">
      <mat-form-field [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Search Container No.</mat-label>
        <input matInput [formControl]="containerNos">
        <button class="clear-search" (click)="clearSearchField(containerNos)" *ngIf="containerNos.value != ''">
          <mat-icon svgIcon="backspace"></mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-md-2-shipments" *ngIf="selectedTab === shipmentTabbedViewEnum.oceanTab">
      <mat-form-field [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Booking Reference</mat-label>
        <input matInput [formControl]="bookingReference">
        <button class="clear-search" (click)="clearSearchField(bookingReference)" *ngIf="bookingReference.value != ''">
          <mat-icon svgIcon="backspace"></mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-md-2-shipments" *ngIf="selectedTab === shipmentTabbedViewEnum.roadTab">
      <mat-form-field [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Client Reference</mat-label>
        <input matInput [formControl]="clientReference">
        <button class="clear-search" (click)="clearSearchField(clientReference)" *ngIf="clientReference.value != ''">
          <mat-icon svgIcon="backspace"></mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="col-md-2-shipments">
      <button class="runReportButton" (click)="runReportClick()" matTooltip="Search for shipments"
        matTooltipPosition="above"
        matTooltipClass="custom-mat-tooltip">Search<mat-icon>arrow_forward</mat-icon></button>
    </div>
  </div>
  
  <div>
    <ng-container *ngIf=" selectedTab === shipmentTabbedViewEnum.oceanTab">
      <div class="filter-btn-wrapper">
        <div class="legend-wrapper">
          <button mat-button class="transport-btn" [class.selected]="isOceanMapSelected" (click)="onTrackLiveMapClick()"
            matTooltip="Open live tracking map." matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
            <img src="/assets/cargo-ship.svg" alt="" class="icon truck-icon">
            Live Map
          </button>
          <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'active'}"
            (click)="selectButton('active')"
            matTooltip="Show all shipments which has not yet arrived at discharge Port." matTooltipPosition="above"
            matTooltipClass="custom-mat-tooltip">
            <img src="/assets/Active.svg" alt="" class="icon">
            Active
          </button>
          <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'arrived'}"
            (click)="selectButton('arrived')" matTooltip="Show all shipments which arrived at discharge Port."
            matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
            <img src="/assets/Confirmed.svg" alt="" class="icon">
            Arrived
          </button>
        </div>

      </div>
    </ng-container>

    <ng-container *ngIf="selectedTab === shipmentTabbedViewEnum.roadTab">
      <button mat-button class="transport-btn" [class.selected]="isSelected" (click)="onTrackTransportLoadsClick()"
        matTooltip="Open live tracking map." matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
        <img src="/assets/Truck White.svg" alt="" class="icon truck-icon">
        Live Tracking
      </button>
    </ng-container>
  </div>

  <ng-container *ngIf="selectedTab === shipmentTabbedViewEnum.oceanTab">
    <ng-container *ngIf="!viewOceanMap">
      <app-shipment-list></app-shipment-list>
    </ng-container>
    <ng-container *ngIf="viewOceanMap">
      <app-ocean-tracking [startLocation]="startLocation" [endLocation]="endLocation" [vesselDataList]="vesselDataList"
        [shipmentDataList]="shipmentDataList" [mapView]="mapView">
      </app-ocean-tracking>
    </ng-container>
  </ng-container>


  <ng-container *ngIf="selectedTab === shipmentTabbedViewEnum.roadTab">
    <ng-container *ngIf="!viewTrucks">
      <app-booking-rolling-plan [selectedCustomer]="selectedCustomer"></app-booking-rolling-plan>
    </ng-container>
    <ng-container *ngIf="viewTrucks">
      <app-road-tracking [startLocation]="[51.505, -0.09]" [endLocation]="[51.515, -0.1]" [vehicles]="roadDataList">
      </app-road-tracking>
    </ng-container>
  </ng-container>

</div>
