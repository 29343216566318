export interface IBookingEditModel {
  appBookingCode: string;
  lineNumber: number;
  bookingNo: string;
  vesselScheduleCode: string;
  carrierCode: string;
  loadPortCode: string;
  customerCode: string;
  createdBy: string;
  clientReference: string;
  comments: string;
  commodityCode: string;
  loadingPoint1: string;
  loadingPoint2: string;
  preVerificationKey: string;
  solasMethod: string;
  confirmedPackDate: Date | null;
  dischargePortCode: string;
  receiverCode: string;
  containerTypeCode: string;
  quantity: number;
  weight: number;
  ventilationCode: string;
  temperatureCode: string;
  temptale: boolean;
  temptaleDescription: string;
  temptaleQty: number;
  sterilized: boolean;
  stepUp: string;
  reason: string;
  recType: string;
  bookingCount: number;
  lateStack: boolean;
  marketCode: string;
  sicutoff: boolean;
}
export class BookingEditModel implements IBookingEditModel {
  appBookingCode: string = '';
  lineNumber: number = 0;
  bookingNo: string = '';
  vesselScheduleCode: string = '';
  carrierCode: string = '';
  loadPortCode: string = '';
  customerCode: string = '';
  createdBy: string = '';
  clientReference: string = '';
  comments: string = '';
  commodityCode: string = '';
  cnfFinalDestination: string = '';
  loadingPoint1: string = '';
  loadingPoint2: string = '';
  preVerificationKey: string = '';
  solasMethod: string = '';
  confirmedPackDate: Date | null = null;
  dischargePortCode: string = '';
  receiverCode: string = '';
  containerTypeCode: string = '';
  quantity: number = 0;
  weight: number = 0;
  ventilationCode: string = '';
  temperatureCode: string = '';
  temptale: boolean = false;
  temptaleDescription: string = '';
  temptaleQty: number = 0;
  sterilized: boolean = false;
  stepUp: string = '';
  reason: string = '';
  recType: string = '';
  bookingCount: number = 0;
  lateStack: boolean = false;
  marketCode: string = '';
  sicutoff: boolean = false;
}
