import { Component, Input, OnInit } from '@angular/core';
import { detailTabbedViewEnum } from '../models/detail-view-enum';
import { CommunicationService } from '../service/communication/communication.service';
import { AlertService } from '../service/alerts/alert.service';
import { Alert } from '../models/alerts/alerts';
import { ShipmentModel, ShipmentTranferGRRefContainerNo } from '../models/shipment/shipment-data-model';
import { ShipmentService } from '../service/shipment/shipment.service';
import { DocumentService } from '../service/documents/document.service';
import { TransportMode } from '../enums/transport-mode.enum';
import { ShipmentTrackingHeader, ShipmentTrackingVessel } from '../models/shipment/shipment-tracking-header-model';
import { OceanTrackingViewEnum } from '../enums/ocean-tracking-view-enum';
import { LegendSetup } from '../models/legend';
import { Subject, takeUntil } from 'rxjs';
import { RouteEvents, RouteInformation } from '../models/shipment/route-information-model';

@Component({
  selector: 'app-detailed-shipment',
  templateUrl: './detailed-shipment.component.html',
  styleUrls: ['./detailed-shipment.component.css']
})
export class DetailedShipmentComponent implements OnInit {
  protected detailTabbedViewEnum = detailTabbedViewEnum
  protected detailTabbedViewEnumSelected = detailTabbedViewEnum.trackingTab
  protected alertData: Alert[] = [];
  public shipmentData: ShipmentModel = new ShipmentModel;
  protected transportMode: TransportMode = TransportMode.Ocean;
  protected voyageCoordinates: ShipmentTrackingHeader[] = [];
  protected startLocation: L.LatLngExpression = [0, 0];
  protected endLocation: L.LatLngExpression = [0, 0];
  documentParameter: string = '';
  shipmentDataList: ShipmentTrackingHeader = new ShipmentTrackingHeader;
  vesselDataList: ShipmentTrackingVessel = new ShipmentTrackingVessel;
  grRefContainerData: ShipmentTranferGRRefContainerNo = new ShipmentTranferGRRefContainerNo;
  public mapView: OceanTrackingViewEnum = OceanTrackingViewEnum.shipmentDetail;
  public actualPathCoordinates: L.LatLngExpression[] = [];

  public routeHeaderData: RouteInformation = new RouteInformation;
  isLoading: boolean = true;
  hasTrackingEvents: boolean = true;
  errorMessage: string = '';
  public canLoad: boolean = false;

  public events: RouteEvents[] = []

  legend: LegendSetup[] =[
    { iconClass: 'icon ship-icon eta', text: 'No ETA change on vessel.' },
    { iconClass: 'icon ship-icon eta-changed', text: 'ETA moved to a later date.' },
    { iconClass: 'icon ship-icon line-expected', text: 'Expected vessel route' },
    { iconClass: 'icon ship-icon line-actual', text: 'Actual route' },
  ];
  @Input() selectedTab = this.detailTabbedViewEnumSelected
  private destroy$ = new Subject<void>();

  constructor(
    private communicationService: CommunicationService,
    private alertDataService: AlertService,
    private shipmentDataService: ShipmentService,
    private documentService: DocumentService,
    //private cdr: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {

    this.communicationService.grRefcontainerNoParameter$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async data => {
          this.grRefContainerData = data;
          try {
            await this.getShipemntsForGRRefContainerNo(this.grRefContainerData);
            this.isLoading = false;
            this.canLoad = true;
          } catch (error) {
            console.error('Error fetching shipment data:', error);
            // Handle the error appropriately
          }
        }
      });
  }

  async getShipemntsForGRRefContainerNo(data: ShipmentTranferGRRefContainerNo): Promise<void> {
    return new Promise((resolve, reject) => {
      this.shipmentDataService.getShipmentsForGRRefContainerNo(data.grReferenceNo, data.containerNo)
        .subscribe({
          next:async (data) => {
            this.shipmentData = data;
            await this.getShipmentEvents();
            await this.getCurrentRoute(this.shipmentData.shippingUnitCode, this.shipmentData.lineNumber.toString());
            this.popContainer();
            this.popVessel();
            resolve(); // Resolve the promise when all methods have run
        },
        error: (error) => {
          reject(error); // Reject the promise on error
        }
      });
    });
  }

  public convertToDate(dateString: string): Date {
    let newDate = new Date(dateString);

    return newDate
  }

  public checkDateNotEmpty(date: Date): boolean {
    let newDate = new Date(date); // Convert to Date object

    if (!(newDate instanceof Date) || isNaN(newDate.getTime())) {
      console.error('Provided date is not a valid Date object:', date);
      return false; // Handle invalid date
    }

    console.log(this.shipmentData);
    return newDate.toISOString() === '0001-01-01T00:00:00.000Z';
  }

  popVessel() {
    this.vesselDataList.vesselCode = this.shipmentData.seaRatesVesselCode;
    this.vesselDataList.vesselName = this.shipmentData.seaRatesVesselName;
    this.vesselDataList.currentLatitude = this.shipmentData.currentLatitude;
    this.vesselDataList.currentLongitude = this.shipmentData.currentLongitude;
    this.vesselDataList.noOfContainers = 1
    this.vesselDataList.eta = this.shipmentData.containerTrackingETA;
    this.vesselDataList.lastModifiedDateTime = this.shipmentData.lastModifiedDateTime;
    this.vesselDataList.bookingReference = this.shipmentData.shippingUnitCode
    this.vesselDataList.bookingLineReference = this.shipmentData.lineNumber;
  }

  popContainer() {
    this.shipmentDataList.id = this.shipmentData.id;
    this.shipmentDataList.bookingReference = this.shipmentData.shippingUnitCode;
    this.shipmentDataList.bookingLineReference = this.shipmentData.lineNumber;
    this.shipmentDataList.containerNumber = this.shipmentData.containerNumber;
    this.shipmentDataList.currentLatitude = this.shipmentData.currentLatitude;
    this.shipmentDataList.currentLongitude = this.shipmentData.currentLongitude;
    this.shipmentDataList.trackContainer = this.shipmentData.trackContainer;
    this.shipmentDataList.eta = this.shipmentData.containerTrackingETA;
    this.shipmentDataList.lastGpsUpdate = null;
    this.shipmentDataList.vesselCode = this.shipmentData.seaRatesVesselCode;
    this.shipmentDataList.vesselName = this.shipmentData.seaRatesVesselName;
    this.shipmentDataList.lastModifiedDateTime = this.shipmentData.lastModifiedDateTime;
    this.shipmentDataList.consignee = this.shipmentData.consignee;
    this.shipmentDataList.destination = this.shipmentData.destination;
    this.shipmentDataList.grReference = this.shipmentData.grReference;
  }

  protected getDelayInDays(eta: Date, etd: Date): number {
    const oneDay = 24 * 60 * 60 * 1000;
    const etaDate = new Date(eta).getTime();
    const etdDate = new Date(etd).getTime();

    return Math.round((etaDate - etdDate) / oneDay);
  }

  getCurrentRoute(bookingRef: string, bookingLineRef: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.shipmentDataService.getShipmentsTrackingHistory(bookingRef, bookingLineRef)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data) => {
            const getPathCoordinates: L.LatLngExpression[] = data.map((point: any) => {
              return [point.latitude, point.longitude] as L.LatLngExpression;
            });
            this.actualPathCoordinates = getPathCoordinates;
            resolve(); // Resolve the promise when data is successfully fetched
          },
          error: (error) => {
            console.error('Error fetching route data:', error);
            reject(error); // Reject the promise on error
          }
        });
    });
  }

  private getShipmentEvents(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.shipmentDataService.getShipmentTrackingHeaders(this.shipmentData.shippingUnitCode, this.shipmentData.containerNumber)
        .subscribe({
          next: (data) => {
            if (data) {
              this.shipmentDataService.getShipmentRouteInformation(data.routeInformationMediaReadLink)
                .subscribe({
                  next: (route) => {
                    this.routeHeaderData = route;
                    this.events = route.routeEvents;
                    resolve(); // Resolve the promise when route data is successfully fetched
                  },
                  error: (error) => {
                    console.error('Error fetching route information:', error);
                    reject(error); // Reject if there's an error fetching route information
                  }
                });
            } else {
              resolve(); // Resolve if no data is returned
            }
          },
          error: (error) => {
            console.error('Error fetching tracking headers:', error);
            reject(error); // Reject if there's an error fetching tracking headers
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
