<div class="tracking-map-wrapper">
  <div class="tracking-map-container">

    <div class="container">
      <div class="map-container-wrapper">
        <div id="map" class="map-container"></div>
      </div>
    </div>
    <div class="legend">

        <div class="legend-header">Ocean Legend:</div>
        <div class="legend-item" *ngFor="let item of legend">
          <div [ngClass]="item.iconClass"></div>
          <span>{{ item.text }}</span>
        </div>
    </div>
  </div>
</div>
