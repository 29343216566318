import { Component, ViewChild, ChangeDetectorRef, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SchedulesService } from '../../service/schedules/schedules.service';
import { IScheduleListModel, ScheduleListModel } from '../../models/schedules/schedule-list-model';
import { FormControl } from '@angular/forms';
import { CommunicationService } from '../../service/communication/communication.service';
import { FilterData } from '../../models/list-model';
import { FilterEnum } from '../../models/filter-enum';
import { ColumnSetup } from '../../models/column-setup';
import { detailView } from '../../models/detail-view-enum';
import { of, Subject } from 'rxjs';
import { DataFilter, FilterTransferModel } from '../../models/filter-models';
import { takeUntil, catchError, distinctUntilChanged, skip } from 'rxjs/operators';
import { ApiUserService } from '../../service/user/api-user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertNotificationModel } from '../../models/alerts/alert-notification-model';
import { AlertSubscriptionModel } from '../../models/alerts/alert-subscription-model';
import { AlertSubscriptionInputModel } from '../../models/alerts/alert-subscription-input-model';
import { SubscriptionComponent } from '../../dialog/subscription/subscription.component';
import { AlertService } from '../../service/alerts/alert.service';
import { AppComponent } from '../../app.component';
import { BookingService } from '../../service/booking/booking.service';
import { BookingDataTransferModel } from '../../models/booking-data-model';
import { BookingEditModel } from '../../models/bookings/booking-edit-model';
import { LateStackWarningComponent } from '../../dialog/late-stack-warning/late-stack-warning.component';

@Component({
  selector: 'app-booking-voyage-search',
  templateUrl: './booking-voyage-search.component.html',
  styleUrl: './booking-voyage-search.component.css'
})
export class BookingVoyageSearchComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  @ViewChild(MatPaginator) paginator = {} as MatPaginator;
  @ViewChild(MatSort) sort = {} as MatSort;
  dataSource = new MatTableDataSource();

  @Input() bookingData!: BookingEditModel;
  @Input() isReadOnly=false;

  @Output() bookingDataOutput = new EventEmitter<BookingEditModel>();
  filter!: FilterData;
  filterParameter!: FilterData;
  savedFilters: DataFilter[] = [];
  currentFilter: DataFilter = new DataFilter();
  filterData: FilterData = {};
  isLoading = false;

  FilterEnum = FilterEnum;
  selectedButton = "";

  detailView = detailView;

  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];
  isSelected: boolean = false;

  scheduleList: ScheduleListModel[] = []

  dateFrom? = new Date;
  dateTo? = new Date;

  _snackBar: any;
  horizontalPosition: any;
  verticalPosition: any;

  constructor(
    private schedulesService: SchedulesService, 
    private bookingService: BookingService, 
    private cdr: ChangeDetectorRef, 
    private communicationService: CommunicationService, 
   
    private dialog: MatDialog, 
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer, 
    private alertDataService: AlertService, 
    private appComponent: AppComponent
  )
  {}

  ngOnInit(): void {
    this.configureColumns()
    this.loadData();
  }

  //Load data with filter? - Do not load on first run
  loadData(): void {
    //Filter the data according to add-booking component filters or data received from schedules or edit/add booking line
    this.bookingService.addBookingSendFilterData$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (filterReceived) => {
          this.isLoading = true;
          this.currentFilter.filter = filterReceived.filter;
          this.currentFilter.id = filterReceived.filterId;
          this.dateFrom = filterReceived.startDate;
          this.dateTo = filterReceived.endDate;
          this.updateData();          
        }
      });


    if (this.bookingData) {
      if (this.bookingData.vesselScheduleCode !== '') {

        this.currentFilter.filter =  {
            sailingScheduleHeaderCode: [this.bookingData.vesselScheduleCode],
            carrierCode: [this.bookingData.carrierCode],
            dischargePortCode: [this.bookingData.dischargePortCode],
            loadPortCode: [this.bookingData.loadPortCode]
        }


        this.schedulesService.getScheduleList(this.currentFilter.id, this.currentFilter.filter).subscribe(data => {
          this.configureColumns();
          this.scheduleList = data;
          this.dataSource.data = this.scheduleList.filter(data => data.sailingScheduleHeaderCode === this.bookingData.vesselScheduleCode && data.loadPortCode === this.bookingData.loadPortCode && data.dischargePortCode === this.bookingData.dischargePortCode && data.carrierCode === this.bookingData.carrierCode);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.isLoading = false;
          this.dataSource.data[0]
          this.isSelected = true;
          this.isReadOnly = true;
          this.bookingDataOutput.emit(this.bookingData);
        });
      }
    }
  }

  async updateAndFilterData() {
    await this.updateData();
    this.dataSource.data = this.scheduleList.filter(data =>
      data.sailingScheduleHeaderCode === this.bookingData.vesselScheduleCode &&
      data.dischargePortCode === this.bookingData.dischargePortCode &&
      data.loadPortCode === this.bookingData.loadPortCode &&
      data.carrierCode === this.bookingData.carrierCode
    );
  }

  configureColumns(): void {
    this.displayedColumns = [
      { name: 'selected', header: '', type: 'bool', visible: true },
      { name: 'serviceName', header: 'Service Name', type: 'string', visible: true },
      { name: 'voyageNo', header: 'Voyage Number', type: 'string', visible: true },
      { name: 'loadPort', header: 'Load Port', type: 'string', visible: true },
      { name: 'carrier', header: 'Carrier', type: 'string', visible: true },
      { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true },
      { name: 'dischargePort', header: 'Discharge Port', type: 'string', visible: true },
      { name: 'stackEnd', header: 'Stack End', type: 'date', visible: true },
      { name: 'loadETD', header: 'Load ETD', type: 'date', visible: true },
    ]
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  //TODO: What must happen here
  checkdata(): void {

  }

  //Call to get the schedule data
  updateData(filterData?: FilterData): void {

    this.schedulesService.getScheduleList(this.currentFilter.id, this.currentFilter.filter, this.dateFrom, this.dateTo).subscribe(data => {
      this.configureColumns();
      this.scheduleList = data;
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    });

    this.filterParameter = {};
  }

  showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: ColumnSetup[]) {
    return columns.map(col => col.name);

  }

  public selectRow(row: any): void {
    row.selected = !row.selected; // Toggle the selected value
    if (row.selected) {
      //console.log('Checkbox is now selected');
      // Perform actions when the checkbox is selected
      this.isSelected = true
      this.dataSource.data = this.scheduleList.filter(data => data.sailingScheduleHeaderCode == row.sailingScheduleHeaderCode && data.loadPortCode === row.loadPortCode && data.dischargePortCode === row.dischargePortCode && data.carrierCode === row.carrierCode);

      this.bookingData.vesselScheduleCode = row.sailingScheduleHeaderCode;
      //this.bookingData.vesselScheduleLineNumber = row.lineNumber;
      this.bookingData.carrierCode = row.carrierCode;
      this.bookingData.loadPortCode = row.loadPortCode;
      this.bookingData.dischargePortCode = row.dischargePortCode;

      this.handleNewBooking(row.stackEnd,row.siCutoffDateTime,row.loadETD)
      // this.bookingDataOutput.emit(this.bookingData);

    } else {
      //console.log('Checkbox is now not selected');
      //TODO: Why am I changing the data source data instead of just clearing the filter
      this.dataSource.data = this.scheduleList;
      this.bookingData.vesselScheduleCode = '';
      this.bookingData.carrierCode = '';
      this.bookingData.loadPortCode ='';
      this.bookingData.dischargePortCode = '';

      this.bookingDataOutput.emit(this.bookingData);
      this.isSelected = false
    }
  } 


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }



  protected async handleNewBooking(stackDate: Date,siCutOff: Date, loadETD: Date) {
    // Ensure stackDate is a Date object
    const dateToCheckstackDate = new Date(stackDate);
    const dateToChecksiCutOff = new Date(siCutOff);
    const now = new Date();
    const timeDifferenceLateStack = dateToCheckstackDate.getTime() - now.getTime(); 
    const timeDifferenceSiCutOff = dateToChecksiCutOff.getTime() - now.getTime(); 
    const SIX_HOURS = 6 * 60 * 60 * 1000; // Constant for 6 hours in milliseconds
    const EIGHTEEN_HOURS = 18 * 60 * 60 * 1000; // Constant for 18 hours in milliseconds
    const INVALID_DATE = -60000000000000;
    try {
      // Show SI Cut Off Warning if needed
      if (timeDifferenceSiCutOff <= EIGHTEEN_HOURS && timeDifferenceSiCutOff >= INVALID_DATE) {
        const siCutOffConfirmed = await this.showWarningDialog(
          'SI Cut Off Warning',
          'This request is close to the SI Cut Off closure. By proceeding you acknowledge acceptance of possible missing SI Cut Off. Do you wish to proceed?'
        );
        if (!siCutOffConfirmed) return this.resetSelection();
        this.bookingData.sicutoff = true;
      }

      // Show Late Stack Warning if needed
      if (timeDifferenceLateStack <= SIX_HOURS) {
        const lateStackConfirmed = await this.showWarningDialog(
          'Late Stack Warning',
          'This request is close to the Stack closure and can incur additional costs. By proceeding you acknowledge acceptance of possible late Stack and related charges should they be incurred by GoReefers. Do you wish to proceed?'
        );
        if (!lateStackConfirmed) return this.resetSelection();
        this.bookingData.lateStack = true;
      }

      // Emit booking data if all warnings (if applicable) are confirmed
      this.bookingDataOutput.emit(this.bookingData);
    } catch (error) {
      console.error('Error handling booking warnings:', error);
    }
  }

  // Helper function to open warning dialogs
  async showWarningDialog(title: string, message: string): Promise<boolean> {
    return this.dialog.open(LateStackWarningComponent, { data: { title, message } })
      .afterClosed()
      .toPromise();
  }

  // Reset selection and data source when dialog is declined
  resetSelection() {
    this.isSelected = false;
    this.dataSource.data = this.scheduleList;
  }
}
