import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TransportMode } from '../enums/transport-mode.enum';
import { ScheduleVoyageCoordinates } from '../models/schedules/schedule-voyage-coordinates';
import { RoadData } from '../models/road-data-model';
import { Subject } from 'rxjs';
import { ShipmentTrackingHeader, ShipmentTrackingVessel } from '../models/shipment/shipment-tracking-header-model';
import { OceanTrackingViewEnum } from '../enums/ocean-tracking-view-enum';
import { ILegendSetup, LegendSetup } from '../models/legend';

@Component({
  selector: 'app-tracking-map',
  templateUrl: './tracking-map.component.html',
  styleUrls: ['./tracking-map.component.css']
})
export class TrackingMapComponent implements OnInit, OnDestroy {

  @Input() vehicleLocation!: L.LatLngExpression;
  @Input() startLocation: L.LatLngExpression = [0, 0]; 
  @Input() endLocation!: L.LatLngExpression;
  @Input() mode!: TransportMode;
  @Input() shipmentDataList: ShipmentTrackingHeader[] = [];
  @Input() vesselDataList: ShipmentTrackingVessel[] = [];
  @Input() vehicles: RoadData[] = [];
  @Input() isOverview!: boolean;
  @Input() legend: ILegendSetup[] =[]
  @Input() mapView: OceanTrackingViewEnum = OceanTrackingViewEnum.overview;
  @Output() vesselDataChanged = new EventEmitter<ShipmentTrackingVessel[]>();
  @Output() vehicleDataChanged = new EventEmitter<RoadData[]>();
  @Output() shipmentDataChanged = new EventEmitter<ShipmentTrackingHeader[]>();

  private destroy$ = new Subject<void>();
  TransportMode = TransportMode;
  legendRoad: LegendSetup[] = [
    { iconClass: 'icon truck-icon green-full', text: 'Container loaded & on route to Port' },
    { iconClass: 'icon truck-icon green-empty', text: 'Truck on route with empty container' },
    { iconClass: 'icon truck-icon green-no', text: 'Truck on route to empty depot' },
    { iconClass: 'icon truck-icon red-full', text: 'Truck stationary with loaded container' },
    { iconClass: 'icon truck-icon red-empty', text: 'Truck stationary with empty container' },
    { iconClass: 'icon truck-icon red-no', text: 'Truck stationary with no container' },
  ];

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    console.log("This is the current Transport Mode: ", this.mode);
    if(this.mode === TransportMode.Road){
      this.legend = this.legendRoad;
    }
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(): void {
    this.cdRef.detectChanges();
  }

  updateVehicleData(newVehicles: RoadData[]) {
    this.vehicleDataChanged.emit(newVehicles);
  }

  updateShipemtnData(newShipment: ShipmentTrackingHeader[]) {
    this.shipmentDataChanged.emit(newShipment);
  }

  updateVesselData(newVessel: ShipmentTrackingVessel[]) {
    this.vesselDataChanged.emit(newVessel);
  }  

}
