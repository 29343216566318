import { Injectable } from '@angular/core';
import { signal } from '@angular/core';

export interface UserActivity {
  action: string;
  timestamp: Date;
}

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  private activitiesSignal = signal<UserActivity[]>([]);
  private maxActivities = 30; // Maximum activities to track

  get activities() {
    return this.activitiesSignal;
  }

  logActivity(action: string) {
    const newActivity: UserActivity = {
      action,
      timestamp: new Date()
    };
    const currentActivities = this.activitiesSignal();

    // Add the new activity
    const updatedActivities = [...currentActivities, newActivity];

    // Remove the oldest activities if the limit is exceeded
    if (updatedActivities.length > this.maxActivities) {
      updatedActivities.splice(0, updatedActivities.length - this.maxActivities);
    }

    this.activitiesSignal.set(updatedActivities);
  }
}
