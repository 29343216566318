import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmailService } from '../service/emails/email.service';
import { EmaiLModel } from '../models/emails/email-model';
import { MatDialog } from '@angular/material/dialog';
import { InformationComponent } from '../dialog/information/information.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent {
protected email =new EmaiLModel

  constructor(private http: HttpClient, private emailService: EmailService, private dialog: MatDialog) { }

  submitForm() {
    this.emailService.sendContactEmail(this.email).subscribe(
      res => {
        const dialogRef = this.dialog.open(InformationComponent, {
          width: '350px',
          height: '350px',
          data: {
            title: 'Success',
            message: 'Your message has been successfully sent and our team will get back to you as soon as possible'
          }
        });

        dialogRef.afterClosed().subscribe(() => {
          window.location.href = '/'; // Redirect to home page
        });
      },
      error => {
        const dialogRef = this.dialog.open(InformationComponent, {
          width: '350px',
          height: '350px',
          data: {
            title: 'Error',
            message: 'There was an error sending your message. Please try again later.'
          }
        });
      }
    );
  }  
}
