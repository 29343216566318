import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import { RoadData } from '../../models/road-data-model';
import { JobStatus } from '../../models/road-data-model';
import { DatePipe } from '@angular/common';
import { LegendSetup } from '../../models/legend';

@Component({
  selector: 'app-road-tracking',
  templateUrl: './road-tracking.component.html',
  styleUrls: ['./road-tracking.component.css'],
  providers: [DatePipe]
})
export class RoadTrackingComponent implements OnInit, OnDestroy {
  constructor(private datePipe: DatePipe) { }

  private map!: L.Map;
  private vehicleMarkers: Map<string, L.Marker> = new Map();
  public noVehiclesMessage: boolean = false;
  private mapInitialized: boolean = false;
  public isLoading = true;

  legendRoad: LegendSetup[] = [
    { iconClass: 'icon truck-icon green-full', text: 'Container loaded & en route to Port' },
    { iconClass: 'icon truck-icon green-empty', text: 'Truck en route with empty container' },
    { iconClass: 'icon truck-icon green-no', text: 'Truck en route to empty depot' },
    { iconClass: 'icon truck-icon red-full', text: 'Truck stationary with loaded container' },
    { iconClass: 'icon truck-icon red-empty', text: 'Truck stationary with empty container' },
    { iconClass: 'icon truck-icon red-no', text: 'Truck stationary with no container' },
    // { iconClass: 'icon truck-icon full', text: 'Container Loaded' },
    // { iconClass: 'icon truck-icon empty', text: 'En Route with empty' },
    { iconClass: 'icon truck-icon to-empty', text: 'Complete' }

  ];
  

  @Input() vehicles: RoadData[] = [];  // Defaulting to an empty array
  @Input() startLocation!: L.LatLngExpression;
  @Input() endLocation!: L.LatLngExpression;

  ngOnInit(): void {
    
    setTimeout(() => {
      this.initMap();
      console.log('This message is delayed by 100 milliseconds');
    }, 100);  
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['vehicles']) {
      this.updateVehiclesOnMap();
    }
    console.log(this.vehicles);
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
  }

  private initMap(): void {
    if (!this.startLocation) {
      this.startLocation = [0, 0];
    }

    const southWest = L.latLng(-90, -180);
    const northEast = L.latLng(90, 180);
    const bounds = L.latLngBounds(southWest, northEast);

    let minZoom = 3;

    if (window.innerWidth <= 576) {
      minZoom = 1.5;
    } else if (window.innerWidth <= 1024) {
      minZoom = 2;
    }

    // Initialize map
    this.map = L.map('map', { worldCopyJump: true, maxBounds: bounds, trackResize: true }).setView(this.startLocation, minZoom);

    // Add tile layer
    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      maxZoom: 25,
      minZoom: minZoom,
      attribution: 'Map data &copy; OpenStreetMap contributors',
      noWrap: true,
    }).addTo(this.map);

    this.mapInitialized = true;

    if (this.vehicles.length > 0) {
      this.vehicles.forEach(vehicle => {
        this.updateVehicleMarker(vehicle);
      });
      this.zoomToMarkers();
    } else {
      this.setDefaultZoom();
    }

    window.addEventListener('resize', () => {
      this.adjustZoomForResize();
      setTimeout(() => this.map.invalidateSize(), 0);
    });

  }

  private adjustZoomForResize(): void {
    // Determine the new minimum zoom level based on screen width
    const newMinZoom = window.innerWidth <= 576 ? 1.5 : window.innerWidth <= 1024 ? 2 : 3;
    const newZoom = window.innerWidth <= 576 ? 4 : window.innerWidth <= 1024 ? 5 : 5;
    // Only update if the minZoom has changed
    if (this.map.getMinZoom() !== newMinZoom) {
      // Set the new minimum zoom and reset the current zoom level to match it
      this.map.setMinZoom(newMinZoom);
      this.map.setZoom(newZoom);
    }

    // Ensure the map is properly resized to avoid visual glitches
    this.map.invalidateSize();
  }


  private updateVehiclesOnMap(): void {
    this.removeAllMarkers();
    if (!this.mapInitialized) {
      console.warn("Map is not initialized yet!");
      return;
    }

    if (this.vehicles.length > 0) {
      this.vehicles.forEach(vehicle => {
        this.updateVehicleMarker(vehicle);
      });
      this.zoomToMarkers();
      this.noVehiclesMessage = false;
    } else {
      this.noVehiclesMessage = true;
      this.removeAllMarkers();
      this.removeNoVehiclesMessage();
      this.showNoVehiclesMessage();
      this.setDefaultZoom();
    }
  }

  private removeAllMarkers(): void {
    if (this.map) {
      this.map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          this.map.removeLayer(layer);
        }
      });
    }
  }

  private showNoVehiclesMessage(): void {
    this.removeNoVehiclesMessage();
    const messageContent = 'No vehicles to display';
    const noVehiclesMarker = L.marker([-33.92574199874307, 18.65486950525504], {
      icon: L.divIcon({
        className: 'no-vehicles-message',
        html: `<div style="
          background-color: white;
          border: 2px solid #f00;
          padding: 5px;
          border-radius: 5px;
          color: red;
          font-weight: bold;
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
          ">${messageContent}</div>`,
        iconSize: [150, 50],
        iconAnchor: [75, 25],
      }),
    }).addTo(this.map);
  }

  private removeNoVehiclesMessage(): void {
    if (this.map) {
      this.map.eachLayer(layer => {
        if (layer instanceof L.Marker) {
          const popup = layer.getPopup();
          if (popup && popup.getContent() === 'No vehicles to display') {
            this.map.removeLayer(layer);
          }
        }
      });
    }
  }

  private updateVehicleMarker(vehicle: RoadData, newsize: [number, number] = [24, 24]): void {
    const color = this.getVehicleColor(vehicle);

    // If the vehicle status is not "InProgress" or "Completed", remove the marker
    if (![JobStatus.InProgress, JobStatus.Completed].includes(vehicle.job.status)) {
      this.removeVehicleMarker(vehicle.id);
      return;
    }

    // Special case for "Completed" status after 1 day
    if (this.CheckCompletedAfter1Day(vehicle)) {
      this.removeVehicleMarker(vehicle.id);
      return;
    }

    // If the marker exists, update its position and content, otherwise create a new marker
    this.loadAndModifySVG(vehicle.job.nextStep?.type, color).then(svgContent => {
      const icon = L.divIcon({
        className: 'vehicle-icon',
        html: svgContent,
        iconSize: [32, 32],
        iconAnchor: [13, 13], // Adjust anchor to the center of the icon
      });

      const marker = L.marker(
        [vehicle.telemetry.currentLocation.latitude, vehicle.telemetry.currentLocation.longitude],
        { icon }
      )
        .addTo(this.map)
        .bindPopup(this.createPopupContent(vehicle));

      this.vehicleMarkers.set(vehicle.id, marker);
    }).catch(err => {
      console.error('Error loading SVG for vehicle', err);
    });
  }

  private CheckCompletedAfter1Day(vehicle: RoadData): boolean {
    if (vehicle.job.status === JobStatus.Completed) {
      const completedDate = new Date(vehicle.job.steps[vehicle.job.steps.length - 1]?.completedDate || '');
      const currentDate = new Date();
      const oneDayMs = 24 * 60 * 60 * 1000;

      if (completedDate && (currentDate.getTime() - completedDate.getTime()) > oneDayMs) {
        return true;
      }
      return false;
    }
    return false;
  }

  private removeVehicleMarker(vehicleId: string): void {
    const marker = this.vehicleMarkers.get(vehicleId);
    if (marker) {
      this.map.removeLayer(marker);
      this.vehicleMarkers.delete(vehicleId);
    }
  }

  private createPopupContent(vehicle: RoadData): string {
    const currentStep = vehicle.job?.nextStep || {};
    const lastCompletedStep = vehicle.job?.steps?.length > 0 ? vehicle.job.steps[vehicle.job.steps.length - 1] : null;
    const formattedPackDate = this.datePipe.transform(vehicle.job.packDate, 'dd MMM yyyy');

    // Step 1: Get distinct job step names for the current vehicle
    const distinctJobStepNames = Array.from(new Set(vehicle.job.steps.map(step => step.name)));

    // Step 2: Format the distinct names into a string
    const distinctNamesString = distinctJobStepNames.join('<br />') || 'N/A';

    return ` 
      <div>
        <h4>Load Details</h4>
        <p>
          <strong>Load Number:</strong> ${vehicle.job.loadNumber}<br />
          <strong>Client Reference:</strong> ${vehicle.job.clientRef}<br />
          <strong>Vessel Name:</strong> ${vehicle.job.vesselName}<br />
          <strong> Pack Date: </strong> ${formattedPackDate || 'N/A'}<br />
          <strong>Status:</strong> ${this.getJobStatus(vehicle.job.status)}<br />
          <strong>Planned Routing:</strong> <br />${distinctNamesString}<br />
          <strong>Next Location:</strong> ${currentStep?.name || 'N/A'}<br />
        </p>
      </div>
    `;
  }

  private getJobStatus(status: JobStatus): string {
    switch (status) {
      case JobStatus.New:
        return 'New';
      case JobStatus.Accepted:
        return 'Accepted';
      case JobStatus.InProgress:
        return 'In Progress';
      case JobStatus.Completed:
        return 'Completed';
      case JobStatus.Rejected:
        return 'Rejected';
      case JobStatus.Cancelled:
        return 'Cancelled';
      default:
        return 'Unknown Status';
    }
  }

  private getVehicleColor(vehicle: RoadData): string {
    if (vehicle.job.status === JobStatus.Completed) {
      return '#162a3e'; //Black
    }

    if (vehicle.job.status === JobStatus.InProgress) {
      if (vehicle.telemetry === null) {
        return '#DC6868'; //Red
      }

      const prevLoc = vehicle.telemetry.previousLocation;

      if (prevLoc === null || prevLoc === undefined) {
        if (vehicle.telemetry.speed === 0) {
          return '#DC6868' //Red
        }
        else {
          return '#36DE6F';
        }
      }

      const currentLoc = new Date(vehicle.telemetry.currentLocation.at).getTime();
      const prevLocTime = new Date(prevLoc.at).getTime();
      const timeDifference = currentLoc - prevLocTime;

      return (timeDifference > 3600000 && vehicle.telemetry.speed === 0) ? '#DC6868' :   //Red
        (vehicle.telemetry.speed > 0 ? '#36DE6F' : '#DC6868') //Green - red);
    }

    return '#DC6868'

  }

  private loadAndModifySVG(stepType: number, color: string): Promise<string> {
    let svgUrl = '';

    switch (stepType) {
      case 0:
        svgUrl = '../../assets/TruckNoContainerV2.svg';
        break;
      case 1:
        svgUrl = '../../assets/TruckNoContainerV2.svg';
        break;
      case 2:
        svgUrl = '../../assets/TruckEmptyContainerV2.svg';
        break;
      case 3:
        svgUrl = '../../assets/TruckEmptyContainerV2.svg';
        break;
      case 4:
        svgUrl = '../../assets/TruckFullContainerV2.svg';
        break;
      case 5:
        svgUrl = '../../assets/TruckFullContainerV2.svg';
        break;
      case 6:
        svgUrl = '../../assets/TruckFullContainerV2.svg';
        break;
      case 7:
        svgUrl = '../../assets/TruckFullContainerV2.svg';
        break;
      case 8:
        svgUrl = '../../assets/TruckFullContainerV2.svg';
        break;
      case 9:
        svgUrl = '../../assets/TruckFullContainerV2.svg';
        break;
      case 10:
        svgUrl = '../../assets/TruckFullContainerV2.svg';
        break;
      case 11:
        svgUrl = '../../assets/TruckEmptyContainerV2.svg';
        break;
      case 12:
        svgUrl = '../../assets/TruckFullContainerV2.svg';
        break;
     
      default:
        svgUrl = '../../assets/TruckNoContainerV2.svg';
        return Promise.resolve('');
    }

    return fetch(svgUrl)
      .then(response => response.text())
      .then(svgContent => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
        const svgElement = svgDoc.documentElement as unknown as SVGElement;
        svgElement.setAttribute('width', '100%');
        svgElement.setAttribute('height', '100%');

        const paths = svgElement.querySelectorAll('path');
        paths.forEach((path: SVGPathElement) => {
          path.setAttribute('fill', color);
        });

        return svgElement.outerHTML;
      })
      .catch(error => {
        console.error('Error loading or modifying SVG:', error);
        return '';
      });
  }

  private zoomToMarkers(): void {
    let activeVehicles = this.vehicles.filter(vehicle => !this.CheckCompletedAfter1Day(vehicle));
    console.log(activeVehicles[0].job.status)
    //activeVehicles = activeVehicles.filter(x => ![JobStatus.InProgress, JobStatus.Completed].includes(x.job.status));

    if (this.map && activeVehicles.length > 0) {
      const bounds = new L.LatLngBounds(
        activeVehicles.map(vehicle => [
          vehicle.telemetry.currentLocation.latitude,
          vehicle.telemetry.currentLocation.longitude
        ] as [number, number])
      );
      this.map.fitBounds(bounds, { maxZoom: 14 });

      
    } else {
      this.noVehiclesMessage = true;
      this.removeAllMarkers();
      this.removeNoVehiclesMessage();
      this.showNoVehiclesMessage();
      this.setDefaultZoom();
    }
  }



  private setDefaultZoom(): void {
    const initialZoom = window.innerWidth <= 576 ? 4 : window.innerWidth <= 1024 ? 5 : 5;

    if (this.map) {
      this.map.setView([-33.92574199874307, 18.65486950525504], initialZoom); // Global zoom level when no vehicles are available
    }
  }
}
