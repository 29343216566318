import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { CommunicationService } from '../service/communication/communication.service';
import { Subscription } from 'rxjs';
import { FilterEnum } from '../models/filter-enum';
import { TransportMode } from '../enums/transport-mode.enum';


@Component({
  selector: 'app-filter-sidenav',
  templateUrl: './filter-sidenav.component.html',
  styleUrls: ['./filter-sidenav.component.css']

})
export class FilterSidenavComponent implements OnInit, OnDestroy {
  @Input() transportMode: TransportMode | undefined; // Accept transportMode as an input from the parent

  @Output() openFilter = new EventEmitter<string>();
  activeFilter!: FilterEnum|null;
  subscription!: Subscription;
  FilterEnum = FilterEnum; 


  constructor(private communicationService: CommunicationService) {}

  ngOnInit(): void {
    this.subscription = this.communicationService.filterToggle$.subscribe(
      (filterName: FilterEnum) => {
        this.activeFilter = filterName;
      }
    );
  }

  onOpenFilter(filterName: FilterEnum) {
    this.communicationService.toggleFilter(filterName);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

