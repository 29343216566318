<div class="dialog-wrap">

  <div mat-dialog-content class="dialog-reason">
    
    <mat-label>
      <h4 mat-dialog-title> {{data.resultStatus||data.title}} </h4>
    </mat-label>
  </div>
  <div mat-dialog-content class="dialog-reason">
    <mat-label>
      {{data.showMessage||data.message}}

    </mat-label>
  </div>

  <div mat-dialog-actions>
    <button class="dialog-okay-button" mat-button (click)="onOk()">Okay</button>

  </div>
</div>  
