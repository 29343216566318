<div class="dialog-wrap">

    <div mat-dialog-content class="dialog-reason">
      <h4 mat-dialog-title>{{ data.title }}</h4>
    </div>
    <div mat-dialog-content class="dialog-reason">
      <p>{{ data.message }}</p>
    </div>
  
    <div mat-dialog-actions>
      <button class="dialog-okay-button" mat-button (click)="onOk()">I agree</button>
      <button class="dialog-deny-button" mat-button (click)="onReject()">Decline</button>
    </div>
</div>