import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilterData } from '../models/list-model';
import { CommunicationService } from '../service/communication/communication.service';
import { FilterEnum } from '../models/filter-enum';
import { ShipmentService } from '../service/shipment/shipment.service';
import { catchError, map, Observable, of, skip, startWith, Subject, takeUntil } from 'rxjs';
import { DataFilter, FilterTransferModel } from '../models/filter-models';
import { shipmentTabbedViewEnum } from '../models/shipment/shipment-data-model';
import { LookupModel } from '../models/lookup-model';
import { LookupService } from '../service/lookup/lookup.service';
import { TransportMode } from '../enums/transport-mode.enum';
import { ShipmentTrackingHeader, ShipmentTrackingVessel } from '../models/shipment/shipment-tracking-header-model';
import { OceanTrackingViewEnum } from '../enums/ocean-tracking-view-enum';
import { RoadDataService } from '../service/roads/road-data.service';
import { JobStatus, RoadData } from '../models/road-data-model';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { ApiUserService } from '../service/user/api-user.service';



@Component({
  selector: 'app-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.css']
})

export class ShipmentsComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  shipmentTabbedViewEnum = shipmentTabbedViewEnum;
  @Input() selectedTab: shipmentTabbedViewEnum = shipmentTabbedViewEnum.oceanTab;
  @Output() selectedCustomerChange = new EventEmitter<string>();
  viewTrucks: boolean = false;
  isCustUser: boolean = false;
  isSelected: boolean = false;
  viewOceanMap: boolean = false;
  isOceanMapSelected: boolean = false;
  selectedCustomer: string = '';
  selectedButton = "";
  dateFrom = new FormControl();
  dateTo = new FormControl();
  query: string[][] = [];
  filterData: FilterData = {};
  filterRoadData: FilterData = {};
  FilterEnum = FilterEnum;
  currentFilter: DataFilter = new DataFilter();
  currentRoadFilter: DataFilter = new DataFilter();
  datafilter: DataFilter = new DataFilter();
  savedFilters: DataFilter[] = [];
  savedRoadFilters: DataFilter[] = [];
  selectedFilterId: number = 0;
  SavedFilterName: string = '';
  selectedType: string = 'Ocean';
  isRoadShipmentDisabled: boolean = true;
  isAirShipmentDisabled: boolean = true;
  transportMode: TransportMode = TransportMode.NoMode;
  protected startLocation: L.LatLngExpression = [0, 0];
  protected endLocation: L.LatLngExpression = [0, 0];
  roadDataList: RoadData[] = [];
  activeLabel: string = 'Active shipments';
  activeCount: number = 0;

  customers = new FormControl<LookupModel[]>([]);
  shipmentCustomer: LookupModel[] = [];
  shipmentDataConsignee: LookupModel[] = [];
  customersMultiFilter = new FormControl<string>('');
  filteredCustomers!: Observable<LookupModel[]>;
  carriers = new FormControl<LookupModel[]>([]);

  shipmentDataList: ShipmentTrackingHeader[] = [];
  vesselDataList: ShipmentTrackingVessel[] = [];
  public mapView: OceanTrackingViewEnum = OceanTrackingViewEnum.overview;
  protected filterList = new FormControl();
  consignees = new FormControl<LookupModel[]>([]);
  filteredConsignees!: Observable<LookupModel[]>;
  filteredTransportModes!: Observable<LookupModel[]>;
  transportModes = new FormControl<LookupModel[]>([]);
  transportModesMultiFilter = new FormControl<string>('');
  transportModeData: LookupModel[] = [];
  carriersMultiFilter = new FormControl<string>('');
  filteredCarriers!: Observable<LookupModel[]>;
  shipmentDataCarrier: LookupModel[] = [];
  dischargePorts = new FormControl<LookupModel[]>([]);
  dischargePortsMultiFilter = new FormControl<string>('');
  filteredDischargePorts!: Observable<LookupModel[]>;
  shipmentDataDischargePort: LookupModel[] = [];

  vesselNames = new FormControl<string[]>([]);
  bookingDataVesselName: LookupModel[] = [];
  filteredVesselNames!: Observable<string[]>;
  vesselsMultiFilter = new FormControl<string>('');

  consigneesMultiFilter = new FormControl<string>('');
  containerNos = new FormControl('');
  bookingReference = new FormControl('');
  clientReference = new FormControl('');

  loadPoints = new FormControl<LookupModel[]>([]);
  loadpointsMultiFilter = new FormControl<string>('');
  filteredLoadpoints!: Observable<LookupModel[]>;
  shipmentDataLoadpoints: LookupModel[] = [];

  constructor(
    private shipingDataService: ShipmentService,
    private communicationService: CommunicationService,
    private lookupService: LookupService,
    private roadDataService: RoadDataService,
    private cdr: ChangeDetectorRef,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private apiUserService: ApiUserService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'backspace',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
  }


  ngOnInit(): void {

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    this.loadSavedFilters();
    this.loadSavedRoadFilters();

    this.selectedCustomer = '';

    this.shipingDataService.filterNameSaved$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (t) => {
          this.SavedFilterName = t;
          this.filterData = {};
          this.loadSavedFilters();
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });

    this.shipingDataService.filterNameRoadSaved$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (t) => {
          this.SavedFilterName = t;
          this.filterRoadData = {};
          this.loadSavedRoadFilters();
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });
    this.reset();
    this.shipingDataService.filterData$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value) => {
          this.filterData = value;
          this.selectedFilterId = 0;
          this.updateCurrentFilter(this.selectedFilterId, this.getSavedFilterNameFromId(this.selectedFilterId), this.filterData)
          this.shipingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
        },
        error: (error) => {
          console.error('Error receiveing filter data', error);
          window.alert('Error receiveing filter data');
        }
      });

    this.shipingDataService.filterRoadData$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value) => {
          this.filterRoadData = value;
          this.selectedFilterId = 0;
          this.updateCurrentFilter(this.selectedFilterId, this.getSavedFilterNameFromId(this.selectedFilterId), this.filterRoadData)
          this.shipingDataService.sendingFilterTransferModelRoad(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
        },
        error: (error) => {
          window.alert('Error receiveing filter data');
        }
      });

    this.lookupService.getCustomers(this.datafilter.filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.shipmentCustomer = data;
          this.customers.setValue(this.shipmentCustomer.filter(customer => customer.isSelected));
          this.filteredCustomers = this.customersMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCustomers(searchText ?? ''))
          );
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

    this.lookupService.getConsignees(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.shipmentDataConsignee = data;
          this.consignees.setValue(this.shipmentDataConsignee.filter(c => c.isSelected));
          this.filteredConsignees = this.consigneesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterConsignees(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Consignee data', error)
      });

    this.lookupService.getLoadpoints(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.shipmentDataLoadpoints = data;
          this.loadPoints.setValue(this.shipmentDataLoadpoints.filter(c => c.isSelected));
          this.filteredLoadpoints = this.loadpointsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterLoadpoints(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });
    this.lookupService.getCarriers(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.shipmentDataCarrier = data;
          this.carriers.setValue(this.shipmentDataCarrier.filter(port => port.isSelected));
          this.filteredCarriers = this.carriersMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCarriers(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Carrier data', error)
      });

    this.lookupService.getVesselNames(this.datafilter.filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.bookingDataVesselName = data;
          // Check if there are saved vessel names in the filter
          const savedVesselNames = this.datafilter.filter['vesselName'] as string[] | undefined;
          if (savedVesselNames && savedVesselNames.length > 0) {
            this.vesselNames.setValue(savedVesselNames);
          } else {
            this.vesselNames.setValue([]);
          }
          this.filteredVesselNames = this.vesselsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterVesselNames(searchText ?? ''))
          );
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Vessel Names', error)
      });

    this.lookupService.getDischargePorts(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.shipmentDataDischargePort = data;
          this.dischargePorts.setValue(this.shipmentDataDischargePort.filter(port => port.isSelected));
          this.filteredDischargePorts = this.dischargePortsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterDischargePorts(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Discharge Port data', error)
      });
  }
  onOpenFilter(filter: FilterEnum) {
    this.communicationService.toggleFilter(filter);
    if (filter == FilterEnum.shipmentFilter) {
      this.sendingActiveFilter();
    }

    if (filter == FilterEnum.roadShipmentFilter) {
      this.sendingActiveFilterRoad();
    }
  }

  applyFilter(filterId: number): void {
    this.filterData = {};

    if (this.currentFilter.id === filterId) {
      this.selectedFilterId = 0;
      this.clearCurrentFilter();
      this.updateCurrentFilter(0, '', {});
    }
    else {
      this.selectedFilterId = filterId;
      this.clearCurrentFilter();
      this.updateCurrentFilter(filterId, this.getSavedFilterNameFromId(filterId), this.getSavedFilterFromId(filterId))
    }
    this.shipingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
  }

  applyRoadFilter(filterId: number): void {
    this.filterData = {};

    if (this.currentFilter.id === filterId) {
      this.selectedFilterId = 0;
      this.clearCurrentFilter();
      this.updateCurrentFilter(0, '', {});
    }
    else {
      this.updateCurrentFilter(filterId, this.getRoadSavedFilterNameFromId(filterId), this.getRoadSavedFilterFromId(filterId))
    }
    this.shipingDataService.sendingFilterTransferModelRoad(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
  }

  loadSavedFilters() {
    this.shipingDataService.getFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedFilters = data;
          if (this.currentFilter.filterName !== "") {
            let filterId = this.getFilterIdByName(this.currentFilter.filterName);
            this.updateCurrentFilter(filterId, this.currentFilter.filterName, this.getSavedFilterFromId(filterId));
            this.shipingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter));
          }
        }
      });
  }

  loadSavedRoadFilters() {
    this.shipingDataService.getRoadShipmentFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedRoadFilters = data;
          if (this.currentFilter.filterName !== "") {
            let filterId = this.getFilterIdByName(this.currentFilter.filterName);
            this.updateCurrentFilter(filterId, this.currentFilter.filterName, this.getRoadSavedFilterFromId(filterId));
            this.shipingDataService.sendingFilterTransferModelRoad(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter));
          }
        }
      });
  }

  changeSelectedButton(newSavedFilterName: string): void {
    this.selectedFilterId = this.getFilterIdByName(newSavedFilterName);
  }

  selectButton(button: string) {
    if (this.selectedButton === button) {
      this.selectedButton = '';
      this.shipingDataService.toggleStatusFilter('');
    } else {
      this.selectedButton = button;
      this.shipingDataService.toggleStatusFilter(button);
    }

  }

  processFilterData(key: string, value: string | string[]) {
    if (Array.isArray(value)) {
      this.filterData[key] = value.filter(item => item.trim() !== '');
    } else {
      const dataArray = value
        .split(',')
        .filter(item => item.trim() !== '');
      this.filterData[key] = dataArray;
    }
  }

  processRoadFilterData(key: string, value: string | string[]) {
    if (Array.isArray(value)) {
      this.filterRoadData[key] = value.filter(item => item.trim() !== '');
    } else {
      const dataArray = value
        .split(',')
        .filter(item => item.trim() !== '');
      this.filterRoadData[key] = dataArray;
    }
  }


  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.currentFilter.id = id;
    this.currentFilter.filterName = name;
    this.currentFilter.filter = filter;
    this.datafilter.filter = filter;

    if (this.datafilter.filter['carrierCode'] != null) {
      const carriersFilter = this.datafilter?.filter['carrierCode'] ?? [];
      this.carriers.setValue(this.shipmentDataCarrier.filter(status => carriersFilter.includes(status.code)))
    }
    if (this.datafilter.filter['customerCode'] != null) {
      const customersFilter = this.datafilter?.filter['customerCode'] ?? [];
      this.customers.setValue(this.shipmentCustomer.filter(status => customersFilter.includes(status.code)))
    }
    if (this.datafilter.filter['containerNo'] != null) {
      const customersFilter = this.datafilter?.filter['containerNo'].toString() ?? null;
      this.containerNos.setValue(customersFilter);
    }
    if (this.datafilter.filter['bookingReference'] != null) {
      const bookingReferenceFilter = this.datafilter?.filter['bookingReference'].toString() ?? null;
      this.bookingReference.setValue(bookingReferenceFilter);
    }
    if (this.datafilter.filter['consigneeCode'] != null) {
      const consigneeFilter = this.datafilter?.filter['consigneeCode'] ?? [];
      this.consignees.setValue(this.shipmentDataConsignee.filter(status => consigneeFilter.includes(status.code)))
    }
    if (this.datafilter.filter['clientReference'] != null) {
      const clientReferenceFilter = this.datafilter?.filter['clientReference'].toString() ?? null;
      this.clientReference.setValue(clientReferenceFilter);
    }
    if (this.datafilter.filter['loadingPointCode'] != null) {
      const loadingPointFilter = this.datafilter?.filter['loadingPointCode'] ?? [];
      this.loadPoints.setValue(this.shipmentDataLoadpoints.filter(status => loadingPointFilter.includes(status.code)))
    }

  }

  getSavedFilterNameFromId(filterId: number): string {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  getSavedFilterFromId(filterId: number): FilterData {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  getFilterIdByName(filterName: string): number {
    const filter = this.savedFilters.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  getRoadSavedFilterNameFromId(filterId: number): string {
    const filter = this.savedRoadFilters.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  getRoadSavedFilterFromId(filterId: number): FilterData {
    const filter = this.savedRoadFilters.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  getRoadFilterIdByName(filterName: string): number {
    const filter = this.savedRoadFilters.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  sendingActiveFilter() {
    this.createFilter();
    this.updateCurrentFilter(this.currentFilter.id, this.currentFilter.filterName, this.filterData);
    this.shipingDataService.sendingActiveFilterToFilter(this.currentFilter)
  }

  sendingActiveFilterRoad() {
    this.createRoadFilter();
    this.updateCurrentFilter(this.currentFilter.id, this.currentFilter.filterName, this.filterRoadData);
    this.shipingDataService.sendingActiveFilterToFilterRoad(this.currentFilter)
  }


  runReportClick(): void {
    if (this.selectedTab === shipmentTabbedViewEnum.oceanTab) {
      this.createFilter();
      this.updateCurrentFilter(this.currentFilter.id, this.currentFilter.filterName, this.filterData);
      this.shipingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));

      if (this.isOceanMapSelected && this.viewOceanMap) {
        this.fetchShipmentData().subscribe()
      }
    }

    if (this.selectedTab === shipmentTabbedViewEnum.roadTab) {
      this.createRoadFilter();
      this.updateCurrentFilter(this.currentFilter.id, this.currentFilter.filterName, this.filterRoadData);
      this.shipingDataService.sendingFilterTransferModelRoad(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
    }

  }

  selectType(type: string) {
    if (type === 'Ocean') {
      this.selectedType = 'Ocean';
    }
    if (type === 'Map') {
      this.selectedType = 'Map';
    }
  }

  runCustomerClick(): void {
    if (this.selectedCustomer) {
      this.shipingDataService.sendingSelectedCustomer(this.selectedCustomer);
    } else {
      console.error('No customer selected');
    }
  }

  onTrackTransportLoadsClick(): void {

    this.transportMode = TransportMode.Road;
    this.viewTrucks = !this.viewTrucks;
    this.isSelected = !this.isSelected;
    this.runCustomerClick()
    this.fetchRoadData()
  }

  onTrackLiveMapClick(): void {
    this.fetchShipmentData().subscribe({
      next: () => {
        this.transportMode = TransportMode.Ocean;
        this.viewOceanMap = !this.viewOceanMap;
        this.isOceanMapSelected = !this.isOceanMapSelected;
      }
    })

  }

  fetchRoadData(filterId?: number, filterData?: FilterData, startDate?: Date, endDate?: Date): void {
    const startDateString = startDate ? startDate.toISOString().split('T')[0] : undefined;
    const endDateString = endDate ? endDate.toISOString().split('T')[0] : undefined;

    this.roadDataService.getRoadData(filterId, filterData, startDateString, endDateString).subscribe(data => {
      this.roadDataList = data;
      this.updateActiveCount();
    });
  }

  updateActiveCount(): void {
    if (this.transportMode === TransportMode.Road) {
      this.activeLabel = 'Active trucks';
      this.activeCount = this.roadDataList.filter(vehicle => vehicle.job.status === JobStatus.InProgress).length;
    } else if (this.transportMode === TransportMode.Ocean) {
      this.activeLabel = 'Active shipments';
      this.activeCount = this.shipmentDataList.length;
    } else {
      this.activeLabel = 'Active shipments';
      this.activeCount = 0;
    }
  }

  //fetchRoadData(): void {
  //  if (this.selectedCustomer) {
  //    const customerCode = this.selectedCustomer;
  //    const filterString = this.savedFilters[this.selectedFilterId]?.filterName;

  //    this.shipingDataService.getRollingData(this.selectedFilterId, filterString, customerCode)
  //      .subscribe(data => {
  //         this.rollingDataList = data;
  //         console.log('Fetched road data:', this.rollingDataList);
  //      });
  //  } else {
  //    console.error('No customer selected');
  //  }
  //}

  onTabChange(tab: shipmentTabbedViewEnum): void {
    this.clearCurrentFilter();
    this.selectedTab = tab;
    this.viewTrucks = false;

    if (this.selectedTab === shipmentTabbedViewEnum.roadTab) {
      //this.fetchRoadData();
    }
    if (this.selectedTab === shipmentTabbedViewEnum.oceanTab) {
      this.reset()
      this.selectedCustomer = ''
    }
  }

  reset() {
    this.customers.reset();
    this.selectedCustomer = '';
    this.viewTrucks = false;
    this.isSelected = false;
    this.filteredCustomers = this.customersMultiFilter.valueChanges.pipe(
      startWith(''),
      map(searchText => this.filterCustomers(searchText ?? ''))
    );
    this.runReportClick()
  }

  onCustomerSelectionChange(customer: string): void {
    this.selectedCustomer = customer;
    this.selectedCustomerChange.emit(this.selectedCustomer);
  }

  private filterCustomers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.shipmentCustomer;
    }
    return this.shipmentCustomer.filter(customer =>
      customer.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  viewTrucksToggle() {
    this.clearCurrentFilter();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const twoDaysFromNow = new Date();
    twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2);

    this.shipingDataService.sendingFilterTransferModelRoad(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, yesterday, twoDaysFromNow));
    if (this.selectedTab === shipmentTabbedViewEnum.roadTab) {
      this.dateFrom = new FormControl(yesterday);
      this.dateTo = new FormControl(twoDaysFromNow);
      this.transportMode = TransportMode.Road;
    }
  }

  viewOceanToggle() {
    this.clearCurrentFilter();
    this.shipingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
    if (this.selectedTab === shipmentTabbedViewEnum.oceanTab) {
      this.transportMode = TransportMode.Ocean;
    }
  }

  fetchShipmentData(filterId?: number, filterData?: FilterData, startDate?: Date, endDate?: Date): Observable<void> {
    return this.shipingDataService.getActiveShipmentsTracking(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value).pipe(
      map(data => {
        if (data !== null) {
          console.log('all sea data', data);
          this.vesselDataList = data.trackedVessels;
          console.log('vessel Data', this.vesselDataList);
          this.shipmentDataList = data.trackedContainers;
          // this.updateActiveCount();
          console.log('shipment', this.shipmentDataList);
        }
        return undefined; // Return undefined after processing
      }),
      catchError(error => {
        console.error('Error fetching shipment data', error);
        return of(undefined); // Handle error and return undefined
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
  }

  private filterConsignees(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.shipmentDataConsignee;
    }
    return this.shipmentDataConsignee.filter(c =>
      c.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterTransportModes(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.transportModeData;
    }
    return this.transportModeData.filter(transportMode =>
      transportMode.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCarriers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.shipmentDataCarrier;
    }
    return this.shipmentDataCarrier.filter(Carrier =>
      Carrier.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterDischargePorts(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.shipmentDataDischargePort;
    }
    return this.shipmentDataDischargePort.filter(dischargePort =>
      dischargePort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterVesselNames(searchText: string): string[] {
    if (!searchText) {
      return this.bookingDataVesselName.map(vessel => vessel.name);
    }
    return this.bookingDataVesselName
      .filter(vessel => vessel.name.toLowerCase().includes(searchText.toLowerCase()))
      .map(vessel => vessel.name);
  }

  createFilter() {
    if (this.customers.value != null) {
      const customerCodes = this.parseFilterSelections(this.customers.value);
      this.processFilterData('customerCode', customerCodes);
    }
    if (this.containerNos.value != null) {
      this.processFilterData('containerNo', this.containerNos.value?.toString());
    }
    if (this.bookingReference.value != null) {
      this.processFilterData('bookingReference', this.bookingReference.value?.toString());
    }
    if (this.consignees.value != null) {
      const consigneeCodes = this.parseFilterSelections(this.consignees.value);
      this.processFilterData('consigneeCode', consigneeCodes);
    }
    if (this.dischargePorts.value != null) {
      const dischargePortCodes = this.parseFilterSelections(this.dischargePorts.value);
      this.processFilterData('destinationPort', dischargePortCodes);
    }
    if (this.vesselNames.value && this.vesselNames.value.length > 0) {
      this.filterData['vesselName'] = this.vesselNames.value;
    }
  }

  createRoadFilter() {
    this.filterRoadData = {};
    if (this.customers.value != null) {
      const customerCodes = this.parseFilterSelections(this.customers.value);
      this.processRoadFilterData('customerCode', customerCodes);
    }
    if (this.loadPoints.value != null) {
      const loadPoints = this.parseFilterSelections(this.loadPoints.value);
      this.processRoadFilterData('loadingPointCode', loadPoints);
    }
    if (this.clientReference.value != null) {
      this.processRoadFilterData('clientReference', this.clientReference.value?.toString());
    }
  }

  parseFilterSelections(selections: LookupModel[]): string {
    const codes: string[] = selections.map(selection => selection.code);
    return codes.join(',');
  }

  clearCurrentFilter() {
    this.currentFilter.filter = {};
    this.currentFilter.filterName = '';
    this.currentFilter.id = 0;
    this.datafilter.filter = {};
    this.bookingReference.reset();
    this.containerNos.reset();
    this.clientReference.reset();
    this.dateFrom.reset();
    this.dateTo.reset();
    this.customers.setValue(this.shipmentCustomer.filter(x => !x));
    this.consignees.setValue(this.shipmentDataConsignee.filter(x => !x));
    this.dischargePorts.setValue(this.shipmentDataDischargePort.filter(x => !x));
    this.loadPoints.setValue(this.shipmentDataLoadpoints.filter(x => !x));
  }

  clearSearchField(control: FormControl, controlSecond?: FormControl): void {
    control.reset(); // Clear the search input field

    if (controlSecond) {
      controlSecond.reset();
    }
  }

  private filterLoadpoints(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.shipmentDataLoadpoints;
    }
    return this.shipmentDataLoadpoints.filter(loadpoint =>
      loadpoint.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  deleteFilter() {
    let filterName = this.currentFilter.filterName;

    this.shipingDataService.deleteShipmentFilter(this.currentFilter.id, this.currentFilter.filterName).subscribe(result => {
      this.shipingDataService.filterNameSavedSend('');
      this.clearCurrentFilter();
    });

  }
}

