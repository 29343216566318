import { OnInit, Component, ViewChild, Output, EventEmitter, OnDestroy, inject, ChangeDetectorRef } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BookingService } from '../service/booking/booking.service';
import { FilterData } from '../models/list-model';
import { CommunicationService } from '../service/communication/communication.service';
import { ColumnSetup } from '../models/column-setup';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { Subject, of } from 'rxjs';
import { takeUntil, catchError, skip } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { SubscriptionComponent } from '../dialog/subscription/subscription.component';
import { AlertNotificationModel } from '../models/alerts/alert-notification-model';
import { AlertSubscriptionInputModel } from '../models/alerts/alert-subscription-input-model';
import { AlertSubscriptionModel } from '../models/alerts/alert-subscription-model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import { AlertService } from '../service/alerts/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { AppBookingListModel } from '../models/bookings/appbooking-list-model';

@Component({
  selector: 'app-booking-submitted-view',
  templateUrl: './booking-submitted-view.component.html',
  styleUrl: './booking-submitted-view.component.css'
})
export class BookingSubmittedViewComponent implements OnInit, OnDestroy {
  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;

  @Output() openDetailDrawer = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<AppBookingListModel>;

  bookingData: AppBookingListModel[] = [];
  filter!: FilterData;

  detailTabbedViewEnum = detailTabbedViewEnum;

  detailView = detailView;
  filterId: number = 0;
  startDate?: Date = new Date;
  endDate?: Date = new Date;

  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];
  appBookingDataSource = new MatTableDataSource<AppBookingListModel>();
  _snackBar: any;
  horizontalPosition: any;
  verticalPosition: any;
  templates: AlertSubscriptionModel[] = [];
  isLoading = true;
  //subscribedTemplatesMap = new Map<string, boolean>(); // To track hasSubscribedTemplates status

  constructor(
    private alertDataService: AlertService,
    private bookingDataService: BookingService,
    private communicationService: CommunicationService,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appComponent: AppComponent,
    private cdr: ChangeDetectorRef
  ) {
    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    )
  }

  ngOnInit(): void {
    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    this.bookingDataService.statusFilterToggle$
      .pipe(
        takeUntil(this.destroy$),
        skip(1)
      )
      .subscribe({
        next:
          value => {
            if (value) {
              this.applyFilter(value);
            } else {
              this.applyFilter('');
            }
          }
      });


    this.bookingDataService.sendFilterTransfer$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (t) => {
          this.filter = t.filter;
          this.filterId = t.filterId;
          this.startDate = t.startDate;
          this.endDate = t.endDate;
          this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
        },
        error: (error) => {
          console.error('Send FilterModel Error', error);
          window.alert('Send FilterModel Error. Please try again later.');
        }
      });

    this.bookingDataService.getAppBookingsDataSource().subscribe(dataSource => {
      this.appBookingDataSource = dataSource; // Set this to your table's data source
    });
  }

  ngAfterViewInit() {
    this.appBookingDataSource.sort = this.sort;
    this.sort.sort({ id: 'appBookingCode', start: 'asc', disableClear: false });
  }

  configureColumns(): void {
    this.displayedColumns = [
      { name: 'appBookingStatusColor', header: '', type: 'string', visible: true },
      { name: 'customerName', header: 'Customer', type: 'string', visible: !this.isCustUser },
      { name: 'appBookingCode', header: 'Booking Code', type: 'string', visible: true },
      { name: 'appBookingStatus', header: 'Booking Status', type: 'string', visible: true },
      { name: 'reason', header: 'Rejection Reason', type: 'string', visible: true },
      { name: 'loadportName', header: 'Load Port', type: 'string', visible: true },
      { name: 'carrierName', header: 'Carrier', type: 'string', visible: true },
      { name: 'sailDateETD', header: 'Load ETD', type: 'date', visible: true },
      { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true },
      { name: 'quantity', header: 'Total Containters', type: 'number', visible: true },
    ];
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  subscribeToAlert(row: any): void {
    const notificationModel = this.createNotificationModel(row);

    this.alertDataService.getAlertSubscriptionTemplates(notificationModel)
      .pipe(
        catchError(error => {
          console.error('Error fetching subscription templates:', error);
          return of([]);
        })
      )
      .subscribe((subscriptionTemplates: AlertSubscriptionModel[]) => {

        const subscriptionInput = new AlertSubscriptionInputModel();
        subscriptionInput.notificationModel = notificationModel;
        subscriptionInput.templates = subscriptionTemplates;

        const dialogRef = this.dialog.open(SubscriptionComponent, {
          data: { subscriptionList: subscriptionTemplates }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {

            subscriptionInput.templates = result;

            this.alertDataService.updateAlertSubscription(subscriptionInput)
              .pipe(
                catchError(error => {
                  console.error('Error updating subscriptions:', error);
                  return of(null);
                })
              )
              .subscribe(response => {
                if (response) {
                  this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
                }
              });
          }
        });
      });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  getBackgroundColor(cellValue: string): string {
    switch (cellValue) {
      case 'Booking Rejected':
      case 'Amendment Rejected':
      case 'Cancel Pending':
      case 'Cancelled':
        return '#DC6868';
      default:
        return '#C2C2C2';
    }
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  updateData(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): void {
    this.isLoading = true;
    this.bookingDataService.getAppBookings().subscribe(data => {
      this.configureColumns();
      this.appBookingDataSource.sort = this.sort;
      this.appBookingDataSource.paginator = this.paginator;
      this.appBookingDataSource.data = data;
      this.isLoading = false;
    });
  }

  private createNotificationModel(row: any): AlertNotificationModel {
    return {
      AlertCode: '',
      CustomerCode: row.customerCode,
      BookingCode: row.bookingHeaderCode,
      TemplateGroup: 'Booking'
    } as AlertNotificationModel;
  }

  viewDetails(row: any, detailViewToOpen: detailView, tab: detailTabbedViewEnum): void {
    var bookingParameter = row['bookingHeaderCode'];
    this.communicationService.toggleDetailView(detailViewToOpen, tab);
    this.communicationService.getDetailViewParameter(bookingParameter)
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  applyFilter(filterValue: string) {
    if (!filterValue) {
      this.appBookingDataSource.filter = '';
    } else {
      this.appBookingDataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  filterByStatus(status: string) {
    this.appBookingDataSource.filterPredicate = (data: AppBookingListModel, filter: string) => data.appBookingStatus === filter;
    this.appBookingDataSource.filter = status;
  }

  filterByDateRange(startDate: string, endDate: string) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    this.appBookingDataSource.filterPredicate = (data: AppBookingListModel, filter: string) => {
      const date = new Date(data.sailDate);
      return date >= start && date <= end;
    };
    this.appBookingDataSource.filter = `${startDate}-${endDate}`;
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
