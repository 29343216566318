<div class="dv-timeline">
  <div *ngIf="isMinWidth(0)" class="dv-timeline-header-row">
    <ng-container *ngFor="let column of displayedColumns">
      <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
        <strong matTooltip="{{column[2]}}" matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">{{ column[1]
          }}</strong>
      </div>
    </ng-container>
  </div>
  <div *ngFor="let row of scheduleRouteLines" class="dv-timeline-data-row" id="mobileCard">
    <ng-container *ngFor="let column of displayedColumns">
      <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
        <ng-container *ngIf="column[0] === 'eta'">
          {{
          getValueForRowColumn(row, column[0]) && getValueForRowColumn(row, column[0]) !== '0001-01-01T00:00:00'
          ? (getValueForRowColumn(row, column[0]) | date: 'dd MMM yyyy') : ' '
          }}
        </ng-container>
        <ng-container *ngIf="column[0] === 'etd'">
          {{
          getValueForRowColumn(row, column[0]) && getValueForRowColumn(row, column[0]) !== '0001-01-01T00:00:00'
          ? (getValueForRowColumn(row, column[0]) | date: 'dd MMM yyyy') : ' '
          }}
        </ng-container>
        <ng-container *ngIf="column[0] === 'duration'">
          {{getTimeDifference(row.eta | date: 'yyyy-MM-dd')}}
        </ng-container>
        <ng-container *ngIf="column[0] === 'report'">
          <button class="primary-button">Report</button>
        </ng-container>
        <ng-container *ngIf="column[0] === 'portType'">
          <span style="font-weight: bold;">
            {{ getValueForRowColumn(row, column[0]) }}
          </span>
        </ng-container>
        <ng-container
          *ngIf="column[0] !== 'eta'&& column[0] !== 'etd' && column[0] !== 'portCode' && column[0] !== 'portType'">
          {{getValueForRowColumn(row, column[0])}}
        </ng-container>
      </div>
    </ng-container>

  </div>
</div>