<div class="alert-management">
  <div class="title-wrapper">
    <h4>Select the type of alerts you want to receive</h4>
  </div>
  <mat-dialog-content class="alert-dialog">
    <div class="alert-subscription-wrapper">
      <div *ngFor="let mode of getTransportModes()">
        <div class="alert-group-title">
          <h5>{{ mode }}</h5>
        </div>
        <div class="alert-subscription-item" *ngFor="let subscription of groupedSubscriptions[mode]">
          <p>{{ subscription.alertTemplateName }}</p>
          <mat-slide-toggle [(ngModel)]="subscription.isSubscribed" aria-label="Toggle alert"></mat-slide-toggle>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="close-dialog" align="end">
    <button class="close-button" mat-dialog-close><mat-icon svgIcon="CancelIcon"></mat-icon></button>
  </mat-dialog-actions>
</div>
<div mat-dialog-actions class="container-aligned-right">
  <button class="roundedButton positive" (click)="onOk()">Confirm</button>
  <button class="roundedButton primary" (click)="subscribeToAll()">Select All</button>
  <button class="roundedButton negative" (click)="onReject()">Remove All</button>
</div>
