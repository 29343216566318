import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { ClaimAction } from '../enums/keycloak-claim-action'

/**
 * This guard return true if the user is logged in
 * This guard can be further modified to :
 * * * check user roles using keycloakService.isUserInRole() function
 */
export const AuthGuard: CanActivateFn = (route, state) => {
  const keycloakService = inject(KeycloakService);
  const router = inject(Router);

  const roles = keycloakService.getUserRoles()

  const requiredRoles = route.data['roles'] as ClaimAction[]; // Get roles from route data

  const isLoggedIn = keycloakService.isLoggedIn();

  let hasRequiredRole = false;

  if (requiredRoles) {
    hasRequiredRole = requiredRoles.some(role => keycloakService.isUserInRole(role));
  }
  else {
    hasRequiredRole = true;
  }  

  if (isLoggedIn && hasRequiredRole) {
    return true; // Allow access
  } else {
    router.navigate(['/access-denied']); // Redirect if not authorized
    return false; // Prevent access
  }

};
