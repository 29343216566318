import { Component, Input } from '@angular/core';
import { ShipmentTrackingHeader } from '../models/shipment/shipment-tracking-header-model';

@Component({
  selector: 'app-shipment-popup',
  templateUrl: './shipment-popup.component.html',
  styleUrls: ['./shipment-popup.component.css'],
})
export class ShipmentPopupComponent {
  @Input() vesselName!: string;
  @Input() formattedPackDate!: string;
  @Input() noOfContainers!: number;
  @Input() containersForVessel!: ShipmentTrackingHeader[];
  @Input() vesselIndex!: number;
}
