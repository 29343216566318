import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FilterEnum } from '../../models/filter-enum';
import { detailTabbedViewEnum, detailView, DetailedViewState } from '../../models/detail-view-enum';
import { ShipmentTranferGRRefContainerNo } from '../../models/shipment/shipment-data-model';
@Injectable({
  providedIn: 'root'
})



export class CommunicationService {
  private hasSiCutOffEmitted = false;
  private hasLateStackEmitted = false;
  private lateStackSubject = new BehaviorSubject<boolean>(false);
  private siCutOffDateSubject = new BehaviorSubject<string>('');
  private siCutOffSubject = new BehaviorSubject<boolean>(false);
  private closeRightNavSubject = new BehaviorSubject<boolean>(false);
  private filterToggleSubject = new BehaviorSubject<FilterEnum>(FilterEnum.none);
  private detailedViewToggleSubject = new BehaviorSubject<DetailedViewState>({
    detailView: detailView.none,
    detailTabbedViewEnum: detailTabbedViewEnum.none
  });
  private detailViewParameter = new BehaviorSubject<string>('');
  private rollingPlanParameter = new BehaviorSubject<string>('');

  closeRightNav$ = this.closeRightNavSubject.asObservable();
  filterToggle$ = this.filterToggleSubject.asObservable();
  detailedViewToggle$ = this.detailedViewToggleSubject.asObservable();
  detailViewParam = this.detailViewParameter.asObservable();
  rollingPlanParam = this.rollingPlanParameter.asObservable();
  lateStack$ = this.lateStackSubject.asObservable();
  siCutOff$ = this.siCutOffSubject.asObservable();
  siCutOffDate$ = this.siCutOffDateSubject.asObservable();

  grRefcontainerNoParameter = new BehaviorSubject<ShipmentTranferGRRefContainerNo>(new ShipmentTranferGRRefContainerNo);
  grRefcontainerNoParameter$ = this.grRefcontainerNoParameter.asObservable();




  toggleFilter(filterName: FilterEnum) {
    this.filterToggleSubject.next(filterName);
  }

  toggleDetailView(detailName: detailView, detailTab?: detailTabbedViewEnum): void {
    this.detailedViewToggleSubject.next({
      detailView: detailName,
      detailTabbedViewEnum: detailTab ?? detailTabbedViewEnum.none
    });
  }

  closeRightNav() {
    this.closeRightNavSubject.next(true);
  }

  getDetailViewParameter(param: string) {
    this.detailViewParameter.next(param);
  }

  getRollingPlanLineNo(param: string) {
    this.rollingPlanParameter.next(param);
  }

  getDetailViewGRRefcontainerNo(param: ShipmentTranferGRRefContainerNo) {
    this.grRefcontainerNoParameter.next(param);
  }

  setLateStack(value: boolean) {
    if (!this.hasLateStackEmitted) {
      this.lateStackSubject.next(value);
      this.hasLateStackEmitted = true;
    }
  }

  setSiCutOff(value: boolean) {
    if (!this.hasSiCutOffEmitted) {
      this.siCutOffSubject.next(value);
      this.hasSiCutOffEmitted = true;
    }
  }

  setSiCutOffDate(value: string) {
    this.siCutOffDateSubject.next(value);
  }

  constructor() { }
}
