import { Component, ViewChild, OnInit, inject, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DocumentService } from '../service/documents/document.service';
import { IDocument, Document, documentTabbedViewEnum } from '../models/documents/document';
import { FormControl } from '@angular/forms';
import { CommunicationService } from '../service/communication/communication.service';
import { FilterData } from '../models/list-model';
import { ColumnSetup } from '../models/column-setup'
import { FilterEnum } from '../models/filter-enum';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { DataFilter, FilterTransferModel } from '../models/filter-models';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil, catchError, skip, map, startWith } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import { AlertSubscriptionModel } from '../models/alerts/alert-subscription-model';
import { MatDialog } from '@angular/material/dialog';
import { DocumentRejectComponent } from '../dialog/document-reject/document-reject.component';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ShipmentTranferGRRefContainerNo } from '../models/shipment/shipment-data-model';
import { LookupService } from '../service/lookup/lookup.service';
import { LookupModel } from '../models/lookup-model';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { TableUtil } from '../service/utils/excel-export-service.service';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {

  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;
  @ViewChild(MatPaginator) paginator = {} as MatPaginator;
  @ViewChild(MatSort) sort = {} as MatSort;
  protected documentTabbedViewEnum = documentTabbedViewEnum;
  protected selectedTab = documentTabbedViewEnum.pending;

  //Approved
  approvedDataSource = new MatTableDataSource<Document>();
  dateFrom = new FormControl();
  dateTo = new FormControl();
  protected filterList = new FormControl();
  savedFilters: DataFilter[] = [];
  filterData: FilterData = {};

  //NotApproved
  origNotApprovedDatasource = new MatTableDataSource<Document>();
  notApprovedDataSource = new MatTableDataSource<Document>();
  protected filterListNotApproved = new FormControl();
  dateFromNotApproved = new FormControl();
  dateToNotApproved = new FormControl();
  savedFiltersNotApproved: DataFilter[] = [];
  filterDataNotApproved: FilterData = {};


  currentDataSource = this.notApprovedDataSource;
  filter!: FilterData;
  FilterEnum = FilterEnum;
  selectedButton = "";
  detailView = detailView;
  detailTabbedViewEnum = detailTabbedViewEnum;
  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];
  documentList: IDocument[] = [];
  templates: AlertSubscriptionModel[] = [];
  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  isLoading = true;
  loadingSearch: boolean = false;
  loadingSearchNotApproved: boolean = false;


  //New

  datafilter: DataFilter = new DataFilter();
  datafilterNotApproved: DataFilter = new DataFilter();

  customers = new FormControl<LookupModel[]>([]);
  customersNotApproved = new FormControl<LookupModel[]>([]);

  grRefNo = new FormControl('');
  grRefNoNotApproved = new FormControl('');

  clientRefNo = new FormControl('');
  clientRefNoNotApproved = new FormControl('');

  bookingRefNo = new FormControl('');

  documentTypeNotApproved = new FormControl<LookupModel[]>([]);

  consignees = new FormControl<LookupModel[]>([]);
  consigneesNotApproved = new FormControl<LookupModel[]>([]);

  customersData: LookupModel[] = [];
  customersDataNotApproved: LookupModel[] = [];

  documentTypesData: LookupModel[] = [];

  documentDataConsignee: LookupModel[] = [];
  documentDataConsigneeNotApproved: LookupModel[] = [];


  customersMultiFilter = new FormControl<string>('');
  customersMultiFilterNotApproved = new FormControl<string>('');

  documentTypesMultiFilter = new FormControl<string>('');

  consigneesMultiFilter = new FormControl<string>('');
  consigneesMultiFilterNotApproved = new FormControl<string>('');

  filteredCustomers!: Observable<LookupModel[]>;
  filteredCustomersNotApproved!: Observable<LookupModel[]>;

  filteredDocumentTypes!: Observable<LookupModel[]>;

  filteredConsignees!: Observable<LookupModel[]>;
  filteredConsigneesNotApproved!: Observable<LookupModel[]>;

  savedFilterName = new FormControl('');




  constructor(
    private documentService: DocumentService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appComponent: AppComponent,
    private cdr: ChangeDetectorRef,
    private lookupService: LookupService
  ) {
    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'backspace',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'export',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Export-Icon.svg')
    )

  }

  ngOnInit(): void {
    this.updateCurrentFilter(0, '', {});
    this.updateCurrentFilterNotApproved(0, '', {});

    this.documentService.filterNameSaved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (t) => {
          this.loadSavedFilters();
          this.updateCurrentFilter(0, t, {});
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });

    this.documentService.filterNameSavedNotApproved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (t) => {
          this.loadSavedFiltersNotApproved();
          this.updateCurrentFilterNotApproved(0, t, {});
          // this.updateDataNotApproved(this.datafilterNotApproved.filter);
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    this.loadSavedFilters();
    this.updateDataNotApproved();
    this.loadSavedFiltersNotApproved();
    this.updateDataApproved();
    this.loadDocumentData();


    this.documentService.sendActiveFilterToList$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (filterReceived) => {
          this.updateCurrentFilter(filterReceived.id, filterReceived.filterName, filterReceived.filter)
          this.updateDataApproved(this.datafilter.filter);
          this.applyStatusFilter('');
        }
      });

    this.documentService.sendActiveFilterToListNotApproved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (filterReceived) => {
          this.updateCurrentFilterNotApproved(filterReceived.id, filterReceived.filterName, filterReceived.filter)
          this.updateDataNotApproved(this.datafilterNotApproved.filter);
          this.applyStatusFilter('');
        }
      });

  }

  loadDocumentData(): void {

    this.lookupService.getCustomers(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.customersData = data;
          this.customers.setValue(this.customersData.filter(filter => filter.isSelected));
          this.filteredCustomers = this.customersMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCustomers(searchText ?? ''))
          );
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

    this.lookupService.getCustomers(this.datafilterNotApproved.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.customersDataNotApproved = data;
          this.customersNotApproved.setValue(this.customersDataNotApproved.filter(filter => filter.isSelected));
          this.filteredCustomersNotApproved = this.customersMultiFilterNotApproved.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCustomersNotApproved(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

    this.lookupService.getConsignees(this.datafilterNotApproved.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.documentDataConsigneeNotApproved = data;
          this.consigneesNotApproved.setValue(this.documentDataConsigneeNotApproved.filter(c => c.isSelected));
          this.filteredConsigneesNotApproved = this.consigneesMultiFilterNotApproved.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterConsigneesNotApproved(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Consignee data', error)
      });

    this.lookupService.getConsignees(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.documentDataConsignee = data;
          this.consignees.setValue(this.documentDataConsignee.filter(c => c.isSelected));
          this.filteredConsignees = this.consigneesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterConsignees(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Consignee data', error)
      });


    this.lookupService.getDocumentTypes(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.documentTypesData = data;
          this.documentTypeNotApproved.setValue(this.documentTypesData.filter(value => value.isSelected));
          this.filteredDocumentTypes = this.documentTypesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterDocumentType(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

  }

  configureColumns(): void {
    if (this.selectedTab === this.documentTabbedViewEnum.approved) {
      this.displayedColumns = [
        { name: 'documentStatusColor', header: '', type: 'string', visible: true },
        { name: 'customer', header: 'Customer', type: 'string', visible: !this.isCustUser, toolTip: 'The name of the customer.' },
        { name: 'grReference', header: 'GR Ref No', type: 'string', visible: true, toolTip: 'GoReefers reference number assigned to the booking.' },
        { name: 'clientReference', header: 'Client Reference', type: 'string', visible: true, toolTip: 'The client’s reference number.' },
        { name: 'destinationDescription', header: 'Discharge Port', type: 'string', visible: true, toolTip: 'Port where the vessel discharges some or all of its cargo.' },
        { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true, toolTip: 'Name of the vessel.' },
        { name: 'eta', header: 'ETA', type: 'date', visible: true, toolTip: 'Estimated time of arrival.' },
        { name: 'consignee', header: 'Consignee', type: 'string', visible: true, toolTip: 'Name of the shipment receiver or importer.' },
        { name: 'bookingReference', header: 'Booking Reference', type: 'string', visible: true, toolTip: 'Shipping line booking reference number.' },
        { name: 'actions', header: 'Action', type: 'string', visible: true }

      ];
    } else {
      this.displayedColumns = [
        { name: 'documentStatusColor', header: '', type: 'string', visible: true },
        { name: 'customer', header: 'Customer', type: 'string', visible: !this.isCustUser, toolTip: 'The name of the customer.' },
        { name: 'grReference', header: 'GR Ref No', type: 'string', visible: true, toolTip: 'GoReefers reference number assigned to the booking.' },
        { name: 'clientReference', header: 'Client Reference', type: 'string', visible: true, toolTip: 'The client’s reference number.' },
        { name: 'destinationDescription', header: 'Discharge Port', type: 'string', visible: true, toolTip: 'Port where the vessel discharges some or all of its cargo.' },
        { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true, toolTip: 'Name of the vessel.' },
        { name: 'eta', header: 'ETA', type: 'date', visible: true, toolTip: 'Estimated time of arrival.' },
        { name: 'consignee', header: 'Consignee', type: 'string', visible: true, toolTip: 'Name of the shipment receiver or importer.' },
        { name: 'documentType', header: 'Document Type', type: 'string', visible: true, toolTip: 'The name of shipping document.' },
        { name: 'documentApprovalStatus', header: 'Approval Status', type: 'string', visible: true, toolTip: 'The status of the document.' },
        { name: 'actions', header: 'Action', type: 'string', visible: true }

      ];
    }
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  protected updateDataApproved(filterData?: FilterData): void {

    const dateFromValue = this.dateFrom.value ? new Date(this.dateFrom.value) : undefined;
    const dateToValue = this.dateTo.value ? new Date(this.dateTo.value) : undefined;

    this.documentService.getApprovedDocumentList(this.datafilter.id, this.datafilter.filter, dateFromValue, dateToValue).subscribe(data => {
      this.configureColumns();
      this.approvedDataSource.data = data;
      this.approvedDataSource.sort = this.sort;
      this.approvedDataSource.paginator = this.paginator;
      this.isLoading = false;
    });

    this.updateCurrentFilter(this.datafilter.id, this.datafilter.filterName, this.datafilter.filter);

    this.filterData = {};
    this.cdr.detectChanges();
  }

  protected updateDataNotApproved(filterData?: FilterData): void {

    const dateFromValue = this.dateFromNotApproved.value ? new Date(this.dateFromNotApproved.value) : undefined;
    const dateToValue = this.dateToNotApproved.value ? new Date(this.dateToNotApproved.value) : undefined;

    this.documentService.getNotApprovedDocumentList(this.datafilterNotApproved.id, this.datafilterNotApproved.filter, dateFromValue, dateToValue).subscribe(data => {
      this.configureColumns();
      this.origNotApprovedDatasource.data = data;
      this.notApprovedDataSource.data = data;
      this.notApprovedDataSource.sort = this.sort;
      this.notApprovedDataSource.paginator = this.paginator;
      this.isLoading = false;
    });

    this.updateCurrentFilterNotApproved(this.datafilterNotApproved.id, this.datafilterNotApproved.filterName, this.datafilterNotApproved.filter);

    this.filterDataNotApproved = {};
    this.cdr.detectChanges();
  }

  protected onOpenFilter(openFiler: FilterEnum) {
    this.communicationService.toggleFilter(openFiler);
    switch (this.selectedTab) {
      case documentTabbedViewEnum.approved:
        this.sendingActiveFilter();
        break;
      case documentTabbedViewEnum.pending:
        this.sendingActiveFilterNotApproved();
        break;
    }
  }

  protected loadSavedFilters() {
    this.documentService.getFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedFilters = data;
          if (this.datafilter.filterName !== "") {
            let filterId = this.getFilterIdByName(this.datafilter.filterName);
            this.updateCurrentFilter(filterId, this.datafilter.filterName, this.getSavedFilterFromId(filterId));
            this.updateDataApproved();
          }
          this.updateDataApproved(this.datafilter.filter)
        }
      });
  }

  protected loadSavedFiltersNotApproved() {
    this.documentService.getFiltersNotApproved()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedFiltersNotApproved = data;
          if (this.datafilterNotApproved.filterName !== "") {
            let filterId = this.getFilterIdByNameNotApproved(this.datafilterNotApproved.filterName);
            this.updateCurrentFilterNotApproved(filterId, this.datafilterNotApproved.filterName, this.getSavedFilterFromIdNotApproved(filterId));
            this.updateDataNotApproved();
          }
          this.updateDataNotApproved(this.datafilterNotApproved.filter)
        }
      });
  }

  protected processFilterData(key: string, value: string | string[]) {
    if (Array.isArray(value)) {
      this.filterData[key] = value.filter(item => item.trim() !== '');
    } else {
      const dataArray = value
        .split(',')
        .filter(item => item.trim() !== '');
      this.filterData[key] = dataArray;
    }

  }

  protected processFilterDataNotApproved(key: string, value: string) {
    if (Array.isArray(value)) {
      this.filterDataNotApproved[key] = value.filter(item => item.trim() !== '');
    } else {
      const dataArray = value
        .split(',')
        .filter(item => item.trim() !== '');
      this.filterDataNotApproved[key] = dataArray;
    }
  }

  protected setupFilter(column: string) {
    this.approvedDataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  protected applyFilter(filterId: number): void {
    this.filterData = {};

    if (this.datafilter.id === filterId) {
      this.updateCurrentFilter(0, '', {});
      // this.openSnackBar(`Removed filter: ${this.getSavedFilterNameFromId(filterId)}`);
    }
    else {
      this.updateCurrentFilter(filterId, this.getSavedFilterNameFromId(filterId), this.getSavedFilterFromId(filterId))
      // this.openSnackBar(`Filter applied: ${this.getSavedFilterNameFromId(filterId)}`);
    }
    this.updateDataApproved(this.datafilter.filter)

  }

  protected applyFilterNotApproved(filterId: number): void {
    this.filterDataNotApproved = {};

    if (this.datafilterNotApproved.id === filterId) {
      this.updateCurrentFilterNotApproved(0, '', {});
      // this.openSnackBar(`Removed filter: ${this.getSavedFilterNameFromIdNotApproved(filterId)}`);
    }
    else {
      this.updateCurrentFilterNotApproved(filterId, this.getSavedFilterNameFromIdNotApproved(filterId), this.getSavedFilterFromIdNotApproved(filterId))
      // this.openSnackBar(`Filter applied: ${this.getSavedFilterNameFromIdNotApproved(filterId)}`);
    }
    this.updateDataNotApproved(this.datafilterNotApproved.filter)
  }

  protected updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.datafilter.id = id;
    this.datafilter.filterName = name;
    this.datafilter.filter = filter;

    let grRefNoRestore = this.datafilter.filter['grRefNo']?.toString() ?? null;
    if (grRefNoRestore != null) {
      this.grRefNo.setValue(grRefNoRestore);
    } else {
      this.grRefNo.setValue('');
    }

    let clientRefNoRestore = this.datafilter.filter['clientRef']?.toString() ?? null;
    if (clientRefNoRestore != null) {
      this.clientRefNo.setValue(clientRefNoRestore);
    } else {
      this.clientRefNo.setValue('');
    }

    let bookingRefNoRestore = this.datafilter.filter['bookingRef']?.toString() ?? null;
    if (bookingRefNoRestore != null) {
      this.bookingRefNo.setValue(bookingRefNoRestore);
    } else {
      this.bookingRefNo.setValue('');
    }

    if (filter['consigneeCode'] != null) {
      const carriersFilter = filter['consigneeCode'] ?? [];
      this.consignees.setValue(this.documentDataConsignee.filter(status => carriersFilter.includes(status.code)))
    } else {
      this.consignees.setValue(this.documentDataConsignee.filter(x => !x));
    }

    if (filter['customerCode'] != null) {
      const carriersFilter = filter['customerCode'] ?? [];
      this.customers.setValue(this.customersData.filter(status => carriersFilter.includes(status.code)))
    } else {
      this.customers.setValue(this.customersData.filter(x => !x));
    }


  }

  protected updateCurrentFilterNotApproved(id: number, name: string, filter: FilterData) {
    this.datafilterNotApproved.id = id;
    this.datafilterNotApproved.filterName = name;
    this.datafilterNotApproved.filter = filter;

    let grRefNoRestore = this.datafilterNotApproved.filter['grRefNo']?.toString() ?? null;
    if (grRefNoRestore != null) {
      this.grRefNoNotApproved.setValue(grRefNoRestore);
    } else {
      this.grRefNoNotApproved.setValue('');
    }

    let clientRefNoRestore = this.datafilterNotApproved.filter['clientRef']?.toString() ?? null;
    if (clientRefNoRestore != null) {
      this.clientRefNoNotApproved.setValue(clientRefNoRestore);
    } else {
      this.clientRefNoNotApproved.setValue('');
    }

    if (filter['consigneeCode'] != null) {
      const carriersFilter = filter['consigneeCode'] ?? [];
      this.consigneesNotApproved.setValue(this.documentDataConsigneeNotApproved.filter(status => carriersFilter.includes(status.code)))
    } else {
      this.consigneesNotApproved.setValue(this.documentDataConsigneeNotApproved.filter(x => !x));
    }



    if (filter['customerCode'] != null) {
      const carriersFilter = filter['customerCode'] ?? [];
      this.customersNotApproved.setValue(this.customersDataNotApproved.filter(status => carriersFilter.includes(status.code)))
    } else {
      this.customersNotApproved.setValue(this.customersDataNotApproved.filter(x => !x));

    }

    if (filter['documentType'] != null) {
      const carriersFilter = filter['documentType'] ?? [];
      this.documentTypeNotApproved.setValue(this.documentTypesData.filter(status => carriersFilter.includes(status.name)))
    } else {
      this.documentTypeNotApproved.setValue(this.documentTypesData.filter(x => !x));
    }
  }

  protected getSavedFilterNameFromId(filterId: number): string {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  protected getSavedFilterNameFromIdNotApproved(filterId: number): string {
    const filter = this.savedFiltersNotApproved.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  protected getSavedFilterFromId(filterId: number): FilterData {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  protected getSavedFilterFromIdNotApproved(filterId: number): FilterData {
    const filter = this.savedFiltersNotApproved.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  protected getFilterIdByName(filterName: string): number {
    const filter = this.savedFilters.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  protected getFilterIdByNameNotApproved(filterName: string): number {
    const filter = this.savedFiltersNotApproved.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  protected sendingActiveFilter() {
    this.documentService.sendingActiveFilterToFilter(this.datafilter)
  }

  protected sendingActiveFilterNotApproved() {
    this.documentService.sendingActiveFilterToFilterNotApproved(this.datafilterNotApproved)
  }


  protected applyStatusFilter(filterValue: string) {
    if (this.selectedTab !== documentTabbedViewEnum.pending) {

      this.approvedDataSource.filterPredicate = (data: Document, filter: string) => {
        this.configureColumns()
        return data.documentApprovalStatus === 'Awaiting Approval';

      };

    } else {
      this.approvedDataSource.filterPredicate = (data: Document, filter: string) => {
        this.configureColumns()
        return data.documentApprovalStatus !== 'Rejected';
      };
    }
    // Apply the filter based on the filterValue
    this.approvedDataSource.filter = filterValue;
  }

  protected viewDetails(row: any, view: detailView, tab: detailTabbedViewEnum): void {
    this.communicationService.toggleDetailView(view, tab);

    let info: ShipmentTranferGRRefContainerNo = new ShipmentTranferGRRefContainerNo;

    info.grReferenceNo = row.grReference;
    info.containerNo = null;
    this.communicationService.getDetailViewGRRefcontainerNo(info)
  }

  runReportClick(): void {
    const dateFromValue = this.dateFrom.value ? new Date(this.dateFrom.value) : undefined;
    const dateToValue = this.dateTo.value ? new Date(this.dateTo.value) : undefined;

    this.createFilter();
    this.updateCurrentFilter(this.datafilter.id, this.datafilter.filterName, this.filterData);

    this.documentService.getApprovedDocumentList(this.datafilter.id, this.datafilter.filter, dateFromValue, dateToValue).subscribe(data => {
      this.configureColumns();
      this.approvedDataSource.data = data;
      this.approvedDataSource.sort = this.sort;
      this.approvedDataSource.paginator = this.paginator;
      this.isLoading = false;
    });
    this.cdr.detectChanges();
  }

  runReportClickNotApproved(): void {
    const dateFromValue = this.dateFromNotApproved.value ? new Date(this.dateFromNotApproved.value) : undefined;
    const dateToValue = this.dateToNotApproved.value ? new Date(this.dateToNotApproved.value) : undefined;

    this.createFilterNotApproved();
    this.updateCurrentFilterNotApproved(this.datafilterNotApproved.id, this.datafilterNotApproved.filterName, this.filterDataNotApproved);

    this.documentService.getNotApprovedDocumentList(this.datafilterNotApproved.id, this.datafilterNotApproved.filter, dateFromValue, dateToValue).subscribe(data => {
      this.configureColumns();
      this.notApprovedDataSource.data = data;
      this.notApprovedDataSource.sort = this.sort;
      this.notApprovedDataSource.paginator = this.paginator;
      this.isLoading = false;
    });
    this.cdr.detectChanges();
  }

  protected showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  public openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  protected isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  protected getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  protected getBackgroundColor(cellValue: string): string {
    switch (cellValue) {
      case 'Approved':
        return '#36DE6F';
      case 'Awaiting Approval':
        return '#FFD34F';
      default:
        return '#DC6868';
    }
  }

  protected approveDocument(row: any): void {
    const approved = {
      documentApprovalStatus: 'Approved'
    };

    this.documentService.updateDocumentStatus(row['id'], JSON.stringify(approved))
      .pipe(
        catchError(error => {
          console.error('Error updating document status:', error);
          this.showInformation('Document Approval Failed', error);
          return of(null); // Return a fallback value or an empty observable
        })
      )
      .subscribe(response => {
        this.updateDataApproved();
        this.updateDataNotApproved();
        if (response) {
          this.openSnackBar('Document Status updated to Approved')
          this.documentService.updateDocumentList(this.documentList); // Notify subscribers
          this.cdr.detectChanges();
        }
      });
  }

  protected showInformation(arg0: string, error: any) {
    throw new Error('Method not implemented.');
  }

  protected rejectDocument(row: any): void {
    const dialogRef = this.dialog.open(DocumentRejectComponent, {
      width: '300px',
      height: '350px',
      data: { documentApprovalStatus: 'Rejected', reasonCode: '', reasonDetail: '' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const rejectionData = {
          documentApprovalStatus: 'Rejected',
          documentReasonCode: result.reasonCode,
          documentReasonDetail: result.reasonDetail,
        };
        this.documentService.updateDocumentStatus(row['id'], JSON.stringify(rejectionData))
          .pipe(
            catchError(error => {
              console.error('Error updating document status:', error);
              this.showInformation('Document rejection Failed', error);
              return of(null); // Return a fallback value or an empty observable
            })
          )
          .subscribe(response => {
            if (response) {
              this.openSnackBar('Document Status updated to Rejected')
              this.updateDataApproved();
              this.updateDataNotApproved();
              this.cdr.detectChanges();
            }

          });

      }
    });
    this.documentService.updateDocumentList(this.documentList); // Update the shared state
  }

  public viewDocument(row: any): void {
    this.documentService.downloadDocumentWithSystemId(row['documentAttachmentSystemId'])
      .pipe(
        catchError(error => {
          console.error('Error updating document status:', error);
          this.showInformation('Download Failed', error);
          return of(null);
        })
      )
      .subscribe(response => {
        if (response) {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const fileName = `${row['attachmentFilename']}.pdf`;
          this.openSnackBar('Openning ' + fileName);


          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.target = '_blank';

          // Programmatically click the anchor to trigger the download
          document.body.appendChild(anchor);
          anchor.click();

          // Clean up by revoking the blob URL and removing the anchor
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);
        }
      });
  }

  protected downloadDocument(row: any): void {
    this.documentService.downloadDocumentWithSystemId(row['documentAttachmentSystemId'])
      .pipe(
        catchError(error => {
          console.error('Error updating document status:', error);
          this.showInformation('Download Failed', error);
          return of(null);
        })
      )
      .subscribe(response => {
        if (response) {
          const fileName = `${row['attachmentFilename']}.pdf`;
          this.openSnackBar('Downloading ' + fileName);
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);


          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.target = '_blank';
          anchor.download = fileName;

          // Programmatically click the anchor to trigger the download
          document.body.appendChild(anchor);
          anchor.click();

          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);
        }
      });
  }

  protected onTabChange(event: MatButtonToggleChange): documentTabbedViewEnum {
    this.selectedTab = event.value;
    this.updateDataSource();
    this.configureColumns();

    return this.selectedTab;
  }

  private updateDataSource() {
    // Switch data sources based on the selected tab
    this.currentDataSource = this.selectedTab === this.documentTabbedViewEnum.pending
      ? this.notApprovedDataSource
      : this.approvedDataSource;
    this.cdr.detectChanges();
  }

  clearSearchField(control: FormControl, controlSecond?: FormControl): void {
    control.reset();

    if (controlSecond) {
      controlSecond.reset();
    }
    this.approvedDataSource.filter = '';
    this.documentService.getApprovedDocumentList(this.datafilter.id, this.datafilter.filter).subscribe(data => {
      this.configureColumns();
      this.approvedDataSource.data = data;
      this.approvedDataSource.sort = this.sort;
      this.approvedDataSource.paginator = this.paginator;
      this.isLoading = false;
    });
  }

  clearSearchFieldNotApproved(control: FormControl, controlSecond?: FormControl): void {
    control.reset();




    if (controlSecond) {
      controlSecond.reset();
    }
    this.notApprovedDataSource.filter = '';
    this.documentService.getNotApprovedDocumentList(this.datafilterNotApproved.id, this.datafilterNotApproved.filter).subscribe(data => {
      this.configureColumns();
      this.notApprovedDataSource.data = data;
      this.notApprovedDataSource.sort = this.sort;
      this.notApprovedDataSource.paginator = this.paginator;
      this.isLoading = false;
    });
  }

  clearCurrentFilter() {

    this.datafilter.filterName = '';
    this.datafilter.id = 0;
    this.datafilter.filter = {};
    this.grRefNo.reset();
    this.clientRefNo.reset();
    this.bookingRefNo.reset();
    this.customers.setValue(this.customersData.filter(x => !x));
    this.consignees.setValue(this.documentDataConsignee.filter(x => !x));
    this.updateDataApproved(this.datafilter.filter)
  }

  clearCurrentFilterNotApproved() {

    this.datafilterNotApproved.filterName = '';
    this.datafilterNotApproved.id = 0;
    this.datafilterNotApproved.filter = {};
    this.grRefNoNotApproved.reset();
    this.clientRefNoNotApproved.reset();
    this.consigneesNotApproved.setValue(this.documentDataConsigneeNotApproved.filter(x => !x));
    this.customersNotApproved.setValue(this.customersDataNotApproved.filter(x => !x));
    this.documentTypeNotApproved.setValue(this.documentTypesData.filter(x => !x));
    this.updateDataNotApproved(this.datafilterNotApproved.filter)
  }

  deleteFilter(): void {
    let filterName = this.datafilter.filterName;

    this.documentService.deleteDocumentFilter(this.datafilter.id).subscribe(result => {
      this.documentService.filterNameSavedSend('');
      this.savedFilterName.setValue('');
      this.datafilter.id = 0;
      this.datafilter.filterName = '';
      this.openSnackBar('Your filter, ' + filterName + ' has been deleted');
      this.clearAllFilters();
      this.updateDataApproved();
    });
  }

  deleteFilterNotApproved(): void {
    let filterName = this.datafilterNotApproved.filterName;

    this.documentService.deleteDocumentFilter(this.datafilterNotApproved.id).subscribe(
      result => {
        // Clear the saved filter name and reset the filter state
        this.documentService.filterNameSavedSendNotApproved('');
        this.savedFilterName.setValue('');
        this.datafilterNotApproved.id = 0;
        this.datafilterNotApproved.filterName = '';

        // Show a success message
        this.openSnackBar('Your filter, ' + filterName + ' has been deleted');

        // Clear filters and update data after successful deletion
        this.clearAllFiltersNotApproved();
        this.updateDataNotApproved(); // Ensure this method fetches the latest data
      },
      error => {
        console.error('Error deleting filter:', error);
        this.openSnackBar('Failed to delete the filter. Please try again.');
      }
    );
  }

  clearAllFilters(): void {
    this.clearLookupFilter(this.customers);
    this.clearLookupFilter(this.consignees);
    this.clearFilter(this.grRefNo);
    this.clearFilter(this.clientRefNo);
    this.clearFilter(this.bookingRefNo);
    this.runReportClick();
    this.openSnackBar('Your filters have been cleared');
  }

  clearAllFiltersNotApproved(): void {
    this.clearLookupFilter(this.customersNotApproved);
    this.clearLookupFilter(this.consigneesNotApproved);
    this.clearLookupFilter(this.documentTypeNotApproved);
    this.clearFilter(this.grRefNoNotApproved);
    this.clearFilter(this.clientRefNo);
    this.runReportClickNotApproved();
    this.openSnackBar('Your filters have been cleared');
  }

  clearFilter(control: FormControl) {
    control.reset();
    control.setValue([]);
  }


  private filterConsignees(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.documentDataConsignee;
    }
    return this.documentDataConsignee.filter(c =>
      c.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterConsigneesNotApproved(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.documentDataConsigneeNotApproved;
    }
    return this.documentDataConsigneeNotApproved.filter(c =>
      c.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCustomers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.customersData;
    }
    return this.customersData.filter(customer =>
      customer.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCustomersNotApproved(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.customersDataNotApproved;
    }
    return this.customersDataNotApproved.filter(customer =>
      customer.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
  }

  applySearch(filterValue: string | undefined) {
    const normalizedFilter = (filterValue || '').trim().toLowerCase();
    this.loadingSearch = true;
    setTimeout(() => {
      this.approvedDataSource.filter = normalizedFilter;
      this.loadingSearch = false;
    }, 200);
  }

  applySearchNotApproved(filterValue: string | undefined) {
    const normalizedFilter = (filterValue || '').trim().toLowerCase();
    this.loadingSearchNotApproved = true;
    setTimeout(() => {
      this.notApprovedDataSource.filter = normalizedFilter;
      this.loadingSearchNotApproved = false;
    }, 200);
  }

  createFilter(): void {
    if (this.customers.value != null) {
      const customerCodes = this.parseFilterSelections(this.customers.value);
      this.processFilterData('customerCode', customerCodes);
    }
    if (this.consignees.value != null) {
      const consigneeCodes = this.parseFilterSelections(this.consignees.value);
      this.processFilterData('consigneeCode', consigneeCodes);
    }

    this.processFilterData('grRefNo', this.grRefNo.value?.toString() || '');

    this.processFilterData('clientRef', this.clientRefNo.value?.toString() || '');

    this.processFilterData('bookingRef', this.bookingRefNo.value?.toString() || '');

  }

  createFilterNotApproved(): void {

    if (this.customersNotApproved.value != null) {
      const customerCodes = this.parseFilterSelections(this.customersNotApproved.value);
      this.processFilterDataNotApproved('customerCode', customerCodes);
    }
    if (this.consigneesNotApproved.value != null) {
      const consigneeCodes = this.parseFilterSelections(this.consigneesNotApproved.value);
      this.processFilterDataNotApproved('consigneeCode', consigneeCodes);
    }

    this.processFilterDataNotApproved('grRefNo', this.grRefNoNotApproved.value?.toString() || '');

    this.processFilterDataNotApproved('clientRef', this.clientRefNoNotApproved.value?.toString() || '');

    if (this.documentTypeNotApproved.value != null) {
      const selectedDocumentTypes = this.parseFilterSelectionsDocType(this.documentTypeNotApproved.value);
      this.processFilterDataNotApproved('documentType', selectedDocumentTypes);
    }

  }

  parseFilterSelections(selections: LookupModel[]): string {
    const codes: string[] = selections.map(selection => selection.code);
    return codes.join(',');
  }

  parseFilterSelectionsDocType(selections: LookupModel[]): string {
    const names: string[] = selections.map(selection => selection.name);
    return names.join(',');
  }



  private filterDocumentType(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.documentTypesData;
    }
    return this.documentTypesData.filter(dt =>
      dt.code.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  filterByApprovalStatus(status: string): void {
    if (this.currentFilterStatus === status) {
      this.currentDataSource.filter = this.notApprovedDataSource.filter = '';
      this.currentFilterStatus = null;
    } else {
      this.currentDataSource.filter = this.notApprovedDataSource.filter = status;
      this.currentFilterStatus = status;
    }
    this.cdr.detectChanges();
  }

  resetTextControl(control: FormControl) {
    control.reset();
  }


  currentFilterStatus: string | null = null;

  exportTable() {
    TableUtil.exportToExcel(this.currentDataSource.data, this.displayedColumns, this.selectedTab == documentTabbedViewEnum.approved ? 'Approved Documents' : this.selectedTab == documentTabbedViewEnum.pending ? 'Pending Documents' : 'Documents');
  }
}
