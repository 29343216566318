// Haversine function to calculate distance between two points on Earth
export function haversine(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371e3; // Earth radius in meters
  const toRad = (x: number) => x * Math.PI / 180;
  const φ1 = toRad(lat1);
  const φ2 = toRad(lat2);
  const Δφ = toRad(lat2 - lat1);
  const Δλ = toRad(lon2 - lon1); // Correct longitude difference

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Return distance in meters
}

type Node = [number, number];  // Represents a point in [latitude, longitude]
type Edge = { node: Node, distance: number };  // Represents an edge connecting two nodes with distance
type Graph = Map<string, Edge[]>;  // Graph structure

// Class for handling the graph with added edges
export class SeaRouteGraph {
  private graph: Graph = new Map();

  // Adds an edge to the graph by calculating the distance between two nodes
  addEdge(node1: Node, node2: Node): void {
    const key1 = JSON.stringify(node1);
    const key2 = JSON.stringify(node2);
    const distance = haversine(node1[0], node1[1], node2[0], node2[1]);  // Calculate the distance using haversine formula

    // Add nodes to graph if they don't exist
    if (!this.graph.has(key1)) {
      this.graph.set(key1, []);
    }
    if (!this.graph.has(key2)) {
      this.graph.set(key2, []);
    }

    // Add the edges for both directions (node1 to node2 and node2 to node1)
    this.graph.get(key1)?.push({ node: node2, distance });
    this.graph.get(key2)?.push({ node: node1, distance });
  }

  // Returns the entire graph
  getGraph(): Graph {
    return this.graph;
  }
}

// Priority Queue class for more efficient queue handling in Dijkstra's algorithm
class PriorityQueue {
  private queue: { node: string, priority: number }[] = [];

  // Enqueue with a priority, sorted by priority
  enqueue(node: string, priority: number): void {
    this.queue.push({ node, priority });
    this.queue.sort((a, b) => a.priority - b.priority);  // Sorting by priority (smallest first)
  }

  // Dequeue and return the node with the highest priority (smallest value)
  dequeue(): string | undefined {
    return this.queue.shift()?.node;
  }

  // Check if the queue is empty
  isEmpty(): boolean {
    return this.queue.length === 0;
  }

  // Update the priority of a node in the queue
  updatePriority(node: string, priority: number): void {
    const index = this.queue.findIndex(item => item.node === node);
    if (index !== -1) {
      this.queue[index].priority = priority;
      this.queue.sort((a, b) => a.priority - b.priority);  // Re-sort after updating the priority
    }
  }
}

// Dijkstra's algorithm for finding the shortest path in a graph
export function dijkstra(graph: Graph, start: Node, end: Node): Node[] | null {
  const startKey = JSON.stringify(start);
  const endKey = JSON.stringify(end);
  const distances = new Map<string, number>();  // Distance map
  const previous = new Map<string, string | null>();  // Previous node map for path reconstruction
  const queue = new PriorityQueue();

  // Initialize all nodes with infinite distance
  for (const node of graph.keys()) {
    distances.set(node, Infinity);
    previous.set(node, null);
  }

  distances.set(startKey, 0);
  queue.enqueue(startKey, 0);  // Enqueue the start node with distance 0

  while (!queue.isEmpty()) {
    const current = queue.dequeue();

    if (!current) continue;

    // If the current node is the end node, reconstruct and return the path
    if (current === endKey) {
      const path: Node[] = [];
      let step: string | null = endKey;
      while (step) {
        path.push(JSON.parse(step)); // Deserialize the node back
        step = previous.get(step) || null;
      }
      return path.reverse();  // Return the path in the correct order (start to end)
    }

    // Explore the neighbors of the current node
    const edges = graph.get(current) || [];
    for (const { node: neighbor, distance } of edges) {
      const neighborKey = JSON.stringify(neighbor);
      const alt = (distances.get(current) || Infinity) + distance;  // Alternative distance

      // If a shorter path to the neighbor is found, update the distance and enqueue the neighbor
      if (alt < (distances.get(neighborKey) || Infinity)) {
        distances.set(neighborKey, alt);
        previous.set(neighborKey, current);
        queue.updatePriority(neighborKey, alt);
        queue.enqueue(neighborKey, alt); // Ensure neighbor is enqueued with updated priority
      }
    }
  }

  return null;  // Return null if no path is found
}
