<div>
  <div class="table-filter-container" *ngIf="this.shipmentDataSource.data.length > 0 && !isLoading">
    <mat-form-field subscriptSizing="dynamic" class="search-wrapper">
      <mat-label>Quick Search</mat-label>
      <input matInput [formControl]="filterList" (keyup)="applySearch(filterList.value)">
      <button class="clear-search" (click)="clearSearchField(filterList)">
        <mat-icon svgIcon="backspace"></mat-icon>
      </button>
    </mat-form-field>
    <div class="export-button">
      <button mat-icon-button color="primary" (click)="exportTable()" matTooltip="Export shipments to excel."
        matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
        <mat-icon svgIcon="export"></mat-icon>
      </button>
    </div>
  </div>
  <div class="mat-elevation-z2 custom-card-radius"
    [ngClass]="{'border-table-header': shipmentDataSource.data.length > 0}">

    <ng-container *ngIf="!isLoading">
      <table mat-table class="full-width-table" [dataSource]="shipmentDataSource" matSort aria-label="Elements">
        <ng-container *ngFor="let column of displayedColumns">
          <ng-container [matColumnDef]="column.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible"
              matTooltip="{{column.toolTip}}" matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              {{ column.header }}
            </th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'loading-cell': loadingSearch}"
              [attr.data-label]="(column.name !== 'actions' && column.name !== 'indicators') ? column.header : null"
              [hidden]="!column.visible" class='has_label_on_mobile'
              [ngStyle]="column.name === 'shipmentStatusColor' ? {'background-color': getBackgroundColor(row['shipmentStatus'])} : {}">

              <ng-container *ngIf="column.name !== 'actions'">
                <ng-container
                  *ngIf="column.name !== 'shipmentStatusColor' && column.name !== 'indicators' && column.name !== 'eta' && column.name !== 'etd'">
                  {{ row[column.name] | truncateText }}
                </ng-container>
                <ng-container *ngIf="column.name === 'eta'">
                  {{ row[column.name] | date: 'dd MMM yyyy' }}
                </ng-container>
                <ng-container *ngIf="column.name === 'etd'">
                  {{ row[column.name] | date: 'dd MMM yyyy' }}
                </ng-container>
              </ng-container>
              <ng-container *ngIf="column.name === 'actions'" class="mat-column-actions">
                <button class="detailed-view-btn" [ngClass]="{'loading-cell': loadingSearch}"
                  matTooltip="Show more shipment details and tracking events." matTooltipPosition="above"
                  matTooltipClass="custom-mat-tooltip"
                  (click)="viewDetails(row, detailView.shipmentDetail, detailTabbedViewEnum.trackingTab)">
                  Tracking <mat-icon>arrow_right_alt</mat-icon>
                </button>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getExtractedColumns(displayedColumns)"></tr>
        <tr mat-row *matRowDef="let row; columns: getExtractedColumns(displayedColumns);"></tr>
      </table>
    </ng-container>

    <ng-container *ngIf="isLoading" class="no-data-container">
      <div class="no-data-container">
        <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
      </div>
    </ng-container>
    <ng-container
      *ngIf="!isLoading && !errorMessage && (shipmentDataSource.filteredData.length === 0 || shipmentDataSource.data.length === 0)"
      class="no-data-container">
      <div class="no-data-container">
        No shipments found.
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading && errorMessage" class="no-data-container">
      <div class="no-data-container">
        {{ errorMessage }}
      </div>
    </ng-container>
    <mat-paginator #paginator [length]="shipmentDataSource.data.length" [pageIndex]="0" [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
    </mat-paginator>
  </div>
</div>