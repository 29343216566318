<div class="booking">
  <mat-card *ngFor="let line of bookingLineData; let i = index" class="booking-detail-card">
    <div class="header-action-container">
      <div class="booking-line-title">Booking Record {{i + 1}}</div>

      <div class="booking-line-options">
        <ng-container
          *ngIf="(line.confirmedPackDate && isConfirmedPackDateInFuture(line.confirmedPackDate)) && isEndStackInFuture(stackEnd)">
          <div class="edit-button" 
               [class.disabled]="!authService.hasClaim(ClaimAction.Root2Go_AddBookings)"
               (click)="editBookingLine(line.lineNo)" 
               [matTooltip]="
                          !authService.hasClaim(ClaimAction.Root2Go_AddBookings)
                          ? getPermissionTooltip(ClaimAction.Root2Go_AddBookings).toolTipMessage
                          : 'Submit changes to the booking record.'
               "
               matTooltipPosition="above"
               matTooltipClass="custom-mat-tooltip"
               [matTooltipShowDelay]="
                  !authService.hasClaim(ClaimAction.Root2Go_AddBookings)
                  ? getPermissionTooltip(ClaimAction.Root2Go_AddBookings).timeOut
                  : 1000"
               >
            Edit <mat-icon>edit</mat-icon>
          </div>
        </ng-container>

        <ng-container>
          <div class="edit-button" (click)="viewBookingLine(line.lineNo)" matTooltip="View all booking details."
            matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
            View <mat-icon>open_in_new</mat-icon>
          </div>
        </ng-container>

        <ng-container *ngIf="(line.confirmedPackDate && isConfirmedPackDateInFuture(line.confirmedPackDate)) && isEndStackInFuture(stackEnd) && line.containerStatus != 'Confirmed'">
          <div class="edit-button"               
               [class.disabled]="!authService.hasClaim(ClaimAction.Root2Go_AddBookings)"
               (click)="cancelBookingLine(line.lineNo, i + 1)"
               [matTooltip]="
                           !authService.hasClaim(ClaimAction.Root2Go_AddBookings)
                            ? getPermissionTooltip(ClaimAction.Root2Go_AddBookings).toolTipMessage
                            :'Submit booking cancelation to GoReefers'
                           "
               matTooltipPosition="above"
               matTooltipClass="custom-mat-tooltip"
               [matTooltipShowDelay]="
                  !authService.hasClaim(ClaimAction.Root2Go_AddBookings)
                  ? getPermissionTooltip(ClaimAction.Root2Go_AddBookings).timeOut
                  : 1000"
               >
            Cancel <mat-icon>cancel</mat-icon>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="three-columns-grid">
      <div>Booking Ref: {{line.bookingRef}}</div>
      <div>Discharge Port: {{line.dischargePort}}</div>
      <div>Commodity: {{line.commodity}}</div>
      <div>Client Ref: {{line.clientRef}}</div>
      <div>Final Destination: {{line.cnfFinalDestinationName}}</div>
      <div>Temperature: {{line.temperature}} °C</div>
      <div>Container Status: {{line.containerStatus}}</div>
      <div>Confirmed Pack Date: {{line.confirmedPackDate | date}}</div>
      <div>Quantity: {{line.quantity}}</div>
      <div>Carrier Booking Status: {{line.appBookingStatus}}</div>
      <div>Loading Point 1: {{line.loadingPoint1}}</div>
      <div>Loading Point 2: {{line.loadingPoint2}}</div>
      <div>Late Stack Request: {{line.lateStackRequest ? 'Yes' : 'No'}}</div>
      <div>ETA: {{line.eta | date}}</div>

    </div>
  </mat-card>
  <!--<div class="detailed-btn-download-group">
    <button class="add-booking-btn">
      <mat-icon>download</mat-icon>Download full booking
    </button>

    <button class="add-booking-btn">
      <mat-icon>download</mat-icon> Per load point
    </button>
  </div>-->
</div>
