<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <ng-container *ngIf="data.widgetData && data.widgetData.length > 0; else noData">
    <div class="scrollable-container">
      <mat-card *ngFor="let delay of data.widgetData; let i = index"
           class="card"
           [ngClass]="{'even-card': i % 2 === 0, 'odd-card': i % 2 !== 0}">
        <mat-card-header class="header">
          <div>Customer name: {{ delay.customerName }}</div>

        </mat-card-header>
        <div class="card-content">
          <div>Event location: {{ delay.eventLocation }}</div>
          <div>Number of days delayed: {{ delay.daysDelayed }}</div>
          <div>Container no: {{ delay.containerNo }}</div>
          <div>Booking Ref: {{ delay.bookingRefNo }}</div>
          <div>GR ref no: {{ delay.grReferenceNo }}</div>
         
        </div>
      </mat-card>
    </div>
  </ng-container>

  <ng-template #noData>
    <p>No data found for the selected criteria.</p>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button class="add-booking-btn" mat-dialog-close>Close</button>
</mat-dialog-actions>
