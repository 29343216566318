<div class="dv-documents-wrapper">
  <div class="dv-document-group">
    <h3 class="dv-table-title">Approved Documents</h3>

    <table mat-table [dataSource]="dataSourceApproved" class="dv-management-table" matSort aria-label="Elements">

      <ng-container *ngFor="let column of displayedColumns">
        <ng-container [matColumnDef]="column.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row" [hidden]="!column.visible">

            <ng-container *ngIf="column.type !== 'button'">
              <ng-container *ngIf="column.type === 'date'">
                {{ row[column.name] | date: 'yyyy-MM-dd' }}
              </ng-container>
              <ng-container *ngIf="column.type !== 'date'">
                {{ row[column.name] }}
              </ng-container>
            </ng-container>

            <!--Add a button for the 'reject' column-->
            <ng-container *ngIf="column.name === 'reject'">
              <button class="rejectButton" mat-button (click)="rejectDocument(row)">
                <img class="action-icons" src="/assets/Prohibit.svg" alt="Reject" width="20" height="20">
                Reject
              </button>
            </ng-container>

            <!--Add a button for the 'download' column-->
            <ng-container *ngIf="column.name === 'download'">
              <button class="downloadButton" mat-button (click)="downloadDocument(row)">
                <img class="action-icons" src="/assets/DownloadSimple.svg" alt="Download" width="20" height="20">
                Download
              </button>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>



      <tr mat-row *matRowDef="let row; columns: getExtractedColumns(displayedColumns);"></tr>
    </table>

  </div>

  <div class="dv-document-group">

    <h3 class="dv-table-title">Awaiting Approval Documents</h3>



    <table mat-table [dataSource]="dataSourcAwaitingAproval" class="dv-management-table" matSort aria-label="Elements">

      <ng-container *ngFor="let column of displayedColumnsWaiting">
        <ng-container [matColumnDef]="column.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row" [hidden]="!column.visible">

            <ng-container *ngIf="column.name !== 'actions'">
              <ng-container *ngIf="column.type === 'date'">
                {{ row[column.name] | date: 'yyyy-MM-dd' }}
              </ng-container>
              <ng-container *ngIf="column.type !== 'date'">
                {{ row[column.name] }}
              </ng-container>
            </ng-container>

            <!--Add a button for the 'approve' column-->
            <ng-container *ngIf="column.name === 'approve'">
              <button class="approveButton" mat-button color="primary" (click)="approveDocument(row)">
                <img class="action-icons" src="/assets/CheckCircle.svg" alt="Approve" width="20" height="20">
                Approve
              </button>
            </ng-container>

            <!--Add a button for the 'reject' column-->
            <ng-container *ngIf="column.name === 'reject'">
              <button class="rejectButton" mat-button color="primary" (click)="rejectDocument(row)">
                <img class="action-icons" src="/assets/Prohibit.svg" alt="Reject" width="20" height="20">
                Reject
              </button>
            </ng-container>

            <!--Add a button for the 'actions' column-->
            <ng-container *ngIf="column.name === 'download'">
              <button class="downloadButton" mat-button color="primary" (click)="downloadDocument(row)">
                <img class="action-icons" src="/assets/DownloadSimple.svg" alt="Dowmload" width="20" height="20">
                Download
              </button>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>


      <tr mat-row *matRowDef="let row; columns: getExtractedColumns(displayedColumnsWaiting);"></tr>
    </table>


  </div>

  <div class="dv-document-group">

    <h3 class="dv-table-title">Documents Resubmitted</h3>


    <table mat-table [dataSource]="dataSourceResubmitted" class="dv-management-table" matSort aria-label="Elements">

      <ng-container *ngFor="let column of displayedColumnsResubmitted">
        <ng-container [matColumnDef]="column.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row" [hidden]="!column.visible">

            <ng-container *ngIf="column.name !== 'actions'">
              <ng-container *ngIf="column.type === 'date'">
                {{ row[column.name] | date: 'yyyy-MM-dd' }}
              </ng-container>
              <ng-container *ngIf="column.type !== 'date'">
                {{ row[column.name] }}
              </ng-container>
            </ng-container>

            <!--Add a button for the 'approve' column-->
            <ng-container *ngIf="column.name === 'approve'">
              <button class="approveButton" mat-button color="primary" (click)="approveDocument(row)">
                <img class="action-icons" src="/assets/CheckCircle.svg" alt="Approve" width="20" height="20">
                Approve
              </button>
            </ng-container>

            <!--Add a button for the 'reject' column-->
            <ng-container *ngIf="column.name === 'reject'">
              <button class="rejectButton" mat-button color="primary" (click)="rejectDocument(row)">
                <img class="action-icons" src="/assets/Prohibit.svg" alt="Reject" width="20" height="20">
                Reject
              </button>
            </ng-container>

            <!--Add a button for the 'actions' column-->
            <ng-container *ngIf="column.name === 'download'">
              <button class="downloadButton" mat-button color="primary" (click)="downloadDocument(row)">
                <img class="action-icons" src="/assets/DownloadSimple.svg" alt="Download" width="20" height="20">
                Download
              </button>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>


      
      <tr mat-row *matRowDef="let row; columns: getExtractedColumns(displayedColumnsResubmitted);"></tr>
    </table>

  </div>

  <div class="dv-document-group">

    <h3 class="dv-table-title">Documents Rejected</h3>


    <table mat-table [dataSource]="dataSourceRejected" class="dv-management-table" matSort aria-label="Elements">

      <ng-container *ngFor="let column of displayedColumnsRejected">
        <ng-container [matColumnDef]="column.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row" [hidden]="!column.visible">

            <ng-container *ngIf="column.name !== 'actions'">
              <ng-container *ngIf="column.type === 'date'">
                {{ row[column.name] | date: 'yyyy-MM-dd' }}
              </ng-container>
              <ng-container *ngIf="column.type !== 'date'">
                {{ row[column.name] }}
              </ng-container>
            </ng-container>

            <!--Add a button for the 'actions' column-->
            <ng-container *ngIf="column.name === 'reasonDetail'">
              <button class="reason-button" mat-button (click)="showDetail(row)">
                <img class="action-icons" src="/assets/ChatCenteredDots.svg" alt="Reason" width="20" height="20">
                Reason
              </button>
            </ng-container>

            <!--Add a button for the 'actions' column-->
            <ng-container *ngIf="column.name === 'download'">
              <button class="downloadButton" mat-button (click)="downloadDocument(row)">
                <img class="action-icons" src="/assets/DownloadSimple.svg" alt="Download" width="20" height="20">
                Download
              </button>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>


      <tr mat-row *matRowDef="let row; columns: getExtractedColumns(displayedColumnsRejected);"></tr>
    </table>
  </div>
</div>
