import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { OverpassService } from '../../service/overpass/overpass.service';
import { SeaRouteGraph, dijkstra } from '../../haversine';

@Component({
  selector: 'app-overview-ocean-tracking',
  templateUrl: './overview-ocean-tracking.component.html',
  styleUrls: ['./overview-ocean-tracking.component.css']
})
export class OverviewOceanTrackingComponent implements OnInit {
  private map!: L.Map;
  private graph!: SeaRouteGraph;
  public routePoints: [number, number][] = []; // Store the route points

  constructor(private overpassService: OverpassService) { }

  ngOnInit(): void {
    this.initMap();

    const bbox = '51.0,-0.5,51.5,0.5'; // Adjust this as needed for your region

    this.overpassService.getSeaRoutes(bbox).subscribe(data => {
      console.log('Fetched sea route data', data);

      this.graph = new SeaRouteGraph();

      data.elements.forEach((element: any) => {
        if (element.type === 'way' && element.geometry.length > 1) {
          // Use the geometry directly for the graph edges
          const nodes = element.geometry.map((g: any) => [g.lat, g.lon] as [number, number]);

          // Add edges to the graph
          for (let i = 0; i < nodes.length - 1; i++) {
            this.graph.addEdge(nodes[i], nodes[i + 1]);
          }

          // Draw the entire route as a single polyline
          this.addPolylineToMap(nodes);
        }
      });


      const startPoint: [number, number] = [30.0444, 31.2357];  // Cairo, Egypt
      const endPoint: [number, number] = [31.2156, 29.9553];    // Alexandria, Egypt

      console.log('Graph edges:', this.graph.getGraph());

      const startKey = JSON.stringify(startPoint);
      const endKey = JSON.stringify(endPoint);
      if (!this.graph.getGraph().has(startKey) || !this.graph.getGraph().has(endKey)) {
        console.error('Start or end point not in the graph.');
        return;
      }

      const route = dijkstra(this.graph.getGraph(), startPoint, endPoint);

      if (route) {
        this.routePoints = route;
        console.log('Route points:', this.routePoints);

        // Merge route segments into a single array of coordinates
        const latLngs = route.map(([lat, lon]) => L.latLng(lat, lon));

        // Check if latLngs has at least one point before drawing
        if (latLngs.length > 0) {
          L.polyline(latLngs, { color: 'blue' }).addTo(this.map);
        }
      } else {
        console.error('No route found between the given points.');
      }
    }, error => {
      console.error('Error fetching sea routes data:', error);
    });
  }

  private initMap(): void {
    if (!this.map) {
      this.map = L.map('mapId').setView([51.505, -0.09], 5);
      L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        maxZoom: 18,
        attribution: 'Map data &copy; OpenStreetMap contributors',
      }).addTo(this.map);
    }
  }

  private addPolylineToMap(nodes: [number, number][]): void {
    const latLngs = nodes.map(([lat, lon]) => L.latLng(lat, lon));
    L.polyline(latLngs, { color: 'red' }).addTo(this.map);
  }
}
