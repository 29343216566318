import { Component, OnInit, inject, Output, EventEmitter } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IUserInfoModel, UserInfoModel } from '../models/user-info-model';
import { ApiUserService } from '../service/user/api-user.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  userInfoModel: IUserInfoModel = new UserInfoModel();

  private apiUserService = inject(ApiUserService)
  private app = inject(AppComponent); 
  constructor(private router: Router) {}

  @Output() menuOpenerClicked = new EventEmitter<void>();
  @Output() openAlerts = new EventEmitter<void>();

  onMenuOpenerClick() {
    this.menuOpenerClicked.emit();
  }
  onOpenAlerts() {
    this.openAlerts.emit();
  }

  ngOnInit(): void {
    this.apiUserService.userInfo.subscribe((userInfo) => {
      this.userInfoModel = userInfo;
      this.app.subscribeNotifications(userInfo.userId);
    });
  }
  
  refreshPage() {
    this.router.navigate(['/']) 
      //.then(() => {
      //  window.location.reload();
      //});
  }


  private breakpointObserver = inject(BreakpointObserver);
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
  );


  isTablet$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Tablet)
    .pipe(
      map(result => result.matches),
      shareReplay()
  );

  isDesktop$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Web)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
}
