import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  loadGoogleAnalytics() {
    if (!environment.googleAnalyticsId) return;

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
    document.head.appendChild(script);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${environment.googleAnalyticsId}');
    `;
    document.head.appendChild(inlineScript);
  }

  loadHotjar() {
    if (!environment.hotjarId) return;

    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = `
      (function(h, o, t, j, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
        h._hjSettings = { hjid: ${environment.hotjarId}, hjsv: ${environment.hotjarVersion} };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script'); r.async = 1;
        j = '.js?sv='
        r.src = 'https://static.hotjar.com/c/hotjar-' + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, '.js?sv=');
    `;
    document.head.appendChild(script);


  }
}
