import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppUtilityService {
  error_messages = {
    service_failure: 'Service failed to process your request. Please try again later.',
  };

  constructor(private http: HttpClient) { }

  displayAlertDialog(options: {
    data: {
      title: string;
      message: string;
      dismiss_text: string;
      action_text: string;
      action: () => void;
    };
  }): void {
    console.log('Alert Dialog:', options.data.title, options.data.message);
    console.log('Dismiss:', options.data.dismiss_text, '| Action:', options.data.action_text);
    // Simulate action confirmation
    if (confirm(`${options.data.title}\n\n${options.data.message}`)) {
      options.data.action();
    }
  }


  scrollToElement(selector: string, offset: number = 0): void {
    console.log(`Scroll to element with selector: ${selector} and offset: ${offset}`);
    // Simulate scrolling
    const element = document.querySelector(selector);
    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top, behavior: 'smooth' });
    }
  }

  // Unsubscribe all subscriptions
  unsubscribeAll(subscriptions: (Subscription | undefined)[]): void {
    subscriptions.forEach((sub) => sub?.unsubscribe());
    console.log('All subscriptions have been unsubscribed.');
  }
}
