<div>
  <div class="middle">
    <img src="../../assets/R2G Circle.png" alt="Root2Go logo" style="border: 7px solid grey; border-radius: 100%; width: 150px; height: 150px;"/>
    <div class="text">
      <p>
        ROOT2GO is transforming the way the fruit shipping industry manages its logistics and supply chain operations.
        With its innovative platform, ROOT2GO delivers real-time, actionable insights at your fingertips, offering seamless
        interaction with key data points. Whether you're on the go or in the office, you can access a live dashboard 24/7
        to monitor the critical stages of your supply chain.
      </p>
      <p>
        With ROOT2GO, your fruit and cargo can now move with greater efficiency, reliability, and insight—keeping produce
        fresh, deliveries timely, and businesses ahead of the curve.
      </p>
    </div>
  </div>


  <div class="middle">
    <img src="../../assets/GoGloballogo2.png" alt="GoGlobal logo" style="border: 7px solid #888888; border-radius: 100%; width: 150px; height: 150px;" />
    <div class="text">
      <p>
        We’re proudly part of the GOGLOBAL GROUP.
      </p>
      <p>For information about us please visit the GOGLOBAL website:</p>
      <button mat-button class="info-button" onclick="window.open('https://goglobal.group/about-goglobal/', '_blank')" style="display: flex; align-items: center; gap: 8px;">
        Visit Website
        <img src="../../assets/ArrowUpRight.svg" alt="Arrow up" class="link-icon" style="width:20px; height:20px" />
      </button>
    </div>
  </div>
</div>
