<div class="dialog-wrap">
  <div mat-dialog-content class="dialog-reason">
    <p>Are you sure you want to cancel adding a new booking? Your current captured data will be lost.</p>
  </div>

  <div mat-dialog-actions>
    <button class="dialog-deny-button" mat-button (click)="onSave()">Yes</button>
    <button class="dialog-okay-button" mat-button (click)="onCancel()">No</button>
  </div>
</div>
