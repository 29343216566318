<div class="booking">
  <mat-card *ngFor="let line of bookingLineData; let i = index" class="booking-detail-card">
    <div class="header-action-container">
      <div class="booking-line-title">Booking Record {{i + 1}}</div>

      <div class="booking-line-options">
        <ng-container
          *ngIf="(line.confirmedPackDate && isConfirmedPackDateInFuture(line.confirmedPackDate)) && isEndStackInFuture(stackEnd)">
          <div class="edit-button" (click)="editBookingLine(line.lineNo)"
            matTooltip="Submit changes to the booking record." matTooltipPosition="above"
            matTooltipClass="custom-mat-tooltip">
            Edit <mat-icon>edit</mat-icon>
          </div>
        </ng-container>

        <ng-container>
          <div class="edit-button" (click)="viewBookingLine(line.lineNo)" matTooltip="View all booking details."
            matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
            View <mat-icon>open_in_new</mat-icon>
          </div>
        </ng-container>

        <ng-container
          *ngIf="(line.confirmedPackDate && isConfirmedPackDateInFuture(line.confirmedPackDate)) && isEndStackInFuture(stackEnd) && line.containerStatus != 'Confirmed'">
          <div class="edit-button" (click)="cancelBookingLine(line.lineNo)"
            matTooltip="Submit booking cancelation to GoReefers" matTooltipPosition="above"
            matTooltipClass="custom-mat-tooltip">
            Cancel <mat-icon>cancel</mat-icon>
          </div>
        </ng-container>
      </div>
    </div>

    <mat-card-content class="booking-detail-content">
      <div class="booking-line booking-line-item">
        <div class="col-md-4">
          <div>Booking Ref: {{line.bookingRef}}</div>
          <div>Client Ref: {{line.clientRef}}</div>
          <div>Container Status: {{line.containerStatus}}</div>
          <div>Carrier Booking Status: {{line.appBookingStatus}}</div>
        </div>

        <div class="col-md-4">
          <div>Discharge Port: {{line.dischargePort}}</div>
          <div>Final Destination: {{line.cnfFinalDestinationName}}</div>
          <div>Confirmed Pack Date: {{line.confirmedPackDate | date}}</div>
          <div>Loading Point 1: {{line.loadingPoint1}}</div>
          <div>Loading Point 2: {{line.loadingPoint2}}</div>
        </div>

        <div class="col-md-4">
          <div>Commodity: {{line.commodity}}</div>
          <div>Temperature: {{line.temperature}} °C</div>
          <div>Quantity: {{line.quantity}}</div>
          <div>Late Stack Request: {{line.lateStackRequest ? 'Yes' : 'No'}}</div>
          <div>ETA: {{line.eta | date}}</div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <!--<div class="detailed-btn-download-group">
    <button class="add-booking-btn">
      <mat-icon>download</mat-icon>Download full booking
    </button>

    <button class="add-booking-btn">
      <mat-icon>download</mat-icon> Per load point
    </button>
  </div>-->
</div>