import { OnInit, OnDestroy, Input, SimpleChanges, Component } from "@angular/core";
import L from "leaflet";
import { PathPoint, RouteInformation } from "../../models/shipment/route-information-model";
import { ShipmentTrackingHeader, ShipmentTrackingVessel } from "../../models/shipment/shipment-tracking-header-model";
import { ShipmentService } from "../../service/shipment/shipment.service";
import { DatePipe } from "@angular/common";
import { detailTabbedViewEnum, detailView } from "../../models/detail-view-enum";
import { CommunicationService } from "../../service/communication/communication.service";
import { ShipmentTranferGRRefContainerNo } from "../../models/shipment/shipment-data-model";
import { OceanTrackingViewEnum } from "../../enums/ocean-tracking-view-enum";
import { FilterData } from "../../models/list-model";
import { Observable } from "rxjs/internal/Observable";
import { of } from "rxjs/internal/observable/of";
import { catchError, map } from "rxjs/operators";
import { LegendSetup } from "../../models/legend";


interface Location {
  locationCode: string;
  name: string;
  countryCode: string;
  country: string;
  latitude: number;
  longitude: number;
}

@Component({
  selector: 'app-container-tracking',
  templateUrl: './container-tracking.component.html',
  styleUrl: './container-tracking.component.css'
})


export class ContainerTrackingComponent implements OnInit, OnDestroy {
  private mapCont!: L.Map;
  private voyageMarkers: L.Marker[] = [];
  private mapInitialized: boolean = false;
  private currentPath: L.Polyline | null = null;
  private currentGreenPath: L.Polyline | null = null;
  private activeMarker: L.Marker | null = null; // To track the currently selected marker
  private blueMarkers: L.Marker[] = [];  // New variable to track blue markers
  public GetRoute: ShipmentTrackingHeader = new ShipmentTrackingHeader;

  public isLoading = false;
  public showRoute: boolean = false;  // To toggle route display
  public showTracking: boolean = false;  // To toggle tracking display

  legend: LegendSetup[] = [
    { iconClass: 'icon ship-icon eta', text: 'Vessel' },
    //{ iconClass: 'icon ship-icon eta-changed', text: 'ETA moved to a later date.' },
    { iconClass: 'icon ship-icon line-expected', text: 'Expected vessel route' },
    { iconClass: 'icon ship-icon line-actual', text: 'Actual route' },
  ];

  startLocation: L.LatLngExpression = [0,0];
  //@Input() voyageCoordinates: ShipmentTrackingHeader[] = [];
  //@Input() endLocation!: L.LatLngExpression;
  @Input() vesselDataList: ShipmentTrackingVessel = new ShipmentTrackingVessel;
  @Input() shipmentDataList: ShipmentTrackingHeader = new ShipmentTrackingHeader;
  @Input() actualPathCoordinates: L.LatLngExpression[] = [];
  @Input() routeHeaderData: RouteInformation = new RouteInformation;

  constructor(private shipmentService: ShipmentService, private communicationService: CommunicationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    console.log("Vessels:", this.vesselDataList);
    console.log("Containers:", this.shipmentDataList);
    setTimeout(() => {
      this.initMap();
      console.log('This message is delayed by 100 milliseconds');
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['actualPathCoordinates'] || changes['routeHeaderData'] || changes['endLocation']) {
        this.removeGreenPath();
        this.updateMarkersOnMap();
    }
  }

  ngOnDestroy(): void {
    if (this.mapCont) {
      this.mapCont.remove();
    }
  }


  private initMap(): void {
    if (this.mapInitialized === true) {
      return;
    }

    if (!this.startLocation) {
      this.startLocation = [0, 0];
    }

    const southWest = L.latLng(-90, -180);
    const northEast = L.latLng(90, 180);
    const bounds = L.latLngBounds(southWest, northEast);

    let minZoom = 3;

    if (window.innerWidth <= 576) {
      minZoom = 1.5; // Higher minZoom for mobile for a closer view
    } else if (window.innerWidth <= 1024) {
      minZoom = 2; // Medium minZoom for tablets
    }

    this.mapCont = L.map('mapCont', { worldCopyJump: true, maxBounds: bounds, trackResize: true }).setView(this.startLocation, minZoom);

    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      maxZoom: 18,
      minZoom: minZoom,
      noWrap: true,
      attribution: 'Map data &copy; OpenStreetMap contributors',
    }).addTo(this.mapCont);

    this.mapInitialized = true;


    if (this.vesselDataList) {
      this.updateMarkersOnMap();
    } else {
      this.setDefaultZoom();
    }

  }

  private updateMarkersOnMap(): void {
    if (this.mapInitialized === false) {
      return;
    }

    this.removeAllMarkers();

    if (this.vesselDataList) {
      
      this.addVoyageMarker();
      this.zoomToMarkers();
    } else {
      this.setDefaultZoom();
    }
  }

  private removeAllMarkers(): void {
    this.voyageMarkers.forEach(marker => {
      this.mapCont.removeLayer(marker);
    });
    this.voyageMarkers = [];

    // Remove all blue markers
    this.blueMarkers.forEach(marker => {
      this.mapCont.removeLayer(marker);
    });
    this.blueMarkers = [];
  }

  private addVoyageMarker(): void {
    const formattedPackDate = this.datePipe.transform(this.shipmentDataList.eta, 'dd MMM yyyy');

    if (this.shipmentDataList.currentLatitude && this.shipmentDataList.currentLongitude) {
      let marker = L.marker([this.shipmentDataList.currentLatitude, this.shipmentDataList.currentLongitude], {
        icon: L.icon({
          iconUrl: '../../assets/Ship-Icon.svg',
          iconSize: [24, 24],
          iconAnchor: [12, 12],
        }),
      }).addTo(this.mapCont);

      this.voyageMarkers.push(marker);
    }

    if (this.shipmentDataList) { 

      this.shipmentService.getShipmentTrackingHeaders(this.shipmentDataList.bookingReference, this.shipmentDataList.containerNumber).
        subscribe(routeLink =>
        {
          this.GetRoute = routeLink;
          const decodedLink = decodeURIComponent(this.GetRoute.routeInformationMediaEditLink);
          this.shipmentService.getShipmentRouteInformation(decodedLink).subscribe(
            (data) => {
              console.log('Fetched Route Data:', data);
              this.displayRouteOnMap(data);
            }
          );

          this.drawGreenPath(this.actualPathCoordinates);
        })


    } else {
      console.warn(`Invalid coordinates for vessel`);

      this.showNoVesselMessage(); 
    }
  }

  private removeBlueMarkers(): void {
    this.blueMarkers.forEach(marker => {
      this.mapCont.removeLayer(marker);
    });
    this.blueMarkers = [];
  }

  //getCurrentRoute(bookingRef: string, bookingLineRef: string): void {
  //  this.shipmentService.getShipmentsTrackingHistory(bookingRef, bookingLineRef).subscribe(
  //    (data) => {
  //      console.log('Fetched Route Data:', data);

  //      // Assuming the data is an array of tracking points (latitude, longitude)
  //      const pathCoordinates: L.LatLngExpression[] = data.map((point: any) => {
  //        return [point.latitude, point.longitude] as L.LatLngExpression;
  //      });

  //      // Remove previous paths before drawing the new one
  //      // Draw the tracking path
  //      this.drawGreenPath(pathCoordinates);
  //    },
  //    (error) => {
  //      console.error('Error fetching route data:', error);
  //    }
  //  );
  //}

  private removePaths(): void {
    // Remove previously drawn paths
    if (this.currentPath) {
      this.mapCont.removeLayer(this.currentPath);
      this.currentPath = null;
    }

  }

  private removeGreenPath(): void {
    if (this.currentGreenPath) {
      this.mapCont.removeLayer(this.currentGreenPath);
      this.currentGreenPath = null;
    }
  }

  private drawGreenPath(pathCoordinates: L.LatLngExpression[]): void {
    this.removeGreenPath();


    this.currentGreenPath = L.polyline(pathCoordinates, {
      color: 'green',
      weight: 4,
      opacity: 0.7
    }).addTo(this.mapCont);
  }

  private displayRouteOnMap(routeData: any): void {
    this.isLoading = true;
    const locations: Location[] = routeData.locations;
    const path: PathPoint[] = routeData.path;

    // Remove any existing path before displaying the new one

    locations.forEach((location: Location) => {
      const { latitude, longitude, name, country } = location;

      const blueIcon = L.icon({
        iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png',
        iconSize: [25, 41],
        iconAnchor: [12, 12],
        popupAnchor: [1, -34],
        shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
        shadowSize: [41, 41],
      });

      let blueMarker = L.marker([latitude, longitude], { icon: blueIcon })
        .addTo(this.mapCont)
        .bindPopup(`
       <div>
         <strong>${name}</strong><br />
         ${country}
       </div>
     `);

      this.blueMarkers.push(blueMarker);
    });


    this.removePaths();

    const pathCoordinates: L.LatLngExpression[] = path.map((point: PathPoint) => {
      return [point.latitude, point.longitude] as L.LatLngExpression;
    });

    this.currentPath = L.polyline(pathCoordinates, { color: '#368EDE', dashArray: '5, 10' }).addTo(this.mapCont);
    this.mapCont.fitBounds(L.latLngBounds(pathCoordinates));
    this.isLoading = false;
  }

  viewDetails(view: detailView, tab: detailTabbedViewEnum, grRef: string, containerNo: string): void {
    this.communicationService.toggleDetailView(view, tab);

    let info: ShipmentTranferGRRefContainerNo = new ShipmentTranferGRRefContainerNo;

    info.grReferenceNo = grRef;
    info.containerNo = containerNo;

    this.communicationService.getDetailViewGRRefcontainerNo(info);
  }

  private zoomToMarkers(): void {
    //if (this.mapCont ) {
    //  const bounds = new L.LatLngBounds(
    //    this.shipmentDataList.
    //  );
      //this.mapCont.fitBounds(bounds);
    //}
  }

  private showNoVesselMessage(): void {
    this.removeNoVesselMessage();
    const messageContent = 'No tracking history to display';
    const noVehiclesMarker = L.marker([0, 0], {
      icon: L.divIcon({
        className: 'no-vehicles-message',
        html: `<div style="
          background-color: white;
          border: 2px solid #f00;
          padding: 5px;
          border-radius: 5px;
          color: red;
          font-weight: bold;
          display: flex; 
          justify-content: center;
          text-align: center;
          align-items: center;
          ">${messageContent}</div>`,
        iconSize: [150, 50],
        iconAnchor: [75, 25],
      }),
    }).addTo(this.mapCont);
  }

  private removeNoVesselMessage(): void {
    if (this.mapCont) {
      this.mapCont.eachLayer(layer => {
        if (layer instanceof L.Marker) {
          const popup = layer.getPopup();
          if (popup && popup.getContent() === 'No vehicles to display') {
            this.mapCont.removeLayer(layer);
          }
        }
      });
    }
  }

  private setDefaultZoom(): void {
    if (this.mapCont) {
      this.mapCont.setView([0, 0], 3);
    }
  }
}
