import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CreateFilterComponent } from '../create-filter/create-filter.component';
import { InformationComponent } from '../information/information.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common'


@Component({
  selector: 'app-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrl: './cancel-booking.component.css'
})
export class CancelBookingComponent {
  constructor(
    public dialogRef: MatDialogRef<CreateFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private router: Router,
    private location: Location
  ) { }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.dialogRef.close(this.data);
    this.location.back()
  }

}
