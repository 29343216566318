import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ApiUserService } from '../../service/user/api-user.service';
import { Subject } from 'rxjs/internal/Subject';
import { LookupModel } from '../../models/lookup-model';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { catchError, map, Observable, of, skip, startWith, takeUntil } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { BookingService } from '../../service/booking/booking.service';
import { LookupService } from '../../service/lookup/lookup.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { FilterData } from '../../models/list-model';
import { DataFilter, FilterTransferModel } from '../../models/filter-models';
import { AddBookingModel, AddBookingEnum, BookingDataTransferModel } from '../../models/booking-data-model';
import { Router } from '@angular/router';
import { ScheduleListModel } from '../../models/schedules/schedule-list-model';
import { ShipmentModel } from '../../models/shipment/shipment-data-model';
import { BookingEditModel } from '../../models/bookings/booking-edit-model';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

export function dateNotInPast(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const currentDate = new Date();
    const selectedDate = control.value;

    currentDate.setHours(0, 0, 0, 0);

    if (selectedDate && selectedDate < currentDate) {
      return { 'dateInPast': true };
    }
    return null;
  };
}

@Component({
  selector: 'app-add-booking',
  templateUrl: './add-booking.component.html',
  styleUrl: './add-booking.component.css'
})
export class AddBookingComponent implements OnInit {

  profileForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),

  });


  //Get customer data
  private apiUserService = inject(ApiUserService)
  isCustUser: boolean = false;

  //Setup Form 

  editBookingTitle: string = 'New Booking';
  private destroy$ = new Subject<void>();
  protected setDate = new Date();
  public dateErrorMessage: string | null = null;
  protected range = new FormGroup({
    dateFrom: new FormControl<Date | null>(new Date(), [Validators.required, dateNotInPast()]),
    dateTo: new FormControl<Date | null>({
      value: this.addDays(new Date(), 14),
      disabled: false,
    }, Validators.required),
  });
  today: Date = new Date();
  protected currentFilter: DataFilter = new DataFilter();

  loadPorts = new FormControl<string>('');
  loadPortsMultiFilter = new FormControl<string>('');
  filteredLoadPorts!: Observable<LookupModel[]>;
  bookingDataLoadPort: LookupModel[] = [];

  dischargePorts = new FormControl<string>('');
  dischargePortsMultiFilter = new FormControl<string>('');
  filteredDischargePorts!: Observable<LookupModel[]>;
  bookingDataDischargePorts: LookupModel[] = [];

  markets = new FormControl<string>('');
  marketsMultiFilter = new FormControl<string>('');
  filteredMarkets!: Observable<LookupModel[]>;
  bookingDataMarkets: LookupModel[] = [];

  countries = new FormControl<string>('');
  countriesMultiFilter = new FormControl<string>('');
  filteredCountries!: Observable<LookupModel[]>;
  bookingDataCountries: LookupModel[] = [];

  carriers = new FormControl<string>('');
  carriersMultiFilter = new FormControl<string>('');
  filteredCarriers!: Observable<LookupModel[]>;
  scheduleDataCarriers: LookupModel[] = [];

  //Setup filter data models
  filterData: FilterData = {};
  datafilter: DataFilter = new DataFilter();
  filterTransfer: FilterTransferModel = new FilterTransferModel(-1, {})

  //Setup booking dataa models
  booking: AddBookingModel = new AddBookingModel();
  bookingType: AddBookingEnum = AddBookingEnum.newBooking;
  bookingTransfer: BookingDataTransferModel = new BookingDataTransferModel();
  bookingData$?: Observable<BookingEditModel>;
  bookingData: BookingEditModel = new BookingEditModel()
  stackStatus = new FormControl<string>('');
  stackStatusMultiFilter = new FormControl<string>('');
  filteredStackStatus!: Observable<LookupModel[]>;
  scheduleDataStackStatus: LookupModel[] = [];

  scheduleSelected: boolean = false;
  voyage: ScheduleListModel | null = null;

  isReadOnly = false;
  isAddBooking = false;

  stackStatusData: LookupModel[] = [
    { name: 'Provisional', code: 'Provisional', isSelected: false },
    { name: 'Firm', code: 'Firm', isSelected: false },
    { name: 'Port Omitted', code: 'Port Omitted', isSelected: false }
  ]

  get dateFrom() {
    return this.range.get('dateFrom') as FormControl;
  }

  get dateTo() {
    return this.range.get('dateTo') as FormControl;
  }

  private addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  constructor(
    private domSanitizer: DomSanitizer, 
    private bookingDataService: BookingService, 
    private lookupService: LookupService, 
    private cdr: ChangeDetectorRef, 
    private dialog: MatDialog, 
    private matIconRegistry: MatIconRegistry, 
    private router: Router,
  ) {
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
    const navigation = this.router.getCurrentNavigation();
    this.bookingTransfer = navigation?.extras.state?.['data'];
  }

  //Setup Snackbar
  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private handleError<T>(message: string, result?: T): Observable<T> {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-fail']
    });
    return of(result as T);
  }

  public openSnackBar(message: string, panelClass: string = 'snackbar-success') {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: [panelClass]
    });
  }
  ngOnInit(): void {
    //this.today = new Date();
    this.today.setHours(0, 0, 0, 0);

    this.loadLookUpData()
    this.loadStartupData();
  }

  loadLookUpData(): void {
    //get Lookup data
    this.lookupService.getLoadPorts()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataLoadPort = data;
          this.filteredLoadPorts = this.loadPortsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterLoadPorts(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

    this.lookupService.getCarriers(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.scheduleDataCarriers = data;
          this.filteredCarriers = this.carriersMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCarriers(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Carrier data', error)
      });

    of(this.stackStatusData)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          this.scheduleDataStackStatus = data;
          this.filteredStackStatus = this.stackStatusMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterStackStatus(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error handling stack status data', error)
      });


    this.lookupService.getDischargePorts()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataDischargePorts = data;
          this.filteredDischargePorts = this.dischargePortsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterDischargePorts(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Discharge Port data', error)
      });

    this.lookupService.getMarkets()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataMarkets = data;
          this.filteredMarkets = this.marketsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterMarkets(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Market data', error)
      });

    of(this.stackStatusData)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          this.scheduleDataStackStatus = data;
          this.filteredStackStatus = this.stackStatusMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterStackStatus(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error handling stack status data', error)
      });


    this.lookupService.getCountries()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataCountries = data;
          this.filteredCountries = this.countriesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCountries(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Country data', error)
      });

    this.dateFrom.valueChanges.subscribe(startDate => {
      if (startDate) {
        this.setDate = startDate;
      } else {
        this.dateTo.clearValidators();
        this.dateErrorMessage = null; // Clear error message if dateFrom is empty
      }
      this.dateTo.updateValueAndValidity();
    });
  }

  clearDate(event: MouseEvent, field: 'dateFrom' | 'dateTo'): void {
    event.preventDefault(); // Prevent default action

    // Clear the specified date field
    if (field === 'dateFrom') {
      this.dateFrom.reset(); // Resets dateFrom to null
    } else if (field === 'dateTo') {
      this.dateTo.reset(); // Resets dateTo to null
    }
  }


  loadStartupData(): void {
    //Get startup Data

    //If there is no information
    if (this.bookingTransfer === undefined) {
      this.bookingTransfer = new BookingDataTransferModel();
      this.bookingTransfer.bookingType = AddBookingEnum.newBooking;
      this.bookingTransfer.appBookingCode = '';
      this.bookingTransfer.appBookingLineNumber = 0;
      this.bookingData = new BookingEditModel();
      this.bookingData$ = this.bookingDataService.getBookingEditModel(
        this.bookingTransfer.appBookingCode,
        this.bookingTransfer.appBookingLineNumber,
        this.bookingTransfer.scheduleCode,
        this.bookingTransfer.loadPortCode,
        this.bookingTransfer.dischargePortCode,
        this.bookingTransfer.carrierCode);
    }
    else {
      this.bookingData$ = this.bookingDataService.getBookingEditModel(
        this.bookingTransfer.appBookingCode,
        this.bookingTransfer.appBookingLineNumber,
        this.bookingTransfer.scheduleCode,
        this.bookingTransfer.loadPortCode,
        this.bookingTransfer.dischargePortCode,
        this.bookingTransfer.carrierCode
      );}

      //What am I doing here
      this.bookingData$!.subscribe(bd => {
        this.bookingData = bd;

        //Switch to edit booking header and what to show depending on the AddBooking Enum received
        switch (this.bookingTransfer.bookingType) {
          case AddBookingEnum.newBookingSailingSchedule:
            this.editBookingTitle = 'New Booking';
            this.scheduleSelected = true;
            this.loadPorts.setValue(this.bookingData.loadPortCode);
            this.dischargePorts.setValue(this.bookingData.dischargePortCode);
            this.isAddBooking = true;            
            break;
          case AddBookingEnum.viewBookingLine:
            this.editBookingTitle = 'View Booking';
            this.scheduleSelected = true;
            this.loadPorts.setValue(this.bookingData.loadPortCode);
            this.dischargePorts.setValue(this.bookingData.dischargePortCode);
            this.isReadOnly = true;
            break;
          case AddBookingEnum.addBookingLine:
            this.editBookingTitle = 'New Booking Line';
            this.scheduleSelected = true;
            this.loadPorts.setValue(this.bookingData.loadPortCode);
            this.dischargePorts.setValue(this.bookingData.dischargePortCode);
            break;
          case AddBookingEnum.editBookingLine:
            this.editBookingTitle = 'Edit Booking Line';
            this.scheduleSelected = true;
            this.loadPorts.setValue(this.bookingData.loadPortCode);
            this.dischargePorts.setValue(this.bookingData.dischargePortCode);
            break;
          case AddBookingEnum.copyBookingLine:
            this.editBookingTitle = 'Copy Booking';
            this.scheduleSelected = true;
            this.loadPorts.setValue(this.bookingData.loadPortCode);
            this.dischargePorts.setValue(this.bookingData.dischargePortCode);
            break;
          case AddBookingEnum.newBooking:
            this.editBookingTitle = 'New Booking';
            this.scheduleSelected = false;
            this.isAddBooking = true;
            break;
          default:
            this.editBookingTitle = 'New Booking';
            this.scheduleSelected = false;
            break;
        }
      })
    
  }

  //What am I doing here?
  subscribeToBookingVoyage(bookingDataFromVoyage: BookingEditModel): void {

    if (bookingDataFromVoyage.carrierCode !== '') {
      //receive data from component booking-voyage-search
      this.bookingData$ = of(bookingDataFromVoyage);;
      this.scheduleSelected = true
      this.bookingData$.subscribe(bd => {
        this.bookingData = bd;
      })
      this.cdr.markForCheck(); // Trigger change detection
    }
    else {
      this.bookingData$ = of(bookingDataFromVoyage);;
      this.scheduleSelected = false
      this.bookingData$.subscribe(bd => {
        this.bookingData = bd;
      })
    }
  }

  //Transfer filter data to booking-voyage-search
  public runFilter(): void {
    if ((this.countries.value === ''||this.countries.value === null) && (this.dischargePorts.value === ''||this.dischargePorts.value === null)) {
      this.openSnackBar('You need to select either a Country or a Discharge Port before searching', 'snackbar-fail');
      return;
    }

    if (this.range.invalid) {
      this.range.markAllAsTouched();
      return;
    }
    else {
      this.createFilter();
      this.filterTransfer.filter = this.filterData;
      this.filterTransfer.startDate = this.dateFrom.value ? new Date(this.dateFrom.value) : undefined;
      this.filterTransfer.endDate = this.dateTo.value ? new Date(this.dateTo.value) : undefined;

      this.bookingDataService.sendingAddBookingFilterData(this.filterTransfer);
    }
  }

  //function to clear a filter control
  clearFilter(control: FormControl) {
    control.reset();
    control.setValue([]);
  }

  //Setup search controls filters
  private filterLoadPorts(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataLoadPort;
    }
    return this.bookingDataLoadPort.filter(loadPort =>
      loadPort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCarriers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.scheduleDataCarriers;
    }
    return this.scheduleDataCarriers.filter(carrier =>
      carrier.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterDischargePorts(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataDischargePorts;
    }
    return this.bookingDataDischargePorts.filter(dischargePort =>
      dischargePort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterMarkets(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataMarkets;
    }
    return this.bookingDataMarkets.filter(market =>
      market.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCountries(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataCountries;
    }
    return this.bookingDataCountries.filter(country =>
      country.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterStackStatus(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.scheduleDataStackStatus;
    }
    return this.scheduleDataStackStatus.filter(stackStatus =>
      stackStatus.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  //create filter to send
  createFilter(): void {
      
      this.processFilterData('carrierCode', this.carriers.value);

      this.processFilterData('loadPortCode', this.loadPorts.value?.toString()|| '');
  
      this.processFilterData('dischargePortCode', this.dischargePorts.value?.toString()|| '');
    
    if (this.markets.value != null && this.markets.value != '') {
      const marketsCodes = this.markets.value;
      this.processFilterData('marketCode', marketsCodes);
    }

      this.processFilterData('countryCode', this.countries.value?.toString()|| '');
  
      this.processFilterData('stackStatus', this.stackStatus.value?.toString()|| '');
    
  }

  dateNotInPast(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const selectedDate = control.value ? new Date(control.value) : null;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (selectedDate && selectedDate < today) {
      return { dateInPast: true };
    }
    return null;
  };
}

  parseFilterSelections(selections: LookupModel[]): string {
    const codes: string[] = selections.map(selection => selection.code);
    return codes.join(',');
  }

processFilterData(key: string, value: any) {
  const dataArray = String(value)
    .split(',')
    .filter(item => item.trim() !== '');
  this.filterData[key] = dataArray;
}

  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
  }

  clearSearchField(control: FormControl, controlSecond?: FormControl): void {
    control.reset(); // Clear the search input field

    if (controlSecond) {
      controlSecond.reset();
    } 
  }

}
