<div class="dv-content-wrap container">

  <div class="dv-header-wrap">
    <div class="detail-header">
      <h3> {{schedule.carrier}} - {{schedule.vesselName}}</h3>
    </div>
    <div class="dv-button-wrapper">
      <button class="primary-button" (click)="handleNewBooking(schedule.stackEnd,schedule.loadETD)"
        matTooltip="Add a new booking to this vessel schedule." matTooltipPosition="above"
        matTooltipClass="custom-mat-tooltip">
        <mat-icon>add_circle</mat-icon> New Booking
      </button>
    </div>
  </div>

  <div class="dv-summary-list">

    <div class="dv-summary-column">
      <div class="dv-summary-item">
        <span class="info-label"> Load Port:</span> <span>{{schedule.loadPort}}</span>
      </div>

      <div class="dv-summary-item">
        <span class="info-label"> Stack Start:</span> <span>{{schedule.stackStart | date: 'dd MMM yyyy, HH:mm'}}</span>
      </div>
    </div>
    <div class="dv-summary-column">
      <div class="dv-summary-item">
        <span class="info-label"> Discharge Port:</span> <span>{{schedule.dischargePort}}</span>
      </div>

      <div class="dv-summary-item">
        <span class="info-label"> Stack End:</span> <span> {{schedule.stackEnd | date: 'dd MMM yyyy, HH:mm'}}</span>
      </div>
    </div>
    <div class="dv-summary-column">
      <div class="dv-summary-item">
        <span class="info-label"> Service Name:</span> <span>{{schedule.serviceName}}</span>
      </div>
      <div class="dv-summary-item">
        <span class="info-label"> Load ETD:</span> <span> {{schedule.loadETD | date: 'dd MMM yyyy'}}</span>
      </div>
    </div>
  </div>

  <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color" [hideSingleSelectionIndicator]="true"
    class="tab-menu" [(ngModel)]="selectedTab">
    <mat-button-toggle disabled="true" class="tab-menu-item" [value]="detailTabbedViewEnum.detailsTab">Projected
      Timeline</mat-button-toggle>
    <!--<mat-button-toggle class="tab-menu-item" [value]="detailTabbedViewEnum.trackingTab"(click)="mapTabClicked()">Map</mat-button-toggle>-->
    <!-- <mat-button-toggle class="tab-menu-item" [value]="detailTabbedViewEnum.alertTab">Alerts</mat-button-toggle> -->
  </mat-button-toggle-group>

  <ng-container *ngIf="selectedTab === detailTabbedViewEnum.trackingTab">
    <!-- <div #mapContainer id="map" class="map-view">
    <div class="map-legend">
      <img class="legend-image" src="/assets/Dashed Line.svg" alt="Dashed line">
      <div class="legend-text">Expected route</div>
    </div>
  </div> -->

    <!--<app-tracking-map [mode]="transportMode" [startLocation]="startLocation" [endLocation]="endLocation" [voyageCoordinates]="voyageCoordinates"></app-tracking-map>-->

  </ng-container>

  <ng-container *ngIf="selectedTab === detailTabbedViewEnum.detailsTab">
    <app-schedules-details-tab [scheduleRouteLines]="scheduleRouteLines"> </app-schedules-details-tab>
  </ng-container>

  <!-- <ng-container *ngIf="selectedTab === detailTabbedViewEnum.alertTab">
    <app-alert-tab [alertData]="alertData"></app-alert-tab>
  </ng-container>  -->

</div>