<div class="container">

  <div class="title-wrapper">
    <div class="saved-view-wrap">
      <h1>Schedules</h1>
      <div class="saved-views">
        <div *ngFor="let savedFilter of savedFilters">
          <button mat-button class="Saved-filter-btn" [ngClass]="{'selected': currentFilter.id === savedFilter.id}"
            (click)="applyFilter(savedFilter.id)">
            {{ savedFilter.filterName }}
          </button>
        </div>
      </div>
    </div>
    <div class="action-buttons-wrapper">
      <div class="action-buttons">
        <button mat-button class=" filter-btn" (click)="onOpenFilter(FilterEnum.scheduleFilter)"
          matTooltip="Save your current search." matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
          <div class="filter-icon-wrap">
            <div class="filter-icon"></div>
            Save Filters
          </div>
        </button>
        <button mat-button class="filter-btn" (click)="clearCurrentFilter()" matTooltip="Clear data in input fields."
          matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
          <div class="filter-icon-wrap">
            <div class="filter-clear-icon"></div>
            Reset Filters
          </div>
        </button>
        <button mat-button class="filter-btn-delete" (click)="deleteFilter()" *ngIf="currentFilter.id !== 0"
          matTooltip="Delete the current saved filter view." matTooltipPosition="above"
          matTooltipClass="custom-mat-tooltip">
          <div class="filter-icon-wrap">
            <div class="filter-delete-icon"></div>
            Delete Saved View
          </div>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2-schedules">
      <mat-form-field>
        <mat-label>Enter a ETD date range</mat-label>
        <mat-date-range-input [rangePicker]="picker" required>
          <input matStartDate placeholder="ETD from" [formControl]="dateFrom">
          <input matEndDate placeholder=" ETD to" [formControl]="dateTo">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker" matTooltip="Pick a date from the calendar"
          matTooltipPosition="above" matTooltipClass="custom-mat-tooltip"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="dateFrom.invalid && dateFrom.touched">Start date is required.</mat-error>
        <mat-error *ngIf="dateTo.invalid && dateTo.touched">End date is required.</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-2-schedules">
      <mat-form-field *ngIf="stackStatus.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Stack Status</mat-label>
        <mat-select [formControl]="stackStatus" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="stackStatusMultiFilter" placeholderLabel="Search"
              noEntriesFoundLabel="Stack Status Not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{stackStatus.value.length > 0 ? stackStatus.value[0].code : ''}}
            <span *ngIf="stackStatus.value[0]  && stackStatus.value.length > 1" class="example-additional-selection">
              (+{{(stackStatus.value.length || 0) - 1}} {{stackStatus.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(stackStatus)" matTooltip="Clear field input."
              matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let stack of filteredStackStatus | async" [value]="stack">
            {{stack.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2-schedules">
      <mat-form-field *ngIf="loadPorts.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Load port</mat-label>
        <mat-select [formControl]="loadPorts" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="loadPortsMultiFilter" placeholderLabel="Search"
              noEntriesFoundLabel="Load Port Not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{loadPorts.value.length > 0 ? loadPorts.value[0].code : ''}}
            <span *ngIf="loadPorts.value[0]  && loadPorts.value.length > 1" class="example-additional-selection">
              (+{{(loadPorts.value.length || 0) - 1}} {{loadPorts.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(loadPorts)" matTooltip="Clear field input."
              matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let loadPort of filteredLoadPorts | async" [value]="loadPort">
            {{loadPort.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2-schedules">
      <mat-form-field *ngIf="countries.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Country</mat-label>
        <mat-select [formControl]="countries" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="countriesMultiFilter" placeholderLabel="Search"
              noEntriesFoundLabel="Country Not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{countries.value.length > 0 ? countries.value[0].code : ''}}
            <span *ngIf="countries.value[0] && countries.value.length > 1" class="example-additional-selection">
              (+{{(countries.value.length || 0) - 1}} {{countries.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(countries)" matTooltip="Clear field input."
              matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
            {{country.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-2-schedules">
      <mat-form-field *ngIf="dischargePorts.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Discharge port</mat-label>
        <mat-select [formControl]="dischargePorts" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="dischargePortsMultiFilter" placeholderLabel="Search"
              noEntriesFoundLabel="Discharge Port Not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{dischargePorts.value.length > 0 ? dischargePorts.value[0].code : ''}}
            <span *ngIf="dischargePorts.value[0]  && dischargePorts.value.length > 1"
              class="example-additional-selection">
              (+{{(dischargePorts.value.length || 0) - 1}} {{dischargePorts.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(dischargePorts)" matTooltip="Clear field input."
              matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let dischargePort of filteredDischargePorts | async" [value]="dischargePort">
            {{dischargePort.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2-schedules">
      <mat-form-field *ngIf="carriers.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Carrier</mat-label>
        <mat-select [formControl]="carriers" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="carriersMultiFilter" placeholderLabel="Search"
              noEntriesFoundLabel="Carrier Not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{carriers.value.length > 0 ? carriers.value[0].code : ''}}
            <span *ngIf="carriers.value[0]  && carriers.value.length > 1" class="example-additional-selection">
              (+{{(carriers.value.length || 0) - 1}} {{carriers.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(carriers)" matTooltip="Clear field input."
              matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier">
            {{carrier.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-1 search-btn">
      <button class="runReportButton" (click)="runReportClick()" matTooltip="Search for schedules."
        matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
        Search<mat-icon>arrow_forward</mat-icon>
      </button>

    </div>
  </div>

  <div class="mat-elevation-z2 full-width-table">
    <div class="table-filter-container" *ngIf="this.scheduleDataSource.data.length > 0 && !isLoading">
      <mat-form-field subscriptSizing="dynamic" class="search-wrapper">
        <mat-label>Quick Search</mat-label>
        <input matInput [formControl]="filterList" (keyup)="applySearch(filterList.value)">
        <button class="clear-search" (click)="clearSearchField(filterList)">
          <mat-icon svgIcon="backspace"></mat-icon>
        </button>
      </mat-form-field>
    </div>

    <ng-container *ngIf="!isLoading">
      <table mat-table [dataSource]="scheduleDataSource" class="full-width-table" matSort aria-label="Elements">

        <ng-container *ngFor="let column of displayedColumns">
          <ng-container [matColumnDef]="column.name" id="test">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible"
              matTooltip="{{column.toolTip}}" matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              {{ column.header }}
            </th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'loading-cell':loadingSearch}"
              [attr.data-label]="(column.name !== 'actions' && column.name !== 'indicators') ? column.header : null"
              [hidden]="!column.visible" class='has_label_on_mobile'>
              <ng-container *ngIf="column.name !== 'actions'">
                <ng-container *ngIf="column.name === 'loadETD'">
                  {{ row[column.name] | date: 'dd MMM yyyy' }}
                </ng-container>
                <ng-container *ngIf="column.name === 'stackStart'">
                  <ng-container
                    *ngIf="row[column.name] === '0001-01-01T00:00:00Z' || row[column.name] === '0001-01-01T00:00:00'; else showDate">
                    <!-- Display a blank if the date is '01 January 0001' -->
                  </ng-container>
                  <ng-template #showDate>
                    {{ row[column.name] | date: 'dd MMM yyyy, HH:mm' }} <!-- Display the date normally -->
                  </ng-template>
                  <!-- {{ row[column.name] | date: 'dd MMM yyyy, HH:mm' }} -->
                </ng-container>
                <ng-container *ngIf="column.name === 'stackEnd'">
                  <ng-container
                    *ngIf="row[column.name] === '0001-01-01T00:00:00Z' || row[column.name] === '0001-01-01T00:00:00'; else showDate">
                    <!-- Display a blank if the date is '01 January 0001' -->
                  </ng-container>
                  <ng-template #showDate>
                    {{ row[column.name] | date: 'dd MMM yyyy, HH:mm' }} <!-- Display the date normally -->
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="column.name === 'dischargeETA'">
                  {{ row[column.name] | date: 'dd MMM yyyy' }}
                </ng-container>
                <ng-container *ngIf="column.name === 'siCutoffDateTime'">
                  <ng-container *ngIf="row[column.name] === '0001-01-01T00:00:00Z'; else showDate">
                    <!-- Display this when siCutoffDateTime is '01 Jan 0001' -->
                  </ng-container>
                  <ng-template #showDate>
                    {{ row[column.name] | date: 'dd MMM yyyy' }} <!-- Display the date normally -->
                  </ng-template>
                </ng-container>
                <ng-container
                  *ngIf="column.name !== 'loadETD'&& column.name !== 'stackEnd' && column.name !== 'stackStart' && column.name !== 'siCutoffDateTime'&& column.name !== 'dischargeETA'">
                  {{ row[column.name] }}
                </ng-container>
              </ng-container>
              <!--Add a button for the 'actions' column-->
              <ng-container *ngIf="column.name === 'actions'" class="mat-column-actions">
                <button class="detailed-view-btn" color="primary" matTooltip="View details and add bookings."
                  matTooltipPosition="above" matTooltipClass="custom-mat-tooltip"
                  [ngClass]="{'loading-cell': loadingSearch}"
                  (click)=" viewDetails(row,detailView.scheduleDetail,detailTabbedViewEnum.detailsTab)">
                  Route Details<mat-icon>arrow_right_alt</mat-icon>
                </button>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getExtractedColumns(displayedColumns)"></tr>
        <tr mat-row *matRowDef="let row; columns: getExtractedColumns(displayedColumns);"></tr>
        <tr *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length">
            <ng-container *ngIf="!isLoading" class="no-data-container">
              <div class="no-data-container">
                <strong *ngIf="checkNoFilter()">
                  No data available for selected filters. Please review filters and search
                  again.
                </strong>
                <strong *ngIf="scheduleDataSource.data.length === 0 && !checkNoFilter()">
                  Please apply filters to see
                  results.
                </strong>
              </div>
            </ng-container>
          </td>
        </tr>
      </table>
    </ng-container>

    <ng-container *ngIf="isLoading" class="no-data-container">
      <div class="no-data-container">
        <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
      </div>
    </ng-container>

    <mat-paginator #paginator [length]="scheduleDataSource.data.length" [pageIndex]="0" [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
    </mat-paginator>
  </div>
  <!-- Cards for smaller screens -->
  <!-- Disclaimer text -->
  <div class="paragraph-wrap">
    <h4>Disclaimer</h4>
    <p class="disclaimer-text">
      You use information provided in our Sailing Schedules at your own risk. We do not warrant its accuracy and
      completeness or that it is up-to-date, free from errors or omissions. Any data which is an estimate by its nature
      (ETDs, ETAs etc.) is not and cannot be guaranteed as accurate. Additionally the data provided includes and is
      based upon data received from third parties (including Shipping Lines) and is beyond our control. and cannot be
      verified. Accordingly any reliance placed upon any part of the sailing schedule is at your own risk. Please see <a
        href="https://www.goglobal.group/terms-and-conditions/">GoGlobal's Terms & Condtions</a> for our full policy on
      information provided and use of our reports and platforms.
    </p>

  </div>

</div>