export interface IRollingPlan {
  id: string;
  appBookingCode: string;
  loadingPointDescription: string;
  finalDestination: string;
  finalDestinationName: string;
  containerStatus: string;
  clientReference: string;
  quantity: number;
  lineNumber: number;
  appBookingStatus: string;
  shipperNumber: string;
  grReferenceNo: string;
  loadingPoint: string;
  vesselName: string;
  voyageNumber: string;
  stackStatus: string;
  startStackDate: Date;
  endStackDate: Date;
  trackingStatus: string;
  trackingDate: string;
  confirmedPackDate: Date;
  confirmedPackTime: Date;
}

export class RollingPlan implements IRollingPlan {
  id: string = '';
  loadingPointDescription: string = '';
  finalDestination: string = '';
  finalDestinationName: string = '';
  containerStatus: string = '';
  clientReference: string = '';
  quantity: number = 0;
  lineNumber: number = 0;
  appBookingStatus: string = '';
  shipperNumber: string = '';
  grReferenceNo: string = '';
  loadingPoint: string = '';
  vesselName: string = '';
  voyageNumber: string = '';
  stackStatus: string = '';
  startStackDate: Date = new Date();
  endStackDate: Date = new Date();
  confirmedPackDate: Date = new Date();
  confirmedPackTime: Date = new Date();
  trackingStatus: string = '';
  trackingDate: string = '';
  appBookingCode: string = '';
  status: string = '';
  success: boolean = false;
  bookingHeaderCode: string = '';
}
