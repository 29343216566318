import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {

  transform(value: string): string {
    if (this.isMobileOrTablet()) {
      // Truncate string to 24 characters and add ellipsis if necessary
      return value.length > 24 ? value.substring(0, 24) + '...' : value;
    }
    // Return the value unchanged for larger screens
    return value;
  }

  private isMobileOrTablet(): boolean {
    const width = window.innerWidth;
    // Consider mobile and tablet screen sizes as 768px or less
    return width <= 768;
  }
}
