<div>
  <div class="table-filter-container" *ngIf="this.rollingPlanDataSource.data.length > 0 && !isLoading">
    <mat-form-field subscriptSizing="dynamic" class="search-wrapper">
      <mat-label matTooltip="Search for any data in the table." matTooltipPosition="above"
        matTooltipClass="custom-mat-tooltip">Quick Search</mat-label>
      <input matInput [formControl]="filterList" (keyup)="applySearch(filterList.value)">
      <button class="clear-search" (click)="clearSearchField(filterList)">
        <mat-icon svgIcon="backspace"></mat-icon>
      </button>
    </mat-form-field>
    <div class="export-button">
      <button mat-icon-button color="primary" (click)="exportTable()" matTooltip="Export bookings to excel."
        matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
        <mat-icon svgIcon="export"></mat-icon>
      </button>
    </div>
  </div>
  <div class="mat-elevation-z2  custom-card-radius">
    <ng-container *ngIf="!isLoading">
      <table mat-table class="full-width-table" [dataSource]="rollingPlanDataSource" matSort aria-label="Elements">
        <ng-container *ngFor="let column of displayedColumns">
          <ng-container [matColumnDef]="column.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible"
              matTooltip="{{column.toolTip}}" matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              {{ column.header }}
            </th>
            <td mat-cell *matCellDef="let row"
              [ngClass]="{'loading-cell': loadingSearch, 'has_label_on_mobile':!loadingSearch}"
              [attr.data-label]="(column.name !== 'actions' && column.name !== 'indicators') ? column.header : null"
              [hidden]="!column.visible">
              <ng-container *ngIf="column.name !== 'actions'">
                <ng-container *ngIf="column.name === 'confirmedPackDate'">
                  {{row[column.name] | date: 'dd MMM yyyy HH:mm' }}
                </ng-container>
                <ng-container *ngIf="column.name === 'confirmedPackTime'">
                  {{row[column.name] | date: 'HH:mm:ss' }}
                </ng-container>
                <ng-container *ngIf="column.name === 'endStackDate'">
                  {{row[column.name] | date: 'dd MMM yyyy, HH:mm' }}
                </ng-container>
                <ng-container *ngIf="column.name === 'startStackDate'">
                  {{row[column.name] | date: 'dd MMM yyyy, HH:mm' }}
                </ng-container>
                <ng-container
                  *ngIf="column.name !== 'confirmedPackDate' && column.name !== 'confirmedPackTime' && column.name !== 'endStackDate' && column.name !== 'startStackDate'">
                  {{ row[column.name] | truncateText }}
                </ng-container>
              </ng-container>
              <ng-container *ngIf="column.name === 'actions'">
                <button class="detailed-view-btn" [ngClass]="{'loading-cell': loadingSearch}"
                  matTooltip="Show more details and load tracking status." matTooltipPosition="above"
                  matTooltipClass="custom-mat-tooltip"
                  (click)="viewDetails(row, detailView.rollingPlan, detailTabbedViewEnum.trackingTab)">
                  View Details <mat-icon>arrow_right_alt</mat-icon>
                </button>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="extractedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: extractedColumns;"></tr>
      </table>
    </ng-container>
    <ng-container *ngIf="isLoading" class="no-data-container">
      <div class="no-data-container">
        <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading && rollingPlanDataSource.filteredData.length === 0" class="no-data-container">
      <div class="no-data-container">
        <strong *ngIf="rollingPlanDataSource.data.length === 0">No data found. Please apply filters to see
          results.</strong>
        <strong *ngIf="rollingPlanDataSource.data.length > 0">No data available.</strong>
      </div>
    </ng-container>
    <mat-paginator #paginator [length]="rollingPlanDataSource.data.length" [pageIndex]="0" [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
    </mat-paginator>
  </div>
</div>