import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { Alert } from '../models/alerts/alerts';
import { AddBookingEnum, AddBookingModel } from '../models/booking-data-model';
import { Router } from '@angular/router';
import { BookingDataTransferModel } from '../models/booking-data-model';
import { CommunicationService } from '../service/communication/communication.service';
import { BookingLineModel } from '../models/bookings/booking-line-model';
import { BookingService } from '../service/booking/booking.service';
import { LateStackWarningComponent } from '../dialog/late-stack-warning/late-stack-warning.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';


@Component({
  selector: 'app-booking-line',
  templateUrl: './booking-line.component.html',
  styleUrl: './booking-line.component.css'
})
export class BookingLineComponent {
  @Input() bookingLineData: BookingLineModel[] = [];
  @Input() stackEnd: Date = new Date();
  bookingTransfer: BookingDataTransferModel = new BookingDataTransferModel();
  today = new Date();
  cancelBookingModel = new AddBookingModel();

  constructor(
    private router: Router,
    private communicationService: CommunicationService,
    private bookingService: BookingService,
    private dialog: MatDialog
  ) { }

  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private openSnackBar(message: string, success: boolean = true) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: success ? ['snackbar-success'] : ['snackbar-fail']
    });
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: string[][]) {
    return columns.map(col => col[0]);
  }

  public editBookingLine(lineNo: number): void {
    var bookingLine = this.getBookingLine(lineNo);

    this.bookingTransfer = {
      appBookingCode: bookingLine.appBookingCode,
      appBookingLineNumber: bookingLine.appBookingLineNumber == 0 ? bookingLine.lineNo : bookingLine.appBookingLineNumber,
      scheduleCode: null,
      loadPortCode: null,
      dischargePortCode: null,
      carrierCode: null,
      bookingType: AddBookingEnum.editBookingLine
    };

    this.communicationService.closeRightNav();
    this.router.navigate(['/add-booking'], { state: { data: this.bookingTransfer } });
  }

  public viewBookingLine(lineNo: number): void {
    var bookingLine = this.getBookingLine(lineNo);

    this.bookingTransfer = {
      appBookingCode: bookingLine.appBookingCode,
      appBookingLineNumber: bookingLine.appBookingLineNumber == 0 ? bookingLine.lineNo : bookingLine.appBookingLineNumber,
      scheduleCode: null,
      loadPortCode: null,
      dischargePortCode: null,
      carrierCode: null,
      bookingType: AddBookingEnum.viewBookingLine
    };

    this.communicationService.closeRightNav();
    this.router.navigate(['/add-booking'], { state: { data: this.bookingTransfer } });
  }

  public cancelBookingLine(lineNo: number): void {
    var bookingLine = this.getBookingLine(lineNo);

    this.cancelBookingModel = {
      appbookingCode: bookingLine.appBookingCode,
      lineNumber: bookingLine.appBookingLineNumber == 0 ? bookingLine.lineNo : bookingLine.appBookingLineNumber,
      confirmedPackDateTime: bookingLine.confirmedPackDate ? bookingLine.confirmedPackDate.toString() : "",
      comments: "null",
      commodityCode: bookingLine.commodity,
      dischargePortCode: bookingLine.dischargePortCode,
      LoadPortCode: 'null',
      quantity: bookingLine.quantity,
      containerTypeCode: 'null',
      customerCode: 'null',
      finalDestinationCode: 'null',
      grReferenceNumber: 'null',
      loadingPoint1: 'null',
      loadingPoint2: 'null',
      loadingPoint3: 'null',
      prelimConsignee: 'null',
      preVerificationKey: 'null',
      ServiceName: 'null',
      solasMethod: 'null',
      stepUp: 'null',
      steri: false,
      temperatureCode: 'null',
      temptale: false,
      temptaleDescription: 'null',
      temptaleQuantity: 0,
      vents: 'null',
      VesselName: 'null',
      VoyageNo: 'null',
      weight: 0,
    };

    this.dialog.open(LateStackWarningComponent, {
      data: {
        title: 'Warning Canceling Booking Line',
        message: "You're about to cancel booking line number " + bookingLine.appBookingLineNumber + " on BookingApp code " + bookingLine.appBookingCode + ". Do you wish to proceed?"
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.bookingService.cancelBooking(this.cancelBookingModel).subscribe(data => {
          if (data.success) {
            // TODO: notify user success
            this.openSnackBar(`${data.appBookingCode}: Cancellation Pending`);
            this.communicationService.closeRightNav();

          }
        });
      }
    });
  }

  public isConfirmedPackDateInFuture(confirmedPackDate: Date): boolean {
    return this.today && confirmedPackDate && new Date(confirmedPackDate) > this.today;
  }

  isEndStackInFuture(stackEnd: Date): boolean {
    return this.today && stackEnd && new Date(stackEnd) > this.today;
  }

  getBookingLine(bookingLineNumber: number): BookingLineModel {
    const editLine: BookingLineModel = this.bookingLineData
      .find(line => line.lineNo === bookingLineNumber) ?? new BookingLineModel();
    return editLine;
  }
}
