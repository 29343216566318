import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { SidenavComponent } from './sidenav/sidenav.component';
import { environment } from '../environments/environment';
import { Alert } from './models/alerts/alerts';
import { detailTabbedViewEnum, detailView } from './models/detail-view-enum';
import { Router, NavigationEnd } from '@angular/router';
import { ScriptService } from '../../src/app/service/script/script.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'goglobal.root2go.ui.frontend';
  worker: Worker | undefined;
  versionWorker: Worker | undefined;
  socketSubscribed: boolean = false;
  alertModel: Alert = new Alert(0, '', '', '', '', '', '', new Date(), '', false, detailView.none, detailTabbedViewEnum.none, '');
  wsMessage = new Subject<Alert>();
  isLoading = false;

  @ViewChild(SidenavComponent, { static: false }) sidenavComponent!: SidenavComponent;

  // Inject ChangeDetectorRef into the constructor
  constructor(
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef, // Inject ChangeDetectorRef
    private router: Router,
    private scriptService: ScriptService
  ) {  }

  getWrapperClass(): string {
    if (this.router.url === '/contact') {
      return 'contact-page';
    } else if (this.router.url === '/about') {
      return 'about-page';
    }
    return '';
  }

  showLoading(show: boolean): void {
    this.isLoading = show;
  }

  ngOnInit() {
    this.scriptService.loadGoogleAnalytics();
    this.scriptService.loadHotjar();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (typeof window.hj === 'function') {
          window.hj('stateChange', event.urlAfterRedirects);
        } else {
          console.warn('Hotjar (hj) is not defined.');
        }
      }
    });
  
    // Ensure ChangeDetectorRef is not undefined before using it
    if (this.cd) {
      this.cd.detectChanges();
    }

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        window.scrollTo(0, 0);
      });

    if (typeof Worker !== 'undefined') {
      // Add web worker to keep track of app version from api.
      // When version has changed,
      // force reload the pag so that user does not have to Ctrl-F5
      this.versionWorker = new Worker(new URL('./workers/version.worker', import.meta.url), { type: 'module' });
      this.versionWorker.onmessage = ({ data }) => {
        var localVersion = localStorage.getItem("Root2GoVersion");
        if (localVersion !== data) {
          localStorage.setItem("Root2GoVersion", data);
          window.location.reload();
        }        
      };

      setTimeout(() => {
        this.subscribeVersion();
      }, 5000);

    //   this.worker = new Worker(new URL('./workers/alert-worker.worker', import.meta.url), { type: 'module' });

    //   // Listen for messages from the worker
    //   this.worker.onmessage = ({ data }) => {
    //     console.log('Message from worker:', data);
    //     const item: any = JSON.parse(data);
    //     const detailsView = detailView[item.DetailsView.toString() as keyof typeof detailView];
    //     const detailsTab = detailTabbedViewEnum[item.DetailsTab.toString() as keyof typeof detailTabbedViewEnum];
    //     const alertModel = new Alert(
    //       item.Id,
    //       item.UserId,
    //       item.CustomerCode,
    //       item.Customer,
    //       item.Title,
    //       item.TemplateGroup,
    //       item.Code,
    //       item.AlertDate,
    //       item.Notification,
    //       item.IsRead,
    //       detailsView,
    //       detailsTab,
    //       item.Reference
    //     );
    //     this.wsMessage.next(alertModel);
    //   };
    } else {
      console.log('Web Workers are not supported in this environment.');
    }
  }

  ngOnDestroy() {
    if (this.worker) {
      this.worker.terminate();
    }
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  subscribeNotifications(userId: string) {
    if (this.worker !== undefined && this.socketSubscribed !== true) {
      this.worker.postMessage({ socketUrl: environment.socketUrl, userId });
    }
    // Ensure ChangeDetectorRef is not undefined before using it
    if (this.cd) {
      this.cd.detectChanges();
    }
  }

  subscribeVersion() {
    if (this.versionWorker !== undefined) {
      this.versionWorker.postMessage({ apiUrl: environment.apiUrl });
    }
  }

  onMenuOpenerClick() {
    this.sidenavComponent.toggleDrawer();
  }

  onOpenAlerts() {
    this.sidenavComponent.openAlertDrawer();
    // Ensure ChangeDetectorRef is not undefined before using it
    if (this.cd) {
      this.cd.detectChanges();
    }
  }
}
