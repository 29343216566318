import { Component, OnInit } from '@angular/core';
import { CommunicationService } from '../service/communication/communication.service';
import { Subject, takeUntil } from 'rxjs';
import { ShipmentModel, ShipmentTranferGRRefContainerNo } from '../models/shipment/shipment-data-model';
import { ShipmentService } from '../service/shipment/shipment.service';

@Component({
  selector: 'app-detailed-document',
  templateUrl: './detailed-document.component.html',
  styleUrl: './detailed-document.component.css'
})
export class DetailedDocumentComponent implements OnInit {
  private destroy$ = new Subject<void>();
  grRefContainerData: ShipmentTranferGRRefContainerNo = new ShipmentTranferGRRefContainerNo;
  public shipmentData: ShipmentModel = new ShipmentModel;
  isLoading: boolean = true;
  emptyShipment: boolean = false;

  constructor(
    private communicationService: CommunicationService,
    private shipmentDataService: ShipmentService,
  ) { }

  ngOnInit(): void {
    this.communicationService.grRefcontainerNoParameter$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async data => {
          this.grRefContainerData = data;
          try {
            await this.getShipemntsForGRRefContainerNo(this.grRefContainerData);
            this.isLoading = false;
          } catch (error) {
            console.error('Error fetching shipment data:', error);
          }
        }
      });
  }

  async getShipemntsForGRRefContainerNo(data: ShipmentTranferGRRefContainerNo): Promise<void> {
    return new Promise((resolve, reject) => {
      this.shipmentDataService.getShipmentsForGRRef(data.grReferenceNo)
        .subscribe({
          next: async (data) => {
            if (data.length > 0)
              this.shipmentData = data[0];
            else
              this.emptyShipment = true;
            resolve();
          },
          error: (error) => {
            reject(error);
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
