import { Component, Output, EventEmitter, OnInit, OnDestroy, Input, ChangeDetectorRef, inject } from '@angular/core';
import { CommunicationService } from '../service/communication/communication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FilterData } from '../models/list-model';
import { DataFilter, FilterTransferModel } from '../models/filter-models';
import { BookingService } from '../service/booking/booking.service';
import { FilterEnum } from '../models/filter-enum';
import { Observable, Subject } from 'rxjs';
import { takeUntil, catchError, distinctUntilChanged, skip, map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AddBookingEnum, BookingDataTransferModel } from '../models/booking-data-model';
import { bookingTabbedViewEnum } from '../models/booking-view-enum';
import { TransportMode } from '../enums/transport-mode.enum';
import { ScheduleVoyageCoordinates } from '../models/schedules/schedule-voyage-coordinates';
import { LookupModel } from '../models/lookup-model';
import { LookupService } from '../service/lookup/lookup.service';
import { ApiUserService } from '../service/user/api-user.service';
import { RoadData } from '../models/road-data-model';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { IBookingListModel, BookingListModel } from '../models/bookings/booking-list-model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TooltipPosition, MatTooltipModule } from '@angular/material/tooltip';
import { UserActivityService } from '../service/user-activity/user-activity.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

// Custom date formats for date picker
export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'dd/MM/yyyy',
  },
  display: {
    dateInput: 'dd/MM/yyyy',
    monthYearLabel: 'MM yyyy',
    dateA11yLabel: 'dd/MM/yyyy',
    monthYearA11yLabel: 'MM yyyy',
  },
};

@Component({
  selector: 'app-booking-management',
  templateUrl: './booking-management.component.html',
  styleUrl: './booking-management.component.css',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ]
})
export class BookingManagementComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  bookingTabbedViewEnum = bookingTabbedViewEnum;
  @Input() selectedTab = bookingTabbedViewEnum.listTab;
  @Output() openFilter = new EventEmitter<string>();
  @Output() selectedCustomerChange = new EventEmitter<string>();
  selectedCustomer: string = '';
  selectedButton = "";
  protected range = new FormGroup({
    dateFrom: new FormControl<Date | null>(null), // Initialize to null
    dateTo: new FormControl<Date | null>(null),   // Initialize to null
  });
  customersMultiFilter = new FormControl<string>('');
  bookingCustomer: LookupModel[] = [];
  datafilter: DataFilter = new DataFilter();
  filterData: FilterData = {};
  FilterEnum = FilterEnum;
  savedFilters: DataFilter[] = [];
  selectedFilterId: number = 0;
  SavedFilterName: string = '';
  currentFilter: DataFilter = new DataFilter();
  filteredCustomers!: Observable<LookupModel[]>;
  bookingTransfer: BookingDataTransferModel = new BookingDataTransferModel();
  isCustUser: boolean = false
  customers = new FormControl<LookupModel[]>([]);
  loadPorts = new FormControl<LookupModel[]>([]);
  markets = new FormControl<LookupModel[]>([]);
  carriers = new FormControl<LookupModel[]>([]);
  vesselNames = new FormControl<string[]>([]);
  latestacks = new FormControl();
  grRefNos = new FormControl('');
  bookingDataLoadPort: LookupModel[] = [];
  bookingDataCarrier: LookupModel[] = [];
  bookingDataMarket: LookupModel[] = [];
  bookingDataVesselName: LookupModel[] = [];
  filteredLoadPorts!: Observable<LookupModel[]>;
  filteredMarkets!: Observable<LookupModel[]>;
  filteredCarriers!: Observable<LookupModel[]>;
  filteredVesselNames!: Observable<string[]>;
  loadPortsMultiFilter = new FormControl<string>('');
  marketsMultiFilter = new FormControl<string>('');
  carriersMultiFilter = new FormControl<string>('');
  vesselsMultiFilter = new FormControl<string>('');
  private apiUserService = inject(ApiUserService)
  protected voyageCoordinates: ScheduleVoyageCoordinates[] = [];
  protected filterList = new FormControl();
  protected bookingsDataSource = new MatTableDataSource<BookingListModel>();
  get dateFrom() {
    return this.range.get('dateFrom') as FormControl;
  }

  get dateTo() {
    return this.range.get('dateTo') as FormControl;
  }

  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private bookingDataService: BookingService,
    private communicationService: CommunicationService,
    private router: Router,
    private lookupService: LookupService,
    private cdr: ChangeDetectorRef,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private userActivityService: UserActivityService) {
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
  }

  ngOnInit(): void {
    this.selectedCustomer = ''
    this.loadSavedFilters();
    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    this.bookingDataService.filterNameSaved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (t) => {
          this.SavedFilterName = t;
          this.filterData = {};
          this.loadSavedFilters();
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });
    this.reset()
    this.bookingDataService.filterData$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (value) => {
          this.filterData = value;
          this.selectedFilterId = 0;
          this.updateCurrentFilter(this.selectedFilterId, this.getSavedFilterNameFromId(this.selectedFilterId), this.filterData);

          this.bookingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
        },
        error: (error) => {
          console.error('Error receiving filter data', error);
          window.alert('Error receiving filter data');
        }
      });
    this.loadBookingData();

    this.bookingDataService.getBookingsDataSource().subscribe(dataSource => {
      this.bookingsDataSource = dataSource;
    });
  }

  loadBookingData(): void {
    this.lookupService.getCustomers(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingCustomer = data;
          this.customers.setValue(this.bookingCustomer.filter(customer => customer.isSelected));
          this.filteredCustomers = this.customersMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCustomers(searchText ?? ''))
          );

          this.cdr.markForCheck();
          console.log('Customer data:', this.bookingCustomer);
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });
    this.lookupService.getLoadPorts(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataLoadPort = data;
          this.loadPorts.setValue(this.bookingDataLoadPort.filter(port => port.isSelected));
          this.filteredLoadPorts = this.loadPortsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterLoadPorts(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

    this.lookupService.getCarriers(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataCarrier = data;
          this.carriers.setValue(this.bookingDataCarrier.filter(port => port.isSelected));
          this.filteredCarriers = this.carriersMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCarriers(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Carrier data', error)
      });

    this.lookupService.getMarkets(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataMarket = data;
          this.markets.setValue(this.bookingDataMarket.filter(port => port.isSelected));
          this.filteredMarkets = this.marketsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterMarkets(searchText ?? ''))
          );
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Market data', error)
      });

    this.lookupService.getVesselNames(this.datafilter.filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.bookingDataVesselName = data;
          // Check if there are saved vessel names in the filter
          const savedVesselNames = this.datafilter.filter['vesselName'] as string[] | undefined;
          if (savedVesselNames && savedVesselNames.length > 0) {
            this.vesselNames.setValue(savedVesselNames);
          } else {
            this.vesselNames.setValue([]);
          }
          this.filteredVesselNames = this.vesselsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterVesselNames(searchText ?? ''))
          );
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Vessel Names', error)
      });

    let lateStackRestore = this.datafilter.filter['latestack']?.toString();
    this.latestacks.setValue(lateStackRestore);

    let grRefNosRestore = this.datafilter.filter['grRefNo']?.toString() ?? null;
    if (grRefNosRestore != null) {
      this.grRefNos.setValue(grRefNosRestore);
    }
  }

  private filterCustomers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingCustomer;
    }
    return this.bookingCustomer.filter(customer =>
      customer.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  loadSavedFilters() {
    this.bookingDataService.getFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedFilters = data;
          if (this.SavedFilterName !== "") {
            this.changeSelectedButton(this.SavedFilterName);
            this.selectedFilterId = this.getFilterIdByName(this.SavedFilterName);
            this.updateCurrentFilter(this.selectedFilterId, this.SavedFilterName, this.getSavedFilterFromId(this.selectedFilterId));
            this.bookingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
          }
        }
      });
  }

  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
    console.log('Filter cleared:', control);
  }

  processFilterData(key: string, value: string | string[]) {
    if (Array.isArray(value)) {
      this.filterData[key] = value.filter(item => item.trim() !== '');
    } else {
      const dataArray = value
        .split(',')
        .filter(item => item.trim() !== '');
      this.filterData[key] = dataArray;
    }
  }

  onOpenFilter(filter: FilterEnum) {
    this.communicationService.toggleFilter(filter);
    this.sendingActiveFilter();
  }

  runReportClick(filter: string = '', dateFromValue?: Date, dateToValue?: Date): void {
    // If dateFromValue and dateToValue are not provided, get them from form controls
    if (!dateFromValue) {
      dateFromValue = this.dateFrom.value ? new Date(this.dateFrom.value) : undefined;
    }

    if (!dateToValue) {
      dateToValue = this.dateTo.value ? new Date(this.dateTo.value) : undefined;
    }

    this.createFilter();
    this.updateCurrentFilter(this.currentFilter.id, this.currentFilter.filterName, this.filterData);

    // Send the filter model
    this.bookingDataService.sendingFilterTransferModel(
      new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, dateFromValue, dateToValue)
    );
  }

  selectButton(button: string) {
    if (this.selectedButton === button) {
      this.selectedButton = '';
      this.bookingDataService.toggleStatusFilter('');
    } else {
      this.selectedButton = button;
      this.bookingDataService.toggleStatusFilter(button);
    }
  }

  applyFilter(filterId: number): void {
    this.filterData = {};

    if (this.selectedFilterId === filterId) {
      this.selectedFilterId = 0;
      this.updateCurrentFilter(0, '', {});
    }
    else {
      this.selectedFilterId = filterId;
      this.clearCurrentFilter();
      this.updateCurrentFilter(this.selectedFilterId, this.getSavedFilterNameFromId(this.selectedFilterId), this.getSavedFilterFromId(this.selectedFilterId))
    }

    if (this.currentFilter.id === 0) {
      this.clearCurrentFilter();
    }

    this.bookingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
  }

  reset() {
    this.dateFrom.reset();
    this.dateTo.reset();
    this.filterList.reset();
    this.customers.reset(); // Clear selected customers
    this.selectedCustomer = ''; // Reset selected customer
    this.filteredCustomers = this.customersMultiFilter.valueChanges.pipe(
      startWith(''),
      map(searchText => this.filterCustomers(searchText ?? ''))
    ); // Reset the filtered customers
    this.runReportClick()
  }

  clearSearchField(control: FormControl, controlSecond?: FormControl): void {
    control.reset(); // Clear the search input field

    if (controlSecond) {
      controlSecond.reset();
    }
    this.bookingsDataSource.filter = ''; // Reset the table filter
    console.log('Search cleared and booking list updated.');
  }

  changeSelectedButton(newSavedFilterName: string): void {
    this.selectedFilterId = this.getFilterIdByName(newSavedFilterName);
  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.currentFilter.id = id;
    this.currentFilter.filterName = name;
    this.currentFilter.filter = filter;

    if (filter['carrierCode'] != null) {
      const carriersFilter = filter['carrierCode'] ?? [];
      this.carriers.setValue(this.bookingDataCarrier.filter(status => carriersFilter.includes(status.code)))
    }

    if (filter['loadPortCode'] != null) {
      const carriersFilter = filter['loadPortCode'] ?? [];
      this.loadPorts.setValue(this.bookingDataLoadPort.filter(status => carriersFilter.includes(status.code)))
    }
    if (filter['marketCode'] != null) {
      const carriersFilter = filter['marketCode'] ?? [];
      this.markets.setValue(this.bookingDataMarket.filter(status => carriersFilter.includes(status.code)))
    }
    if (filter['customerCode'] != null) {
      const carriersFilter = filter['customerCode'] ?? [];
      this.customers.setValue(this.bookingCustomer.filter(status => carriersFilter.includes(status.code)))
    }
    if (filter['vesselName'] != null) {
      const savedVesselNames = filter['vesselName'] as string[] | undefined;
      if (savedVesselNames && savedVesselNames.length > 0) {
        this.vesselNames.setValue(savedVesselNames);
      } else {
        this.vesselNames.setValue([]);
      }
    }
    if (filter['grRefNo'] != null) {
      let grRefNosRestore = filter['grRefNo']?.toString() ?? null;
      if (grRefNosRestore != null) {
        this.grRefNos.setValue(grRefNosRestore);
      }
    }
  }

  getSavedFilterNameFromId(filterId: number): string {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  getSavedFilterFromId(filterId: number): FilterData {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  getFilterIdByName(filterName: string): number {
    const filter = this.savedFilters.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  sendingActiveFilter() {
    this.createFilter();
    this.updateCurrentFilter(this.currentFilter.id, this.currentFilter.filterName, this.filterData);
    this.bookingDataService.sendingActiveFilterToFilter(this.currentFilter)
  }

  navigateToAddBooking() {
    this.bookingTransfer = {
      appBookingCode: null,
      appBookingLineNumber: null,
      scheduleCode: null,
      loadPortCode: null,
      dischargePortCode: null,
      carrierCode: null,
      bookingType: AddBookingEnum.newBooking
    }
    this.router.navigate(['/add-booking'], { state: { data: this.bookingTransfer } });
  }
  onCustomerSelectionChange(customer: string): void {
    this.selectedCustomer = customer;
    this.selectedCustomerChange.emit(this.selectedCustomer);
    // Remove automatic fetch here
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearCurrentFilter() {
    this.currentFilter.filter = {};
    this.currentFilter.filterName = '';
    this.currentFilter.id = 0;
    this.datafilter.filter = {};
    this.grRefNos.reset();
    this.loadPorts.setValue(this.bookingDataLoadPort.filter(x => !x));
    this.carriers.setValue(this.bookingDataCarrier.filter(x => !x));
    this.markets.setValue(this.bookingDataMarket.filter(x => !x));
    this.vesselNames.setValue([]);
    this.customers.setValue(this.bookingCustomer.filter(x => !x));
  }

  deleteFilter() {
    let filterName = this.currentFilter.filterName;

    this.bookingDataService.deleteBookingFilter(this.currentFilter.id).subscribe(result => {
      this.bookingDataService.filterNameSavedSend('');
      this.clearCurrentFilter();
      this.openSnackBar('Your filter, ' + filterName + ' has been deleted');
      this.bookingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
    });
  }

  private filterLoadPorts(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataLoadPort;
    }
    return this.bookingDataLoadPort.filter(loadPort =>
      loadPort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterMarkets(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataMarket;
    }
    return this.bookingDataMarket.filter(loadPort =>
      loadPort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCarriers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataCarrier;
    }
    return this.bookingDataCarrier.filter(loadPort =>
      loadPort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterVesselNames(searchText: string): string[] {
    if (!searchText) {
      return this.bookingDataVesselName.map(vessel => vessel.name);
    }
    return this.bookingDataVesselName
      .filter(vessel => vessel.name.toLowerCase().includes(searchText.toLowerCase()))
      .map(vessel => vessel.name);
  }

  createFilter(): void {
    if (this.customers.value != null) {
      const customerCodes = this.parseFilterSelections(this.customers.value);
      this.processFilterData('customerCode', customerCodes);
    }
    if (this.loadPorts.value != null) {
      const loadPortCodes = this.parseFilterSelections(this.loadPorts.value);
      this.processFilterData('loadPortCode', loadPortCodes);
    }
    if (this.markets.value != null) {
      const marketCodes = this.parseFilterSelections(this.markets.value);
      this.processFilterData('marketCode', marketCodes);
    }
    if (this.carriers.value != null) {
      const carrierCodes = this.parseFilterSelections(this.carriers.value);
      this.processFilterData('carrierCode', carrierCodes);
    }
    if (this.vesselNames.value && this.vesselNames.value.length > 0) {
      this.filterData['vesselName'] = this.vesselNames.value;
    } else {
      delete this.filterData['vesselName'];
    }
    if (this.latestacks.value != null) {
      this.processFilterData('latestack', this.latestacks.value?.toString());
    }
    if (this.grRefNos.value != null) {
      this.processFilterData('grRefNo', this.grRefNos.value?.toString());
    }
  }

  parseFilterSelections(selections: LookupModel[]): string {
    const codes: string[] = selections.map(selection => selection.code);
    return codes.join(',');
  }

  public openSnackBar(message: string, panelClass: string = 'snackbar-success') {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: [panelClass]
    });
  }
}
