import { Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges, ViewChild, ElementRef, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { SchedulesService } from '../service/schedules/schedules.service';
import { ScheduleListModel } from '../models/schedules/schedule-list-model';
import { ScheduleVoyageCoordinates } from '../models/schedules/schedule-voyage-coordinates';
import { CommunicationService } from '../service/communication/communication.service';
import { detailTabbedViewEnum } from '../models/detail-view-enum';
import { Alert } from '../models/alerts/alerts';
import { AlertService } from '../service/alerts/alert.service';
import { AddBookingEnum, BookingDataTransferModel } from '../models/booking-data-model';
import { Router } from '@angular/router';
import * as L from 'leaflet';
import { ScheduleRouteLinesModel } from '../models/schedules/schedule-route-lines-model';
import { TransportMode } from '../enums/transport-mode.enum';
import { MatDialog } from '@angular/material/dialog';
import { LateStackWarningComponent } from '../dialog/late-stack-warning/late-stack-warning.component';
import { BookingEditModel } from '../models/bookings/booking-edit-model';

@Component({
  selector: 'app-schedules-detail-view',
  templateUrl: './schedules-detail-view.component.html',
  styleUrls: ['./schedules-detail-view.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulesDetailViewComponent implements OnInit, AfterViewInit {

  detailTabbedViewEnum = detailTabbedViewEnum;
  detailTabbedViewEnumSelected = detailTabbedViewEnum.trackingTab;
  @Input() selectedTab: detailTabbedViewEnum = this.detailTabbedViewEnumSelected;
  public bookingData = new BookingEditModel;
  @Output() bookingDataOutput = new EventEmitter<BookingEditModel>();
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  private map: any;
  private routePolyline: any;
  public alertData: Alert[] = [];
  public scheduleRouteLines: ScheduleRouteLinesModel[] = [];
  protected voyageCoordinates: ScheduleVoyageCoordinates[] = [];

  private scheduleHeaderCode: string = '';
  private carrierCode: string = '';
  private scheduleLoadPortCode: string = '';
  private dischargePortCode: string = '';
  private scheduleLineNumber: number = 0;
  private isLateStack: boolean | null = null;
  private isSiCutOff: boolean | null = null;
  private siCutoffDate: Date = new Date();

  public schedule = new ScheduleListModel();
  public bookingTransfer = new BookingDataTransferModel();
  public transportMode: TransportMode = TransportMode.Ocean;

  public startLocation: L.LatLngExpression = [0, 0];
  public endLocation: L.LatLngExpression = [0, 0];

  constructor(
    private schedulesService: SchedulesService,
    private alertDataService: AlertService,
    private communicationService: CommunicationService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.communicationService.siCutOffDate$.subscribe(value => {
      this.siCutoffDate = value as unknown as Date;
    });

    this.communicationService.detailViewParam.subscribe(param => {
      var paramParts = param.split('|');
      this.scheduleHeaderCode = paramParts[0];
      this.carrierCode = paramParts[1];
      this.scheduleLoadPortCode = paramParts[2];
      this.dischargePortCode = paramParts[3];
    });

    this.communicationService.detailedViewToggle$.subscribe(d => {
      this.selectedTab = d.detailTabbedViewEnum;
    });
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.getScheduleListDetail(this.scheduleHeaderCode, this.carrierCode, this.scheduleLoadPortCode, this.dischargePortCode);
    this.getScheduleLines(this.scheduleHeaderCode, this.scheduleLoadPortCode, this.dischargePortCode, this.carrierCode);
    //this.getCoordinates(this.scheduleHeaderCode); //TODO update endpoint
    // this.getAlertsForGRRef(this.scheduleHeaderCode);
  }

  protected mapTabClicked(): void {
    this.loadData();
  }

  public initMap(): void {
    if (this.mapContainer && this.voyageCoordinates.length > 0) {

      if (this.map) {
        this.map.remove();
      }

      if (this.schedule.vesselName.includes('AIRFREIGHT')) {
        this.transportMode = TransportMode.Air;
      } else {
        this.transportMode = TransportMode.Ocean; // Set default or other modes as needed
      }

      // Initialize the map
      this.map = L.map(this.mapContainer.nativeElement).setView(
        [this.voyageCoordinates[0].latitude, this.voyageCoordinates[0].longitude], 2
      );

      const tiles = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        maxZoom: 18,
        minZoom: 3,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      });

      // Add route polyline
      // this.routePolyline =
      L.polyline(
        this.voyageCoordinates.map(p => [p.latitude, p.longitude]),
        { color: '#162a3e', dashArray: '5, 10' }
      ).addTo(this.map);

      tiles.addTo(this.map);
      this.map.invalidateSize(); // Ensure map is resized correctly

      console.log('Map initialized with coordinates:', this.voyageCoordinates);
    } else {
      console.warn('Map container or voyage coordinates are not available');
    }
  }

  //private getCoordinates(scheduleHeaderCode: string): void {
  //  this.schedulesService.getVoyageCoordinates(scheduleHeaderCode).subscribe(data => { //TODO update endpoint or use coordinates from scheduleRouteLines
  //    this.voyageCoordinates = data;
  //    console.log('voyageCoordinates', this.voyageCoordinates);

  //    this.startLocation = [this.voyageCoordinates[0].latitude, this.voyageCoordinates[0].longitude];
  //    this.endLocation = [this.voyageCoordinates[this.voyageCoordinates.length - 1].latitude, this.voyageCoordinates[this.voyageCoordinates.length - 1].longitude];

  //    if (this.selectedTab === this.detailTabbedViewEnum.trackingTab) {
  //      this.initMap();
  //    }
  //  });
  //}

  private getScheduleLines(scheduleHaderCode: string, loadPort: string, dischargePort: string, carrierCode: string): void {
    this.schedulesService.getScheduleRoute(scheduleHaderCode, loadPort, dischargePort, carrierCode).subscribe(data => {
      this.scheduleRouteLines = data;
    });
  }

  private getScheduleListDetail(scheduleHaderCode: string, carrierCode: string, scheduleLoadPortCode: string, dischargePortCode: string): void {
    this.schedulesService.getScheduleListDetail(scheduleHaderCode, carrierCode, scheduleLoadPortCode, dischargePortCode).subscribe(data => {
      this.schedule = data;
      console.log('schedule', this.schedule)
      this.scheduleLineNumber = this.schedule.lineNumber;
    })
  }

  private getAlertsForGRRef(alertParameter: string): void {
    this.alertDataService.getAlertsForReference('Schedule', alertParameter).subscribe(data => { this.alertData = data })
    console.log(this.alertData);
  }

  async handleNewBooking(stackDate: Date, loadETD: Date) {
    // Ensure stackDate is a Date object
    const dateToCheckstackDate = new Date(stackDate);
    let dateToChecksiCutOff = new Date(this.siCutoffDate);
    const dateToCheckloadETD = new Date(loadETD);
    const now = new Date();
    const timeDifferenceLateStack = dateToCheckstackDate.getTime() - now.getTime();
    const timeDifferenceSiCutOff = dateToChecksiCutOff.getTime() - now.getTime();
    const SIX_HOURS = 6 * 60 * 60 * 1000; // Constant for 6 hours in milliseconds
    const EIGHTEEN_HOURS = 18 * 60 * 60 * 1000; // Constant for 18 hours in milliseconds
    const INVALID_DATE = -60000000000000;

    try {
      // Show SI Cut Off Warning if needed
      if (timeDifferenceSiCutOff <= EIGHTEEN_HOURS && timeDifferenceSiCutOff >= INVALID_DATE) {
        const siCutOffConfirmed = await this.openWarningDialog(
          'SI Cut Off Warning',
          'This request is close to the SI Cut Off closure. By proceeding you acknowledge acceptance of possible missing SI Cut Off. Do you wish to proceed?'
        );
        if (!siCutOffConfirmed) return; // Exit if SI Cut Off warning is declined
        this.isSiCutOff = true;
        this.communicationService.setSiCutOff(this.isSiCutOff);
      }

      // Show Late Stack Warning if needed
      if (timeDifferenceLateStack <= SIX_HOURS) {
        const lateStackConfirmed = await this.openWarningDialog(
          'Late Stack Warning',
          'This request is close to the Stack closure and can incur additional costs. By proceeding you acknowledge acceptance of possible late Stack and related charges should they be incurred by GoReefers. Do you wish to proceed?'
        );
        if (!lateStackConfirmed) return; // Exit if Late Stack warning is declined
        this.isLateStack = true;
        this.communicationService.setLateStack(this.isLateStack);
      }

      // Proceed to add booking if all required warnings are confirmed
      this.addBooking();
    } catch (error) {
      console.error('Error handling booking:', error);
    }
  }

  async openWarningDialog(title: string, message: string): Promise<boolean> {
    return this.dialog.open(LateStackWarningComponent, {
      data: { title, message }
    }).afterClosed().toPromise();
  }

  private addBooking() {
    this.bookingTransfer = {
      appBookingCode: null,
      appBookingLineNumber: null,
      scheduleCode: this.scheduleHeaderCode,
      loadPortCode: this.scheduleLoadPortCode,
      dischargePortCode: this.dischargePortCode,
      carrierCode: this.carrierCode,
      bookingType: AddBookingEnum.newBookingSailingSchedule
    };

    this.router.navigate(['/add-booking'], { state: { data: this.bookingTransfer } });
    this.closeRightDrawer();
  }

  closeRightDrawer() {
    this.communicationService.closeRightNav();
  }
}
