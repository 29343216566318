import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, model, OnInit, signal, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiUserService } from '../../service/user/api-user.service';
import { Subject } from 'rxjs/internal/Subject';
import { LookupModel } from '../../models/lookup-model';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { map, Observable, of, skip, startWith, take, takeUntil } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { BookingService } from '../../service/booking/booking.service';
import { LookupService } from '../../service/lookup/lookup.service';
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { FilterData } from '../../models/list-model';
import { DataFilter } from '../../models/filter-models';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { AddBookingEnum, AddBookingModel, BookingDataTransferModel } from '../../models/booking-data-model';
import { ScheduleListModel } from '../../models/schedules/schedule-list-model';
import { BookingEditModel } from '../../models/bookings/booking-edit-model';
import { BookingSubmitResponse } from '../../models/bookings/booking-response-model';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { InformationComponent } from '../../dialog/information/information.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { merge } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommunicationService } from '../../service/communication/communication.service';
import { BookingSubmissionComponent } from '../../dialog/booking-submission/booking-submission.component';
import { MatButtonModule } from '@angular/material/button';
import { CancelBookingComponent } from '../../dialog/cancel-booking/cancel-booking.component';
import { switchMap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { time } from 'echarts';
import { UserActivityService } from '../../service/user-activity/user-activity.service';
import { TimePickerComponent } from '../../time-picker/time-picker.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';


export function dateNotInPast(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const currentDate = new Date();
    const selectedDate = control.value;

    currentDate.setHours(0, 0, 0, 0);

    if (selectedDate && selectedDate < currentDate) {
      return { 'dateInPast': true };
    }
    return null;
  };
}

@Component({
  selector: 'app-add-booking-input',
  templateUrl: './add-booking-input.component.html',
  styleUrl: './add-booking-input.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddBookingInputComponent implements OnInit {
  @ViewChild('timePickerTemplate') timePickerTemplate!: TemplateRef<any>;
  overlayRef: OverlayRef | null = null;
  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;
  hideSingleSelectionIndicator = signal(false);
  hideMultipleSelectionIndicator = signal(false);
  addBookingForm!: FormGroup; // FormGroup to manage the form
  errorMessage = signal('');
  @Input() bookingData!: BookingEditModel;
  @Input() isReadOnly=false;
  @Input() isAddBooking = false;
  weight = new FormControl(0);
  clientRefNo = new FormControl('');
  quantity = new FormControl(0);
  isChecked = false;
  isLateStack = false;
  isSiCutOff = false;
  isSterilized = new FormControl(false);
  isTemptale = new FormControl(false);
  loadDateTime = new FormControl<Date | null>(null, [Validators.required, dateNotInPast()]);
  //loadTime = new FormControl('', [Validators.required, loadTimeValidator(this.loadDateTime)]);
  //temptaleDescription = new FormControl('');
  temptaleQTY = new FormControl(0);
  comment = new FormControl('', []);
  stepUp = new FormControl('');
  preVerificationKey = new FormControl('');
  prelimConsignee = new FormControl('');

  customers = new FormControl<string>('', [Validators.required]);
  customersMultiFilter = new FormControl<string>('');
  filteredCustomers!: Observable<LookupModel[]>;
  customersData: LookupModel[] = [];

  loadPorts = new FormControl<string>('')
  loadPortsMultiFilter = new FormControl<string>('');
  filteredLoadPorts!: Observable<LookupModel[]>;
  bookingDataLoadPort: LookupModel[] = [];

  dischargePorts = new FormControl<string>('')
  dischargePortsMultiFilter = new FormControl<string>('');
  filteredDischargePorts!: Observable<LookupModel[]>;
  bookingDataDischargePorts: LookupModel[] = [];

  finalDestinations = new FormControl<string>('')
  finalDestinationsMultiFilter = new FormControl<string>('');
  filteredFinalDestinations!: Observable<LookupModel[]>;
  bookingDataFinalDestinations: LookupModel[] = [];

  commodities = new FormControl<string>('', [Validators.required]);
  commoditiesMultiFilter = new FormControl<string>('');
  filteredCommodities!: Observable<LookupModel[]>;
  bookingDataCommodities: LookupModel[] = [];

  countries = new FormControl<string>('')
  countriesMultiFilter = new FormControl<string>('');
  filteredCountries!: Observable<LookupModel[]>;
  bookingDataCountries: LookupModel[] = [];

  containerTypes = new FormControl<string>('', [Validators.required])
  containerTypesMultiFilter = new FormControl<string>('');
  filteredContainerTypes!: Observable<LookupModel[]>;
  bookingDataContainerTypes: LookupModel[] = [];

  vents = new FormControl<string>('', [Validators.required])
  ventsMultiFilter = new FormControl<string>('');
  filteredVents!: Observable<LookupModel[]>;
  bookingDataVents: LookupModel[] = [];

  solasMethods = new FormControl<string>('', [Validators.required])
  solasMethodsMultiFilter = new FormControl<string>('');
  filteredSolasMethods!: Observable<LookupModel[]>;
  bookingDataSolasMethods: LookupModel[] = [];

  temptaleDescription = new FormControl<string>('', [Validators.required])
  temptaleDescriptionsMultiFilter = new FormControl<string>('');
  filteredTemptaleDescriptions!: Observable<LookupModel[]>;
  bookingDataTemptaleDescriptions: LookupModel[] = [];

  loadpoint1 = new FormControl<string>('')
  loadpoint2 = new FormControl<string>('')
  loadpointsMultiFilter = new FormControl<string>('');
  filteredLoadpoints!: Observable<LookupModel[]>;
  bookingDataLoadpoints: LookupModel[] = [];

  temperatures = new FormControl<string>('', [Validators.required])
  temperaturesMultiFilter = new FormControl<string>('');
  filteredTemperatures!: Observable<LookupModel[]>;
  bookingDataTemperatures: LookupModel[] = [];

  filterData: FilterData = {};
  datafilter: DataFilter = new DataFilter();

  booking: AddBookingModel = new AddBookingModel();
  voyage: ScheduleListModel | null = null;

  isSendToReefersDisabled: boolean = false;

  loadHours: string = '';
  loadMinutes: string = '';
  today: Date | undefined;
  loadTime = new FormControl('', [
    Validators.required,
    this.loadTimeValidator.bind(this)
  ]);
  selectedTime: string | null = null;
  isSpinnerVisible: boolean = false;

 
  constructor(
    private router: Router,
    private domSanitizer: DomSanitizer,
    private bookingDataService: BookingService,
    private lookupService: LookupService,
    private communicationService: CommunicationService,
    private cdr: ChangeDetectorRef,
    private matIconRegistry: MatIconRegistry,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private userActivityService: UserActivityService,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef

  ) {
    this.addBookingForm = new FormGroup({
      loadDateTime: this.loadDateTime,
      loadTime: new FormControl('', [
        Validators.required,
        this.loadTimeValidator.bind(this) // Custom validator if you have one
      ]),
    });

    this.loadDateTime.valueChanges.subscribe(() => {
      this.loadTime.updateValueAndValidity();
    });

    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));

  }

  validateLoadTime(): void {
    const now = new Date();
    const currentTimePlusSixHours = new Date(now.getTime() + 6 * 60 * 60 * 1000);
    const loadDateControl = this.addBookingForm.get('loadDateTime');
    const loadTimeControl = this.addBookingForm.get('loadTime');

    if (loadDateControl && loadDateControl.value) {
      const selectedDate = new Date(loadDateControl.value);
      const isSameDay = selectedDate.toDateString() === now.toDateString();

      if (isSameDay) {
        const inputTime = this.parseTime(loadTimeControl?.value);
        const inputDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), inputTime.hours, inputTime.minutes);

        // Set the error if the load time is too early
        if (inputDate < currentTimePlusSixHours) {
          loadTimeControl?.setErrors({ loadTimeTooEarly: true });
        } else {
          loadTimeControl?.setErrors(null);
        }
      } else {
        // Clear errors if the date is not today
        loadTimeControl?.setErrors(null);
      }
    }
  }

  // Helper function to parse HH:MM string
  parseTime(timeString: string): { hours: number; minutes: number } {
    const [hours, minutes] = timeString.split(':').map(Number);
    return { hours, minutes };
  }


  loadTimeValidator(control: AbstractControl): ValidationErrors | null {
    const inputTime = control.value;

    if (!inputTime) return { required: true }; // Handle required validation

    const [hoursStr, minutesStr] = inputTime.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return { invalidTime: true };
    }

    const now = new Date();
    const currentTimePlusSixHours = new Date(now.getTime() + 6 * 60 * 60 * 1000);
    const inputDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);

    const currentDate = formatDateWithoutTime(now); // Get only the date part in local time
    const formDate = this.addBookingForm.get('loadDateTime');
    const loadDate = formDate?.value ? formatDateWithoutTime(new Date(formDate.value)) : null;

    if (
      inputDate < currentTimePlusSixHours &&
      loadDate === currentDate
    ) {
      return { loadTimeTooEarly: true };
    }

    // Helper function to format date in YYYY-MM-DD without timezone adjustment
    function formatDateWithoutTime(date: Date): string {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    return null;
  }

  openTimePicker(target: EventTarget | null) {

    if (this.overlayRef) {
      // If the overlay is already open, do nothing or close it
      this.closeTimePicker();
      return; // Prevent opening a new overlay
    }

    if (target instanceof HTMLElement) {
      const overlayPosition = this.overlay.position()
        .flexibleConnectedTo(target)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          }
        ]);

      this.overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        positionStrategy: overlayPosition,
        scrollStrategy: this.overlay.scrollStrategies.block(), // Fixes the overlay in place while scrolling
        width: 'fit-content' // Set the desired width here, e.g., '200px' or 'fit-content'
      });

      const timePickerPortal = new ComponentPortal(TimePickerComponent);
      const timePickerComponentRef = this.overlayRef.attach(timePickerPortal);

      // Get the current time from the form
      const currentTime = this.addBookingForm.get('loadTime')?.value;

      // Pass the current time to the TimePickerComponent
      timePickerComponentRef.instance.currentTime = currentTime;

      // Listen for the emitted time from the child component
      timePickerComponentRef.instance.timeSelected.subscribe((selectedTime: string) => {
        if (selectedTime) {
          this.addBookingForm.get('loadTime')?.setValue(selectedTime);
        }
        this.overlayRef?.detach(); // Close the overlay after setting the time
      });

      this.overlayRef.backdropClick().subscribe(() => this.closeTimePicker());
    } else {
      console.error('Event target is not an HTMLElement');
    }
  }

  closeTimePicker() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }

  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private handleError<T>(message: string, result?: T): Observable<T> {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-fail']
    });
    return of(result as T);
  }

  private openSnackBar(message: string, success: boolean = true) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: success ? ['snackbar-success'] : ['snackbar-fail']
    });
  }

  ngOnInit(): void {
    this.today = new Date();

    //For when creating booking from schedules page>>
    this.communicationService.lateStack$.pipe(take(1)).subscribe(value => {
      this.isLateStack = value;
    });
    this.communicationService.siCutOff$.pipe(take(1)).subscribe(value => {
      this.isSiCutOff = value;
    });

    this.loadLookupData().subscribe({
      next: () => {
        console.log('I1______________________________________________');
        this.initializeForm().subscribe({  //initializeForm
          next: () => {
            console.log('I2____________________________________________________');
            this.loadBookingEditModel().subscribe({ //subscribeToInputs
              next: () => {
                console.log('I3________________________________________________________________');
                this.subscribeToInputs().subscribe({
                  next: () => {
                    console.log('I4___________________________________________________________________________________');
                    if (this.isReadOnly) {
                      this.setFormFieldsReadOnly();
                    }
                  },
                  error: (err) => console.error('Error loading booking model:', err)
                });
              },
              error: (err) => console.error('Error subscribing to inputs:', err)
            });
          },
          error: (err) => console.error('Error loading lookup data:', err)
        });
      },
      error: (err) => console.error('Error initializing form:', err)
    });
  }



  initializeForm(): Observable<void> {
      console.log('initializeForm +++++++++++')
      this.addBookingForm = this.fb.group({
        commodities: ['', Validators.required], // Form control for commodities, required
        containerTypes: ['', Validators.required],
        temperatures: ['', Validators.required],
        vents: ['', Validators.required],
        solasMethods: ['', Validators.required],
        isTemptale: [false],
        loadDateTime: ['', Validators.required],
        loadTime: ['', [Validators.required, this.loadTimeValidator.bind(this)]],  //, this.loadTimeValidator(this.loadDateTime)
        weight: [null, [Validators.min(0), Validators.max(35000)]],
        quantity: [null, [Validators.min(1), Validators.max(99)]],
        comment: ['', []],
        customers: ['', Validators.required]
      });

      this.addBookingForm.addControl('temptaleDescription', this.fb.control(''));

    console.log('initializeForm Done +++++++++++');
    return of(undefined);
  }


  openDialog(): void {
    this.dialog.open(CancelBookingComponent, {
      width: '250px',
    });
  }


  // Method to clear the date
  clearDate(): void {
    this.loadDateTime.setValue(null);
  }

  loadBookingEditModel(): Observable<void> {
    console.log('loadBookingEditModel Start XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
    let confirmedPackDateString = this.bookingData.confirmedPackDate?.toString();
    let confirmedPackDate = new Date(confirmedPackDateString as string);

    if (confirmedPackDate !== null) {
      this.loadHours = confirmedPackDate.getHours().toString().padStart(2, '0');;
      this.loadMinutes = confirmedPackDate.getMinutes().toString().padStart(2, '0');;
    }
    else {
      this.loadHours = '00';
      this.loadMinutes = '00';
    }

    this.clientRefNo.setValue(this.bookingData.clientReference);
    this.comment.setValue(this.bookingData.comments);
    this.loadDateTime.setValue(this.bookingData.confirmedPackDate);
    this.loadTime.setValue(this.loadHours + ':' + this.loadMinutes);
    this.containerTypes.setValue(this.bookingData.containerTypeCode);
    this.customers.setValue(this.bookingData.customerCode);
    this.dischargePorts.setValue(this.bookingData.dischargePortCode);
    if (this.isAddBooking && (this.bookingData.cnfFinalDestination == '' || typeof this.bookingData.cnfFinalDestination === 'undefined')) {
      this.finalDestinations.setValue(this.bookingData.dischargePortCode);
    }
    else {
      this.finalDestinations.setValue(this.bookingData.cnfFinalDestination);
    }
    this.loadpoint1.setValue(this.bookingData.loadingPoint1);
    this.loadpoint2.setValue(this.bookingData.loadingPoint2);
    this.prelimConsignee.setValue(this.bookingData.receiverCode);
    this.preVerificationKey.setValue(this.bookingData.preVerificationKey);
    this.quantity.setValue(this.bookingData.quantity);
    this.solasMethods.setValue(this.bookingData.solasMethod);
    this.stepUp.setValue(this.bookingData.stepUp);
    this.isSterilized.setValue(this.bookingData.sterilized);
    this.temperatures.setValue(this.bookingData.temperatureCode);
    this.isTemptale.setValue(this.bookingData.temptale);
    this.temptaleDescription.setValue(this.bookingData.temptaleDescription);
    this.temptaleQTY.setValue(this.bookingData.temptaleQty);
    this.vents.setValue(this.bookingData.ventilationCode);
    this.weight.setValue(this.bookingData.weight);
    this.commodities.setValue(this.bookingData.commodityCode);
    this.addBookingForm.setValue({
      containerTypes: this.bookingData.containerTypeCode,
      temperatures: this.bookingData.temperatureCode,
      vents: this.bookingData.ventilationCode,
      solasMethods: this.bookingData.solasMethod,
      isTemptale: this.bookingData.temptale,
      loadDateTime: this.bookingData.confirmedPackDate,
      loadTime: this.loadHours + ':' + this.loadMinutes,
      comment: this.bookingData.comments,
      weight: this.bookingData.weight,
      quantity: this.bookingData.quantity,
      commodities: this.bookingData.commodityCode,
      temptaleDescription: this.bookingData.temptaleDescription,
      customers: this.bookingData.customerCode
    });
    console.log('loadBookingEditModel 1 XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')

    this.lookupService.getFinalDestinations(this.bookingData.dischargePortCode as string, this.datafilter.filter)
        .subscribe(options => {
            this.bookingDataFinalDestinations = options;
            this.filteredFinalDestinations = of(this.bookingDataFinalDestinations);
            if (!this.isReadOnly) {
              this.finalDestinations.setValue(this.dischargePorts.value);
            }
        });

    this.lookupService.getScheduleDischargePorts(this.bookingData.vesselScheduleCode, this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataDischargePorts = data;
          this.filteredDischargePorts = this.dischargePortsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterDischargePorts(searchText ?? ''))
          );
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Discharge Port data', error)
      });


    console.log('loadBookingEditModel End XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')

    return of(undefined);
  }

  subscribeToInputs(): Observable<void> {
    console.log('subscribeToInputs Start ==========================================')

    if (!this.isReadOnly) {
      this.clientRefNo.valueChanges.subscribe(newValue => { this.bookingData.clientReference = newValue as string; });
      this.comment.valueChanges.subscribe(newValue => { this.bookingData.comments = newValue as string; });
      this.commodities.valueChanges.subscribe(newValue => { this.bookingData.commodityCode = newValue as string; });
      this.containerTypes.valueChanges.subscribe(newValue => { this.bookingData.containerTypeCode = newValue as string; });
      this.customers.valueChanges.subscribe(newValue => { this.bookingData.customerCode = newValue as string; });
      this.dischargePorts.valueChanges.subscribe(newValue => { this.bookingData.dischargePortCode = newValue as string; });
      this.finalDestinations.valueChanges.subscribe(newValue => { this.bookingData.cnfFinalDestination = newValue as string; });
      this.loadpoint1.valueChanges.subscribe(newValue => { this.bookingData.loadingPoint1 = newValue as string; });
      this.loadpoint2.valueChanges.subscribe(newValue => { this.bookingData.loadingPoint2 = newValue as string; });
      this.prelimConsignee.valueChanges.subscribe(newValue => { this.bookingData.receiverCode = newValue as string; });
      this.preVerificationKey.valueChanges.subscribe(newValue => { this.bookingData.preVerificationKey = newValue as string; });
      this.quantity.valueChanges.subscribe(newValue => { this.bookingData.quantity = newValue as number; });
      this.solasMethods.valueChanges.subscribe(newValue => { this.bookingData.solasMethod = newValue as string; });
      this.stepUp.valueChanges.subscribe(newValue => { this.bookingData.stepUp = newValue as string; });
      this.isSterilized.valueChanges.subscribe(newValue => { this.bookingData.sterilized = newValue as boolean; });
      this.temperatures.valueChanges.subscribe(newValue => { this.bookingData.temperatureCode = newValue as string; });
      this.isTemptale.valueChanges.subscribe(newValue => { this.bookingData.temptale = newValue as boolean; });
      this.temptaleDescription.valueChanges.subscribe(newValue => { this.bookingData.temptaleDescription = newValue as string; });
      this.temptaleQTY.valueChanges.subscribe(newValue => { this.bookingData.temptaleQty = newValue as number; });
      this.vents.valueChanges.subscribe(newValue => { this.bookingData.ventilationCode = newValue as string; });
      this.weight.valueChanges.subscribe(newValue => { this.bookingData.weight = newValue as number; });

      // Subscribe to loadDateTime and loadTime changes
      this.addBookingForm.valueChanges.subscribe(newValues => {
        this.bookingData.containerTypeCode = newValues.containerTypes as string;
        this.bookingData.temperatureCode = newValues.temperatures as string;
        this.bookingData.solasMethod = newValues.solasMethods as string;
        this.bookingData.temptale = newValues.isTemptale as boolean;
        this.bookingData.confirmedPackDate = this.createDateTime(newValues.loadDateTime as Date, newValues.loadTime as string) as Date;
        this.bookingData.comments = newValues.comment as string;
        this.bookingData.weight = newValues.weight as number;
        this.bookingData.quantity = newValues.quantity as number;
        this.bookingData.commodityCode = newValues.commodities as string;
        this.bookingData.temptaleDescription = newValues.temptaleDescription as string;
        this.bookingData.customerCode = newValues.customers as string;

        if (newValues.vents === 'CA') {
          this.addBookingForm.get('comment')?.setValidators([Validators.required]);
          //this.comment.setValidators([Validators.required]);
        } else {
          this.addBookingForm.get('comment')?.clearValidators();
          //this.comment.clearValidators();
        }
        if (newValues.vents !== this.bookingData.ventilationCode) {

          this.bookingData.ventilationCode = newValues.vents as string;
          this.addBookingForm.get('comment')?.updateValueAndValidity();
        }
      });

      // Subscribe to value changes on the dischargePorts FormControl - I have 
      this.dischargePorts.valueChanges
        .pipe(
          // Filter out empty values
          filter(value => !!value && value.trim() !== ''), // Ensure value is not empty or just whitespace
          switchMap(value => {
            return this.lookupService.getFinalDestinations(value as string, this.datafilter.filter);
          })
        )
        .subscribe(options => {
          this.bookingDataFinalDestinations = options;
          this.filteredFinalDestinations = of(this.bookingDataFinalDestinations);
          this.finalDestinations.setValue(this.dischargePorts.value);
        });
    }
  
    console.log('subscribeToInputs End ==========================================')

    return of(void 0)
  }

  loadLookupData() {
    console.log('loadLookupData Start ++++++++++++++++++++++++++++++++++++++++++++++++++')
      this.apiUserService.userInfo
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe({
          next:
            (_) => {
              this.isCustUser = this.apiUserService.IsCustUser;
            }
        });

      this.isCustUser = this.apiUserService.IsCustUser;

      this.lookupService.getCustomers(this.datafilter.filter)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (data) => {
            this.customersData = data;
            this.filteredCustomers = this.customersMultiFilter.valueChanges.pipe(
              startWith(''),
              map(searchText => this.filterCustomers(searchText ?? ''))
            );

            this.cdr.markForCheck();
          },
          error: (error) => console.error('Error fetching Customers data', error)
        });

      this.lookupService.getLoadPorts(this.datafilter.filter)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (data) => {
            this.bookingDataLoadPort = data;
            this.filteredLoadPorts = this.loadPortsMultiFilter.valueChanges.pipe(
              startWith(''),
              map(searchText => this.filterLoadPorts(searchText ?? ''))
            );

            this.cdr.markForCheck();
          },
          error: (error) => console.error('Error fetching Load Port data', error)
        });

      this.lookupService.getCommodities(this.datafilter.filter)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (data) => {
            this.bookingDataCommodities = data;
            this.filteredCommodities = this.commoditiesMultiFilter.valueChanges.pipe(
              startWith(''),
              map(searchText => this.filterCommodities(searchText ?? ''))
            );

            this.cdr.markForCheck();
          },
          error: (error) => console.error('Error fetching Commodity data', error)
        });

      this.lookupService.getCountries(this.datafilter.filter)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (data) => {
            this.bookingDataCountries = data;
            this.filteredCountries = this.countriesMultiFilter.valueChanges.pipe(
              startWith(''),
              map(searchText => this.filterCountries(searchText ?? ''))
            );

            this.cdr.markForCheck();
          },
          error: (error) => console.error('Error fetching Country data', error)
        });


      this.lookupService.getTemperatures(this.datafilter.filter)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (data) => {
            //this.bookingDataTemperatures = data;
            this.bookingDataTemperatures = data.map(item => ({
              code: item.code,
              name: `${item.code} : ${item.name}`
            })) as LookupModel[]; // Ensure it returns an array of LookupModel
            // .map(item => `${item.code} : ${item.name}`);
            this.filteredTemperatures = this.temperaturesMultiFilter.valueChanges.pipe(
              startWith(''),
              map(searchText => this.filterTemperatures(searchText ?? ''))
            );

            this.cdr.markForCheck();
          },
          error: (error) => console.error('Error fetching Temperature data', error)
        });

      this.lookupService.getContainerTypes(this.datafilter.filter)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (data) => {
            this.bookingDataContainerTypes = data;
            this.filteredContainerTypes = this.containerTypesMultiFilter.valueChanges.pipe(
              startWith(''),
              map(searchText => this.filterContainerTypes(searchText ?? ''))
            );

            this.cdr.markForCheck();
          },
          error: (error) => console.error('Error fetching Container data', error)
        });

      this.lookupService.getSolasMethods(this.datafilter.filter)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (data) => {
            this.bookingDataSolasMethods = data;
            this.filteredSolasMethods = this.solasMethodsMultiFilter.valueChanges.pipe(
              startWith(''),
              map(searchText => this.filterSolasMethods(searchText ?? ''))
            );
            this.cdr.markForCheck();
          },
          error: (error) => console.error('Error fetching Solas Method data', error)
        });

      this.lookupService.getLoadpoints(this.datafilter.filter)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (data) => {
            this.bookingDataLoadpoints = data;
            this.filteredLoadpoints = this.loadpointsMultiFilter.valueChanges.pipe(
              startWith(''),
              map(searchText => this.filterLoadpoints(searchText ?? ''))
            );

            this.cdr.markForCheck();
          },
          error: (error) => console.error('Error fetching Load Port data', error)
        });

      this.lookupService.getVents(this.datafilter.filter)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (data) => {
            this.bookingDataVents = data;
            this.filteredVents = this.ventsMultiFilter.valueChanges.pipe(
              startWith(''),
              map(searchText => this.filterVents(searchText ?? ''))
            );

            this.cdr.markForCheck();
          },
          error: (error) => console.error('Error fetching Load Port data', error)
        });

      this.lookupService.getTemptaleDescriptions(this.datafilter.filter)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (data) => {
            this.bookingDataTemptaleDescriptions = data;
            this.filteredTemptaleDescriptions = this.temptaleDescriptionsMultiFilter.valueChanges.pipe(
              startWith(''),
              map(searchText => this.temptaleDescriptions(searchText ?? ''))
            );

            this.cdr.markForCheck();
          },
          error: (error) => console.error('Error fetching Load Port data', error)
        });

    console.log('loadLookupData End ++++++++++++++++++++++++++++++++++++++++++++++++++')
    return of(undefined);
  }



  onCheckboxChange(event: MatCheckboxChange) {
    this.isChecked = event.checked; // Update the state based on the checkbox change
  }

  clearFilter(control: FormControl) {
    control.reset();
    control.setValue([]);
  }

  private filterLoadPorts(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataLoadPort;
    }
    return this.bookingDataLoadPort.filter(loadPort =>
      loadPort.name.toLowerCase().includes(searchText.toLowerCase())
    ); 
  }

  private filterDischargePorts(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataDischargePorts;
    }
    return this.bookingDataDischargePorts.filter(dischargePort =>
      dischargePort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterFinalDestinations(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataFinalDestinations;
    }
    return this.bookingDataFinalDestinations.filter(finalDestination =>
      finalDestination.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCommodities(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataCommodities;
    }
    return this.bookingDataCommodities.filter(commodity =>
      commodity.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCountries(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataCountries;
    }
    return this.bookingDataCountries.filter(country =>
      country.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterTemperatures(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataTemperatures;
    }
    return this.bookingDataTemperatures.filter(country =>
      country.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterSolasMethods(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataSolasMethods;
    }
    return this.bookingDataSolasMethods.filter(solasMethod =>
      solasMethod.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterLoadpoints(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataLoadpoints;
    }
    return this.bookingDataLoadpoints.filter(loadpoint =>
      loadpoint.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterContainerTypes(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataContainerTypes;
    }
    return this.bookingDataContainerTypes.filter(containerType =>
      containerType.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterVents(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataVents;
    }
    return this.bookingDataVents.filter(vent =>
      vent.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCustomers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.customersData;
    }
    return this.customersData.filter(customer =>
      customer.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private temptaleDescriptions(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataTemptaleDescriptions;
    }
    return this.bookingDataTemptaleDescriptions.filter(temptaleDescription =>
      temptaleDescription.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  public sterilizedClick(): void {
    if (this.isSterilized.value === true) {
      this.isSterilized.setValue(false)
    }
    else {
      this.isSterilized.setValue(true)
    }
  }

  public temptaleClick(): void {
    // Toggle the isTemptale checkbox value
    const isTemptaleChecked = this.isTemptale.value;
    this.isTemptale.setValue(!isTemptaleChecked);
    this.addBookingForm.get('isTemptale')?.setValue(!isTemptaleChecked); // Update the specific form control

  
    // Check if temptale checkbox is selected
    if (!isTemptaleChecked) {
      // Checkbox is now selected, add the required validator to temptaleDescription
      this.addBookingForm.get('temptaleDescription')?.setValidators([Validators.required]);
      
    } else {
      // Checkbox is deselected, remove the required validator and clear the field
      this.addBookingForm.get('temptaleDescription')?.clearValidators();
      this.addBookingForm.get('temptaleDescription')?.reset(); // Clear the field value
    }
    // Update the validity of temptaleDescription field
    // this.addBookingForm.get('temptaleDescription')?.updateValueAndValidity();
  }

  startSpinner(): void{
    this.isSpinnerVisible = true;
    const spinnerOverlay = document.querySelector('.spinner-overlay') as HTMLElement;

    if (spinnerOverlay) {
      spinnerOverlay.style.display = 'flex'; // Hide the spinner overlay
    }
  }

  stopSpinner(): void {
    this.isSpinnerVisible = false;

    // Get the spinner overlay element by class name
    const spinnerOverlay = document.querySelector('.spinner-overlay') as HTMLElement;

    if (spinnerOverlay) {
      spinnerOverlay.style.display = 'none'; // Hide the spinner overlay
    }
  }

  public sendToReefers(): void {
    this.startSpinner()

    if (this.bookingData.confirmedPackDate) {
      const loadDate = this.loadDateTime.value;
      const loadTime = this.loadTime.value;
    }

    // Mark all controls as touched to trigger validation errors to show
    this.addBookingForm.markAllAsTouched();
    this.addBookingForm.controls['comment'].markAsTouched();

    // Check if the form is valid before proceeding
    if (this.addBookingForm.invalid || this.comment.invalid) {
      const invalidFields: string[] = [];

      Object.keys(this.addBookingForm.controls).forEach((field) => {
        const control = this.addBookingForm.get(field);

        if (control?.hasError('required') && control.touched) {
          invalidFields.push(field);
        }

        if (control?.hasError('min')) {
          invalidFields.push(field);
        }

        if (control?.hasError('max')) {
          invalidFields.push(field);
        }

        if (control?.hasError('loadTimeTooEarly')) {
          invalidFields.push(field);
        }

        if (control?.hasError('invalidTime')) {
          invalidFields.push(field);
        }
      });

      const fieldCount = invalidFields.length;
      const message = fieldCount > 0
        ? `Please fill in all required fields before submitting the form: ${fieldCount} field${fieldCount > 1 ? 's' : ''} invalid.`
        : 'Please fill in all required fields before submitting the form.';

      this.dialog.open(InformationComponent, {
        data: {
          title: 'Complete required fields',
          message: message
        }
      });
      this.stopSpinner()
      return;
    }

    //this.isSendToReefersDisabled = true;

    if (!this.bookingData.lateStack && this.isLateStack) {
      this.bookingData.lateStack = this.isLateStack;
    }

    if (!this.bookingData.sicutoff && this.isSiCutOff) {
      this.bookingData.sicutoff = this.isSiCutOff;
    }

    if (this.dischargePorts.value !== this.bookingData.dischargePortCode) {
      this.bookingData.dischargePortCode = this.dischargePorts.value as string;
    }

    this.bookingDataService.submitBooking(this.bookingData).subscribe({
      next: (data) => {
        if (data.success) {
          console.log('Booking created!');
          this.userActivityService.logActivity('Created a booking');

          const showDialog = () => {
            const submissionDialog = this.dialog.open(BookingSubmissionComponent, {
              width: '350px',
              height: '350px',
              hasBackdrop: true,
              disableClose: true,
              data: { ResponseData: data, RequestData: this.bookingData }

            });

            this.stopSpinner()
            submissionDialog.afterClosed().subscribe(() => {
              submissionDialog.disableClose = false
              this.stopSpinner()
            });
          };

          if (this.bookingData.sicutoff) {
            const firstpopup = this.dialog.open(InformationComponent, {
              data: {
                title: 'Warning',
                message: 'Your booking submitted is close to the SI cut-off on the schedule. Please call your logistics coordinator to confirm that the booking can be executed. This booking will remain unconfirmed until confirmed telephonically'
              }
            });

            firstpopup.afterClosed().subscribe(() => {
              showDialog();
            });
          } else {
            showDialog();
          }
        } else {

          this.stopSpinner()
          console.error(this.isSpinnerVisible);
          this.handleError(`${data.appBookingCode}: ${data.status}`);
        }
      },
      error: (err) => {
        console.error('Error during booking submission:', err);
        this.isSpinnerVisible = false; // Stop spinner on error
        this.dialog.open(InformationComponent, {
          data: {
            title: 'Submission Error',
            message: 'An error occurred while submitting the booking. Please try again later.'
          }
        });
      }

    });
  }

// This function creates a Date object by combining a date and time string
createDateTime(date: Date, time: string): Date {
  let hours: number, minutes: number;


  console.log("createDateTime hours", date  + time)
  if (time.includes(':')) {
    [hours, minutes] = time.split(':').map(Number);
  } else {
    // Handle case where time string does not include ':'
    hours = parseInt(time, 10);
    minutes = 0;
  }

  // Create a new Date object to avoid mutating the original date
  const newDate = new Date(date);
  newDate.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, and milliseconds

  // Return the combined date-time as a Date object
  return newDate;
}


  formatTime(): void {
    const loadTimeControl = this.addBookingForm.get('loadTime');
    const input = loadTimeControl?.value;

    if (input) {
      const formattedTime = this.formatToISOTime(input);
      if (formattedTime !== 'Invalid time') {
        loadTimeControl?.setValue(formattedTime, { emitEvent: false });
      }
    }
  }

// This function formats the time input to ISO time format
formatToISOTime(input: string): string {
  // Validate the input time
  const validationResult = this.timeValidator(input);

  if (validationResult) {
    return 'Invalid time'; // Return a string indicating invalid time
  }

  let hours: number, minutes: number;
  if (input.includes(':')) {
    [hours, minutes] = input.split(':').map(Number);
  } else {
    hours = Number(input.slice(0, 2));
    minutes = Number(input.slice(2, 4));
  }

  // Ensure hours and minutes are zero-padded
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
  }

  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
    console.log('Filter cleared:', control);
  }


// Time validator function that checks if the time input is in a valid format
timeValidator(input: string): string | null {
  const timePattern = /^([01]\d|2[0-3])(:?)([0-5]\d)$/;
  return timePattern.test(input) ? null : 'Invalid time';
  }

  public returnToBookings(): void {
    this.router.navigate(['/booking management'], {});
  }
  
private setFormFieldsReadOnly(): void {
  this.weight.disable();
  this.clientRefNo.disable();
  this.quantity.disable(); 
  this.isSterilized.disable();
  this.isTemptale.disable();
  this.comment.disable();
  this.clientRefNo.disable();
  this.commodities.disable();
  this.loadDateTime.disable();
  this.containerTypes.disable();
  this.customers.disable();
  this.dischargePorts.disable();
  this.finalDestinations.disable();
  this.loadpoint1.disable();
  this.loadpoint2.disable();
  this.loadDateTime.disable();
  this.loadTime.disable();
  this.prelimConsignee.disable();
  this.preVerificationKey.disable();
  this.quantity.disable();
  this.solasMethods.disable();
  this.stepUp.disable();
  this.isSterilized.disable();
  this.temperatures.disable();
  this.isTemptale.disable();
  this.temptaleDescription.disable();
  this.temptaleQTY.disable();
  this.vents.disable();
  this.weight.disable();
  this.addBookingForm.disable();
  this.addBookingForm.get('comment')?.disable();
  }
}

function distinctUntilChanged(): import("rxjs").OperatorFunction<import("../../models/schedules/schedule-list-model").ScheduleListModel | null, unknown> {
  throw new Error('Function not implemented.');
}




