export enum ClaimAction {
  None = 'None',
  Root2Go_ViewBookings = 'Root2Go_ViewBookings',
  Root2Go_AddBookings = 'Root2Go_AddBookings',
  Root2Go_ViewDocuments = 'Root2Go_ViewDocuments',
  Root2Go_ViewShipments = 'Root2Go_ViewShipments',
  Root2Go_ViewSchedules = 'Root2Go_ViewSchedules',
  Root2Go_ViewAnalytics = 'Root2Go_ViewAnalytics',
  Root2Go_ViewAlerts = 'Root2Go_ViewAlerts',
  Root2Go_SendAlerts = 'Root2Go_SendAlerts',
  Root2Go_EditDocuments = 'Root2Go_EditDocuments',
  Root2Go_Lookups = 'Root2Go_Lookups'
}
