<div class="container">
  <h1>Contact Us</h1>
</div>
<p style="font-family: var(--app-text-size); text-align: center;">Need assistance? Our support team is here for you!</p>
<p style="font-family: var(--app-text-size);  text-align: center;">We're here to help you every step of the way!</p>
<p style="font-family: var(--app-text-size);  text-align: center;">Your suggestions or feedback would be valued!</p>

<form id="mainForm" style="background: #162A3E" (ngSubmit)="submitForm()">

  <mat-form-field>
    <mat-label>Subject</mat-label>
    <mat-select [(ngModel)]="email.subject" name="subject" required>
      <mat-option value="Enquiry">Enquiry</mat-option>
      <mat-option value="Support">Support</mat-option>
      <mat-option value="Feedback">Feedback</mat-option>
      <mat-option value="Complaint">Complaint</mat-option>
      <mat-option value="Feature Suggestion">Feature Suggestion</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Message Body</mat-label>
    <textarea matInput [(ngModel)]="email.message" name="message" required></textarea>
  </mat-form-field>

  <button type="submit"
          style="margin-top: 15px; padding: 10px 20px; color: #fff; background-color: #2196F3; border-radius: 5px; font-weight: bold;">
    Submit
  </button>
</form>

<div id="notification" style="display: none;">Email Sent Successfully!</div>

<script>
  document.getElementById("submitButton").addEventListener("click", function () {
    const subject = document.getElementById("subject").value;
    const messageBody = document.getElementById("messageBody").value;

    fetch("http://localhost:3000/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ subject, messageBody }),
    })
      .then((response) => {
        if (response.ok) {
          document.getElementById("notification").style.display = "block";
          setTimeout(() => {
            document.getElementById("notification").style.display = "none";
          }, 3000);
        } else {
          alert("Failed to send email. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while sending the email.");
      });
  });
</script>
