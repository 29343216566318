import { OnInit, OnDestroy, Input, SimpleChanges, Component } from "@angular/core";
import L from "leaflet";
import { PathPoint } from "../../models/shipment/route-information-model";
import { ShipmentTrackingHeader, ShipmentTrackingVessel } from "../../models/shipment/shipment-tracking-header-model";
import { ShipmentService } from "../../service/shipment/shipment.service";
import { DatePipe } from "@angular/common";
import { detailTabbedViewEnum, detailView } from "../../models/detail-view-enum";
import { CommunicationService } from "../../service/communication/communication.service";
import { ShipmentTranferGRRefContainerNo } from "../../models/shipment/shipment-data-model";
import { OceanTrackingViewEnum } from "../../enums/ocean-tracking-view-enum";
import { takeUntil } from "rxjs/operators";
import { LegendSetup } from "../../models/legend";
import { ApiUserService } from "../../service/user/api-user.service";
import { Subject } from "rxjs";

interface Location {
  locationCode: string;
  name: string;
  countryCode: string;
  country: string;
  latitude: number;
  longitude: number;
}

@Component({
  selector: 'app-ocean-tracking',
  templateUrl: './ocean-tracking.component.html',
  styleUrls: ['./ocean-tracking.component.css']
})
export class OceanTrackingComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  private map!: L.Map;
  private voyageMarkers: L.Marker[] = [];
  private mapInitialized: boolean = false;
  private currentPath: L.Polyline | null = null;
  private currentGreenPath: L.Polyline | null = null;
  private activeMarker: L.Marker | null = null; // To track the currently selected marker
  private blueMarkers: L.Marker[] = [];  // New variable to track blue markers
  public GetRoute: ShipmentTrackingHeader = new ShipmentTrackingHeader;
  isCustUser: boolean = false;

  public isLoading = false;
  public showRoute: boolean = false;  // To toggle route display
  public showTracking: boolean = false;  // To toggle tracking display

  legend: LegendSetup[] = [
    { iconClass: 'icon ship-icon eta', text: 'Vessel.' },
    //{ iconClass: 'icon ship-icon eta-changed', text: 'ETA moved to a later date.' },
    //{ iconClass: 'icon ship-icon line-expected', text: 'Expected vessel route' },
    { iconClass: 'icon ship-icon line-actual', text: 'Actual route' },
  ];

  @Input() vehicleLocation!: L.LatLngExpression;
  @Input() startLocation!: L.LatLngExpression;
  @Input() voyageCoordinates: ShipmentTrackingHeader[] = [];
  @Input() endLocation!: L.LatLngExpression;
  @Input() vesselDataList: ShipmentTrackingVessel[] = [];
  @Input() shipmentDataList: ShipmentTrackingHeader[] = [];
  @Input() mapView: OceanTrackingViewEnum = OceanTrackingViewEnum.overview;

  constructor(private shipmentService: ShipmentService,
              private communicationService: CommunicationService,
              private apiUserService: ApiUserService,
              private datePipe: DatePipe) { }

  ngOnInit(): void {

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    setTimeout(() => {
      this.initMap();
      console.log('This message is delayed by 100 milliseconds');
    }, 100);    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['vesselDataList'] || changes['startLocation'] || changes['endLocation']) {
      console.log('Ocean tracking component updated:', this.vesselDataList);
      this.removeGreenPath();
      this.updateMarkersOnMap();
    }
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }


  private initMap(): void {
    if (this.mapInitialized === true) {
      return;
    }

    if (!this.startLocation) {
      this.startLocation = [0, 0];
    }

    const southWest = L.latLng(-90, -180);
    const northEast = L.latLng(90, 180);
    const bounds = L.latLngBounds(southWest, northEast);


    let minZoom = 3;

    if (window.innerWidth <= 576) {
      minZoom = 1.5; // Higher minZoom for mobile for a closer view
    } else if (window.innerWidth <= 1024) {
      minZoom = 2; // Medium minZoom for tablets
    }

    this.map = L.map('map', { worldCopyJump: true, maxBounds: bounds, trackResize: true }).setView(this.startLocation, minZoom);

    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      maxZoom: 18,
      minZoom: minZoom,
      noWrap: true,
      attribution: 'Map data &copy; OpenStreetMap contributors',
    }).addTo(this.map);

    this.mapInitialized = true;


    if (this.vesselDataList.length > 0) {
      this.updateMarkersOnMap();
    } else {
      this.setDefaultZoom();
    }     
    
  }

  private updateMarkersOnMap(): void {
    if (this.mapInitialized === false) {
      return;
    }

    this.removeAllMarkers();

    if (this.vesselDataList.length > 0) {
      this.vesselDataList.forEach((coordinate, index) => {
        this.addVoyageMarker(coordinate, index);
      });
      this.zoomToMarkers();
    } else {
      this.setDefaultZoom();
    }
  }

  private removeAllMarkers(): void {
    this.voyageMarkers.forEach(marker => {
      this.map.removeLayer(marker);
    });
    this.voyageMarkers = [];

    // Remove all blue markers
    this.blueMarkers.forEach(marker => {
      this.map.removeLayer(marker);
    });
    this.blueMarkers = [];
  }

  private addVoyageMarker(coordinate: ShipmentTrackingVessel, vesselIndex: number): void {
    const { currentLatitude, currentLongitude, vesselName, eta, noOfContainers, routeInformationMediaEditLink, vesselCode, bookingReference, bookingLineReference } = coordinate;
    const formattedPackDate = this.datePipe.transform(eta, 'dd MMM yyyy');
    console.log(`Adding marker for ${vesselName} at (${currentLatitude}, ${currentLongitude})`);

    if (currentLatitude && currentLongitude) {
      let marker = L.marker([currentLatitude, currentLongitude], {
        icon: L.icon({
          iconUrl: '../../assets/Ship-Icon.svg',
          iconSize: [24, 24],
          iconAnchor: [12, 12],
        }),
      }).addTo(this.map);

      // Filter shipmentDataList to find containers matching the current vesselCode
      const containersForVessel = this.shipmentDataList.filter((container: ShipmentTrackingHeader) => container.vesselCode === vesselCode);

      const popupContent = `
        <div>
          <h4 style="font-family: Arial, sans-serif; font-size: 18px; font-weight: 600; color: #368ede; margin-bottom: 10px;">Shipment Details</h4>
    
          <p style="display: grid; grid-template-columns: auto auto auto; gap: 20px; margin-bottom: 20px;">
            <span><strong>Vessel:</strong> ${vesselName || 'Unknown'}</span>
            <span><strong>ETA:</strong> ${formattedPackDate || 'N/A'}<br /></span>
            <span><strong>Containers:</strong> ${noOfContainers || 'Unknown'}</span>

          </p>

          <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px; border: 1px solid #ddd;">
            <thead>
              <tr style="background-color: #162a3e; text-align: left;">
                <th style="padding: 10px; font-weight: 600; color: #FFFFFF; border-bottom: 1px solid #ddd;">Container No</th>
                <th style="padding: 10px; font-weight: 600; color: #FFFFFF; border-bottom: 1px solid #ddd;">Consignee</th>
                <th style="padding: 10px; font-weight: 600; color: #FFFFFF; border-bottom: 1px solid #ddd;">Destination</th>
                <th style="padding: 10px; font-weight: 600; color: #FFFFFF; border-bottom: 1px solid #ddd;"></th>
              </tr>
            </thead>
            <tbody>
              ${containersForVessel.length > 0
                ? containersForVessel.map((container: ShipmentTrackingHeader, index: number ) => `
                  <tr style="background-color: #fff;">
                    <td style="padding: 10px; border-bottom: 1px solid #ddd;")">${container.containerNumber || 'N/A'}</td>
                    <td style="padding: 10px; border-bottom: 1px solid #ddd;">${container.consignee || 'N/A'}</td>
                    <td style="padding: 10px; border-bottom: 1px solid #ddd;">${container.destination || 'N/A'}</td>  <td style="padding: 10px; border-bottom: 1px solid #ddd;">
                      <button style="border-radius: 30px; background-color: #368ede; color: white; border: none; font-size: 8px; font-family: 'Arial', sans-serif; font-weight: 600; cursor: pointer; transition: background-color 0.3s ease; width: auto;" id="view-detail-btn-${vesselIndex}-${index}">
                        View Detail
                      </button>
                    </td>
                  </tr>
                `).join('') : '<tr><td colspan="2" style="text-align: center; padding: 10px;">No containers available</td></tr>'
              }
            </tbody>
          </table>
        </div>
      `;

      if (this.mapView === OceanTrackingViewEnum.overview) {
        marker.bindPopup(popupContent, { maxHeight: 700, minWidth: 400 });
      }
      else {
        if (vesselCode != '') {
          containersForVessel.forEach((container: ShipmentTrackingHeader) => {

            const decodedLink = decodeURIComponent(routeInformationMediaEditLink);
            this.onRouteInfoButtonClick(decodedLink);
            this.getCurrentRoute(bookingReference, bookingLineReference.toString());
          });
        }
      }

      marker.on('click', () => {

        //this.removePaths();
        this.removeBlueMarkers();
        // Close any open popups
        //this.map.closePopup();
        marker.closePopup();

        // Open the popup for the clicked marker
        marker.openPopup();
      });

      marker.on('popupopen', () => {
        // Remove paths and blue markers when a new marker is clicked
        containersForVessel.forEach((container: ShipmentTrackingHeader, index: number) => {


          const decodedLink = decodeURIComponent(routeInformationMediaEditLink);
          this.onRouteInfoButtonClick(decodedLink);
          this.getCurrentRoute(bookingReference, bookingLineReference.toString());
          const infoBtn = document.getElementById(`view-detail-btn-${vesselIndex}-${index}`);
          if (infoBtn) {
            infoBtn.onclick = () => {
              this.viewDetails(detailView.shipmentDetail, detailTabbedViewEnum.detailsTab, container.grReference, container.containerNumber)

            };
          }



        });
      });

      this.voyageMarkers.push(marker);
    } else {
      console.warn(`Invalid coordinates for vessel: ${vesselName}`);
    }
  }

  private removeBlueMarkers(): void {
    this.blueMarkers.forEach(marker => {
      this.map.removeLayer(marker);
    });
    this.blueMarkers = [];
  }

  getCurrentRoute(bookingRef: string, bookingLineRef: string): void {
    this.shipmentService.getShipmentsTrackingHistory(bookingRef, bookingLineRef).subscribe(
      (data) => {
        console.log('Fetched Route Data:', data);

        // Assuming the data is an array of tracking points (latitude, longitude)
        const pathCoordinates: L.LatLngExpression[] = data.map((point: any) => {
          return [point.latitude, point.longitude] as L.LatLngExpression;
        });

        // Remove previous paths before drawing the new one
        // Draw the tracking path
        this.drawGreenPath(pathCoordinates);
      },
      (error) => {
        console.error('Error fetching route data:', error);
      }
    );
  }

  onRouteInfoButtonClick(routeInformationMediaEditLink: string): void {
    const decodedLink = decodeURIComponent(routeInformationMediaEditLink);
    this.shipmentService.getShipmentRouteInformation(decodedLink).subscribe(
      (data) => {
        console.log('Fetched Route Data:', data);
        this.displayRouteOnMap(data);
      }
    );
  }

  private removePaths(): void {
    // Remove previously drawn paths
    if (this.currentPath) {
      this.map.removeLayer(this.currentPath);
      this.currentPath = null;
    }

  }

  private removeGreenPath(): void {
    if (this.currentGreenPath) {
      this.map.removeLayer(this.currentGreenPath);
      this.currentGreenPath = null;
    }
  }

  private drawGreenPath(pathCoordinates: L.LatLngExpression[]): void {
    this.removeGreenPath();


    this.currentGreenPath = L.polyline(pathCoordinates, {
      color: 'green',
      weight: 4,
      opacity: 0.7
    }).addTo(this.map);
  }

  private displayRouteOnMap(routeData: any): void {
    this.isLoading = true;
    const locations: Location[] = routeData.locations;
    const path: PathPoint[] = routeData.path;

    // Remove any existing path before displaying the new one

    locations.forEach((location: Location) => {
      const { latitude, longitude, name, country } = location;

      const blueIcon = L.icon({
        iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png',
        iconSize: [25, 41],
        iconAnchor: [12, 12],
        popupAnchor: [1, -34],
        shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
        shadowSize: [41, 41],
      });

      let blueMarker = L.marker([latitude, longitude], { icon: blueIcon })
        .addTo(this.map)
        .bindPopup(`
       <div>
         <strong>${name}</strong><br />
         ${country}
       </div>
     `);

      this.blueMarkers.push(blueMarker);
    });


    this.removePaths();

    const pathCoordinates: L.LatLngExpression[] = path.map((point: PathPoint) => {
      return [point.latitude, point.longitude] as L.LatLngExpression;
    });

    this.currentPath = L.polyline(pathCoordinates, { color: '#368EDE', dashArray: '5, 10' }).addTo(this.map);
    this.map.fitBounds(L.latLngBounds(pathCoordinates));
    this.isLoading = false;
  }

  viewDetails(view: detailView, tab: detailTabbedViewEnum, grRef: string | null, containerNo: string | null): void {
    this.communicationService.toggleDetailView(view, tab);

    let info: ShipmentTranferGRRefContainerNo = new ShipmentTranferGRRefContainerNo;

    info.grReferenceNo = grRef;
    info.containerNo = containerNo;

    this.communicationService.getDetailViewGRRefcontainerNo(info);
  }

  private zoomToMarkers(): void {
    if (this.map && this.voyageCoordinates.length > 0) {
      const bounds = new L.LatLngBounds(
        this.voyageCoordinates.map(coordinate => [
          coordinate.currentLatitude,
          coordinate.currentLongitude
        ] as [number, number])
      );
      this.map.fitBounds(bounds);
    }
  }

  private setDefaultZoom(): void {
    if (this.map) {
      this.map.setView([0, 0], 3);
    }
  }

  private showNoVesselMessage(): void {
    this.removeNoVesselMessage();
    const messageContent = 'No vessel to display';
    const noVehiclesMarker = L.marker([-33.92574199874307, 18.65486950525504], {
      icon: L.divIcon({
        className: 'no-vehicles-message',
        html: `<div style="background-color: white; border: 2px solid #f00; padding: 5px; border-radius: 5px; color: red; font-weight: bold;">${messageContent}</div>`,
        iconSize: [150, 50],
        iconAnchor: [75, 25],
      }),
    }).addTo(this.map);

    noVehiclesMarker.bindPopup(messageContent).openPopup();
  }

  private removeNoVesselMessage(): void {
    if (this.map) {
      this.map.eachLayer(layer => {
        if (layer instanceof L.Marker) {
          const popup = layer.getPopup();
          if (popup && popup.getContent() === 'No vehicles to display') {
            this.map.removeLayer(layer);
          }
        }
      });
    }
  }

}
