import { Component, ChangeDetectorRef, OnInit, ChangeDetectionStrategy, Input, OnDestroy, inject } from '@angular/core';
import { FilterData } from '../models/list-model';
import { FormControl } from '@angular/forms';
import { CommunicationService } from '../service/communication/communication.service';
import { DocumentService } from '../service/documents/document.service';
import { ColumnSetup } from '../models/column-setup'
import { LookupModel } from '../models/lookup-model';
import { DataFilter } from '../models/filter-models';
import { MatDialog } from '@angular/material/dialog';
import { InformationComponent } from '../dialog/information/information.component';
import { LookupService } from '../service/lookup/lookup.service';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';

@Component({
  selector: 'app-document-filter-pending-documents',
  templateUrl: './document-filter-pending-documents.component.html',
  styleUrls: ['./document-filter-pending-documents.component.css','../filter-sidenav/filter-sidenav.component.css']
})
export class DocumentFilterPendingDocumentsComponent implements OnInit, OnDestroy {
  
  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;

  savedFilterName = new FormControl('');
  customers = new FormControl<LookupModel[]>([]);
  grRefNo = new FormControl('');
  clientRefNo = new FormControl('');
  documentType = new FormControl<LookupModel[]>([]);
  consignees = new FormControl<LookupModel[]>([]);
  
  

  customersMultiFilter = new FormControl<string>('');
  documentTypesMultiFilter = new FormControl<string>('');
  consigneesMultiFilter = new FormControl<string>('');

  filteredCustomers!: Observable<LookupModel[]>;
  filteredDocumentTypes!: Observable<LookupModel[]>;
  filteredConsignees!: Observable<LookupModel[]>;

  customersData: LookupModel[] = [];
  documentTypesData: LookupModel[] = [];
  documentDataConsignee:LookupModel[] =[];
  checkedItems: Set<string> = new Set<string>();

  filterData: FilterData = {};
  filterName: string = '';
  datafilter: DataFilter = new DataFilter();
  savedFilters: DataFilter[] = [];

  sortingOptionsList: ColumnSetup[] = [];
  selectedSortingOption = new FormControl();

  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private communicationService: CommunicationService,
    private documentDataService: DocumentService, 
    private cdr: ChangeDetectorRef, 
    private domSanitizer: DomSanitizer, 
    private lookupService: LookupService, 
    private dialog: MatDialog, 
    private matIconRegistry: MatIconRegistry
  ) {
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
  }

  documentTypeList: string[] = [];

  ngOnInit(): void {

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    this.loadDocumentData();
  }

  loadDocumentData(): void {

    this.documentDataService.sendActiveFilterToFilterNotApproved$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.datafilter = data;
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Filter data', error)
      });

    this.lookupService.getCustomers(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.customersData = data;
          this.customers.setValue(this.customersData.filter(filter => filter.isSelected));
          this.filteredCustomers = this.customersMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCustomers(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

      this.lookupService.getDocumentTypes(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.documentTypesData = data;
          this.documentType.setValue(this.documentTypesData.filter(value => value.isSelected));
          this.filteredDocumentTypes = this.documentTypesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterDocumentType(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

      this.lookupService.getConsignees(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next:(data) => {
          this.documentDataConsignee = data;
          this.consignees.setValue(this.documentDataConsignee.filter(c => c.isSelected));
          this.filteredConsignees = this.consigneesMultiFilter.valueChanges.pipe(
            startWith(''), 
            map(searchText => this.filterConsignees(searchText ?? ''))
          );
      
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Consignee data', error)
      });

    let grRefNoRestore = this.datafilter.filter['grRefNo']?.toString() ?? null;
    if (grRefNoRestore != null) {
      this.grRefNo.setValue(grRefNoRestore);
    }

    let clientRefNoRestore = this.datafilter.filter['clientRef']?.toString() ?? null;
    if (clientRefNoRestore != null) {
      this.clientRefNo.setValue(clientRefNoRestore);
    }


    if (this.datafilter.id !== 0) {
      this.filterName = this.datafilter.filterName;
      this.savedFilterName.setValue(this.filterName);
    }
  }

  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
    console.log('Filter cleared:', control);
  }

  save(): void {
    if (!this.savedFilterName.value) {
      // Optionally, you can show an error message here
      console.error('Filter name cannot be empty');
      return;
    }

    this.createFilter();
    this.filterName = this.savedFilterName.value.toString();
    this.documentDataService.saveDocumentFilterNotApproved(this.filterName, this.filterData).subscribe(_ => {
      this.documentDataService.filterNameSavedSendNotApproved(this.filterName);
      this.documentDataService.getFiltersNotApproved().subscribe(result => {
        this.savedFilters = result;
        const savedFil = this.savedFilters.find(f => f.filterName === this.filterName);
        this.datafilter.id = savedFil!.id;
        this.datafilter.filterName = savedFil!.filterName;
        this.datafilter.filter = savedFil!.filter;
      });
    });
    this.openSnackBar('Your filter ' + this.filterName + ' has been created');
  }

  clearAllFilters(): void {
    this.clearLookupFilter(this.customers);
    this.clearLookupFilter(this.consignees);
    this.clearFilter(this.grRefNo);
    this.clearFilter(this.clientRefNo);
    this.clearLookupFilter(this.documentType);
    this.applyFilters();
    this.openSnackBar('Your filters have been cleared');
  }

  clearFilter(control: FormControl) {
    control.reset();
    control.setValue([]);
  }

  private filterCustomers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.customersData;
    }
    return this.customersData.filter(customer =>
      customer.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterDocumentType(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.documentTypesData;
    }
    return this.documentTypesData.filter(dt =>
      dt.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  get isSavedFilterNameEmpty(): boolean {
    return !this.savedFilterName.value;
  }

  deleteFilter(): void {
    let filterName = this.datafilter.filterName;

    this.documentDataService.deleteDocumentFilter(this.datafilter.id).subscribe(result => {
      this.documentDataService.filterNameSavedSendNotApproved('');
      this.savedFilterName.setValue('');
      this.datafilter.id = 0;
      this.datafilter.filterName = '';
      this.openSnackBar('Your filter, ' + filterName + ' has been deleted');
      this.clearAllFilters();
      console.log(result);
    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  applyFilters(): void {
    this.createFilter();
    this.updateCurrentFilter(0, '', this.filterData);
    this.documentDataService.transferFilterDataNotApproved(this.filterData);
    this.documentDataService.sendingActiveFilterToListNotApproved(this.datafilter);
    this.closeRightDrawer();
    this.openSnackBar('Your filter has been applied');
  }

  createFilter(): void {
    if (this.customers.value != null) {
      const customerCodes = this.parseFilterSelections(this.customers.value);
      this.processFilterData('customerCode', customerCodes);
    }
    if (this.consignees.value != null) {
      const consigneeCodes = this.parseFilterSelections(this.consignees.value);
      this.processFilterData('consigneeCode', consigneeCodes);
    }
    if (this.grRefNo.value != null) {
      this.processFilterData('grRefNo', this.grRefNo.value?.toString());
    }
    if (this.clientRefNo.value != null) {
      this.processFilterData('clientRef', this.clientRefNo.value?.toString());
    }

    if (this.documentType.value != null) {
      const selectedDocumentTypes = this.parseFilterSelections(this.documentType.value);
      this.processFilterData('documentType', selectedDocumentTypes);
    }
    
  }

  private filterConsignees(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.documentDataConsignee;
    }
    return this.documentDataConsignee.filter(c =>
      c.name.toLowerCase().includes(searchText.toLowerCase())
    );
    }

  parseFilterSelections(selections: LookupModel[]): string {
    const codes: string[] = selections.map(selection => selection.code);
    return codes.join(',');
  }

  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');

    this.filterData[key] = dataArray;
  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.datafilter.id = id;
    this.datafilter.filterName = name;
    this.datafilter.filter = filter;
  }

  showInformation(result: any, message: string | null): void {
    const dialogRef = this.dialog.open(InformationComponent, {
      width: '300px',
      height: '250px',
      data: { resultStatus: result, showMessage: message },
    });
  }

  showError(message: string): void {
    console.error(message);
    this.showInformation('Document rejection Failed', message)
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeRightDrawer(){
    this.communicationService.closeRightNav();
  }

}
