import { Component, ViewChild, OnInit, inject, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DocumentService } from '../service/documents/document.service';
import { IDocument, Document, documentTabbedViewEnum } from '../models/documents/document';
import { FormControl } from '@angular/forms';
import { CommunicationService } from '../service/communication/communication.service';
import { FilterData } from '../models/list-model';
import { ColumnSetup } from '../models/column-setup'
import { FilterEnum } from '../models/filter-enum';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { DataFilter, FilterTransferModel } from '../models/filter-models';
import { Subject, of } from 'rxjs';
import { takeUntil, catchError, skip } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import { AlertSubscriptionModel } from '../models/alerts/alert-subscription-model';
import { AlertService } from '../service/alerts/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { DocumentRejectComponent } from '../dialog/document-reject/document-reject.component';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ShipmentTranferGRRefContainerNo } from '../models/shipment/shipment-data-model';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {

  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;

  @ViewChild(MatPaginator) paginator = {} as MatPaginator;
  @ViewChild(MatSort) sort = {} as MatSort;
  dataSource = new MatTableDataSource<Document>();
  notApprovedDataSource = new MatTableDataSource<Document>();
  currentDataSource = this.notApprovedDataSource;
  dateFrom = new FormControl();
  dateTo = new FormControl();
  protected filterList = new FormControl();
  protected filterListNotApproved = new FormControl();
  dateFromNotApproved = new FormControl();
  dateToNotApproved = new FormControl();
  protected documentTabbedViewEnum = documentTabbedViewEnum;
  protected selectedTab = documentTabbedViewEnum.pending;
  filter!: FilterData;
  filterParameter!: FilterData;
  filterParameterNotApproved!: FilterData;
  savedFilters: DataFilter[] = [];
  savedFiltersNotApproved: DataFilter[] = [];
  currentFilter: DataFilter = new DataFilter();
  currentFilterNotApproved: DataFilter = new DataFilter();
  filterData: FilterData = {};
  filterDataNotApproved: FilterData = {};
  FilterEnum = FilterEnum;
  selectedButton = "";
  detailView = detailView;
  detailTabbedViewEnum = detailTabbedViewEnum;
  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];
  documentList: IDocument[] = [];
  templates: AlertSubscriptionModel[] = [];
  _snackBar: any;
  horizontalPosition: any;
  verticalPosition: any;
  isLoading = true;
  
  //subscribedTemplatesMap = new Map<string, boolean>(); // To track hasSubscribedTemplates status

  constructor(
    private alertDataService: AlertService,
    private documentService: DocumentService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appComponent: AppComponent,
    private cdr: ChangeDetectorRef
  ) {
    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'backspace',
       this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));

  }

  ngOnInit(): void {
    this.updateCurrentFilter(0, '', {});

    this.documentService.filterNameSaved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (t) => {
          this.loadSavedFilters();
          this.updateCurrentFilter(0, t, {});
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });

      this.documentService.filterNameSavedNotApproved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (t) => {
          this.loadSavedFiltersNotApproved();
          this.updateCurrentFilterNotApproved(0, t, {});
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    this.loadSavedFilters();
    this.updateDataNotApproved();
    this.loadSavedFiltersNotApproved();
    this.updateDataApproved();
   

    this.documentService.sendActiveFilterToList$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
          next: (filterReceived) => {
            this.updateCurrentFilter(filterReceived.id, filterReceived.filterName, filterReceived.filter)
            this.updateDataApproved(this.currentFilter.filter);
            this.applyStatusFilter(''); 
          }
      });

      this.documentService.sendActiveFilterToListNotApproved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
          next: (filterReceived) => {
            this.updateCurrentFilterNotApproved(filterReceived.id, filterReceived.filterName, filterReceived.filter)
            this.updateDataNotApproved(this.currentFilterNotApproved.filter);
            this.applyStatusFilter(''); 
          }
      });

  }

  configureColumns(): void {
    if (this.selectedTab === this.documentTabbedViewEnum.approved) {
    this.displayedColumns = [
      { name: 'documentStatusColor', header: '', type: 'string', visible: true },
      { name: 'customerCode', header: 'Customer', type: 'string', visible: !this.isCustUser },
      { name: 'grReference', header:  'GR Ref No', type: 'string', visible: true },
      { name: 'clientReference', header: 'Client Reference', type: 'string', visible: true },
      { name: 'destinationDescription', header: 'Discharge Port', type: 'string', visible: true },
      { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true },
      { name: 'eta', header: 'ETA', type: 'date', visible: true },
      { name: 'consignee', header: 'Consignee', type: 'string', visible: true },
      { name: 'bookingReference', header: 'Booking Reference', type: 'string', visible: true },
      { name: 'containerNo', header: 'Container Number', type: 'string', visible: true },
      { name: 'actions', header: 'Action', type: 'string', visible: true }
      
      
      // { name: 'documentReasonCode', header: 'Reason Code', type: 'string', visible: false },
      // { name: 'documentReasonDetail', header: 'Reason Detail', type: 'string', visible: false },
      // { name: 'documentPartition', header: 'Document Partition', type: 'string', visible: false },
      
      // { name: 'documentAttachmentSystemId', header: 'Attachment SystemId', type: 'string', visible: true },
      // { name: 'indicators', header: 'Indicators', type: 'string', visible: true },
      
    ];
  }else {
    this.displayedColumns = [
      { name: 'documentStatusColor', header: '', type: 'string', visible: true },
      { name: 'customerCode', header: 'Customer', type: 'string', visible: !this.isCustUser },
      { name: 'grReference', header: 'GR Ref No', type: 'string', visible: true },
      { name: 'clientReference', header: 'Client Reference', type: 'string', visible: true },
      { name: 'destinationDescription', header: 'Discharge Port', type: 'string', visible: true },
      { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true },
      { name: 'eta', header: 'ETA', type: 'date', visible: true },
      { name: 'consignee', header: 'Consignee', type: 'string', visible: true },
      // { name: 'isCompleteAddedums', header: 'Voyage', type: 'string', visible: true },
      { name: 'documentType', header: 'Document Type', type: 'string', visible: true },
      { name: 'documentApprovalStatus', header: 'Approval Status', type: 'string', visible: true },
      { name: 'actions', header: 'Action', type: 'string', visible: true }
      // { name: 'containerNo', header: 'Container Number', type: 'string', visible: true },
      // Add any other columns specific to approved documents
    ];
  }
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  protected updateDataApproved(filterData?: FilterData): void {

    const dateFromValue = this.dateFrom.value ? new Date(this.dateFrom.value) : undefined;
    const dateToValue = this.dateTo.value ? new Date(this.dateTo.value) : undefined;

    this.documentService.getApprovedDocumentList(this.currentFilter.id, this.currentFilter.filter,dateFromValue,dateToValue).subscribe(data => {
      this.configureColumns();
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    });

    // this.updateDataSource();
    this.filterParameter = {};
    this.cdr.detectChanges();
  }

  protected updateDataNotApproved(filterData?: FilterData): void {

    const dateFromValue = this.dateFromNotApproved.value ? new Date(this.dateFromNotApproved.value) : undefined;
    const dateToValue = this.dateToNotApproved.value ? new Date(this.dateToNotApproved.value) : undefined;

  this.documentService.getNotApprovedDocumentList(this.currentFilterNotApproved.id, this.currentFilterNotApproved.filter,dateFromValue,dateToValue).subscribe(data => {
      this.configureColumns();
      this.notApprovedDataSource.data = data;
      this.notApprovedDataSource.sort = this.sort;
      this.notApprovedDataSource.paginator = this.paginator;
      this.isLoading = false;
    });

    this.filterParameterNotApproved = {};
    this.cdr.detectChanges();
  }

  protected onOpenFilter(openFiler: FilterEnum) {
    this.communicationService.toggleFilter(openFiler);
    switch (this.selectedTab) {
      case documentTabbedViewEnum.approved:
        this.sendingActiveFilter();
        break;
      case documentTabbedViewEnum.pending:
        this.sendingActiveFilterNotApproved();
        break;
    }
  }

  protected loadSavedFilters() {
    this.documentService.getFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedFilters = data;
          if (this.currentFilter.filterName !== "") {
            let filterId = this.getFilterIdByName(this.currentFilter.filterName);
            this.updateCurrentFilter(filterId, this.currentFilter.filterName, this.getSavedFilterFromId(filterId));
            this.updateDataApproved();
          }
        }
      });
  }

  protected loadSavedFiltersNotApproved() {
    this.documentService.getFiltersNotApproved()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedFiltersNotApproved = data;
          console.log('NotApprovedFilters',data)
          if (this.currentFilterNotApproved.filterName !== "") {
            let filterId = this.getFilterIdByNameNotApproved(this.currentFilterNotApproved.filterName);
            this.updateCurrentFilter(filterId, this.currentFilterNotApproved.filterName, this.getSavedFilterFromId(filterId));
            this.updateDataNotApproved();
          }
        }
      });
  }

  protected processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');
    this.filterParameter[key] = dataArray;
  }

  protected processFilterDataNotApproved(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');
    this.filterParameterNotApproved[key] = dataArray;
  }

  protected setupFilter(column: string) {
    this.dataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  protected applyFilter(filterId: number): void {
    this.filterData = {};

    if (this.currentFilter.id === filterId) {
      this.updateCurrentFilter(0, '', {});
    }
    else {
      this.updateCurrentFilter(filterId, this.getSavedFilterNameFromId(filterId), this.getSavedFilterFromId(filterId))
    }
    this.updateDataApproved(this.currentFilter.filter)
  }

  protected applyFilterNotApproved(filterId: number): void {
    this.filterDataNotApproved = {};

    if (this.currentFilterNotApproved.id === filterId) {
      this.updateCurrentFilterNotApproved(0, '', {});
    }
    else {
      this.updateCurrentFilterNotApproved(filterId, this.getSavedFilterNameFromIdNotApproved(filterId), this.getSavedFilterFromIdNotApproved(filterId))
    }
    this.updateDataNotApproved(this.currentFilterNotApproved.filter)
  }

  protected updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.currentFilter.id = id;
    this.currentFilter.filterName = name;
    this.currentFilter.filter = filter;
  }

  protected updateCurrentFilterNotApproved(id: number, name: string, filter: FilterData) {
    this.currentFilterNotApproved.id = id;
    this.currentFilterNotApproved.filterName = name;
    this.currentFilterNotApproved.filter = filter;
  }

  protected getSavedFilterNameFromId(filterId: number): string {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  protected getSavedFilterNameFromIdNotApproved(filterId: number): string {
    const filter = this.savedFiltersNotApproved.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  protected getSavedFilterFromId(filterId: number): FilterData {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  protected getSavedFilterFromIdNotApproved(filterId: number): FilterData {
    const filter = this.savedFiltersNotApproved.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  protected getFilterIdByName(filterName: string): number {
    const filter = this.savedFilters.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  protected getFilterIdByNameNotApproved(filterName: string): number {
    const filter = this.savedFiltersNotApproved.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  protected sendingActiveFilter() {
    this.documentService.sendingActiveFilterToFilter(this.currentFilter)
  }

  protected sendingActiveFilterNotApproved() {
    this.documentService.sendingActiveFilterToFilterNotApproved(this.currentFilterNotApproved)
  }

  // selectButton(button: string) {
  //   if (this.selectedButton === button) {
  //     this.selectedButton = '';
  //     this.bookingDataService.toggleStatusFilter('');
  //   } else {
  //     this.selectedButton = button;
  //     this.bookingDataService.toggleStatusFilter(button);
  //   }    
  // }

  protected applyStatusFilter(filterValue: string) {
    if (this.selectedTab !== documentTabbedViewEnum.pending) {
     
      this.dataSource.filterPredicate = (data: Document, filter: string) => {
        this.configureColumns()  
        return data.documentApprovalStatus === 'Approved';
          
      };

    }else{
      this.dataSource.filterPredicate = (data: Document, filter: string) => {
        this.configureColumns()
        return data.documentApprovalStatus !== 'Approved';
      };
    }
    // Apply the filter based on the filterValue
    this.dataSource.filter = filterValue; // Set the filter value
  }

  // private createNotificationModel(row: any): AlertNotificationModel {
  //   return {
  //     AlertCode: '',
  //     CustomerCode: row.customerCode,
  //     GRReference: String(row.grReference),
  //     TemplateGroup: 'Document'
  //   } as AlertNotificationModel;
  // }

  protected viewDetails(row: any, view: detailView, tab: detailTabbedViewEnum): void {
    this.communicationService.toggleDetailView(view, tab);


    let info: ShipmentTranferGRRefContainerNo = new ShipmentTranferGRRefContainerNo;

    info.grReferenceNo = row.grReference;
    info.containerNo = null;

    this.communicationService.getDetailViewGRRefcontainerNo(info)
  }

  runReportClick(): void {
    const dateFromValue = this.dateFrom.value ? new Date(this.dateFrom.value) : undefined;
    const dateToValue = this.dateTo.value ? new Date(this.dateTo.value) : undefined;

    this.documentService.getApprovedDocumentList(this.currentFilter.id, this.currentFilter.filter,dateFromValue,dateToValue).subscribe(data => {
      this.configureColumns();
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    });
    this.cdr.detectChanges();
  }

  runReportClickNotApproved(): void {
    const dateFromValue = this.dateFromNotApproved.value ? new Date(this.dateFromNotApproved.value) : undefined;
    const dateToValue = this.dateToNotApproved.value ? new Date(this.dateToNotApproved.value) : undefined;

  this.documentService.getNotApprovedDocumentList(this.currentFilterNotApproved.id, this.currentFilterNotApproved.filter,dateFromValue,dateToValue).subscribe(data => {
      this.configureColumns();
      this.notApprovedDataSource.data = data;
      this.notApprovedDataSource.sort = this.sort;
      this.notApprovedDataSource.paginator = this.paginator;
      this.isLoading = false;
    });
    this.cdr.detectChanges();
  }

  protected showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  public openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  protected isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  protected getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  protected getBackgroundColor(cellValue: string): string {
    switch (cellValue) {
      case 'Approved':
        return '#36DE6F';
      case 'Awaiting Approval':
        return '#FFD34F';
      default:
        return '#DC6868';
    }
  }

  protected approveDocument(row : any): void{
    const approved = {
      documentApprovalStatus: 'Approved'
    };

    this.documentService.updateDocumentStatus(row['id'], JSON.stringify(approved))
      .pipe(
        catchError(error => {
          console.error('Error updating document status:', error);
          this.showInformation('Document Approval Failed', error);
          return of(null); // Return a fallback value or an empty observable
        })
      )
      .subscribe(response => {
        this.updateDataApproved();
        this.updateDataNotApproved();
        if (response) {
          this.showInformation('Document Status updated to Approved', '')
          this.documentService.getDocumentsForGRRef(row['grReference']).subscribe(data => {
            // this.documentList = data;
            // this.dataSourceApproved.data = this.documentList.filter(status => status.documentApprovalStatus === 'Approved')
            // this.dataSourcAwaitingAproval.data = this.documentList.filter(status => status.documentApprovalStatus === 'AwaitingApproval')
            // this.dataSourceResubmitted.data = this.documentList.filter(status => status.documentApprovalStatus === 'Resubmitted')
            // this.dataSourceRejected.data = this.documentList.filter(status => status.documentApprovalStatus === 'Rejected')

          })
          this.documentService.updateDocumentList(this.documentList); // Notify subscribers
          
        }
      });
 console.log(row)
  }

  protected showInformation(arg0: string, error: any) {
    throw new Error('Method not implemented.');
  }

  protected rejectDocument(row : any): void{
    const dialogRef = this.dialog.open(DocumentRejectComponent, {
      width: '300px',
      height: '350px',
      data: { documentApprovalStatus: 'Rejected', reasonCode: '', reasonDetail: '' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const rejectionData = {
          documentApprovalStatus: 'Rejected',
          documentReasonCode: result.reasonCode,
          documentReasonDetail: result.reasonDetail,
        };
        this.documentService.updateDocumentStatus(row['id'], JSON.stringify(rejectionData))
          .pipe(
          catchError(error => {
            console.error('Error updating document status:', error);
            this.showInformation('Document rejection Failed', error);
            return of(null); // Return a fallback value or an empty observable
          })
        )
          .subscribe(response => {
            if (response) {
              console.log('Document rejected:', response);
              this.showInformation('Document Status was updated', '')
              this.updateDataApproved();
              this.updateDataNotApproved();
            }

        });
        // this.documentService.updateDocumentList(this.documentList); // Notify subscribers
      }
    });
     this.documentService.updateDocumentList(this.documentList); // Update the shared state
  }

  public viewDocument(row: any): void {
    this.documentService.downloadDocumentWithSystemId(row['documentAttachmentSystemId'])
    .pipe(
      catchError(error => {
        console.error('Error updating document status:', error);
        this.showInformation('Download Failed', error);
        return of(null);
      })
    )
    .subscribe(response => {
      if (response) {
        const blob = new Blob([response], { type: 'application/pdf' }); 
        const url = window.URL.createObjectURL(blob); 
        const fileName = `${row['attachmentFilename']}.pdf`; 
        // this.openSnackBar('Openning '+fileName);

        
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank'; 

        // Programmatically click the anchor to trigger the download
        document.body.appendChild(anchor);
        anchor.click();

        // Clean up by revoking the blob URL and removing the anchor
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(url);
      }
    });
  }

  protected downloadDocument(row: any): void {
    this.documentService.downloadDocumentWithSystemId(row['documentAttachmentSystemId'])
    .pipe(
      catchError(error => {
        console.error('Error updating document status:', error);
        this.showInformation('Download Failed', error);
        return of(null);
      })
    )
    .subscribe(response => {
      if (response) {
        
        const blob = new Blob([response], { type: 'application/pdf' }); 
        const url = window.URL.createObjectURL(blob); 
        const fileName = `${row['attachmentFilename']}.pdf`; 
        // this.openSnackBar('Downloading '+fileName);
        
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank'; 
         anchor.download = fileName; 

        // Programmatically click the anchor to trigger the download
        document.body.appendChild(anchor);
        anchor.click();

        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(url);
      }
    });
  }

  protected onTabChange(event: MatButtonToggleChange): documentTabbedViewEnum {
    this.selectedTab = event.value;
    console.log('tab change',event.value);
    this.updateDataSource(); 
    this.configureColumns();

    return this.selectedTab;
  }

  private updateDataSource() {
  // Switch data sources based on the selected tab
  this.currentDataSource = this.selectedTab === this.documentTabbedViewEnum.pending 
    ? this.notApprovedDataSource 
    : this.dataSource;
    this.cdr.detectChanges();
  }

  clearSearchField(control: FormControl, controlSecond?: FormControl): void {
    control.reset();

    if (controlSecond) {
      controlSecond.reset();
    }
    this.dataSource.filter = '';
    this.documentService.getApprovedDocumentList(this.currentFilter.id, this.currentFilter.filter).subscribe(data => {
      this.configureColumns();
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    });
  }

  clearSearchFieldNotApproved(control: FormControl, controlSecond?: FormControl): void {
    control.reset();

    if (controlSecond) {
      controlSecond.reset();
    }
    this.notApprovedDataSource.filter = '';
    this.documentService.getNotApprovedDocumentList(this.currentFilterNotApproved.id, this.currentFilterNotApproved.filter).subscribe(data => {
      this.configureColumns();
      this.notApprovedDataSource.data = data;
      this.notApprovedDataSource.sort = this.sort;
      this.notApprovedDataSource.paginator = this.paginator;
      this.isLoading = false;
    });
  }
  
}
