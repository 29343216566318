import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { EmaiLModel } from '../../models/emails/email-model';
import { ScheduleIncorrectEmailModel } from '../../models/schedules/schedule-incorrect-email-model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  sendContactEmail(data: EmaiLModel): Observable<string> {
    var url = this.apiUrl + '/User/SendContactEmail';

    return this.http.post<string>(url, data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Error occurred:', error);
          return throwError('Something went wrong; please try again later.');
        })
      );
  }

  reportSailingSchedule(data: ScheduleIncorrectEmailModel): Observable<string> {
    var url = this.apiUrl + '/User/ReportSailingSchedule';

    return this.http.post<string>(url, data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError('Something went wrong; please try again later.');
        })
      );
  }
}
