export interface IDocument {
  id: string;
  entryNo: number;
  customerCode: string;
  consignee: string;
  consigneeCode: string;
  customer: string;
  grReference: string;
  clientReference: string;
  bookingReference: string;
  dhlReference: string;
  documentType: string;
  documentPrintingMethod: string;
  documentTypeDocument: string;
  documentApprovalStatus: string;
  documentReasonCode: string;
  documentReasonDetail: string;
  documentPartition: string;
  documentAttachmentSystemId: string;
  attachmentType: string;
  attachmentFilename: string;
  hasSubscriptions: boolean;
  isCompleteAddedums: boolean;
  hasAlerts: boolean;
  eta: Date;
  vesselName: string;
  vesselCode: string;
  containerNo: string;
  destinationDescription: string;
  destinationPort: string;
  dischargePort: string;
  voyage: string;
}

export class Document implements IDocument {
  id: string = '';
  entryNo: number = 0;
  customerCode: string = '';
  consignee: string = '';
  consigneeCode: string = '';
  customer: string = '';
  grReference: string = '';
  clientReference: string = '';
  bookingReference: string = '';
  dhlReference: string = '';
  documentType: string = '';
  documentPrintingMethod: string = '';
  documentTypeDocument: string = '';
  documentApprovalStatus: string = '';
  documentReasonCode: string = '';
  documentReasonDetail: string = '';
  documentPartition: string = '';
  documentAttachmentSystemId: string = '';
  attachmentType: string = '';
  attachmentFilename: string = '';
  isCompleteAddedums: boolean = false;
  hasSubscriptions: boolean = false;
  hasAlerts: boolean = false;
  eta: Date = new Date;
  vesselName: string = '';
  vesselCode: string = '';
  containerNo: string = '';
  destinationDescription: string = '';
  destinationPort: string = '';
  dischargePort: string = '';
  voyage: string = '';
}

export enum documentTabbedViewEnum {
  none,
  approved,
  pending
}

