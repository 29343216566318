import { Component, Input } from '@angular/core';
import { Alert } from '../models/alerts/alerts';

@Component({
  selector: 'app-shipment-tracking-detail',
  templateUrl: './shipment-tracking-detail.component.html',
  styleUrl: './shipment-tracking-detail.component.css'
})
export class ShipmentTrackingDetailComponent {
  @Input()alertData: Alert[] = [];
  
  displayedColumns = [
    ['title', 'Activities'],
    ['alertDate', 'Date and Time'],
    ['notification', 'Duration'],
  ];

getValueForRowColumn(row: any, columnName: string): any {
  return row[columnName];
}

getExtractedColumns(columns: string[][]) {
  return columns.map(col => col[0]);

}
}
