import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { InformationComponent } from '../information/information.component';

@Component({
  selector: 'app-report-schedule-dialog',
  templateUrl: './report-schedule-dialog.component.html',
  styleUrl: './report-schedule-dialog.component.css'
})
export class ReportScheduleDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ReportScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) { }


  onCancel(): void {
    this.dialogRef.close();
  }

  onReport(): void {
    console.log(this.data)
    if (!this.data.comment) {
       this.showInformation('Input Error', 'Please enter a reason')

    } else {
      this.dialogRef.close(this.data);
    }
  }

  showInformation(result: any, message: string): void {
    const dialogRef = this.dialog.open(InformationComponent, {
      width: '300px',
      height: '250px',
      data: { resultStatus: result, showMessage: message },
    });
  }

}
