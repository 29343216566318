import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { initializeKeycloak } from './init/keycloak-init.factory';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ShipmentsComponent } from './shipments/shipments.component';
import { AlertComponent } from './alert/alert.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { BookingManagementComponent } from './booking-management/booking-management.component';
import { DocumentManagementComponent } from './document-management/document-management.component';
import { SchedulesComponent } from './schedules/schedules.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HeaderComponent } from './header/header.component';
import { HeaderIconsComponent } from './header-icons/header-icons.component';
import { FooterComponent } from './footer/footer.component';
import { AlertSidenavComponent } from './alert-sidenav/alert-sidenav.component';
import { AlertCardComponent } from './alert-card/alert-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FilterSidenavComponent } from './filter-sidenav/filter-sidenav.component';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule } from '@angular/material/checkbox';
import { BookingfilterComponent } from './bookingfilter/bookingfilter.component';
import { BookingListComponent } from './booking-list/booking-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetailedViewNavComponent } from './detailed-view-nav/detailed-view-nav.component';
import { DateAdapter } from '@angular/material/core';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SchedulesDetailViewComponent } from './schedules-detail-view/schedules-detail-view.component';
import { BookingDetailedViewComponent } from './booking-detailed-view/booking-detailed-view.component';
import { SchedulesFilterComponent } from './schedules-filter/schedules-filter.component';
import { ShipmentListComponent } from './shipment-list/shipment-list.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ShipmentFilterComponent } from './shipment-filter/shipment-filter.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentFilterComponent } from './document-filter/document-filter.component';
import { DocumentRejectComponent } from './dialog/document-reject/document-reject.component';
import { InformationComponent } from './dialog/information/information.component';
import { GridsterModule } from 'angular-gridster2';
import { WidgetSelectComponent } from './widget-select/widget-select.component';
import { WidgetVesselperportComponent } from './widget-vesselperport/widget-vesselperport.component';
import { CreateFilterComponent } from './dialog/create-filter/create-filter.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ShipmentAlertTabComponent } from './alert-tab/alert-tab.component';
import { DetailedShipmentComponent } from './detailed-shipment/detailed-shipment.component';
import { AlertManagementComponent } from './alert-management/alert-management.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AlertFilterComponent } from './alert-filter/alert-filter.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatBadgeModule } from '@angular/material/badge';
import { AlertShipmentDetailsComponent } from './alert-shipment-details/alert-shipment-details.component';
import { SubscriptionComponent } from './dialog/subscription/subscription.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { SchedulesDetailsTabComponent } from './schedules-details-tab/schedules-details-tab.component';
import { AddBookingComponent } from './booking/add-booking/add-booking.component';
import { EditBookingComponent } from './booking/edit-booking/edit-booking.component';
import { BookingVoyageSearchComponent } from './booking/booking-voyage-search/booking-voyage-search.component';
import { AddBookingInputComponent } from './booking/add-booking-input/add-booking-input.component';
import { BookingLineComponent } from './booking-line/booking-line.component';
import { ShipmentTrackingDetailComponent } from './shipment-tracking-detail/shipment-tracking-detail.component';
import { TrackingMapComponent } from './tracking-map/tracking-map.component';
import { OceanTrackingComponent } from './tracking-map/ocean-tracking/ocean-tracking.component';
// import { RoadTrackingComponent } from './tracking-map/road-tracking/road-tracking.component';
import { MatNativeDateModule } from '@angular/material/core';
import { CUSTOM_DATE_FORMATS } from './booking-management/booking-management.component';
import { LateStackWarningComponent } from './dialog/late-stack-warning/late-stack-warning.component'; // Import the custom date formats
import { RoadTrackingComponent } from './tracking-map/road-tracking/RoadTrackingComponent';
import { AirTrackingComponent } from './tracking-map/air-tracking/air-tracking.component';
import { DashboardTrackingComponent } from './dashboard-tracking/dashboard-tracking.component';
import { BookingRollingPlanComponent } from './booking-rolling-plan/booking-rolling-plan.component';
import { TruncateTextPipe } from './truncate-text.pipe';
import { OverviewOceanTrackingComponent } from './tracking-map/overview-ocean-tracking/overview-ocean-tracking.component';
import { DashboardTrackingFilterComponent } from './dashboard-tracking-filter/dashboard-tracking-filter.component';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BookingSubmittedViewComponent } from './booking-submitted-view/booking-submitted-view.component';
import { WidgetComponent } from './widget/widget.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BookingSubmissionComponent } from './dialog/booking-submission/booking-submission.component';
import { CancelBookingComponent } from './dialog/cancel-booking/cancel-booking.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DocumentFilterPendingDocumentsComponent } from './document-filter-pending-documents/document-filter-pending-documents.component';

import { WidgetDataComponent } from './dialog/widget-data/widget-data.component';

import { TimePickerComponent } from './time-picker/time-picker.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CommonModule } from '@angular/common';



import { NgCircleProgressModule } from 'ng-circle-progress';
import { CardInViewDirective } from './card-in-view.directive';
import { RollingPlanFilterComponent } from './rolling-plan-filter/rolling-plan-filter.component';
import { ContainerTrackingComponent } from './tracking-map/container-tracking/container-tracking.component';
import { ShipmentEventsComponent } from './shipment-events/shipment-events.component';
import { RollingPlanDetailedViewComponent } from './rolling-plan-detailed-view/rolling-plan-detailed-view.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SidenavComponent,
    AlertComponent,
    BookingManagementComponent,
    DocumentManagementComponent,
    ShipmentsComponent,
    SchedulesComponent,
    AnalyticsComponent,
    HeaderComponent,
    HeaderIconsComponent,
    FooterComponent,
    AlertSidenavComponent,
    AlertCardComponent,
    FilterSidenavComponent,
    BookingfilterComponent,
    BookingListComponent,
    DetailedViewNavComponent,
    AlertCardComponent,
    SchedulesDetailViewComponent,
    BookingDetailedViewComponent,
    DocumentListComponent,
    DocumentRejectComponent,
    InformationComponent,
    SchedulesFilterComponent,
    ShipmentListComponent,
    ShipmentFilterComponent,
    DocumentFilterComponent,
    WidgetSelectComponent,
    WidgetVesselperportComponent,
    CreateFilterComponent,
    ShipmentAlertTabComponent,
    DetailedShipmentComponent,
    AlertManagementComponent,
    DetailedShipmentComponent,
    AlertFilterComponent,
    AlertShipmentDetailsComponent,
    SubscriptionComponent,
    SchedulesDetailsTabComponent,
    AddBookingComponent,
    EditBookingComponent,
    BookingVoyageSearchComponent,
    AddBookingInputComponent,
    BookingLineComponent,
    ShipmentTrackingDetailComponent,
    TrackingMapComponent,
    OceanTrackingComponent,
    // RoadTrackingComponent,
    AirTrackingComponent,
    LateStackWarningComponent,
    RoadTrackingComponent,
    DashboardTrackingComponent,
    BookingRollingPlanComponent,
    TruncateTextPipe,
    OverviewOceanTrackingComponent,
    DashboardTrackingFilterComponent,
    OceanTrackingComponent,
    BookingSubmittedViewComponent,
    DashboardComponent,
    WidgetComponent,
    SidebarComponent,
    BookingSubmissionComponent,
    CancelBookingComponent,
    DocumentFilterPendingDocumentsComponent,
    TimePickerComponent,
    AboutComponent,
    ContactComponent,
    CardInViewDirective,
    RollingPlanFilterComponent,
    ContainerTrackingComponent,
    ShipmentEventsComponent,
    WidgetDataComponent,
    RollingPlanDetailedViewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    MatButtonToggleModule,
    GridsterModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatBadgeModule,
    MatDialogModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    HttpClientModule,
    MatTooltipModule,
    NgCircleProgressModule.forRoot(),
    CommonModule,
    MatDialogModule,  // Import MatDialogModule
    MatButtonModule,   // For MatButton used in the close button
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'This item is actually loading...' }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    [DatePipe],
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(), provideNativeDateAdapter()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
