<div style="overflow-x:auto;" class="mat-elevation-z2" >
  <table mat-table [dataSource]="dataSource" class="full-width-table" matSort aria-label="Elements">

    <ng-container *ngFor="let column of displayedColumns">
      <ng-container [matColumnDef]="column.name" id="test">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
          {{ column.header }}
        </th>
        <td mat-cell *matCellDef="let row"  [attr.data-label]="(column.name !== 'actions' && column.name !== 'indicators') ? column.header : null"
 [hidden]="!column.visible" class='has_label_on_mobile'>
          <ng-container *ngIf="column.name !== 'selected'">
            <ng-container *ngIf="column.type === 'date' && column.name !== 'stackEnd'">
              {{ row[column.name] | date: 'dd MMM yyyy' }}
            </ng-container>
            <ng-container *ngIf="column.name === 'stackEnd'">
              {{ row[column.name] | date: 'dd MMM yyyy, HH:mm' }}
            </ng-container>
            <ng-container *ngIf="column.type !== 'date'">
              {{ row[column.name] | truncateText }}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="column.name === 'selected'">
            <mat-checkbox  class="primary" [(ngModel)]="isSelected" [disabled]="isReadOnly" (click)="selectRow(row)">
            </mat-checkbox>
          </ng-container>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="getExtractedColumns(displayedColumns)"></tr>
    <tr mat-row *matRowDef="let row; columns: getExtractedColumns(displayedColumns);"></tr>

    <tr class="mat-row" *matNoDataRow style=" min-height: 100px;width: -webkit-fill-available;">
      <td class="mat-cell center" style=" width: -webkit-fill-available; justify-content: center;" [attr.colspan]="displayedColumns.length">
        <div *ngIf="isLoading; else noData"  style="display: flex; justify-content: center; align-items: center; background: white;" >
          <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>
        <ng-template #noData>
          <div style="text-align:center; padding: 16px;">
            <strong *ngIf="dataSource.data.length === 0">No data found. Please apply filters to see results.</strong>
            <strong *ngIf="dataSource.data.length > 0">No data available.</strong>
          </div>
        </ng-template>
      </td>
    </tr>
  </table>

  <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
  </mat-paginator>
</div>


