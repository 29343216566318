<div class="container">
  <div class="title-wrapper">
    <div class="saved-view-wrap">
      <h1>Document Management</h1>
      <ng-container *ngIf="selectedTab == documentTabbedViewEnum.approved">
        <div class="saved-views">
          <div *ngFor="let savedFilter of savedFilters">
            <button mat-button class="Saved-filter-btn" [ngClass]="{'selected': currentFilter.id === savedFilter.id}"
              (click)="applyFilter(savedFilter.id)">
              {{ savedFilter.filterName }}
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedTab == documentTabbedViewEnum.pending">
        <div class="saved-views">
          <div *ngFor="let savedFilter of savedFiltersNotApproved">
            <button mat-button class="Saved-filter-btn"
              [ngClass]="{'selected': currentFilterNotApproved.id === savedFilter.id}"
              (click)="applyFilterNotApproved(savedFilter.id)">
              {{ savedFilter.filterName }}
            </button>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
  <ng-container *ngIf="selectedTab == documentTabbedViewEnum.approved">
    <div class="row">
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Enter an ETA date range</mat-label>
          <mat-date-range-input [rangePicker]="picker" required>
            <input matStartDate placeholder="ETA from" [formControl]="dateFrom">
            <input matEndDate placeholder="ETA to" [formControl]="dateTo">
          </mat-date-range-input>

          <!-- Clear button for the Start Date -->
          <ng-container *ngIf="dateFrom.value||dateFrom.value && dateTo.value">
            <button class="clear-search" (click)="clearSearchField(dateFrom,dateTo)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </ng-container>

          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="dateFrom.invalid && dateFrom.touched">Start date is required.</mat-error>
          <mat-error *ngIf="dateTo.invalid && dateTo.touched">End date is required.</mat-error>
        </mat-form-field>
      </div>

      <!-- <div class="col-md-2">
        <mat-form-field>
          <mat-label>Quick Search</mat-label>
          <input matInput [formControl]="filterList">
          <mat-select-trigger *ngIf="filterList.value">
            <button class="clear-search" (click)="clearSearchField(filterList)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
        </mat-form-field>
      </div> -->

      <div class="col-md-1">

        <button class="runReportButton" (click)="runReportClick()">
          Search<mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedTab == documentTabbedViewEnum.pending">
    <div class="row">
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Enter an ETA date range</mat-label>
          <mat-date-range-input [rangePicker]="picker" required>
            <input matStartDate placeholder="ETA from" [formControl]="dateFromNotApproved">
            <input matEndDate placeholder="ETA to" [formControl]="dateToNotApproved">
          </mat-date-range-input>

          <!-- Clear button for the Start Date -->
          <ng-container *ngIf="dateFromNotApproved.value||dateFromNotApproved.value && dateToNotApproved.value">
            <button class="clear-search" (click)="clearSearchFieldNotApproved(dateFrom,dateTo)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </ng-container>

          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="dateFrom.invalid && dateFrom.touched">Start date is required.</mat-error>
          <mat-error *ngIf="dateTo.invalid && dateTo.touched">End date is required.</mat-error>
        </mat-form-field>
      </div>

      <!-- <div class="col-md-2">
        <mat-form-field>
          <mat-label>Quick Search</mat-label>
          <input matInput [formControl]="filterList">
          <mat-select-trigger *ngIf="filterList.value">
            <button class="clear-search" (click)="clearSearchField(filterList)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
        </mat-form-field>
      </div> -->

      <div class="col-md-1">

        <button class="runReportButton" (click)="runReportClickNotApproved()">
          Search<mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <div class="parameters-filter-wrap">
    <div class="legend-wrapper">
      <button *ngIf="selectedTab == documentTabbedViewEnum.approved" mat-button class="alert-btns"
        [ngClass]="{'selected': selectedButton === 'approved'}">
        <!-- (click)="selectButton('approved')" -->
        <img src="/assets/Confirmed.svg" alt="" class="icon">
        Approved
      </button>
      <button *ngIf="selectedTab == documentTabbedViewEnum.pending" mat-button class="alert-btns"
        [ngClass]="{'selected': selectedButton === 'awaitingapproval'}">
        <!-- (click)="selectButton('awaitingapproval')" -->
        <img src="/assets/Awaiting.svg" alt="" class="icon">
        Awaiting approval
      </button>
      <button *ngIf="selectedTab == documentTabbedViewEnum.pending" mat-button class="alert-btns"
        [ngClass]="{'selected': selectedButton === 'rejected'}">
        <!-- (click)="selectButton('rejected')" -->
        <img src="/assets/Cancelled.svg" alt="" class="icon">
        Rejected
      </button>
    </div>
    <ng-container *ngIf="selectedTab == documentTabbedViewEnum.approved">
      <button mat-button class="filter-btn" (click)="onOpenFilter(FilterEnum.documentFilter)">
        <div class="filter-icon-wrap">
          <div class="filter-icon"></div>
          Show Filters
        </div>
      </button>
    </ng-container>
    <ng-container *ngIf="selectedTab == documentTabbedViewEnum.pending">

      <button mat-button class="filter-btn" (click)="onOpenFilter(FilterEnum.pendingDocumentsFilter)">
        <div class="filter-icon-wrap">
          <div class="filter-icon"></div>
          Show Filters
        </div>
      </button>
    </ng-container>

  </div>


  <div class="filter-btn-wrapper">
    <div style="width:auto">
      <mat-button-toggle-group name=""
                               aria-label="Favorite Color"
                               [hideSingleSelectionIndicator]="true"
                               class="tab-menu"
                               (change)="onTabChange($event)"
                               [(ngModel)]="selectedTab">
        <mat-button-toggle class="tab-menu-item" [value]="documentTabbedViewEnum.pending">Pending Documents</mat-button-toggle>
        <mat-button-toggle class="tab-menu-item" [value]="documentTabbedViewEnum.approved">Approved Documents</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>



  <div class="mat-elevation-z2 full-width-table">
    <table mat-table [dataSource]="currentDataSource" class="full-width-table" matSort aria-label="Elements">
      <ng-container *ngFor="let column of displayedColumns">
        <ng-container [matColumnDef]="column.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row"
            [attr.data-label]="(column.name !== 'actions' && column.name !== 'indicators') ? column.header : null"
            [hidden]="!column.visible" class='has_label_on_mobile'
            [ngStyle]="column.name === 'documentStatusColor' ? {'background-color': getBackgroundColor(row['documentApprovalStatus'])} : {}">
            <ng-container *ngIf="column.name !== 'actions'">
              <ng-container *ngIf="column.type === 'date'">
                {{ row[column.name] | date: 'dd MMM yyyy' }}
              </ng-container>
              <ng-container *ngIf="column.name === 'documentStatusColor'">
              </ng-container>
              <ng-container *ngIf="column.type !== 'date'&& column.name !== 'documentStatusColor'">
                {{ row[column.name] }}
              </ng-container>
              <!-- <ng-container *ngIf="column.name === 'indicators'">
                  <button [ngClass]="row.hasSubscriptions ? 'indicator-btn-subbed' : 'indicator-btn'" (click)="subscribeToAlert(row)">
                    <mat-icon svgIcon="Subscribe"></mat-icon>
                  </button>
                  <button [ngClass]="row.hasAlerts ? 'indicator-btn-alerts-unread' : 'indicator-btn'" (click)="viewDetails(row, detailView.shipmentDetail, detailTabbedViewEnum.alertTab)">
                    <mat-icon svgIcon="ViewAlerts"></mat-icon>
                  </button>
                </ng-container> -->

            </ng-container>
            <ng-container *ngIf="column.name === 'actions'" class="mat-column-actions">
              <ng-container *ngIf="row.documentApprovalStatus === 'Approved'; else notApproved">
                <button class="detailed-view-btn"
                  (click)="viewDetails(row, detailView.shipmentDetail, detailTabbedViewEnum.documentTab)">
                  View Details<mat-icon>arrow_forward</mat-icon>
                </button>
              </ng-container>
              <ng-template #notApproved>
                <ng-container *ngIf="row.documentApprovalStatus !== 'Approved'; else rejectedAction">
                  <ng-container *ngIf="row.documentApprovalStatus === 'Rejected'; else awaitingApproval">
                    <button class="viewButton" mat-button color="primary" (click)="viewDocument(row)">
                      <img class="action-icons" src="/assets/eye.svg" alt="View" width="20" height="20">
                    </button>
                    <button class="approveButton" mat-button color="primary">
                      <img class="action-icons" src="/assets/CheckCircle.svg" alt="Approve" width="20" height="20">
                    </button>
                    <button class="downloadButton" mat-button color="primary" (click)="downloadDocument(row)">
                      <img class="action-icons" src="/assets/DownloadSimple.svg" alt="Download" width="20" height="20">
                    </button>

                    </ng-container>
                    <ng-template #awaitingApproval>
                      <ng-container *ngIf="row.documentApprovalStatus === 'Awaiting Approval'">
                        <div class="document-actions">
                          <button class="downloadButton" mat-button color="primary" (click)="viewDocument(row)">
                            <img class="action-icons" src="/assets/eye.svg" alt="View" width="20" height="20">
                          </button>
                          <button class="approveButton" mat-button color="primary" (click)="approveDocument(row)">
                            <img class="action-icons" src="/assets/CheckCircle.svg" alt="Approve" width="20" height="20">
                          </button>
                          <button class="rejectButton" mat-button color="primary" (click)="rejectDocument(row)">
                            <img class="action-icons" src="/assets/Prohibit.svg" alt="Reject" width="20" height="20">
                          </button>
                          <button class="downloadButton" mat-button color="primary" (click)="downloadDocument(row)">
                            <img class="action-icons" src="/assets/DownloadSimple.svg" alt="Download" width="20" height="20">
                          </button>
                        </div>
                      </ng-container>
                    </ng-template>
                  </ng-container>
                  <ng-template #rejectedAction>
                    <div class="document-actions">
                      <button class="downloadButton" mat-button color="primary" (click)="viewDocument(row)">
                        <img class="action-icons" src="/assets/eye.svg" alt="Download" width="20" height="20">
                      </button>
                      <button class="approveButton" mat-button color="primary" (click)="approveDocument(row)">
                        <img class="action-icons" src="/assets/CheckCircle.svg" alt="Approve" width="20" height="20">
                      </button>
                      <button class="downloadButton" mat-button color="primary" (click)="downloadDocument(row)">
                        <img class="action-icons" src="/assets/DownloadSimple.svg" alt="Download" width="20" height="20">
                      </button>
                    </div>
                  </ng-template>
                </ng-template>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="extractedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: extractedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell center no-data-container"  [attr.colspan]="displayedColumns.length">
            <div *ngIf="isLoading" class="no-data-container-content">
              <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
            </div>
            <ng-container *ngIf="!isLoading" class="no-data-container-content">
              No documents found.
            </ng-container>
          </td>
        </tr>
      </table>
      <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSize]="10"
                     [pageSizeOptions]="[5, 10, 20, 50]" aria-label="Select page">
      </mat-paginator>
    </div>
  

