import { Component, Input, OnInit } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-air-tracking',
  templateUrl: './air-tracking.component.html',
  styleUrls: ['./air-tracking.component.css']
})
export class AirTrackingComponent implements OnInit {

  private map!: L.Map;
  private planeMarker!: L.Marker;

  @Input() vehicleLocation!: L.LatLngExpression;
  @Input() startLocation!: L.LatLngExpression;
  @Input() endLocation!: L.LatLngExpression; // Added end location

  ngOnInit(): void {
    this.initMap();
  }

  private initMap(): void {
    this.map = L.map('map').setView(this.startLocation || [0, 0], 6);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: 'Map data &copy; OpenStreetMap contributors',
    }).addTo(this.map);

    this.planeMarker = L.marker(this.startLocation, {
      icon: L.icon({
        iconUrl: 'assets/plane-icon.png',
        iconSize: [32, 32],
        iconAnchor: [16, 16]
      })
    }).addTo(this.map).bindPopup('Plane Location').openPopup();

    this.drawRoute(this.startLocation, this.endLocation);
  }

  private drawRoute(start: L.LatLngExpression, end: L.LatLngExpression): void {
    L.polyline([start, end], { color: 'red' }).addTo(this.map);
  }
}
