export interface EmailAttachmentsModel {
    name: string;
    base64: string;
}

export class EmaiLModel {
    subject: string = '';
    message: string = '';
    attachments: EmailAttachmentsModel[] = [];
}
