<!--<form class="example-form">-->
<mat-card class="add-booking-card">
  <mat-card-header class="add-booking-header">
    <mat-card-title>Booking Info</mat-card-title>
  </mat-card-header>
  <mat-card-content class="add-booking-content">
    <form [formGroup]="addBookingForm" class="row">
      <div class="col-md-12">
        <ng-container *ngIf="!isCustUser">
          <mat-form-field *ngIf="customers">
            <mat-label>Select Clients</mat-label>
            <mat-select formControlName="customers">
              <mat-option>
                <ngx-mat-select-search [formControl]="customersMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Customer not found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.code">
                {{customer.name}}
              </mat-option>
            </mat-select>
            <button matSuffix
                    *ngIf="customers.value && !isReadOnly"
                    mat-icon-button
                    (click)="$event.stopPropagation(); customers.setValue('')"
                    aria-label="Clear selection"
                    class="clear-filter-icon">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
            <mat-error *ngIf="addBookingForm.get('customers')?.hasError('required')">Client is required.</mat-error>
          </mat-form-field>
        </ng-container>
      </div>

      <div class="col-md-12">
        <ng-container *ngIf="!isAddBooking">
          <mat-form-field *ngIf="dischargePorts">
            <mat-label>Select Discharge Port</mat-label>
            <mat-select [formControl]="dischargePorts">
              <mat-option>
                <ngx-mat-select-search [formControl]="dischargePortsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Discharge Port not found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let dischargePort of filteredDischargePorts | async" [value]="dischargePort.code">
                {{dischargePort.name}}
              </mat-option>
            </mat-select>
            <button matSuffix
                    *ngIf="dischargePorts.value && !isReadOnly"
                    mat-icon-button
                    class="clear-filter-icon"
                    aria-label="Clear selection"
                    (click)="clearLookupFilter(dischargePorts)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-form-field>
        </ng-container>
      </div>

      <div class="col-md-12">
        <ng-container>
          <mat-form-field *ngIf="finalDestinations">
            <mat-label>Select Final Destination</mat-label>
            <mat-select [formControl]="finalDestinations">
              <mat-option>
                <ngx-mat-select-search [formControl]="finalDestinationsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Final Destination not found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let cnfFinalDestination of filteredFinalDestinations | async" [value]="cnfFinalDestination.code">
                {{cnfFinalDestination.name}}
              </mat-option>
            </mat-select>
            <button matSuffix
                    *ngIf="finalDestinations.value && !isReadOnly"
                    mat-icon-button
                    (click)="$event.stopPropagation(); finalDestinations.setValue('')"
                    aria-label="Clear selection"
                    class="clear-filter-icon">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-form-field>
        </ng-container>
      </div>

      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Client Ref</mat-label>
          <input matInput [formControl]="clientRefNo">
          <button matSuffix
                  *ngIf="clientRefNo.value && !isReadOnly"
                  mat-icon-button
                  (click)="clientRefNo.setValue('')"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field style="width:30%">
          <mat-label>Commodity</mat-label>
          <mat-select formControlName="commodities">
            <mat-option>
              <ngx-mat-select-search [formControl]="commoditiesMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Commodity Not Found"></ngx-mat-select-search>
            </mat-option>

            <mat-option *ngFor="let commodity of filteredCommodities | async" [value]="commodity.code">
              {{commodity.name}}
            </mat-option>
          </mat-select>
          <button matSuffix
                  *ngIf="addBookingForm.get('commodities')?.value && !isReadOnly"
                  mat-icon-button
                  (click)="$event.stopPropagation(); addBookingForm.get('commodities')?.setValue('')"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
          <mat-error>
            Commodity is required.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field style="width:30%">
          <mat-label>Temperature</mat-label>
          <mat-select formControlName="temperatures" required>
            <mat-option>
              <ngx-mat-select-search [formControl]="temperaturesMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Temperature Not Found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let temperature of filteredTemperatures | async" [value]="temperature.code">
              {{temperature.name}}
            </mat-option>
          </mat-select>
          <button matSuffix
                  *ngIf="addBookingForm.get('temperatures')?.value && !isReadOnly"
                  mat-icon-button
                  (click)="$event.stopPropagation(); addBookingForm.get('temperatures')?.setValue('')"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
          <mat-error *ngIf="addBookingForm.get('temperatures')?.hasError('required')">Temperature is required.</mat-error>
          <mat-error *ngIf="addBookingForm.get('temperatures')?.invalid && addBookingForm.get('temperatures')?.touched">
            {{errorMessage()}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field class="col-xs-12">
          <mat-label>Vents</mat-label>
          <mat-select formControlName="vents" required>
            <mat-option>
              <ngx-mat-select-search [formControl]="ventsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Vents Not Found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let vent of filteredVents | async" [value]="vent.code">
              {{vent.name}}
            </mat-option>
          </mat-select>
          <button matSuffix
                  *ngIf="addBookingForm.get('vents')?.value && !isReadOnly"
                  mat-icon-button
                  (click)="$event.stopPropagation(); addBookingForm.get('vents')?.setValue('')"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
          <mat-error *ngIf="addBookingForm.get('vents')?.hasError('required')">Vents is required.</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field class="col-xs-12">
          <mat-label>Container type</mat-label>
          <mat-select formControlName="containerTypes" required>
            <mat-option>
              <ngx-mat-select-search [formControl]="containerTypesMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Container Types Not Found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let containerType of filteredContainerTypes | async" [value]="containerType.code">
              {{containerType.name}}
            </mat-option>
          </mat-select>
          <button matSuffix
                  *ngIf="addBookingForm.get('containerTypes')?.value && !isReadOnly"
                  mat-icon-button
                  (click)="$event.stopPropagation();addBookingForm.get('containerTypes')?.setValue('')"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
          <mat-error *ngIf="addBookingForm.get('containerTypes')?.hasError('required')">Container Type is required.</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Container Qty</mat-label>
          <input matInput
                 required
                 type="number"
                 min="1"
                 max="99"
                 formControlName="quantity"
                 (input)="addBookingForm.get('quantity')?.updateValueAndValidity()" />
          <button matSuffix
                  *ngIf="addBookingForm.get('quantity')?.value && !isReadOnly"
                  mat-icon-button
                  (click)="addBookingForm.get('quantity')?.setValue(0)"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
          <mat-error *ngIf="addBookingForm.get('quantity')?.hasError('min')"> Container qty must be at least 1.</mat-error>
          <mat-error *ngIf="addBookingForm.get('quantity')?.hasError('max')">
            Max container qty is 99.
          </mat-error>
          <mat-error *ngIf="addBookingForm.get('quantity')?.hasError('min') && addBookingForm.get('quantity')?.touched">
            Container qty must be at least 1.
          </mat-error>
        </mat-form-field>
      </div>


      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Solas method</mat-label>
          <mat-select formControlName="solasMethods">
            <mat-option>
              <ngx-mat-select-search [formControl]="solasMethodsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Solas Method Not Found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let solasMethod of filteredSolasMethods | async" [value]="solasMethod.code">
              {{ (solasMethod.code === null || solasMethod.code === '' || solasMethod.code === '0') ? 'Not Available' : solasMethod.name }}
            </mat-option>

          </mat-select>
          <button matSuffix
                  *ngIf="addBookingForm.get('solasMethods')?.value && !isReadOnly"
                  mat-icon-button
                  (click)="$event.stopPropagation(); addBookingForm.get('solasMethods')?.setValue('')"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
          <mat-error *ngIf="addBookingForm.get('solasMethods')?.hasError('required')">Solas Methods is required.</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field style="width:30%">
          <mat-label>Weight (kg)</mat-label>
          <input matInput
                 type="number"
                 min="0"
                 max="35000"
                 formControlName="weight"
                 (input)="addBookingForm.get('weight')?.updateValueAndValidity()" />
          <button matSuffix
                  *ngIf="addBookingForm.get('weight')?.value && !isReadOnly && !isReadOnly"
                  mat-icon-button
                  (click)="addBookingForm.get('weight')?.setValue(0)"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
          <mat-error *ngIf="addBookingForm.get('weight')?.hasError('max') && addBookingForm.get('weight')?.touched">
            Max weight is 35,000 kg.
          </mat-error>
          <mat-error *ngIf="addBookingForm.get('weight')?.hasError('min') && addBookingForm.get('weight')?.touched">
            Weight must be at least 0 kg.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field class="col-xs-12">
          <mat-label>Loading point 1</mat-label>
          <mat-select [formControl]="loadpoint1">
            <mat-option>
              <ngx-mat-select-search [formControl]="loadpointsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Load Point Not Found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let loadpoint of filteredLoadpoints | async" [value]="loadpoint.code">
              {{loadpoint.name}}
            </mat-option>
          </mat-select>
          <button matSuffix
                  *ngIf="loadpoint1.value && !isReadOnly"
                  mat-icon-button
                  (click)="$event.stopPropagation(); loadpoint1.setValue('')"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field class="col-xs-12">
          <mat-label>Loading point 2</mat-label>
          <mat-select [formControl]="loadpoint2">
            <mat-option>
              <ngx-mat-select-search [formControl]="loadpointsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Load Point Not Found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let loadpoint of filteredLoadpoints | async" [value]="loadpoint.code">
              {{loadpoint.name}}
            </mat-option>
          </mat-select>
          <button matSuffix
                  *ngIf="loadpoint2.value && !isReadOnly"
                  mat-icon-button
                  (click)="$event.stopPropagation(); loadpoint2.setValue('')"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Load date</mat-label>
          <input matInput
                 [matDatepicker]="dateToPicker"
                 placeholder="Choose a date"
                 formControlName="loadDateTime"
                 [min]="today"
                 required
                 (dateChange)="validateLoadTime()" />
          <div matSuffix class="calendar-input-suffix">
            <button *ngIf="addBookingForm.get('loadDateTime')?.value && !isReadOnly"
                    mat-icon-button
                    (click)="clearDate()"
                    aria-label="Clear selection"
                    class="clear-filter-icon">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
            <mat-datepicker-toggle [for]="dateToPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateToPicker></mat-datepicker>
          </div>
          <mat-error *ngIf="addBookingForm.get('loadDateTime')?.hasError('required')">Date is required.</mat-error>
          <mat-error *ngIf="addBookingForm.get('loadDateTime')?.hasError('dateInPast')">Date cannot be in the past.</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Load time</mat-label>
          <input matInput placeholder="HH:MM" formControlName="loadTime" (focus)="openTimePicker($event.target)" readonly />
          <div matSuffix class="calendar-input-suffix">
            <button matSuffix
                    *ngIf="addBookingForm.get('loadTime')?.value && !isReadOnly"
                    mat-icon-button
                    (click)="addBookingForm.get('loadTime')?.setValue('')"
                    aria-label="Clear selection"
                    class="clear-filter-icon">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
            <button mat-icon-button matSuffix aria-label="Select Time" *ngIf="!isReadOnly">
              <mat-icon>access_time</mat-icon>
            </button>
          </div>
          <!-- Display error messages based on validation errors -->
          <mat-error *ngIf="addBookingForm.get('loadTime')?.hasError('required')">
            Load time is required.
          </mat-error>
          <mat-error *ngIf="addBookingForm.get('loadTime')?.hasError('invalidTime')">
            Please enter a valid time in HH:MM format.
          </mat-error>
          <mat-error *ngIf="addBookingForm.get('loadTime')?.hasError('loadTimeTooEarly')">
            Load time must be at least 6 hours from now.
          </mat-error>
        </mat-form-field>
        <ng-template #timePickerTemplate style="width:fit-content">
          <app-time-picker></app-time-picker>
        </ng-template>
      </div>

      <div class="checkbox-wrap col-md-12">
        <div>Select all that apply:</div>
        <mat-checkbox [formControl]="isSterilized" (click)="sterilizedClick()">Sterilized</mat-checkbox>
        <mat-checkbox [formControl]="isTemptale" (click)="temptaleClick()">Temptale</mat-checkbox>
      </div>

      <div class="col-md-12" *ngIf="isTemptale.value">
        <mat-form-field class="col-xs-12">
          <mat-label>Temptale description</mat-label>
          <mat-select formControlName="temptaleDescription">
            <mat-option>
              <ngx-mat-select-search [formControl]="temptaleDescriptionsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Temptale Description Not Found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let temptaleDescription of filteredTemptaleDescriptions | async" [value]="temptaleDescription.code">
              {{temptaleDescription.name}}
            </mat-option>
          </mat-select>
          <button matSuffix
                  *ngIf="addBookingForm.get('temptaleDescription')?.value && !isReadOnly"
                  mat-icon-button
                  (click)="$event.stopPropagation(); addBookingForm.get('temptaleDescription')?.setValue('')"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
          <mat-error *ngIf="addBookingForm.get('solasMethods')?.hasError('required')">Temptale description is required.</mat-error>
          <mat-error *ngIf="temptaleDescription.invalid">{{errorMessage()}}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4" *ngIf="isTemptale.value">
        <mat-form-field>
          <mat-label>Total Temptale Qty</mat-label>
          <input matInput type="number" min="0" [formControl]="temptaleQTY" />
          <button matSuffix
                  *ngIf="temptaleQTY.value && !isReadOnly"
                  mat-icon-button
                  (click)="temptaleQTY.setValue(0); $event.stopPropagation()"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Prelim Consignee</mat-label>
          <input matInput [formControl]="prelimConsignee" />
          <button matSuffix
                  *ngIf="prelimConsignee.value && !isReadOnly"
                  mat-icon-button
                  (click)="prelimConsignee.setValue('')"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Pre-Verification Key</mat-label>
          <input matInput [formControl]="preVerificationKey" />
          <button matSuffix
                  *ngIf="preVerificationKey.value && !isReadOnly"
                  mat-icon-button
                  (click)="preVerificationKey.setValue('')"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field>
          <mat-label>Leave a comment</mat-label>
          <textarea matInput formControlName="comment" rows="3"></textarea>
          <button matSuffix
                  *ngIf="addBookingForm.get('comment')?.value && !isReadOnly"
                  mat-icon-button
                  (click)=" addBookingForm.get('comment')?.setValue('')"
                  aria-label="Clear selection"
                  class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
          <mat-error *ngIf="addBookingForm.get('comment')?.hasError('required') && addBookingForm.get('comment')?.touched">
            If Vents is CA then Comment is required.
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label>Step Up</mat-label>
        <textarea matInput [formControl]="stepUp" rows="3"></textarea>
        <button matSuffix
                *ngIf="stepUp.value && !isReadOnly"
                mat-icon-button
                (click)="stepUp.setValue('')"
                aria-label="Clear selection"
                class="clear-filter-icon">
          <mat-icon svgIcon="backspace"></mat-icon>
        </button>
      </mat-form-field>
      <ng-container *ngIf="!isReadOnly">
        <div class="container-aligned-right col-md-12">
          <!--<button mat-button class="filter-btn"><mat-icon>content_copy</mat-icon>Save & Duplicate Booking</button>-->
          <button mat-button class="add-booking-btn" (click)="openDialog()" type="button">Cancel</button>
          <button mat-button class="add-booking-btn" (click)="sendToReefers()" type="button" [disabled]="isSendToReefersDisabled"><mat-icon>send</mat-icon>Send to GoReefers</button>
        </div>
      </ng-container>

      <ng-container *ngIf="isReadOnly">
        <div class="container-aligned-right col-md-12">
          <button mat-button class="add-booking-btn" (click)="returnToBookings()" type="button">Return to Bookings</button>
        </div>
      </ng-container>
    </form>
    <div *ngIf="isSpinnerVisible" class="spinner-overlay">
      <mat-spinner color="primary" diameter="80"></mat-spinner>
    </div>
  </mat-card-content>
</mat-card>



