export interface IScheduleListModel {
  sailingScheduleHeaderCode: string,
  lineNumber: number,
  dischargePortCode: string,
  dischargePort: string,
  dischargeETA: Date,
  carrierCode: string,
  carrier: string,
  portCountryCode: string,
  marketCode: string,
  portCountry: string,
  loadPortCode: string,
  loadPort: string,
  loadETD: Date,
  stackStatus: string,
  stackStart: Date,
  stackEnd: Date,
  vesselCode: string,
  vesselName: string,
  serviceCode: string,
  serviceName: string,
  voyageNo: string,
  siCutoffDateTime: Date,
  hasSubscriptions: boolean,
  hasAlerts: boolean
}

export class ScheduleListModel implements IScheduleListModel {
  sailingScheduleHeaderCode: string = '';
  lineNumber: number = 0;
  dischargePortCode: string = '';
  dischargePort: string = '';
  dischargeETA: Date = new Date();
  carrierCode: string = '';
  carrier: string = '';
  marketCode: string = '';
  portCountryCode: string = '';
  portCountry: string = '';
  loadPortCode: string = '';
  loadPort: string = '';
  loadETD: Date = new Date();
  stackStatus: string = '';
  stackStart: Date = new Date();
  stackEnd: Date = new Date();
  vesselCode: string = '';
  vesselName: string = '';
  serviceCode: string = '';
  serviceName: string = '';
  voyageNo: string = '';
  siCutoffDateTime: Date = new Date();
  hasSubscriptions: boolean = false;
  hasAlerts: boolean = false;
}
