import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AddBookingModel, BookingModel } from '../../models/booking-data-model';
import { FilterData } from '../../models/list-model';
import { DataFilter, FilterTransferModel } from '../../models/filter-models';
import { BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ILookupModel, LookupModel } from '../../models/lookup-model';
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle, } from '@angular/material/dialog';
import { InformationComponent } from '../../dialog/information/information.component';
import { of } from 'rxjs';
import { ScheduleListModel } from '../../models/schedules/schedule-list-model';
import { BookingListModel } from '../../models/bookings/booking-list-model';
import { AppBookingListModel } from '../../models/bookings/appbooking-list-model';
import { BookingHeaderModel } from '../../models/bookings/booking-header-model';
import { BookingLineModel } from '../../models/bookings/booking-line-model';
import { BookingEditModel } from '../../models/bookings/booking-edit-model';
import { BookingSubmitResponse } from '../../models/bookings/booking-response-model';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { RollingPlan } from '../../models/bookings/rolling-plan-model';
import { RoadData } from '../../models/road-data-model';
import { MatTableDataSource } from '@angular/material/table';



@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private apiUrl = environment.apiUrl;


  private filterDataTransfer = new BehaviorSubject<FilterData>({});
  filterData$ = this.filterDataTransfer.asObservable();

  private statusFilterSubject = new BehaviorSubject<string | null>(null);
  statusFilterToggle$ = this.statusFilterSubject.asObservable();

  private sendFilter = new BehaviorSubject<string | null>(null);
  sendFilter$ = this.sendFilter.asObservable();

  private filterNameSaved = new BehaviorSubject('');
  filterNameSaved$ = this.filterNameSaved.asObservable();

  private sendFilterId = new BehaviorSubject<number>(-1);
  sendFilterId$ = this.sendFilterId.asObservable()

  private sendActiveFilterToFilter = new BehaviorSubject<DataFilter>(new DataFilter());
  sendActiveFilterToFilter$ = this.sendActiveFilterToFilter.asObservable();

  private sendFilterTransfer = new BehaviorSubject<FilterTransferModel>(new FilterTransferModel(-1, {}));
  sendFilterTransfer$ = this.sendFilterTransfer.asObservable();

  private sendVoyageSelected = new BehaviorSubject<ScheduleListModel | null> (null);
  sendVoyageSelected$ = this.sendVoyageSelected.asObservable();

  private addBookingSendFilterData = new BehaviorSubject<FilterTransferModel>(new FilterTransferModel(-1, {}));
  addBookingSendFilterData$ = this.addBookingSendFilterData.asObservable();

  private bookingsDataSource = new BehaviorSubject<MatTableDataSource<BookingListModel>>(new MatTableDataSource());

  private appBookingsDataSource = new BehaviorSubject<MatTableDataSource<AppBookingListModel>>(new MatTableDataSource());


  //private addBookingData = new BehaviorSubject<BookingDataTransferModel>(new BookingDataTransferModel());
  //addBookingData$ = this.addBookingData.asObservable();


  constructor(private http: HttpClient, private dialog: MatDialog) { }

  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private handleError<T>(message: string, result?: T): Observable<T> {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-fail']
    });
    return of(result as T);
  }

  private openSnackBar(message: string, success: boolean = true) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: success ? ['snackbar-success'] : ['snackbar-fail']
    });
  }

  getBookings(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): Observable<BookingListModel[]> {
    let url = this.apiUrl + '/Booking/GetBookings';
  
    if (filterData && Object.keys(filterData).length > 0) {

      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);
        
         url = url + '?filter=' + jsonData;
      }
    }
    else if (filterId && filterId > 0) {
      url = url + '?filterId=' + filterId.toString();
    }

    if (startDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateFrom=' + startDate.toISOString();
    }

    if (endDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateTo=' + endDate.toISOString();
    }
    console.log('Request URL:', url);
    return this.http.get<BookingListModel[]>(url);
  }



  getBooking(bookingHeaderCode:string): Observable<BookingHeaderModel>{
    const url = this.apiUrl + '/Booking/GetBooking?bookingHeaderCode='+ bookingHeaderCode;    

    return this.http.get<BookingHeaderModel>(url);
  }

  getAppBooking(bookingHeaderCode: string): Observable<BookingHeaderModel> {
    const url = this.apiUrl + '/Booking/GetAppBookings?bookingHeaderCode=' + bookingHeaderCode;

    return this.http.get<BookingHeaderModel>(url);
  }

  

  getBookingLines(bookingHeaderCode:string): Observable<BookingLineModel[]> {
    const url = this.apiUrl + '/Booking/GetBookingLines?bookingHeaderCode='+ bookingHeaderCode;

    return this.http.get<BookingLineModel[]>(url);
  }

  getBookingEditModel(appBookingCode: string | null,
    appBookingLineNumber: number | null,
    scheduleCode: string | null,
    loadPortCode: string | null,
    dischargePortCode: string | null,
    carrierCode: string | null): Observable<BookingEditModel> {
      var url = this.apiUrl + '/Booking/GetBookingEditModel';
      var parameters: string[] = [];

      if (appBookingCode !== null) { parameters.push('appBookingCode=' + appBookingCode); }
      if (appBookingLineNumber !== null) { parameters.push('appBookingLine=' + appBookingLineNumber); }
      if (scheduleCode !== null) { parameters.push('scheduleCode=' + scheduleCode); }
      if (dischargePortCode !== null) { parameters.push('dischargePortCode=' + dischargePortCode); }
      if (loadPortCode !== null) { parameters.push('loadPortCode=' + loadPortCode); }
      if (carrierCode !== null) { parameters.push('carrierCode=' + carrierCode); }

      var paramString = parameters.join('&');

      if (paramString !== '') { url += '?' + paramString; }

      return this.http.get<BookingEditModel>(url);
  }

  submitBooking(bookingEditModel: BookingEditModel): Observable<BookingSubmitResponse> {
    const url = this.apiUrl + '/Booking/SubmitBooking';
    const body = JSON.stringify(bookingEditModel);
    
    return this.http.post<BookingSubmitResponse>(url, body, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError(error => {
          return of({
            appBookingCode: bookingEditModel.appBookingCode,
            status: `Error: ${error.message}`,
            success: false
          });
        })
      );
  }

  cancelBooking(bookingCancelModel: AddBookingModel): Observable<BookingSubmitResponse> {
    const url = this.apiUrl + '/Booking/CancelBooking';
    const body = JSON.stringify(bookingCancelModel);
    
    return this.http.post<BookingSubmitResponse>(url, body, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError(error => {
          return of({
            appBookingCode: bookingCancelModel.appbookingCode,
            status: `Error: ${error.message}`,
            success: false
          });
        })
      );
  }

  addBooking(booking: AddBookingModel): Observable<any> {
    const url = this.apiUrl + '/Booking/AddBooking';
    const body = JSON.stringify(booking);

    return this.http.post<string>(url, body, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError(error => {
          window.alert('An error occurred: ' + error.error);
          return of(`Error: ${error.message}`);
        })
      );
  }
 
  getFilters(): Observable<DataFilter[]> {
    const url = this.apiUrl + '/Booking/GetFilters';
    return this.http.get<DataFilter[]>(url);
  }

  saveBookingFilter(filterName: string, filterData?: FilterData): Observable<any> {
    const url = this.apiUrl + '/Booking/SaveFilter?filterName=' + filterName; 
    let body = {};

    if (filterData) {
      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        body = JSON.stringify(filteredData);
      }
    }
    console.log(url)

    return this.http.post<string>(url, body, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError(error => {
          // Display the error message as an alert
          window.alert('An error occurred: ' + error.error);
          // Return an observable with a user-facing error message
          return of(`Error: ${error.message}`);
        })
      );
  }

  deleteBookingFilter(filterId: number): Observable<any> {
    const url = this.apiUrl + '/Booking/DeleteFilter?filterId=' + filterId;

    return this.http.delete<string>(url)
      .pipe(
        catchError(error => {
          window.alert('An error occurred: ' + error.error);
          return of(`Error: ${error.message}`);
        })
      );
  }

  transferFilterData(filterData: FilterData) {
    this.filterDataTransfer.next(filterData);
  }

  toggleStatusFilter(statusFilterName: string){
    this.statusFilterSubject.next(statusFilterName);
  }

  sendFilterSetupToFilter(filter: string) {
    this.sendFilter.next(filter);
  }

  filterNameSavedSend(savedFilterName: string) {
    this.filterNameSaved.next(savedFilterName);
  }

  sendingFilterId(filterId: number) {
    this.sendFilterId.next(filterId);
  }

  sendingActiveFilterToFilter(filter: DataFilter) {
    this.sendActiveFilterToFilter.next(filter);
  }

  sendingFilterTransferModel(transferModel: FilterTransferModel) {
    this.sendFilterTransfer.next(transferModel);
  }

  sendingSelectedVoyage(voyage: ScheduleListModel | null) {
    this.sendVoyageSelected.next(voyage);
  }



  sendingAddBookingFilterData(filterTransfer: FilterTransferModel) {
    this.addBookingSendFilterData.next(filterTransfer);
  }

  getBookingsDataSource() {
    return this.bookingsDataSource.asObservable();
  }

  setBookingsDataSource(data: BookingListModel[]) {
    const dataSource = new MatTableDataSource<BookingListModel>(data);
    this.bookingsDataSource.next(dataSource);
  }

  getAppBookingsDataSource() {
    return this.appBookingsDataSource.asObservable();
  }

  setAppBookingsDataSource(data: AppBookingListModel[]) {
    const dataSource = new MatTableDataSource<AppBookingListModel>(data);
    this.appBookingsDataSource.next(dataSource);
  }

  //sendingAddBookingData(addBookingData: BookingDataTransferModel) {
  //  this.addBookingSendFilterData.next(addBookingData);
  //}

  getRollingPlans(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): Observable<RollingPlan[]> {
    let url = this.apiUrl + '/Booking/GetRollingPlan';

    if (filterData && Object.keys(filterData).length > 0) {

      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);

        url = url + '?filter=' + jsonData;
      }
    }
    else if (filterId && filterId > 0) {
      url = url + '?filterId=' + filterId.toString();
    }

    if (startDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateFrom=' + startDate.toISOString();
    }

    if (endDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateTo=' + endDate.toISOString();
    }
    return this.http.get<RollingPlan[]>(url)
  }


  getAppBookings(): Observable<AppBookingListModel[]> {
    const url = this.apiUrl + '/Booking/GetAppBookings';
    return this.http.get<AppBookingListModel[]>(url);
  }


  getVesselNames(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/Lookup/GetVesselNames`);
  }
}
