import { OnInit, Component, ViewChild, Output, EventEmitter, OnDestroy, inject, ChangeDetectorRef } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BookingService } from '../service/booking/booking.service';
import { FilterData } from '../models/list-model';
import { CommunicationService } from '../service/communication/communication.service';
import { ColumnSetup } from '../models/column-setup';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { Subject, of } from 'rxjs';
import { takeUntil, catchError, skip } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { SubscriptionComponent } from '../dialog/subscription/subscription.component';
import { AlertNotificationModel } from '../models/alerts/alert-notification-model';
import { AlertSubscriptionInputModel } from '../models/alerts/alert-subscription-input-model';
import { AlertSubscriptionModel } from '../models/alerts/alert-subscription-model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import { AlertService } from '../service/alerts/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { BookingListModel } from '../models/bookings/booking-list-model';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.css'],
})


export class BookingListComponent implements OnInit, OnDestroy {

  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;

  @Output() openDetailDrawer = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.bookingDataSource.sort = sort;
  }
  @ViewChild(MatTable) table!: MatTable<BookingListModel>;

  bookingData: BookingListModel[] = [];
  filter!: FilterData;

  detailTabbedViewEnum = detailTabbedViewEnum;

  detailView = detailView;
  filterId: number = 0;
  startDate?: Date = new Date;
  endDate?: Date = new Date;

  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];
  bookingDataSource = new MatTableDataSource<BookingListModel>();
  _snackBar: any;
  horizontalPosition: any;
  verticalPosition: any;
  templates: AlertSubscriptionModel[] = [];
  isLoading = true;
  protected filterList = new FormControl();
  loadingSearch: boolean = false;
  //subscribedTemplatesMap = new Map<string, boolean>(); // To track hasSubscribedTemplates status

  constructor(
    private alertDataService: AlertService,
    private bookingDataService: BookingService,
    private communicationService: CommunicationService,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appComponent: AppComponent,
    private cdr: ChangeDetectorRef
  ) {
    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    )
  }

  ngOnInit(): void {
    this.configureColumns()
    this.bookingDataSource.filterPredicate = (data: BookingListModel, filter: string) => {
      const normalizedFilter = filter.toLowerCase();
      return (
        (data.carrier?.toLowerCase().includes(normalizedFilter) || false) ||
        (data.vesselName?.toLowerCase().includes(normalizedFilter) || false) ||
        (data.customer?.toLowerCase().includes(normalizedFilter) || false) ||
        (data.gRReferenceNo?.toLowerCase().includes(normalizedFilter) || false) ||
        (data.loadPort?.toLowerCase().includes(normalizedFilter) || false)
      );
    }

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    this.bookingDataService.statusFilterToggle$
      .pipe(
        takeUntil(this.destroy$),
        skip(1)
      )
      .subscribe({
        next:
          value => {
            if (value) {
              this.applyFilter(value);
            } else {
              this.applyFilter('');
            }
          }
      });


    this.bookingDataService.sendFilterTransfer$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (t) => {
          this.filter = t.filter;
          this.filterId = t.filterId;
          this.startDate = t.startDate;
          this.endDate = t.endDate;
          this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
        },
        error: (error) => {
          console.error('Send FilterModel Error', error);
          window.alert('Send FilterModel Error. Please try again later.');
        }
      });

    this.bookingDataService.getBookingsDataSource().subscribe(dataSource => {
      this.bookingDataSource = dataSource; // Set this to your table's data source
    });
  }

  configureColumns(): void {
    this.displayedColumns = [
      { name: 'appBookingStatusColor', header: '', type: 'string', visible: true },
      { name: 'customer', header: 'Customer', type: 'string', visible: !this.isCustUser },
      { name: 'grRefNo', header: 'GR Ref No', type: 'string', visible: true, toolTip: 'GoReefers reference number assigned to the booking.' },
      { name: 'appBookingStatus', header: 'Booking Status', type: 'string', visible: true, toolTip: 'Status of booking with GoReefers.' },
      { name: 'loadPort', header: 'Load Port', type: 'string', visible: true, toolTip: 'Port where goods will be loaded on to vessel.' },
      { name: 'carrier', header: 'Carrier', type: 'string', visible: true, toolTip: 'Your selected shipping line.' },
      { name: 'sailDate', header: 'Load ETD', type: 'date', visible: true, toolTip: 'Estimated date the vessel will departure.' },
      { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true, toolTip: 'Name of the vessel.' },
      { name: 'totalQtyContainers', header: 'Total Containters', type: 'number', visible: true, toolTip: 'No. of containers on booking.' },
      { name: 'actions', header: 'Actions', type: 'string', visible: true }
    ];
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  subscribeToAlert(row: any): void {
    const notificationModel = this.createNotificationModel(row);

    this.showLoading(true);

    this.alertDataService.getAlertSubscriptionTemplates(notificationModel)
      .pipe(
        catchError(error => {
          console.error('Error fetching subscription templates:', error);
          return of([]);
        })
      )
      .subscribe((subscriptionTemplates: AlertSubscriptionModel[]) => {

        this.showLoading(false);

        const subscriptionInput = new AlertSubscriptionInputModel();
        subscriptionInput.notificationModel = notificationModel;
        subscriptionInput.templates = subscriptionTemplates;

        const dialogRef = this.dialog.open(SubscriptionComponent, {
          data: { subscriptionList: subscriptionTemplates }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {

            subscriptionInput.templates = result;

            this.alertDataService.updateAlertSubscription(subscriptionInput)
              .pipe(
                catchError(error => {
                  console.error('Error updating subscriptions:', error);
                  return of(null);
                })
              )
              .subscribe(response => {
                if (response) {
                  this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
                }
              });
          }
        });
      });
  }

  showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  getBackgroundColor(cellValue: string): string {
    switch (cellValue) {
      case 'Cancelled':
        return '#DC6868';
      case 'Confirmed':
        return '#36DE6F';
      case 'Awaiting Approval':
        return '#FFD34F';
      default:
        return '#C2C2C2';
    }
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  updateData(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): void {
    this.clearSearchField(this.filterList);
    this.isLoading = true;
    this.bookingDataService.getBookings(filterId, filterData, startDate, endDate).subscribe(data => {
      this.configureColumns();
      this.bookingDataSource.paginator = this.paginator;
      this.bookingDataSource.data = data;
      this.bookingDataSource.data.sort((a, b) => {
        return new Date(a.sailDate).getTime() - new Date(b.sailDate).getTime();
      })
      this.isLoading = false;
    });
  }

  private createNotificationModel(row: any): AlertNotificationModel {
    return {
      AlertCode: '',
      CustomerCode: row.customerCode,
      BookingCode: row.bookingHeaderCode,
      TemplateGroup: 'Booking'
    } as AlertNotificationModel;
  }

  viewDetails(row: any, detailViewToOpen: detailView, tab: detailTabbedViewEnum): void {
    var bookingParameter = row['bookingHeaderCode'];
    this.communicationService.toggleDetailView(detailViewToOpen, tab);
    this.communicationService.getDetailViewParameter(bookingParameter)
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  applyFilter(filterValue: string) {
    if (!filterValue) {
      this.bookingDataSource.filter = '';
    } else {
      this.bookingDataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  filterByStatus(status: string) {
    this.bookingDataSource.filterPredicate = (data: BookingListModel, filter: string) => data.appBookingStatus === filter;
    this.bookingDataSource.filter = status;
  }

  filterByDateRange(startDate: string, endDate: string) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    this.bookingDataSource.filterPredicate = (data: BookingListModel, filter: string) => {
      const date = new Date(data.sailDate);
      return date >= start && date <= end;
    };
    this.bookingDataSource.filter = `${startDate}-${endDate}`;
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearSearchField(control: FormControl): void {
    control.reset(); // Clear the search input field
    this.bookingDataSource.filter = ''; // Reset the table filter
  }

  applySearch(filterValue: string | undefined) {
    const normalizedFilter = (filterValue || '').trim().toLowerCase();
    this.loadingSearch = true;
    setTimeout(() => {
      this.bookingDataSource.filter = normalizedFilter;
      this.loadingSearch = false;
    }, 200);
  }

  checkNoFilter(): boolean {
    if (Object.values(this.filter).some(value => value && value.length > 0)) {
      return true;
    }
    return false;
  }
}
