import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';


@Directive({
  selector: '[appCardInView]'
})
export class CardInViewDirective {
  @Output() inView = new EventEmitter<boolean>();

  constructor(private el: ElementRef) {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.inView.emit(true);
          observer.unobserve(this.el.nativeElement); // Stop observing once in view
        }
      });
    });

    observer.observe(this.el.nativeElement);
  }
}
