import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmailService } from '../service/emails/email.service';
import { EmailAttachmentsModel, EmaiLModel } from '../models/emails/email-model';
import { MatDialog } from '@angular/material/dialog';
import { InformationComponent } from '../dialog/information/information.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements AfterViewInit {
  protected email = new EmaiLModel();
  protected attachments: string[] = [];
  base64Files: string[] = [];
  submitted: boolean = false;
  isSpinnerVisible: boolean = false;
  isDisabled = false;

  @ViewChild('formContainer') formContainer: ElementRef | undefined; 

  file_upload_config = {
    MIME_types_accepted: "image/jpeg,image/png,application/pdf",
    is_multiple_selection_allowed: true,
    data: null
  };


  constructor(private http: HttpClient, private emailService: EmailService, private dialog: MatDialog) { }

  ngAfterViewInit() {
    if (this.formContainer) {
      this.formContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  submitForm() {
    this.submitted = true;

    if (this.email.subject === '' && this.email.message === '') {
        const dialogRef = this.dialog.open(InformationComponent, {
            width: '350px',
            height: '350px',
            data: {
                title: 'Validation Error',
                message: 'Please fill in both the subject and message fields before submitting.'
            }
        });
        return;
    }

    this.startSpinner()
    this.emailService.sendContactEmail(this.email).subscribe(
      res => {
        this.stopSpinner();
        const dialogRef = this.dialog.open(InformationComponent, {
          width: '350px',
          height: '350px',
          data: {
            title: 'Success',
            message: 'Your message has been successfully sent and our team will get back to you as soon as possible'
          }
        });

        dialogRef.afterClosed().subscribe(() => {
          window.location.href = '/'; 
        });
      },
      error => {
        this.stopSpinner();
        const dialogRef = this.dialog.open(InformationComponent, {
          width: '350px',
          height: '350px',
          data: {
            title: 'Error',
            message: 'There was an error sending your message. Please try again later.' 
          }
        });
      }
    );
  }

  onFileSelected(files: { name: string; base64: string }[]): void {
    const maxSizeInBytes = 25 * 1024 * 1024;
    let totalSize = 0;

    const newFiles = files.filter(file => 
      !this.email.attachments.some(existingFile => existingFile.name === file.name)
    );

    this.email.attachments.forEach(existingFile => {
      totalSize += (existingFile.base64.length * (3 / 4)) - (existingFile.base64.endsWith('==') ? 2 : existingFile.base64.endsWith('=') ? 1 : 0);
    });

    newFiles.forEach(file => {
      const fileSize = (file.base64.length * (3 / 4)) - (file.base64.endsWith('==') ? 2 : file.base64.endsWith('=') ? 1 : 0);
      totalSize += fileSize; 
    });

    if (totalSize > maxSizeInBytes) {
        
      this.dialog.open(InformationComponent, {
        width: '350px',
        height: '350px',
        data: {
            title: 'Upload Error',
            message: 'Total attachments size exceeds 25 MB. Please remove some files before adding new ones.'
        }
    });
    return;

    }else{
      this.email.attachments = this.email.attachments.concat(newFiles);
    }
    
  }

  onFilesDropped(files: EmailAttachmentsModel[]): void {
    this.onFileSelected(files.map(file => ({ name: file.name, base64: file.base64 })));
  }

  startSpinner(): void{
    this.isSpinnerVisible = true;
    this.isDisabled = true;
    const spinnerOverlay = document.querySelector('.spinner-overlay') as HTMLElement;

    if (spinnerOverlay) {
      spinnerOverlay.style.display = 'flex';
    }
  }

  stopSpinner(): void {
    this.isSpinnerVisible = false;
    this.isDisabled = false;

    
    const spinnerOverlay = document.querySelector('.spinner-overlay') as HTMLElement;

    if (spinnerOverlay) {
      spinnerOverlay.style.display = 'none'; 
    }
  }

  updateEmailAttachments(selectedFiles: any[]) {
    this.email.attachments = selectedFiles;
  }

  removeEmailAttachment(fileName: string) {
    const attachmentIndex = this.email.attachments.findIndex(att => att.name === fileName);
    if (attachmentIndex !== -1) {
      this.email.attachments.splice(attachmentIndex, 1);
    }
  }

  onAttachmentCanceled(fileName: string) {
    const attachmentIndex = this.email.attachments.findIndex(att => att.name === fileName);
    if (attachmentIndex !== -1) {
      this.email.attachments.splice(attachmentIndex, 1);
    }
  }

  cancelAllAttachments(cancel: boolean) {
    if(cancel)
    this.email.attachments = [];
  }
}
