<div class="filter-container">

  <div class="filter-field-wrapper">


    <mat-form-field>
      <mat-label>Search Container No.</mat-label>
      <input matInput [formControl]="containerNos">
    </mat-form-field>

    <ng-container *ngIf="!isCustUser">
      <mat-form-field *ngIf="customers.value">
        <mat-label>Customer</mat-label>
        <mat-select [formControl]="customers" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="customersMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Customer not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{customers.value.length > 0 ? customers.value[0].code : ''}}
            <span *ngIf="customers.value[0]  && customers.value.length > 1" class="example-additional-selection">
              (+{{(customers.value.length || 0) - 1}} {{customers.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(customers)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
            {{customer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="!isCustUser">
      <mat-form-field *ngIf="customers.value">
        <mat-label>Status</mat-label>
        <mat-select [formControl]="status" >
          <mat-option>
            <ngx-mat-select-search [formControl]="customersMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Status not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{customers.value.length > 0 ? customers.value[0].code : ''}}
            <span *ngIf="customers.value[0]  && customers.value.length > 1" class="example-additional-selection">
              (+{{(customers.value.length || 0) - 1}} {{customers.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(customers)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
            {{customer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <div class="action-buttons">
      <button mat-button class="small-button" (click)="applyFilters()">Apply Filters</button>

      <button class="small-button soft-blue" (click)="clearAllFilters()">
        Clear All Filters
      </button>
    </div>

  </div>
</div>

