import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilterData } from '../models/list-model';
import { ShipmentService } from '../service/shipment/shipment.service';
import { DatePipe } from '@angular/common';
import { LookupService } from '../service/lookup/lookup.service';
import { LookupModel } from '../models/lookup-model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DataFilter } from '../models/filter-models';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { ApiUserService } from '../service/user/api-user.service';
import { RoadDataService } from '../service/roads/road-data.service';
import { TransportMode } from '../enums/transport-mode.enum';

@Component({
  selector: 'app-dashboard-tracking-filter',
  templateUrl: './dashboard-tracking-filter.component.html',
  styleUrl: './dashboard-tracking-filter.component.css'
})
export class DashboardTrackingFilterComponent implements OnInit {
  @Input() transportMode: TransportMode | undefined;
  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;

  private _snackBar = inject(MatSnackBar);
  markets = new FormControl('');
  carriers = new FormControl<LookupModel[]>([]);
  containerNos = new FormControl('');
  grRefNos = new FormControl('');
  startDate = new FormControl('');
  endDate = new FormControl('');

  carriersMultiFilter = new FormControl<string>('');
  filteredCarriers!: Observable<LookupModel[]>;
  customers = new FormControl<LookupModel[]>([]);
  status = new FormControl<LookupModel[]>([]);
  consignees = new FormControl<LookupModel[]>([]);
  filteredConsignees!: Observable<LookupModel[]>;

  transportModes = new FormControl<LookupModel[]>([]);
  customersMultiFilter = new FormControl<string>('');
  consigneesMultiFilter = new FormControl<string>('');
  transportModesMultiFilter = new FormControl<string>('');
  filteredCustomers!: Observable<LookupModel[]>;
  filteredTransportModes!: Observable<LookupModel[]>;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  //Store filter data
  filterData: FilterData = {};
  savedFilterName = new FormControl('');
  datafilter: DataFilter = new DataFilter();
  filterName: string = '';
  savedFilters: DataFilter[] = [];

  customersData: LookupModel[] = [];
  transportModeData: LookupModel[] = [];
  shipmentDataCarrier: LookupModel[] = [];
  shipmentDataConsignee: LookupModel[] = [];
  shipmentDataMarket: string[] = [];
  shipmentDataStartDate: string[] = [];
  checkedItems: Set<string> = new Set<string>();

  constructor(private domSanitizer: DomSanitizer, private shipmentDataService: ShipmentService, private roadDataService: RoadDataService, private lookupService: LookupService, private cdr: ChangeDetectorRef, private datePipe: DatePipe, private matIconRegistry: MatIconRegistry) {
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
  }

  ngOnInit(): void {
    this.loadShipmentData();
  }

  loadShipmentData(): void {

    this.shipmentDataService.sendActiveFilterToFilter$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.datafilter = data;
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Filter data', error)
      });

    this.lookupService.getCustomers(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.customersData = data;
          this.customers.setValue(this.customersData.filter(customer => customer.isSelected));
          this.filteredCustomers = this.customersMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCustomers(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

    this.lookupService.getCarriers(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.shipmentDataCarrier = data;
          this.carriers.setValue(this.shipmentDataCarrier.filter(port => port.isSelected));
          this.filteredCarriers = this.carriersMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCarriers(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Carrier data', error)
      });

    this.lookupService.getConsignees(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.shipmentDataConsignee = data;
          this.consignees.setValue(this.shipmentDataConsignee.filter(c => c.isSelected));
          this.filteredConsignees = this.consigneesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterConsignees(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Consignee data', error)
      });

    this.lookupService.getModesOfTransport(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.transportModeData = data;
          this.transportModes.setValue(this.transportModeData.filter(filter => filter.isSelected));
          this.filteredTransportModes = this.transportModesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterTransportModes(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

    let grRefNosRestore = this.datafilter.filter['grRefNo']?.toString() ?? null;
    if (grRefNosRestore != null) {
      this.grRefNos.setValue(grRefNosRestore);
    }

    let containerNosRestore = this.datafilter.filter['containerNo']?.toString() ?? null;
    if (containerNosRestore != null) {
      this.containerNos.setValue(containerNosRestore);
    }

    if (this.datafilter.id !== 0) {
      this.filterName = this.datafilter.filterName;
      this.savedFilterName.setValue(this.filterName);
    }

  }

  save(): void {
    if (!this.savedFilterName.value) {
      console.error('Filter name cannot be empty');
      return;
    }

    this.createFilter();
    this.filterName = this.savedFilterName.value.toString();
    this.shipmentDataService.saveBookingFilter(this.filterName, this.filterData).subscribe(_ => {
      this.shipmentDataService.filterNameSavedSend(this.filterName);
      this.shipmentDataService.getFilters().subscribe(result => {
        this.savedFilters = result;
        const savedFil = this.savedFilters.find(f => f.filterName === this.filterName);
        this.datafilter.id = savedFil!.id;
        this.datafilter.filterName = savedFil!.filterName;
        this.datafilter.filter = savedFil!.filter;
      });
    });
    this.openSnackBar('Your filter ' + this.filterName + ' has been created');
  }

  createFilter(): void {
    if (this.customers.value != null) {
      const customerCodes = this.parseFilterSelections(this.customers.value);
      this.processFilterData('customerCode', customerCodes);
    }
    if (this.containerNos.value != null) {
      this.processFilterData('containerNo', this.containerNos.value?.toString());
    }
    if (this.consignees.value != null) {
      const consigneeCodes = this.parseFilterSelections(this.consignees.value);
      this.processFilterData('consigneeCode', consigneeCodes);
    }
    if (this.grRefNos.value != null) {
      this.processFilterData('grRefNo', this.grRefNos.value?.toString());
    }
    if (this.carriers.value != null) {
      const carrierCodes = this.parseFilterSelections(this.carriers.value);
      this.processFilterData('carrierCode', carrierCodes);
    }
    this.shipmentDataService.transferFilterData(this.filterData);
  }

  deleteFilter(): void {
    this.shipmentDataService.deleteShipmentFilter(this.datafilter.id, this.datafilter.filterName).subscribe(result => {
      this.shipmentDataService.filterNameSavedSend('');
      this.savedFilterName.setValue('');
      this.datafilter.id = 0;
      this.datafilter.filterName = '';
    });
  }

  applyFilters(): void {
    this.createFilter();
    this.roadDataService.transferFilterData(this.filterData);
    this.openSnackBar('Your filter has been applied');
  }

  clearAllFilters(): void {
    this.clearLookupFilter(this.customers);
    this.clearLookupFilter(this.transportModes);
    this.clearLookupFilter(this.carriers);
    this.clearLookupFilter(this.consignees);
    this.clearLookupFilter(this.markets);
    this.clearFilter(this.grRefNos);
    this.clearFilter(this.containerNos);
    this.applyFilters();
    this.openSnackBar('Your filters have been cleared');
  }

  clearFilter(control: FormControl) {
    control.reset();
    control.setValue([]);
  }

  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
  }

  private filterCarriers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.shipmentDataCarrier;
    }
    return this.shipmentDataCarrier.filter(Carrier =>
      Carrier.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterConsignees(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.shipmentDataConsignee;
    }
    return this.shipmentDataConsignee.filter(c =>
      c.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  get isSavedFilterNameEmpty(): boolean {
    return !this.savedFilterName.value;
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  parseFilterSelections(selections: LookupModel[]): string {
    const codes: string[] = selections.map(selection => selection.code);
    return codes.join(',');
  }

  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');

    this.filterData[key] = dataArray;
  }

  private filterCustomers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.customersData;
    }
    return this.customersData.filter(customer =>
      customer.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterTransportModes(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.transportModeData;
    }
    return this.transportModeData.filter(transportMode =>
      transportMode.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }
}
