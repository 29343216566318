import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommunicationService } from '../service/communication/communication.service';
import { BookingService } from '../service/booking/booking.service';
import { AddBookingEnum, BookingModel } from '../models/booking-data-model';
import { Alert } from '../models/alerts/alerts';
import { detailTabbedViewEnum } from '../models/detail-view-enum';
import { ScheduleLinesModel } from '../models/schedules/schedule-lines-model';
import { ScheduleListModel } from '../models/schedules/schedule-list-model';
import { ScheduleVoyageCoordinates } from '../models/schedules/schedule-voyage-coordinates';
import { AlertService } from '../service/alerts/alert.service';
import { SchedulesService } from '../service/schedules/schedules.service';
import L from 'leaflet';
import { BookingDataTransferModel } from '../models/booking-data-model';
import { Router } from '@angular/router';
import { BookingHeaderModel } from '../models/bookings/booking-header-model';
import { BookingLineModel } from '../models/bookings/booking-line-model';
import { AuthService } from '../service/auth-service/auth-service.service';
import { ClaimAction } from '../enums/keycloak-claim-action';
import { CommonService } from '../service/common/common.service';
import { TableUtil } from '../service/utils/excel-export-service.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-booking-detailed-view',
  templateUrl: './booking-detailed-view.component.html',
  styleUrl: './booking-detailed-view.component.css',
})
export class BookingDetailedViewComponent implements OnInit, AfterViewInit {

  private bookingParameter: string = '';
  public booking = new BookingHeaderModel;
  public bookingLines: BookingLineModel[] = [];

  detailTabbedViewEnum = detailTabbedViewEnum
  detailTabbedViewEnumSelected = detailTabbedViewEnum.detailsTab
  @Input() selectedTab = this.detailTabbedViewEnumSelected
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  private map: any;
  private routePolyline: any;
  alertData: Alert[] = [];
  public scheduleLines: ScheduleLinesModel[] = [];
  private voyageCoordinates: ScheduleVoyageCoordinates[] = [];
  private sheduleParameter: string = '';
  public voyage = new ScheduleListModel();
  bookingTransfer: BookingDataTransferModel = new BookingDataTransferModel();
  ClaimAction = ClaimAction; // Expose the enum to the template
  constructor(private bookingService: BookingService,
    private schedulesService: SchedulesService,
    private alertDataService: AlertService,
    private commonService: CommonService,
    private communicationService: CommunicationService,
    public authService: AuthService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,) {
    this.matIconRegistry.addSvgIcon(
      'export',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Export-Icon.svg'));
  }

  ngOnInit(): void {
    this.communicationService.detailViewParam.subscribe(param => { this.bookingParameter = param });
    this.getBookingDetail(this.bookingParameter);
    this.getBookingLines(this.bookingParameter);
    // this.getAlertsForGRRef(this.bookingParameter);

    // Subscribe to handle detailed view toggle
    this.communicationService.detailedViewToggle$.subscribe(d => {
      this.selectedTab = d.detailTabbedViewEnum;
    });

    console.log('Booking Parameter:', this.bookingParameter);  // Check if this is correct

  }


  ngAfterViewInit(): void {
  }

  private initMap(): void {

    this.map = L.map('map').setView([this.voyageCoordinates[0].latitude, this.voyageCoordinates[0].longitude], 2);

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    // Add route polyline
    this.routePolyline = L.polyline(this.voyageCoordinates.map(p => [p.latitude, p.longitude]), { color: '#162a3e', dashArray: '5, 10' }).addTo(this.map);

    tiles.addTo(this.map);

  }

  private getBookingDetail(bookingHeaderCode: string): void {
    this.bookingService.getBooking(bookingHeaderCode).subscribe(data => {
      this.booking = data

      console.log(data);
    }

    )
  }

  private getBookingLines(bookingHeaderCode: string): void {
    this.bookingService.getBookingLines(bookingHeaderCode).subscribe(data => { this.bookingLines = data })
  }

  private getAlertsForGRRef(alertParameter: string): void {
    this.alertDataService.getAlertsForReference('Booking', alertParameter)
      .subscribe(data => { this.alertData = data })
    console.log(this.alertData);
  }

  public addBookingLine(): void {
    this.bookingTransfer = {
      appBookingCode: this.booking.appBookingCode,
      appBookingLineNumber: null,
      scheduleCode: this.booking.scheduleCode,
      loadPortCode: null,
      dischargePortCode: null,
      carrierCode: null,
      bookingType: AddBookingEnum.addBookingLine
    }

    this.router.navigate(['/add-booking'], { state: { data: this.bookingTransfer } });
    this.closeRightDrawer();
  }

  public getPermissionTooltip(permission: ClaimAction): { toolTipMessage: string; timeOut: number } {
    return this.commonService.getNoPermissionTooltipMessage(permission);
  }


  closeRightDrawer() {
    this.communicationService.closeRightNav();
  }

  exportTable() {
    const dateFormatter = new Intl.DateTimeFormat(undefined, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    const headerData = {
      'Customer': this.booking.customerCode,
      'GoReefers Reference': this.booking.grRefNo,
      'Booking Status': this.booking.appBookingStatus,
      'Load Port': this.booking.loadPort,
      'Stack Start': this.booking.stackStart ? dateFormatter.format(new Date(this.booking.stackStart)) : '',
      'Stack End': this.booking.stackEnd ? dateFormatter.format(new Date(this.booking.stackEnd)) : '',
      'Vessel Name': this.booking.vesselName,
      'Carrier': this.booking.carrier,
      'Voyage Number': this.booking.voyageNumber,
      'Service Code': this.booking.serviceCode,
      'Load ETD': this.booking.sailDate ? dateFormatter.format(new Date(this.booking.sailDate)) : '',
    }

    const lineData = this.bookingLines.map(bookingLine => ({
      'Booking Reference': bookingLine.bookingRef,
      'Client Reference': bookingLine.clientRef,
      'Container Status': bookingLine.containerStatus,
      'Carrier Booking Status': bookingLine.appBookingStatus,
      'Discharge Port': bookingLine.dischargePort,
      'Final Destination': bookingLine.finalDestination,
      'Confirmed Pack Date': bookingLine.confirmedPackDate ? dateFormatter.format(new Date(bookingLine.confirmedPackDate)) : '',
      'Loading Point 1': bookingLine.loadingPoint1,
      'Loading Point 2': bookingLine.loadingPoint2,
      'Commodity': bookingLine.commodity,
      'Temperature': bookingLine.temperature,
      'Quantity': bookingLine.quantity,
      'Late Stack Request': bookingLine.lateStackRequest,
      'ETA': bookingLine.eta ? dateFormatter.format(new Date(bookingLine.eta)) : '',
    }));

    TableUtil.exportHeaderAndLinesToExcel(lineData, this.booking.grRefNo, headerData);
  }
}
