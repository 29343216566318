import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverpassService {
  private overpassUrl = 'https://overpass-api.de/api/interpreter';

  constructor(private http: HttpClient) { }

  // Updated to accept bounding box parameters
  getSeaRoutes(bbox: string): Observable<any> {
    // Modified query with dynamic bounding box
    const query = `[out:json];(way["route"="ferry"](${bbox});way["natural"="coastline"](${bbox}););out geom;`;

    // Send the query to the Overpass API
    return this.http.post(this.overpassUrl, query, { responseType: 'json' });
  
  }
}
