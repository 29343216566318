<div class="modhyobitto-file-uploader" dragAndDrop (onFileDropped)="selectFiles($event)">
  <div class="file-uploader__instructions">
    Drag and drop the file(s) here or click on "Browse Files". 
    (25 MB limit)
  </div>
  <div class="files-for-upload">
    <mat-expansion-panel *ngFor="let selected_file of selected_files; index as i"
                         class="selected-file" hideToggle disabled
                         expanded="{{!!selected_file.upload_result}}">
      <mat-expansion-panel-header style="display: flex; justify-content: space-between; flex-direction: row;">
        <div class="selected-file__header" style=" width: -webkit-fill-available; justify-content: space-between; ">
          <div class="selected-file__name" style="padding: 12px 16px;">
            {{selected_file.file.name}}
          </div>
          <div class="selected-file__actions">
            <button matRipple
                    class="selected_file__cancel secondary-button"
                    matTooltip="Cancel"
                    matTooltipPosition="left"
                    aria-label="Cancel File"
                    (click)="inititateFileCancel(selected_file.file.name)">
              <span aria-hidden="true" class="material-icons">clear</span>
            </button>
          </div>
        </div>
        <mat-progress-bar *ngIf="selected_file.is_upload_in_progress" mode="indeterminate"></mat-progress-bar>
      </mat-expansion-panel-header>
      <div *ngIf="selected_file.upload_result=='success'"
           class="selected-file__upload-result">
        The file was uploaded successfully.
      </div>
      <div *ngIf="!!selected_file.upload_result && selected_file.upload_result!='success'"
           class="selected-file__upload--error">
        {{selected_file.upload_result}}
      </div>
    </mat-expansion-panel>
  </div>
  <div class="file-upload__options">
    <form class="file-upload__native-selection"
          [formGroup]="file_selection_form">
      <input type="file" #fileSelector
             formControlName="file_selection"
             [accept]="config.MIME_types_accepted"
             [multiple]="config.is_multiple_selection_allowed">
    </form>
    <button type="button" matRipple
            class="file-upload__browse primary-button"
            (click)="openFileSelector()">
      Browse Files
    </button>
    <ng-container *ngIf="selected_files.length>1">
      <button matRipple
              class="browse_file__cancel secondary-button"
              matTooltip="Cancel All"
              matTooltipPosition="above"
              aria-label="Cancel All Files"
              (click)="initiateCancelAll()">
        <span aria-hidden="true" class="material-icons">clear</span>
      </button>
    </ng-container>
  </div>
</div>
