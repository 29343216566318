import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router, private keycloakService: KeycloakService) { }

  hasRequiredRolesForRoute(route: string): boolean {
    const routeConfig = this.router.config.find(r => r.path === route);
    const requiredRoles = routeConfig?.data?.['roles'] || [];
    return requiredRoles.some((role: string) => this.keycloakService.isUserInRole(role));
  }

  hasClaim(claim: string): boolean {
    return this.keycloakService.isUserInRole(claim);
  }


}
