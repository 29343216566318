<div style="border-radius: 4px; overflow: hidden;">
  <div class="dashboard-tracking-container">
    <div class="col-md-12 row" style="gap: 12px; align-items: center;">
      <div>
        <!-- Dynamically display active shipments or active trucks -->
        <div>{{ activeLabel }} | Total {{ activeCount }}</div>
      </div>
      <div class="col-md-4">
        <mat-button-toggle-group name="favoriteColor"
                                 aria-label="Favorite Color"
                                 [hideSingleSelectionIndicator]="true"
                                 class="tab-menu"
                                 [(ngModel)]="selectedTab"
                                 (change)="onTabChange()">

          <mat-button-toggle class="tab-menu-item" [value]="modeTabbedViewEnum.Ocean">Ocean</mat-button-toggle>
          <!--<mat-button-toggle class="tab-menu-item" [value]="modeTabbedViewEnum.Air" disabled>Air</mat-button-toggle>-->
          <mat-button-toggle class="tab-menu-item" [value]="modeTabbedViewEnum.Road">Road</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="dashboard-filter-btn-wrapper">
        <div class="filter-btn-wrapper">
          <button mat-button class="filter-btn dashboard-filter-icon-button" (click)="onOpenFilter(FilterEnum.dashboardTrackingFilter)">
            <div class="filter-icon-wrap">
              <div class="filter-icon dashboard-filter-icon"></div>
            </div>
          </button>
        </div>
      </div>
    </div>

  </div>

  <app-tracking-map [mode]="transportMode"
                    [startLocation]="startLocation"
                    [endLocation]="endLocation"
                    [vesselDataList]="vesselDataList"
                    [shipmentDataList] ="shipmentDataList"
                    [vehicles]="roadDataList"
                    [mapView]="mapView"
                    [legend]="legend"
                   >
  </app-tracking-map>

</div>
