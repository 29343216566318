// src/app/services/road-data.service.ts
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { RoadData } from '../../models/road-data-model';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { DataFilter, FilterTransferModel } from '../../models/filter-models';
import { FilterData } from '../../models/list-model';

@Injectable({
  providedIn: 'root'
})
export class RoadDataService {

  private apiUrl = environment.apiUrl;
  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private filterDataTransfer = new BehaviorSubject<FilterData>({});
  filterData$ = this.filterDataTransfer.asObservable();

  private statusFilterSubject = new BehaviorSubject<string | null>(null);
  statusFilterToggle$ = this.statusFilterSubject.asObservable();

  private sendFilter = new BehaviorSubject<string | null>(null);
  sendFilter$ = this.sendFilter.asObservable();

  private filterNameSaved = new BehaviorSubject('');
  filterNameSaved$ = this.filterNameSaved.asObservable();

  private sendFilterId = new BehaviorSubject<number>(-1);
  sendFilterId$ = this.sendFilterId.asObservable()

  private sendActiveFilterToFilter = new BehaviorSubject<DataFilter>(new DataFilter());
  sendActiveFilterToFilter$ = this.sendActiveFilterToFilter.asObservable();

  private sendFilterTransfer = new BehaviorSubject<FilterTransferModel>(new FilterTransferModel(-1, {}));
  sendFilterTransfer$ = this.sendFilterTransfer.asObservable();

  constructor(private http: HttpClient) { }

  transferFilterData(filterData: FilterData) {
    this.filterDataTransfer.next(filterData);
    console.log("service filter data", filterData);

  }

  getRoadData(
    filterId?: number,
    filterData?: FilterData,
    dateFrom?: string,
    dateTo?: string,
    customerCode?: string
  ): Observable<RoadData[]> {
    const url = this.apiUrl + '/RoadData/GetRoadData';
    let params = new HttpParams();

    // Check if filterData is provided and contains any non-empty arrays
    const hasValidFilterData = filterData && Object.values(filterData).some(value => value && value.length > 0);

    // If filterData is empty (all arrays are empty), return the full list
    if (!hasValidFilterData) {
      return this.http.get<RoadData[]>(url);
    }

    // Add filterId if provided and not empty
    if (filterId !== undefined && filterId !== null) {
      params = params.set('filterId', filterId.toString());
    }

    // Add filterData if provided and not empty
    if (filterData && Object.keys(filterData).length > 0) {
      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);
        params = params.set('filter', jsonData);
      }
    }

    // Add dateFrom if provided and not empty
    if (dateFrom) {
      params = params.set('dateFrom', dateFrom.toString());
    }

    // Add dateTo if provided and not empty
    if (dateTo) {
      params = params.set('dateTo', dateTo.toString());
    }

    // Add customerCode if provided and not empty
    if (customerCode) {
      params = params.set('customerCode', customerCode);
    }

    // If all params are empty, return the full list
    if (params.keys().length === 0 ||
      Array.from(params.keys()).every(key => !params.get(key))) {
      return this.http.get<RoadData[]>(url);
    }

    // Otherwise, return data with the set parameters
    return this.http.get<RoadData[]>(url, { params });
  }

}
