import { Component, Input, OnInit } from '@angular/core';
import { ScheduleRouteLinesModel } from '../models/schedules/schedule-route-lines-model';

@Component({
  selector: 'app-schedules-details-tab',
  templateUrl: './schedules-details-tab.component.html',
  styleUrls: ['./schedules-details-tab.component.css']
})
export class SchedulesDetailsTabComponent implements OnInit {
  @Input() scheduleRouteLines!: ScheduleRouteLinesModel[];

  // Property to hold the hierarchical structure
  hierarchicalScheduleLines: any[] = [];

  displayedColumns = [
    ['portType', '', ''],
    ['location', 'Port Name', ''],
    ['eta', 'ETA', 'Estimated time of arrival.'],
    ['etd', 'ETD', 'Estimated time of discharge.'],
    ['duration', 'Arrival Days', 'Number of days before ETA.'],
  ];

  ngOnInit() {
  }


  // Get value for a specific column in a row
  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  // Format ETA or ETD into a readable date format
  public getTimeDifference(eta: string | null): string {
    const today = new Date();

    if (eta) {
      const etaDate = new Date(eta);
      const timeDifference = etaDate.getTime() - today.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

      if (daysDifference === 0) {
        return 'Today';
      } else if (daysDifference > 0) {
        return `In ${daysDifference} day${daysDifference !== 1 ? 's' : ''}`;
      } else {
        return `${Math.abs(daysDifference)} day${Math.abs(daysDifference) !== 1 ? 's' : ''} ago`;
      }
    } else {
      return 'N/A';
    }
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }
}
