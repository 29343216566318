import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, inject, Input } from '@angular/core';
import { SchedulesService } from '../service/schedules/schedules.service';
import { IScheduleListModel } from '../models/schedules/schedule-list-model';
import { CommunicationService } from '../service/communication/communication.service';
import { FilterData } from '../models/list-model';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { ColumnSetup } from '../models/column-setup';
import { LookupService } from '../service/lookup/lookup.service';
import { LookupModel } from '../models/lookup-model';
import { Observable, Subject, of } from 'rxjs';
import { startWith, map, takeUntil, take } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DataFilter } from '../models/filter-models';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-schedules-filter',
  templateUrl: './schedules-filter.component.html',
  styleUrls: ['./schedules-filter.component.css', '../filter-sidenav/filter-sidenav.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class SchedulesFilterComponent implements OnInit {


  private apiUserService = inject(ApiUserService);
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;

  private _snackBar = inject(MatSnackBar);

  savedFilterName = new FormControl('', [Validators.maxLength(20)]);

  filterData: FilterData = {};
  filterName: string = '';
  datafilter: DataFilter = new DataFilter();
  savedFilters: DataFilter[] = [];

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private domSanitizer: DomSanitizer, private schedulesService: SchedulesService, private lookupService: LookupService, private cdr: ChangeDetectorRef, private dialog: MatDialog, private communicationService: CommunicationService, private matIconRegistry: MatIconRegistry) {
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
  }

  ngOnInit(): void {
    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;
    this.loadScheduleData();

    this.savedFilterName.statusChanges.subscribe(() => {
      if ((this.savedFilterName.value?.length ?? 0) > 20) {
        this.savedFilterName.markAsTouched();
      }
    });
  }

  loadScheduleData(): void {

    this.schedulesService.sendActiveFilterToFilter$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.datafilter = data;
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Filter data', error)
      });

    if (this.datafilter.id !== 0) {
      this.filterName = this.datafilter.filterName;
      this.savedFilterName.setValue(this.filterName);
    }
  }


  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');

    this.filterData[key] = dataArray;

    console.log(this.filterData);
  }

  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
    console.log('Filter cleared:', control);
  }

  save(): void {
    if (!this.savedFilterName.value) {
      // Optionally, you can show an error message here
      console.error('Filter name cannot be empty');
      return;
    }

    if (this.datafilter.filter &&
      (!this.datafilter.filter['countryCode'] || this.datafilter.filter['countryCode']?.length === 0) &&
      (!this.datafilter.filter['dischargePortCode'] || this.datafilter.filter['dischargePortCode']?.length === 0)) {
      this.openSnackBar('You need to select either a Country or a Discharge Port before saving a filter.', 'snackbar-fail');
      return;
    };

    this.createFilter();
    this.filterName = this.savedFilterName.value.toString();
    this.schedulesService.saveScheduleFilter(this.filterName, this.filterData).subscribe(_ => {
      this.schedulesService.filterNameSavedSend(this.filterName);
      this.schedulesService.getFilters().subscribe(result => {
        this.savedFilters = result;
        const savedFil = this.savedFilters.find(f => f.filterName === this.filterName);
        this.datafilter.id = savedFil!.id;
        this.datafilter.filterName = savedFil!.filterName;
        this.datafilter.filter = savedFil!.filter;
        this.closeRightDrawer();
      });
    });
    this.openSnackBar('Your filter ' + this.filterName + ' has been created');
  }


  clearFilter(control: FormControl) {
    control.reset();
    control.setValue([]);
  }

  get isSavedFilterNameEmpty(): boolean {
    return !this.savedFilterName.value;
  }

  openSnackBar(message: string, panelClass: string = 'snackbar-success') {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: [panelClass]
    });
  }

  createFilter(): void {
    if (this.datafilter.filter['loadPortCode'] && this.datafilter.filter['loadPortCode']?.length > 0) {
      const countryCodes = this.datafilter.filter['loadPortCode'].join(',') || '';
      this.processFilterData('loadPortCode', countryCodes);
    }

    if (this.datafilter.filter['dischargePortCode'] && this.datafilter.filter['dischargePortCode']?.length > 0) {
      const countryCodes = this.datafilter.filter['dischargePortCode'].join(',') || '';
      this.processFilterData('dischargePortCode', countryCodes);
    }

    if (this.datafilter.filter['carrierCode'] && this.datafilter.filter['carrierCode']?.length > 0) {
      const countryCodes = this.datafilter.filter['carrierCode'].join(',') || '';
      this.processFilterData('carrierCode', countryCodes);
    }

    if (this.datafilter.filter['stackStatus'] && this.datafilter.filter['stackStatus']?.length > 0) {
      const countryCodes = this.datafilter.filter['stackStatus'].join(',') || '';
      this.processFilterData('stackStatus', countryCodes);
    }

    if (this.datafilter.filter['countryCode'] && this.datafilter.filter['countryCode']?.length > 0) {
      const countryCodes = this.datafilter.filter['countryCode'].join(',') || '';
      this.processFilterData('countryCode', countryCodes);
    }
  }

  parseFilterSelections(selections: LookupModel[]): string {
    const codes: string[] = selections.map(selection => selection.code);
    return codes.join(',');

  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.datafilter.id = id;
    this.datafilter.filterName = name;
    this.datafilter.filter = filter;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //TODO: Fine a way to get this window to close without forcing it closed using CDR
  //Application : When a user clicks on apply filter this filter side nav needs to close
  //Scale: P3 None Critical
  closeRightDrawer() {
    this.communicationService.closeRightNav();
  }
}
