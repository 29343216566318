<div>
  <h4 class="popup-title">Shipment Details</h4>
  <p class="popup-summary">
    <span><strong>Vessel:</strong> {{ vesselName || 'Unknown' }}</span>
    <span><strong>ETA:</strong> {{ formattedPackDate || 'N/A' }}</span>
    <span><strong>Containers:</strong> {{ noOfContainers || 'Unknown' }}</span>
  </p>
  <div class="popup-table-container">
    <table class="popup-table">
      <thead>
        <tr>
          <th>Container No</th>
          <th>Consignee</th>
          <th>Destination</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container >
          <tr *ngFor="let container of containersForVessel; let index = index">
            <td>{{ container.containerNumber || 'N/A' }}</td>
            <td>{{ container.consignee || 'N/A' }}</td>
            <td>{{ container.destination || 'N/A' }}</td>
            <td>
              <button class="view-detail-btn"
                      id="view-detail-btn-{{ vesselIndex }}-{{ index }}">
                View Detail
              </button>
            </td>
          </tr>
        </ng-container>
        <ng-template #noData>
          <tr>
            <td colspan="4" class="no-data">No containers available</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
</div>
