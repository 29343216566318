import { DocumentStatus } from '../../enums/document-status.enum';

export interface IShipmentModel {
  id: string;
  shippingUnitCode: string;
  lineNumber: number;
  grReference: string;
  customerCode: string;
  customer: string;
  consigneeCode: string;
  consignee: string;
  containerNumber: string;
  bookingReference: string;
  dischargePortCode: string;
  destination: string;
  eta: Date;
  etd: Date;
  loadPortCode: string;
  loadPortName: string;
  scheduleCode: string;
  carrierCode: string;
  carrierName: string;
  vesselVoyage: string;
  vesselCode: string;
  vesselName: string;
  dateCreated: Date;
  createdBy: string;
  lastModifiedDateTime: Date;
  controlSheetFinalised: boolean;
  daysPriorToETA: number;
  trackContainer: boolean;
  seaRatesVesselName: string;
  seaRatesVesselCode: string;
  currentLatitude: number;
  currentLongitude: number;
  containerTrackingETA: Date;
  shipmentStatusColor: string;
  indicators:string;
  hasSubscriptions: boolean;
  hasAlerts: boolean;
  DocumentStatus: DocumentStatus;
  confirmedPackDate: Date;
  originalETA: Date;
  originalETD: Date;
  shipmentStatus: string;
  delay: number;
  clientReference: string;
}


export class ShipmentModel implements IShipmentModel {
  id: string = '';
  shippingUnitCode: string = '';
  lineNumber: number = 0;
  grReference: string = '';
  customerCode: string = '';
  customer: string = '';
  consigneeCode: string = '';
  consignee: string = '';
  containerNumber: string = '';
  bookingReference: string = '';
  dischargePortCode: string = '';
  destination: string = '';
  eta: Date = new Date;
  etd: Date = new Date;
  loadPortCode: string = '';
  loadPortName: string = '';
  scheduleCode: string = '';
  carrierCode: string = '';
  carrierName: string = '';
  vesselVoyage: string = '';
  vesselCode: string = '';
  vesselName: string = '';
  dateCreated: Date = new Date;
  createdBy: string = '';
  lastModifiedDateTime: Date = new Date;
  controlSheetFinalised: boolean = false;
  daysPriorToETA: number = 0;
  trackContainer: boolean = true;
  seaRatesVesselName: string ='';
  seaRatesVesselCode: string = '';
  currentLatitude: number = 0;
  currentLongitude: number = 0;
  containerTrackingETA: Date = new Date(0, 0, 0);
  confirmedPackDate: Date = new Date(0, 0, 0);
  originalETA: Date = new Date(0, 0, 0);
  originalETD: Date = new Date(0, 0, 0);
  shipmentStatus: string = '';
  delay: number = 0;
  clientReference: string = '';

  shipmentStatusColor: string = '';
  indicators: string = '';
  hasSubscriptions: boolean = false;
  hasAlerts: boolean = false;
  DocumentStatus: DocumentStatus = DocumentStatus.Unknown;
}


export interface IShipmentTranferGRRefContainerNo {
  grReferenceNo: string | null;
  containerNo: string | null;
}

export class ShipmentTranferGRRefContainerNo implements IShipmentTranferGRRefContainerNo {
  grReferenceNo: string | null = null;
  containerNo: string | null = null;
}

export enum shipmentTabbedViewEnum {
  none,
  oceanTab,
  roadTab,
  oceanOverviewMapTab
}

export interface IShipmentDataTransferModel {
 startDate: Date | null;
  endDate: Date | null;
  scheduleCode: string | null;
  loadPortCode: string | null;
  dischargePortCode: string | null;
  carrierCode: string | null;

}

export class ShipmentDataTransferModel implements IShipmentDataTransferModel {
  startDate: Date | null = null;
  endDate: Date | null = null;
  scheduleCode: string | null = null;
  loadPortCode: string | null = null;
  dischargePortCode: string | null = null;
  carrierCode: string | null = null;
}







