import * as XLSX from "xlsx";

export class TableUtil {
  static exportArrayToExcel(arr: any[], name?: string) {
    const getFileName = (name: string) => {
      let timeSpan = new Date().toISOString();
      let sheetName = name || "ExportResult";
      let fileName = `${sheetName}-${timeSpan}`;
      return {
        sheetName,
        fileName
      };
    };
    let { sheetName, fileName } = getFileName(name ?? '');

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(arr);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  static exportHeaderAndLinesToExcel(
    lineData: any[],
    fileName: string,
    headerData?: { [key: string]: any },
    customLineHeaders?: { [key: string]: string }
  ) {
    const getFileName = (name: string) => {
      let timeSpan = new Date().toISOString();
      let sheetName = "Result";
      let excelFileName = `${fileName}-${timeSpan}`;
      return {
        sheetName,
        excelFileName
      };
    };

    const { sheetName, excelFileName } = getFileName(fileName ?? "");

    const headerRows = headerData
      ? Object.entries(headerData).map(([key, value]) => [key, value])
      : [];

    const tableHeaders = lineData.length > 0
      ? Object.keys(lineData[0]).map(key => customLineHeaders?.[key] || key)
      : [];

    const tableRows = lineData.map(item =>
      Object.keys(item).map(key => item[key])
    );

    const aoaData = [
      ...(headerRows.length > 0 ? [...headerRows, []] : []),
      tableHeaders,
      ...tableRows
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(aoaData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    XLSX.writeFile(workbook, `${excelFileName}.xlsx`);
  }

  static exportToExcel(dataSource: any[], displayedColumns: { name: string; header: string; type?: string; visible: boolean }[], fileName: string) {
    const dateFormatter = new Intl.DateTimeFormat(undefined, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    const columnsToExport = displayedColumns
      .filter(column => column.name !== 'actions' && column.visible && column.header !== '')
      .map(column => ({ name: column.name, header: column.header, type: column.type }));

    const exportData = dataSource.map(item => {
      const filteredItem: Record<string, any> = {}; // Use a flexible object type for dynamic keys
      columnsToExport.forEach(column => {
        let value = (item as any)[column.name]; // Access property dynamically

        // Format date fields manually if the type is 'date'
        if (column.type === 'date' && value) {
          const date = new Date(value);
          value = dateFormatter.format(date);
        }

        // Use the header value as the key in the exported data
        filteredItem[column.header] = value;
      });
      return filteredItem;
    });

    TableUtil.exportHeaderAndLinesToExcel(exportData, fileName);
  }
}

