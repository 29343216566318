import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { RollingPlan } from '../models/bookings/rolling-plan-model';
import { detailTabbedViewEnum } from '../models/detail-view-enum';
import { BookingService } from '../service/booking/booking.service';
import { CommunicationService } from '../service/communication/communication.service';
import { Router } from '@angular/router';
import { ShipmentService } from '../service/shipment/shipment.service';
import { RoadDataService } from '../service/roads/road-data.service';
import { FilterData } from '../models/list-model';
import { RoadData } from '../models/road-data-model';
import { TransportLoad } from '../models/shipment/transport-loads-model';
import { filter } from 'rxjs';

@Component({
  selector: 'app-rolling-plan-detailed-view',
  templateUrl: './rolling-plan-detailed-view.component.html',
  styleUrl: './rolling-plan-detailed-view.component.css'
})
export class RollingPlanDetailedViewComponent implements OnInit, AfterViewInit {
  private bookingParameter: string = '';
  private rollingPlanLineParam: string = '';
  public rollingPlan: RollingPlan = new RollingPlan();
  filterData: FilterData = {};
  // public bookingLines: BookingLineModel[] = [];

  detailTabbedViewEnum = detailTabbedViewEnum
  detailTabbedViewEnumSelected = detailTabbedViewEnum.trackingTab
  @Input() selectedTab = this.detailTabbedViewEnumSelected
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  private map: any;
  private routePolyline: any;
  roadDataList: RoadData[] = [];
  transportDataList: TransportLoad[] = [];

  displayedColumns = [
    ['loadNo', 'Load Number', 'Transport reference number assigned to a load.'],
    ['trackingStatus', 'Tracking Status', 'Latest status update received on transport load.'],
    ['trackingStatusDateTime', 'Date & Time', 'Date and time latest status was received.'],
  ];

  constructor(private shipmentService: ShipmentService, private communicationService: CommunicationService, private router: Router, private cdf: ChangeDetectorRef, private roadDataService: RoadDataService) { }

  ngOnInit(): void {
    this.communicationService.detailViewParam.subscribe(param => { this.bookingParameter = param });
    this.communicationService.rollingPlanParam.subscribe(param => { this.rollingPlanLineParam = param });

    // Subscribe to handle detailed view toggle
    this.communicationService.detailedViewToggle$.subscribe(d => {
      this.selectedTab = d.detailTabbedViewEnum;
    });

    this.getRollingPlan(this.bookingParameter, this.rollingPlanLineParam);

    console.log('Booking Parameter:', this.bookingParameter);  // Check if this is correct

  }

  fetchRoadData(filterId?: number, roadFilter?: FilterData, startDate?: Date, endDate?: Date): void {
    const startDateString = startDate ? startDate.toISOString().split('T')[0] : undefined;
    const endDateString = endDate ? endDate.toISOString().split('T')[0] : undefined;


    this.roadDataService.getRoadData(filterId, roadFilter, startDateString, endDateString).subscribe(data => {
      this.roadDataList = data;
    });
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  ngAfterViewInit(): void {

  }

  private getRollingPlan(bookingHeaderCode: string, rollingPlanLineParam: string): void {
    this.shipmentService.getRollingPlan(bookingHeaderCode, rollingPlanLineParam).subscribe(data => {
      if (data.length > 0) {
        this.rollingPlan = data[0];
        this.getTransportLoads(this.bookingParameter, this.rollingPlanLineParam);
      }
    }
    )
  }

  private getTransportLoads(bookingHeaderCode: string, rollingPlanLineParam: string): void {
    this.shipmentService.getTransportLoads(bookingHeaderCode, rollingPlanLineParam).subscribe(data => {
      if (data.length > 0) {
        this.transportDataList = data;

        if (this.selectedTab === this.detailTabbedViewEnum.trackingTab) {
          this.filterData['loadNumber'] = this.transportDataList.map(transport => transport.loadNo);
          this.fetchRoadData(0, this.filterData);
        }
      }
    }
    )
  }

  closeRightDrawer() {
    this.communicationService.closeRightNav();
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }
}
