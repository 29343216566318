<div class="dv-content-wrap container">

    <div class="dv-header-wrap">
        <div class="detail-header">
            <div class="detail-header">
                <h3>GR Reference - {{rollingPlan.grReferenceNo ? rollingPlan.grReferenceNo :'N/A'}} </h3>
            </div>
        </div>
    </div>

    <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color" [hideSingleSelectionIndicator]="true"
        class="tab-menu" [(ngModel)]="selectedTab">

        <mat-button-toggle class="tab-menu-item" [value]="detailTabbedViewEnum.trackingTab"
            matTooltip="Location of transport load on live map." matTooltipPosition="above"
            matTooltipClass="custom-mat-tooltip">Tracking</mat-button-toggle>
        <mat-button-toggle class="tab-menu-item" [value]="detailTabbedViewEnum.detailsTab"
            matTooltip="Show the current status update per transport load." matTooltipPosition="above"
            matTooltipClass="custom-mat-tooltip">Tracking
            Status</mat-button-toggle>
        <!-- <mat-button-toggle class="tab-menu-item" [value]="detailTabbedViewEnum.alertTab">Alerts</mat-button-toggle> -->

    </mat-button-toggle-group>

    <ng-container *ngIf="selectedTab === detailTabbedViewEnum.trackingTab">
        <app-road-tracking [startLocation]="[51.505, -0.09]" [endLocation]="[51.515, -0.1]" [vehicles]="roadDataList">
        </app-road-tracking>
    </ng-container>

    <ng-container *ngIf="selectedTab === detailTabbedViewEnum.detailsTab">
        <div class="dv-timeline">
            <div *ngIf="isMinWidth(0)" class="dv-timeline-header-row">
                <ng-container *ngFor="let column of displayedColumns">
                    <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
                        <strong matTooltip="{{column[2]}}" matTooltipPosition="above"
                            matTooltipClass="custom-mat-tooltip">{{ column[1]
                            }}</strong>
                    </div>
                </ng-container>
            </div>
            <div *ngFor="let row of transportDataList" class="dv-timeline-data-row" id="mobileCard">
                <ng-container *ngFor="let column of displayedColumns">
                    <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
                        <ng-container *ngIf="column[0] === 'eta'">
                            {{
                            getValueForRowColumn(row, column[0]) && getValueForRowColumn(row, column[0]) !==
                            '0001-01-01T00:00:00'
                            ? (getValueForRowColumn(row, column[0]) | date: 'dd MMM yyyy') : ' '
                            }}
                        </ng-container>
                        <ng-container *ngIf="column[0] === 'trackingStatusDateTime'">
                            {{
                            getValueForRowColumn(row, column[0]) && getValueForRowColumn(row, column[0]) !==
                            '0001-01-01T00:00:00' && getValueForRowColumn(row, column[0]) !==
                            '0001-01-01T00:00:00Z'
                            ? (getValueForRowColumn(row, column[0]) | date: 'dd MMM yyyy') : ' '
                            }}
                        </ng-container>
                        <ng-container
                            *ngIf="column[0] !== 'eta'&& column[0] !== 'trackingStatusDateTime' && column[0] !== 'portCode' && column[0] !== 'portType'">
                            {{getValueForRowColumn(row, column[0])}}
                        </ng-container>
                    </div>
                </ng-container>

            </div>
        </div>

    </ng-container>
</div>