import { OnInit, Component, ViewChild, Output, EventEmitter, OnDestroy, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BookingService } from '../service/booking/booking.service';
import { FilterData } from '../models/list-model';
import { CommunicationService } from '../service/communication/communication.service';
import { ColumnSetup } from '../models/column-setup';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import { BookingListModel } from '../models/bookings/booking-list-model';
import { RollingPlan } from '../models/bookings/rolling-plan-model';
import { LookupModel } from '../models/lookup-model';
import { RoadData } from '../models/road-data-model';
import { ShipmentService } from '../service/shipment/shipment.service';
import { FormControl } from '@angular/forms';
import { TableUtil } from '../service/utils/excel-export-service.service';

@Component({
  selector: 'app-booking-rolling-plan',
  templateUrl: './booking-rolling-plan.component.html',
  styleUrls: ['./booking-rolling-plan.component.css']
})
export class BookingRollingPlanComponent implements OnInit, OnDestroy {

  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;

  @Input() selectedCustomer: string = '';
  @Output() openDetailDrawer = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.rollingPlanDataSource.sort = sort;
  }
  @ViewChild(MatTable) table!: MatTable<BookingListModel>;

  bookingData: BookingListModel[] = [];
  filter!: FilterData;

  detailTabbedViewEnum = detailTabbedViewEnum;
  detailView = detailView;
  filterId: number = 0;
  startDate?: Date = new Date;
  endDate?: Date = new Date;

  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];
  rollingPlanDataSource = new MatTableDataSource<RollingPlan>();
  _snackBar: any;
  horizontalPosition: any;
  verticalPosition: any;
  isLoading = false;
  protected filterList = new FormControl();
  loadingSearch: boolean = false;

  constructor(
    private bookingDataService: BookingService,
    private shipingDataService: ShipmentService,
    private communicationService: CommunicationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appComponent: AppComponent,
  ) {
    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'backspace',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
  }

  ngOnInit(): void {

    this.configureColumns();

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    this.shipingDataService.sendFilterRoadTransfer$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (t) => {
          this.isLoading = true;
          this.filter = t.filter;
          this.filterId = t.filterId;
          this.startDate = t.startDate;
          this.endDate = t.endDate;
          this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
        },
        error: (error) => {
          console.error('Send FilterModel Error:', error);
          window.alert('Send FilterModel Error. Please try again later.');
        }
      });
    //
  }

  configureColumns(): void {
    this.displayedColumns = [
      { name: 'shipperNo', header: 'Customer', type: 'string', visible: !this.isCustUser, toolTip: 'Name of the customer.' },
      { name: 'grReferenceNo', header: 'GR Reference No', type: 'string', visible: true, toolTip: 'GoReefers reference number for the booking.' },
      { name: 'clientReference', header: 'Client Reference', type: 'string', visible: true, toolTip: 'Client’s reference number for the booking.' },
      { name: 'confirmedPackDate', header: 'Confirmed Pack Date', type: 'date', visible: true, toolTip: 'Date the packing was confirmed.' },
      { name: 'loadingPointDescription', header: 'Loading Point', type: 'string', visible: true, toolTip: 'Description of the loading location.' },
      { name: 'containerStatus', header: 'Container Status', type: 'string', visible: true, toolTip: 'Description of the container status.' },
      { name: 'loadingPortName', header: 'Loading Port', type: 'string', visible: true, toolTip: 'Port where cargo/container will be loaded onto vessel.' },
      { name: 'finalDestinationName', header: 'Discharge Port', type: 'string', visible: true, toolTip: 'Port where the vessel discharges some or all of its cargo.' },
      { name: 'bookingReference', header: 'Booking Reference', type: 'string', visible: true, toolTip: 'Booking reference number.' },
      { name: 'vesselName', header: 'Vessel', type: 'string', visible: true, toolTip: 'Name of the vessel.' },
      { name: 'voyageNumber', header: 'Voyage Number', type: 'string', visible: true, toolTip: 'Number assigned to voyage.' },
      { name: 'startStackDate', header: 'Start Stack Date', type: 'date', visible: true, toolTip: 'Date and time that stacks open.' },
      { name: 'endStackDate', header: 'End Stack Date', type: 'date', visible: true, toolTip: 'Date and time that stacks close.' },
      { name: 'actions', header: 'Actions', type: 'string', visible: true, toolTip: 'Show more details and load tracking status.' }
      //{ name: 'quantity', header: 'Quantity', type: 'number', visible: true },
    ];
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  getBackgroundColor(cellValue: string): string {
    switch (cellValue) {
      case 'Cancelled':
        return '#DC6868';
      case 'Confirmed':
        return '#36DE6F';
      case 'Awaiting Approval':
        return '#FFD34F';
      default:
        return '#C2C2C2';
    }
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  updateData(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): void {
    this.isLoading = true;
    this.bookingDataService.getRollingPlans(filterId, filterData, startDate, endDate)
      .subscribe(data => {
        this.configureColumns();
        this.rollingPlanDataSource.paginator = this.paginator;
        this.rollingPlanDataSource.data = data;
        this.isLoading = false;
      });

  }

  viewDetails(row: any, detailViewToOpen: detailView, tab: detailTabbedViewEnum): void {
    var bookingParameter = row['bookingHeaderCode'];
    this.communicationService.toggleDetailView(detailViewToOpen, tab);
    this.communicationService.getDetailViewParameter(bookingParameter);
    this.communicationService.getRollingPlanLineNo(row['lineNumber']);
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  applyFilter(filterValue: string) {
    if (!filterValue) {
      this.rollingPlanDataSource.filter = '';
    } else {
      this.rollingPlanDataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearSearchField(control: FormControl): void {
    control.reset(); // Clear the search input field
    this.rollingPlanDataSource.filter = ''; // Reset the table filter
  }

  applySearch(filterValue: string | undefined) {
    const normalizedFilter = (filterValue || '').trim().toLowerCase();
    this.loadingSearch = true;
    setTimeout(() => {
      this.rollingPlanDataSource.filter = normalizedFilter;
      this.loadingSearch = false;
    }, 200);
  }

  exportTable() {
    TableUtil.exportToExcel(this.rollingPlanDataSource.data, this.displayedColumns, 'Shipments');
  }
}
