import { Injectable } from '@angular/core';
import { ClaimAction } from '../../enums/keycloak-claim-action';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  //No Permission Tooltip messages
  noAddBookingPermissionTooltipMessage: string = 'You do not have permission to add a booking';
  noEditDocumentPermissionTooltipMessage: string = 'You do not have permission to approve or reject a document';
  toolTipTimeOut: number = 0;

  constructor() { }

  getNoPermissionTooltipMessage(permission: ClaimAction): { toolTipMessage: string; timeOut: number } {
    switch (permission) {
      case ClaimAction.Root2Go_AddBookings:
        return { toolTipMessage: this.noAddBookingPermissionTooltipMessage, timeOut: this.toolTipTimeOut};
      case ClaimAction.Root2Go_EditDocuments:
        return { toolTipMessage: this.noEditDocumentPermissionTooltipMessage, timeOut: this.toolTipTimeOut };
      default:
        return { toolTipMessage: 'You do not have permission to view this page', timeOut: this.toolTipTimeOut };
    }
  }
}


