<div class="dv-content-wrap container">

  <div class="dv-header-wrap">
    <div class="detail-header">
      <div class="detail-header">
        <h3>{{booking.customerCode}} - {{booking.grRefNo}} </h3>
      </div>
      <div class="booking-status">Booking Status: <span class="booking-status-ul">{{booking.appBookingStatus}}</span>
      </div>
    </div>
    <div class="dv-button-wrapper">
      <button class="primary-button" routerLink="/add-booking" (click)="addBookingLine()"
        [disabled]="!authService.hasClaim(ClaimAction.Root2Go_AddBookings)" [matTooltip]="
                                !authService.hasClaim(ClaimAction.Root2Go_AddBookings)
                                ? getPermissionTooltip(ClaimAction.Root2Go_AddBookings).toolTipMessage
                                : 'Click to proceed to schedule search to add new booking.'
                                " matTooltipPosition="above" matTooltipClass="custom-mat-tooltip"
        [matTooltipShowDelay]="!authService.hasClaim(ClaimAction.Root2Go_AddBookings) ? getPermissionTooltip(ClaimAction.Root2Go_AddBookings).timeOut : 1000">
        <mat-icon>add_circle</mat-icon> Add Containers
      </button>
      <button mat-icon-button color="primary" (click)="exportTable()" matTooltip="Export all booking records to excel."
        matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
        <mat-icon svgIcon="export"></mat-icon>
      </button>
    </div>
  </div>

  <div class="dv-summary-list">

    <div class="dv-summary-column">
      <div class="dv-summary-item">
        <span> Load Port:</span> <span>{{booking.loadPort}}</span>
      </div>

      <div class="dv-summary-item">
        <span> Stack Start:</span> <span>{{booking.stackStart | date: 'dd MMM yyyy, HH:mm'}}</span>
      </div>

      <div class="dv-summary-item">
        <span> Vessel Name:</span> <span>{{booking.vesselName}}</span>
      </div>
    </div>
    <div class="dv-summary-column">
      <div class="dv-summary-item">
        <span> Carrier:</span> <span>{{booking.carrier}}</span>
      </div>

      <div class="dv-summary-item">
        <span> Stack End:</span> <span> {{booking.stackEnd | date: 'dd MMM yyyy, HH:mm'}}</span>
      </div>

      <div class="dv-summary-item">
        <span> Voyage Number:</span> <span> {{booking.voyageNumber}}</span>
      </div>
    </div>
    <div class="dv-summary-column">
      <div class="dv-summary-item">
        <span> Service:</span> <span>{{booking.serviceCode}}</span>
      </div>
      <div class="dv-summary-item">
        <span> Load ETD:</span> <span> {{booking.sailDate | date: 'dd MMM yyyy'}}</span>
      </div>
    </div>

  </div>

  <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color" [hideSingleSelectionIndicator]="true"
    class="tab-menu" [(ngModel)]="selectedTab">

    <mat-button-toggle class="tab-menu-item" [value]="detailTabbedViewEnum.detailsTab">Details</mat-button-toggle>
  </mat-button-toggle-group>


  <ng-container *ngIf="selectedTab === detailTabbedViewEnum.detailsTab">
    <app-booking-line [stackEnd]="booking.stackEnd" [bookingLineData]="bookingLines"
      [bookingHeader]="booking"></app-booking-line>
  </ng-container>

  <ng-container *ngIf="selectedTab === detailTabbedViewEnum.trackingTab">
    <div #mapContainer id="map" class="map-view">
      <div class="map-legend">
        <img class="legend-image" src="/assets/Dashed Line.svg" alt="Dashed line">
        <div class="legend-text">Expected route</div>
      </div>
    </div>
  </ng-container>

  <!-- <ng-container *ngIf="selectedTab === detailTabbedViewEnum.alertTab">
    <app-alert-tab [alertData]="alertData"></app-alert-tab>
  </ng-container> -->

</div>