import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Alert } from '../models/alerts/alerts';

@Component({
  selector: 'app-alert-tab',
  templateUrl: './alert-tab.component.html',
  styleUrl: './alert-tab.component.css'
})
export class ShipmentAlertTabComponent{

    @Input()alertData: Alert[] = [];
  
    displayedColumns = [
      ['title', 'Alert'],
      ['alertDate', 'Time of Alert'],
      ['notification', 'Details'],
    ];

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: string[][]) {
    return columns.map(col => col[0]);

  }

}
