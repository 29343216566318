<div class="middle">
  <img src="../../assets/Root2Go Circle.svg" alt="Root2Go logo" />
  <p>
    ROOT2GO is transforming the way the fruit shipping industry manages its logistics and supply chain operations. With its innovative platform, ROOT2GO delivers real-time, actionable insights at your fingertips, offering seamless interaction with key data points. Whether you're on the go or in the office, you can access a live dashboard 24/7 to monitor the critical stages of your supply chain.
  </p>
  <p>
    With ROOT2GO, your fruit and cargo can now move with greater efficiency, reliability, and insight—keeping produce fresh, deliveries timely, and businesses ahead of the curve.
  </p>
</div>

<div class="middle">
  <img src="../../assets/GoGlobal Circle.svg" alt="GoGlobal logo" />
  <p>
    For more information about us - visit our website by clicking on the below link:
  </p>
  <button class="info-button" onclick="window.open('https://goglobal.group/about-goglobal/', '_blank')">
    For more information
  </button>
</div>
