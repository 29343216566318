<div class="mat-elevation-z2 custom-card-radius">
  <div class="table-filter-container">
      <mat-form-field *ngIf="this.bookingDataSource.data.length > 0 && !isLoading" subscriptSizing="dynamic"
                      class="search-wrapper">
        <mat-label>Quick Search</mat-label>
        <input matInput [formControl]="filterList" (keyup)="applySearch(filterList.value)">
        <button class="clear-search" (click)="clearSearchField(filterList)">
          <mat-icon svgIcon="backspace"></mat-icon>
        </button>
      </mat-form-field>
  </div>
    <ng-container *ngIf="!isLoading">
    <table mat-table class="full-width-table" [dataSource]="bookingDataSource" matSort aria-label="Elements">
      <ng-container *ngFor="let column of displayedColumns">
        <ng-container [matColumnDef]="column.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible"
            matTooltip="{{column.toolTip}}" matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{'loading-cell': loadingSearch}"
            [attr.data-label]="(column.name !== 'actions' && column.name !== 'indicators') ? column.header : null"
            [hidden]="!column.visible" class='has_label_on_mobile'
            [ngStyle]="column.name === 'appBookingStatusColor' ? {'background-color': getBackgroundColor(row['appBookingStatus'])} : {}">

              <ng-container *ngIf="column.name !== 'actions'">
                <ng-container *ngIf="column.name !== 'sailDate' && column.name !== 'appBookingStatusColor'  && column.name !== 'indicators'">
                  {{ row[column.name] | truncateText}}
                </ng-container>
                <ng-container *ngIf="column.name === 'sailDate'">
                  {{row[column.name] | date: 'dd MMM yyyy' }}
                </ng-container>
              </ng-container>
              <ng-container *ngIf="column.name === 'actions'" class="mat-column-actions">
                <button class="detailed-view-btn" [ngClass]="{'loading-cell': loadingSearch}"
                        (click)="viewDetails(row, detailView.bookingDetail, detailTabbedViewEnum.detailsTab)">
                  Booking Details<mat-icon>arrow_right_alt</mat-icon>
                </button>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="extractedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: extractedColumns;"></tr>
        <tr *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length">
            <ng-container *ngIf="!isLoading" class="no-data-container">
              <div class="no-data-container">
                <strong *ngIf="checkNoFilter()">
                  No data available for selected filters. Please review filters and search
                  again.
                </strong>
                <strong *ngIf="bookingDataSource.data.length === 0 && !checkNoFilter()">
                  Please apply filters to see
                  results.
                </strong>
              </div>
            </ng-container>
          </td>
        </tr>
      </table>
    </ng-container>
    <ng-container *ngIf="isLoading" class="no-data-container">
      <div class="no-data-container">
        <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
      </div>
    </ng-container>

    <mat-paginator #paginator [length]="bookingDataSource.data.length" [pageIndex]="0" [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
    </mat-paginator>
  </div>
