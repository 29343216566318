import { Component, ElementRef, ViewChild, OnInit, ChangeDetectorRef, ViewChildren, QueryList, ViewEncapsulation } from '@angular/core';
import { GridsterConfig, GridType, DisplayGrid, CompactType, GridsterItem } from 'angular-gridster2';
import { ShipmentService } from '../service/shipment/shipment.service';
import { SchedulesService } from '../service/schedules/schedules.service';
import { FilterData } from '../models/list-model';
import { DocumentService } from '../service/documents/document.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { number } from 'echarts';
import { from, concatMap, Observable, of, tap, map, startWith, takeUntil, Subject, debounceTime } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { LookupModel } from '../models/lookup-model';
import { FormControl } from '@angular/forms';
import { LookupService } from '../service/lookup/lookup.service';
import { DataFilter } from '../models/filter-models';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { WidgetDataComponent } from '../dialog/widget-data/widget-data.component';
import {switchMap } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { ApiUserService } from '../service/user/api-user.service';
import { ShipmentDataTransferModel } from '../models/shipment/shipment-data-model';

interface DashboardEntry {
  id: number;
  filterData?: FilterData | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  title: string;
  toggle: string;
  type: string;
  order: number;
  chartType: string;
  x: number; 
  y: number;
  cols: number;  
  rows: number;
  additionalFilterField?: string;
  additionalFilterValues?: [string, string];
}

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.css'],
})
export class WidgetComponent implements OnInit {
  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef<HTMLDivElement>;
  isScrollable: boolean = false;
  @ViewChild('customerMenu') customerMenu: MatMenu | undefined;
  @ViewChild('searchInput') searchInput: ElementRef | undefined;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  private destroy$ = new Subject<void>();
  @ViewChild('gridsterContainer', { static: false }) gridsterContainer!: ElementRef;
  customers = new FormControl<LookupModel[]>([]);
  customersMultiFilter = new FormControl<string>('');
  filteredCustomers!: Observable<LookupModel[]>;
  customersData: LookupModel[] = [];
  filterData: FilterData = {};
  @ViewChild('multiSelect', { static: false }) multiSelect!: MatSelect;
  @ViewChildren(MatSelect) matSelects!: QueryList<MatSelect>;
  datafilter: DataFilter = new DataFilter();
  private currentWidgetBeingFiltered: GridsterItem | null = null;
  shipmentTransfer: ShipmentDataTransferModel = new ShipmentDataTransferModel();

  loading: boolean = false;
  stackStatus = false
  gridOptions: GridsterConfig = {
    keepFixedHeightInMobile: true,
    gridType: GridType.Fit,
    displayGrid: DisplayGrid.OnDragAndResize,
    compactType: CompactType.None,
    mobileBreakpoint: 990, 
    pushItems: true,
    swap: true,
    margin: 3,
    draggable: {
      enabled: false
    },
    resizable: {
      enabled: false
    },
    minCols: 1,
    maxCols: 3,
    minRows: 5,
    maxRows: 55, 
    maxItemCols: 12,
    minItemCols: 1,
    maxItemRows: 55,
    minItemRows: 1,
    maxItemArea: 5500,
    minItemArea: 1,
    defaultItemCols: 1,
    defaultItemRows: 1
  };

  dashboard: GridsterItem[] = [];
  activeCardLoading: { [key: number]: boolean } = {};
  private dashboardEntries: DashboardEntry[] = [
    {
      id: 4,
      filterData: {eta:[]},
      dateFrom: new Date(), 
      dateTo: new Date(), 
      title: 'Shipments arriving today', 
      toggle: '', 
      type: 'Shipments',
      chartType: 'progress',
      order: 10,
      x: 0,
      y: 6,
      cols: 1, 
      rows: 4  
    },
    {
      id: 5,
      filterData: { eta: [] },
      dateFrom: new Date(), 
      dateTo: new Date(new Date().setDate(new Date().getDate() + 1)), 
      title: 'Containers delayed in transshipment port',
      toggle: '',
      chartType: 'progress',
      type: 'Schedules',
      order: 5,
      x: 1,
      y: 6,
      cols: 1,
      rows: 4 
    },
    {
      id: 6,
      filterData: { eta: [] },
      dateFrom: new Date(),
      dateTo: new Date('2024-11-07'),
      title: 'Containers delayed in discharge ports',
      toggle: '',
      chartType: 'progress',
      type: 'Schedules',
      order: 6,
      x: 2,  
      y: 6, 
      cols: 1, 
      rows: 4 
    },
    {
      id: 1,
      filterData: { loadPortCode: ['ZACPT'], stackStatus: ['Firm'] },
      dateFrom: new Date(),
      dateTo: null,
      title: 'Cape Town Stacks',
      toggle: 'Firm',
      type: 'Schedules',
      order: 1,
      chartType: 'cards',
      x: 0,  
      y: 0,
      cols: 1, 
      rows: 6,
      additionalFilterField: 'stackStatus',
      additionalFilterValues: ['Firm','Provisional']
    },
    {
      id: 2,
      filterData: {
        loadPortCode: ['ZADUR'], stackStatus: ['Firm']},
      dateFrom: new Date(),
      dateTo: null,
      title: 'Durban Stacks',
      toggle: 'Firm',
      type: 'Schedules',
      order: 2,
      chartType: 'cards',
      x: 1, 
      y: 0, 
      cols: 1, 
      rows: 6,  
      additionalFilterField: 'stackStatus',
      additionalFilterValues: ['Firm' ,'Provisional']
    },
    {
      id: 3,
      filterData: { loadPortCode: ['ZAZBA', 'ZAPLZ'], stackStatus: ['Firm'] },
      dateFrom: new Date(),
      dateTo: null,
      title: 'Coega | PE Stacks',
      toggle: 'Firm',
      type: 'Schedules',
      order: 3,
      chartType: 'cards',
      x: 2,
      y: 0, 
      cols: 1, 
      rows: 6,   
      additionalFilterField: 'stackStatus',
      additionalFilterValues: ['Provisional','Firm']
    },
    {
      id: 7,
      filterData: { eta: [] },
      dateFrom: null,
      dateTo: null,
      title: 'ETA drifting more than 5 days',
      toggle: '',
      type: 'Shipments',
      order: 8,
      chartType: 'cards',
      x: 1, 
      y: 8, 
      cols: 2,  
      rows: 6  
    },
    {
      id: 8,
      filterData: { loadPortCode: ['ZADUR'] },
      dateFrom: new Date(),
      dateTo: new Date('2024-11-13'),
      title: '',
      toggle: '',
      type: 'Map',
      order: 7,
      chartType: 'map',
      x: 4, 
      y: 8, 
      cols: 1, 
      rows: 12 
    },
    {
      id: 9,
      filterData: undefined,
      dateFrom: new Date(),
      dateTo: new Date(new Date().setDate(new Date().getDate() + 4)),
      title: 'Documents outstanding',
      toggle: '',
      type: 'Documents',
      order: 9,
      chartType: 'cards',
      x: 0,  
      y: 8,
      cols: 2,  
      rows: 6   
    }
  ];
  widgetFilters: { [key: number]: { customers: FormControl<LookupModel[]>; customersMultiFilter: FormControl<string>; filteredCustomers?: Observable<LookupModel[]> } } = {};
  protected isCustUser: boolean = false;
  dataSource = new MatTableDataSource<any>();
  constructor(
    private shipmentService: ShipmentService,
    private schedulesService: SchedulesService,
    private documentService: DocumentService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private router: Router,
    private lookupService: LookupService,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private apiUserService: ApiUserService
  ) {
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));

    this.matIconRegistry.addSvgIcon(
      'arrow_up_right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/ArrowUpRight.svg')
    );
  }

  ngOnInit() {
    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    this.dashboard = this.dashboardEntries.map(entry => {
      const widgetId = entry.id;
      this.widgetFilters[widgetId] = {
        customers: new FormControl<LookupModel[]>([], { nonNullable: true }),
        customersMultiFilter: new FormControl<string>('', { nonNullable: true })
      };

      this.widgetFilters[widgetId].filteredCustomers = this.widgetFilters[widgetId].customersMultiFilter.valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        map(searchText => {
          const filtered = this.filterCustomers(searchText || '', widgetId);
          return this.sortSelectedCustomers(filtered, this.widgetFilters[widgetId].customers.value);
        })
      );

      let headings: string[] = [];
      if (entry.id === 7) {
        const fieldMapping = {
          field1: 'shipment.vesselName',
          field2: 'shipment.voyageNumber',
          field3: 'shipment.destination ',
          field5: 'shipment.originalETA',
          field6: 'shipment.ETA'
        };
        headings = this.extractFieldsForHeading(fieldMapping);
      } else if (entry.id === 1 || entry.id === 2 || entry.id === 3) {
        const fieldMapping = {
          field1: 'shipment.vesselName',
          field2: 'shipment.voyageNumber',
          field3: 'shipment.stackStart',
          field4: 'shipment.stackEnd'
        };
        headings = this.extractFieldsForHeading(fieldMapping);
      }
      return {
        id: entry.id,
        x: entry.x,
        y: entry.y,
        cols: entry.cols,
        rows: entry.rows,
        type: entry.chartType,
        chartType: entry.type,
        cardTitle: entry.title,
        cardToggle: entry.toggle,
        cards: [], 
        headings: headings 
      };
    });

    from(this.dashboardEntries)
      .pipe(
        concatMap(entry => this.updateData(
          entry.id,
          entry.filterData || {},
          entry.dateFrom,
          entry.dateTo,
          entry.title,
          entry.toggle,
          entry.type,
          entry.chartType,
          entry.order,
          entry.additionalFilterField,
          entry.additionalFilterValues
        ))
      )
      .subscribe({
        error: (err) => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      });

    this.lookupService.getCustomers({}).subscribe(data => {
      this.customersData = data;
    });

  }
  ngAfterViewInit() {
    this.customersMultiFilter.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.focusSearchInput();
    });
  }

  openCustomerMenu(): void {
    this.menuTrigger.openMenu();
  }

  focusSearchInput(): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.focus();
    }
  }

  getDynamicFields(data: any, fields: string[]): { [key: string]: any } {
    const result: { [key: string]: any } = {};
    fields.forEach(field => {
      result[field] = data[field] ?? 'N/A';
    });
    return result;
  }

  selectCustomerForWidget(item: GridsterItem): void {
    const expectedId = `mat-select-${item['id']}`;
    const selectedCustomers = this.widgetFilters[item['id']].customers.value;
    const selectedSelect = this.matSelects.find(select => select.id === `mat-select-${item['id']}`);
    if (selectedSelect) {
      selectedSelect.close();
    }

    const widgetFieldMap: { [key: number]: string[] } = {
      5: ['eventLocation', 'daysDelayed', 'containerNo', 'bookingRefNo', 'grReferenceNo', 'customerName'],
      6: ['eventLocation', 'daysDelayed', 'containerNo', 'bookingRefNo', 'grReferenceNo', 'customerName'],
      7: ['vesselName', 'vesselVoyage', 'destination', 'originalETA', 'eta'],
      9: ['grReference', 'consignee', 'vesselName', 'voyage', 'DaysToArrival'],
    };

    if (selectedCustomers && item) {
      if (selectedCustomers.length > 0) {
        const customerCodes = this.parseFilterSelections(selectedCustomers);
        const dataArray = customerCodes
          .split(',')
          .filter(item => item.trim() !== '');
        item['filterData'] = { ...item['filterData'], customerCode: dataArray };
      } else {
        delete item['filterData'].customerCode;
      }

      this.activeCardLoading[item['id']] = true;
      const fields = widgetFieldMap[item['id']] || ['field1', 'consignee', 'vesselName', 'field4'];

      this.updateData(
        item['id'],
        item['filterData'],
        item['dateFrom'],
        item['dateTo'],
        item['cardTitle'],
        item['cardToggle'],
        item['chartType']
      ).subscribe({
        next: (updatedData) => {
          if (Array.isArray(updatedData) && updatedData.length > 0) {
            item['cards'] = updatedData.slice(0, 10).map(data => {
              const cardData: { [key: string]: any } = { title: item['cardTitle'] };
              fields.forEach((field, index) => {
                let fieldValue = data[field];
                if (fieldValue === '0001-01-01T00:00:00' || fieldValue === '') {
                  fieldValue = 'N/A';
                }
                if (item['id'] === 7 && (field === 'originalETA' || field === 'eta')) {
                  if (fieldValue && fieldValue !== 'N/A') {
                    const date = new Date(fieldValue);
                    fieldValue = isNaN(date.getTime())
                      ? 'N/A'
                      : date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
                  }
                }
                if (item['id'] === 9 && field === 'DaysToArrival') {
                  const today = new Date();
                  const etaDate = data.eta ? new Date(data.eta) : null;
                  const daysToArrival = etaDate
                    ? Math.ceil((etaDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24))
                    : 'N/A';
                  fieldValue = typeof daysToArrival === 'number' ? `${daysToArrival} days` : 'N/A';
                }

                cardData[`field${index + 1}`] = fieldValue;
              });
              return cardData;
            });
          } else {
            item['cards'] = [{ title: item['cardTitle'], field1: 'No data found' }];
          }
        },
        error: (error) => {
          item['cards'] = [{ title: item['cardTitle'], field1: 'Error loading data' }];
        },
        complete: () => {
          this.activeCardLoading[item['id']] = false;
        }
      });
    }
  }

  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
  }

  private processFilterDataForWidget(key: string, value: string, widget: GridsterItem) {
    const dataArray = value.split(',').filter(item => item.trim() !== '');
    widget['filterData'] = { ...widget['filterData'], [key]: dataArray };
  }

  resetCustomerSelection(): void {
    this.customers.reset();
  }

  toggleCustomerSelection(customer: LookupModel): void {
    if (this.isCustomerSelected(customer)) {
      this.customers.setValue(this.customers.value?.filter(c => c.code !== customer.code) || []);
    } else {
      this.customers.setValue([...(this.customers.value || []), customer]);
    }
  }

  isCustomerSelected(customer: LookupModel): boolean {
    return (this.customers.value || []).some(c => c.code === customer.code);
  }

  private filterCustomers(searchText: string, widgetId: number): LookupModel[] {
    if (!searchText) return this.customersData;
    return this.customersData.filter(customer =>
      customer.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  createFilter(): void {
    if (this.customers.value != null) {
      const customerCodes = this.parseFilterSelections(this.customers.value);
      this.processFilterData('customerCode', customerCodes);
    }
  }

  private parseFilterSelections(selections: LookupModel[]): string {
    return selections.map(selection => selection.code).join(',');
  }

  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');

    this.filterData[key] = dataArray;
  }

  extractFieldsForHeading(fieldMapping: { [key: string]: string }): string[] {
    return Object.values(fieldMapping).map(field => {
      if (field === 'document.GRReferrence') {
        return 'GR' + ' ' + 'Reference'; 
      }
      const fieldNameMatch = field.match(/(document|schedule|shipment)\.(\w+)/);
      if (fieldNameMatch && fieldNameMatch[1]) {
        const fieldName = fieldNameMatch[2];
        const formattedFieldName = fieldName.replace(/([a-z])([A-Z])/g, '$1 $2');
        return formattedFieldName.replace(/\b\w/g, char => char.toUpperCase());
      }
      return 'N/A';
    });
  }

  openDataDialog(item: any, delays: any[], progressTitle: string): void {
    this.dialog.open(WidgetDataComponent, {
      data: { title: progressTitle, widgetData: delays }
    });
  }

  onCardInView(item: any): void {
    item.isInView = true;
  }

  updateData(id: number, filter: FilterData, dateFrom?: Date | null, dateTo?: Date | null, title: string = '', toggle: string = '', type: string = '',chartType: string = '', order?: number, toggleField?: string, toggleValues?: [string, string]): Observable<any> {
    this.activeCardLoading[id] = true;
    if (type === "Schedules") {
      if (id === 5) {
        return this.shipmentService.getShipmentDelayInTranshipment(filter).pipe(
          tap(delays => {
            this.activeCardLoading[id] = false;
            const filteredDelays: any[] = [];
            delays.forEach(delay => {
              if (delay.daysDelayed !== undefined && delay.daysDelayed >= 3) {
                filteredDelays.push(delay);
              }
            });
            const progressTitle = 'Containers delayed in transshipment port';
            const progressValue = filteredDelays.length;
            const existingItem = this.dashboard.find(item => item['id'] === id);
            const formattedDateFrom: string[] | undefined = dateFrom instanceof Date && !isNaN(dateFrom.getTime())
              ? [dateFrom.toISOString()]
              : undefined;
            const formattedDateTo: string[] | undefined = dateTo instanceof Date && !isNaN(dateTo.getTime())
              ? [dateTo.toISOString()]
              : undefined;
            if (!existingItem) {
              this.addProgress(
                id,
                progressValue,
                progressTitle,
                order,
                () => this.navigateWithDetails('shipments', {
                  ...filter,
                  dateFrom: formattedDateFrom,
                  dateTo: formattedDateTo
                }),
                'custom-progress-height',
                dateFrom,
                dateTo
              );
            } else {
              existingItem['progressValue'] = progressValue;
              existingItem['cardTitle'] = progressTitle;
              existingItem['delays'] = delays;
              existingItem['progressTitle'] = progressTitle;
              existingItem['onClick'] = () => this.navigateWithDetails('shipments', {
                ...filter,
                dateFrom: formattedDateFrom,
                dateTo: formattedDateTo
              });
              existingItem['dateFrom'] = formattedDateFrom;
              existingItem['dateTo'] = formattedDateTo;
            }
          })
        );
      }

      if (id === 6) {
        return this.shipmentService.getShipmentDelayInDischarges(filter).pipe(
          tap(delays => {
            this.activeCardLoading[id] = false;
            const filteredDelays: any[] = [];
            delays.forEach(delay => {
              if (delay.daysDelayed !== undefined && delay.daysDelayed >= 2) {
                filteredDelays.push(delay);
              }
            });
            const progressTitle = 'Containers delayed in discharge ports';
            const progressValue = filteredDelays.length; 
            const existingItem = this.dashboard.find(item => item['id'] === id);

            if (!existingItem) {
              this.addProgress(id, progressValue, progressTitle, order, () => this.openDataDialog(existingItem, filteredDelays, progressTitle), 'custom-progress-height');
            } else {
              existingItem['progressValue'] = progressValue;
              existingItem['cardTitle'] = progressTitle;
              existingItem['delays'] = filteredDelays;
              existingItem['progressTitle'] = progressTitle;
              existingItem['onClick'] = () => this.openDataDialog(existingItem, filteredDelays, progressTitle);
            }
          })
        );
      }
      else {
        return this.schedulesService.getScheduleGroupStacks(filter, dateFrom, dateTo).pipe(
          tap(shipments => {
            this.activeCardLoading[id] = false;
            const sortedShipments = shipments.sort((a, b) => {
              const stackEndA = a.stackEnd ? new Date(a.stackEnd).getTime() : 0;
              const stackEndB = b.stackEnd ? new Date(b.stackEnd).getTime() : 0;
              return stackEndA - stackEndB;
            });

            const limitedShipments = sortedShipments.slice(0, 10);
            if (id === 1 || id === 2 || id === 3) {
              const fieldMapping = {
                field1: 'shipment.vesselName',
                field2: 'shipment.voyageNumber',
                field3: 'shipment.stackStart',
                field4: 'shipment.stackEnd',
              };
              const headings = this.extractFieldsForHeading(fieldMapping);

              const cardData = limitedShipments.map(shipment => ({
                title,
                field1: shipment.vesselName ?? 'N/A', 
                field2: shipment.voyageNumber ?? 'N/A',
                field3: shipment.stackStart ? this.datePipe.transform(shipment.stackStart, 'dd MMM yyyy, HH:mm') ?? 'N/A' : 'N/A',
                field4: shipment.stackEnd ? this.datePipe.transform(shipment.stackEnd, 'dd MMM yyyy, HH:mm') ?? 'N/A' : 'N/A',
              }));

              const existingItem = this.dashboard.find(item => item['id'] === id);

              if (!existingItem) {
                this.addCards(title, toggle, cardData, order, id, 1, this.dashboard.find(entry => entry['id'] === id)?.['headings'] || [], 'custom-card-height');
              } else {
                if (cardData.length === 0) {
                  existingItem['cards'] = [
                    {
                      title,
                      field1: 'No data found',
                    },
                  ];
                } else
                  existingItem['cards'] = cardData;
              }
            }
          })
        );
      }
    }

    else if (type === "Shipments") {
      this.activeCardLoading[id] = true;

      if (id === 7) {
        return this.shipmentService.getActiveShipmentsDrifting(filter).pipe(
          tap(shipments => {
            this.activeCardLoading[id] = false;
            const cardData = Array.isArray(shipments)
              ? shipments.map((shipment: any) => {
                const originalETA = new Date(shipment.originalETA);
                const eta = new Date(shipment.eta);
                const timeDifference = Math.abs(eta.getTime() - originalETA.getTime());
                const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
                if (dayDifference > 5) {
                  console.log(`Shipment ${shipment.clientReference}: Difference in days is ${dayDifference}`);
                }
                return {
                  title,
                  field1: shipment.vesselName?.toString() ?? 'N/A',
                  field2: shipment.vesselVoyage ?? 'N/A',
                  field3: shipment.destination && shipment.destination.trim() !== '' ? shipment.destination : 'N/A',
                  field5: shipment.originalETA ? this.datePipe.transform(shipment.originalETA, 'dd MMM yyyy') ?? 'N/A' : 'N/A',
                  field6: shipment.eta ? this.datePipe.transform(shipment.eta, 'dd MMM yyyy') ?? 'N/A' : 'N/A',
                  dayDifference
                };
              })
              : [];

            const existingItem = this.dashboard.find(item => item['id'] === id);
            if (!existingItem) {
              this.addCards(title, toggle, cardData, order, id, 1, this.dashboard.find(entry => entry['id'] === id)?.['headings'] || [], 'custom-card-height');
            } else {
              if (cardData.length === 0) {
                existingItem['cards'] = [
                  {
                    title,
                    field1: 'No data found',
                  },
                ];
              }else
              existingItem['cards'] = cardData;
            }
          })
        );
      } else if (id === 4) {
        return this.shipmentService.getArrivingShipments(id, filter, dateFrom, dateTo).pipe(
          tap(shipments => {
            this.activeCardLoading[id] = false;
            const todayStart = new Date();
            todayStart.setHours(0, 0, 0, 0);
            const todayEnd = new Date();
            todayEnd.setHours(23, 59, 59, 999);

            const arrivingTodayCount = shipments.filter(shipment => {
              const etaDate = shipment.eta ? new Date(shipment.eta) : null;
              return etaDate && etaDate >= todayStart && etaDate <= todayEnd;
            }).length;

            const existingItem = this.dashboard.find(item => item['id'] === id);
            const progressTitle = 'Shipments arriving today';
            const progressValue = arrivingTodayCount;

            const formattedDateFrom = dateFrom instanceof Date && !isNaN(dateFrom.getTime())
              ? dateFrom
              : null;
            const formattedDateTo = dateTo instanceof Date && !isNaN(dateTo.getTime())
              ? dateTo
              : null;

            if (!existingItem) {
              this.addProgress(
                id,
                arrivingTodayCount,
                progressTitle,
                order,
                () => this.navigateWithDetails('shipments', {
                  ...filter,
                  dateFrom: undefined,
                  dateTo: undefined
                }),
                'custom-progress-height'
              );
            } else {
              existingItem['progressValue'] = progressValue;
              existingItem['cardTitle'] = progressTitle;
              existingItem['delays'] = shipments;
              existingItem['progressTitle'] = progressTitle;
              existingItem['onClick'] = () => this.navigateWithDetails('shipments', {
                ...filter,
                dateFrom: undefined,
                dateTo: undefined
              });
              existingItem['dateFrom'] = formattedDateFrom;
              existingItem['dateTo'] = formattedDateTo;
            }
          })
        );
      }
    }

    else if (type === "Map") {
      this.activeCardLoading[id] = true;
      const existingItem = this.dashboard.find(item => item['id'] === id);

      if (!existingItem) {
        return of(this.addMap(id, 0, 'Shipment tracking', order, () => this.navigate('/shipments', { map: true }), 'custom-map-height')).pipe(
          tap(() => {
            this.activeCardLoading[id] = false;
          })
        );
      } else {
        existingItem['cardTitle'] = 'Shipment tracking';
        existingItem['progressValue'] = 0;
        existingItem['onClick'] = () => this.navigate('/shipments', { map: true });
        this.activeCardLoading[id] = false;
        return of(null);
      }
    }

    else if (type === "Documents") {
      this.activeCardLoading[id] = false;
      return this.shipmentService.getDocumentsOutstandingPerShipmentList(filter).pipe(
        tap(documents => {
          const limitedDocuments = documents.slice(0, 10);

          if (id === 9) {
            const fieldMapping = {
              field1: 'document.GRReferrence',
              field2: 'document.consignee',
              field3: 'document.vesselName',
              field4: 'document.voyage',
              field5: 'document.DaysToArrival'
            };

            const headings = this.extractFieldsForHeading(fieldMapping);
            const cardData = limitedDocuments.map(document => {
              const today = new Date();
              const etaDate = document.eta ? new Date(document.eta) : null;
              const daysToArrival = etaDate ? Math.ceil((etaDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)) : 'N/A';
              return {
                title,
                field1: document.grReference ?? 'N/A',
                field2: document.consignee ?? 'N/A',
                field3: document.vesselName ?? 'N/A',
                field4: document.voyage ?? 'N/A',
                field5: typeof daysToArrival === 'number' ? `${daysToArrival} days` : 'N/A'
              };
            });

            const existingItem = this.dashboard.find(item => item['id'] === id);
            if (!existingItem) {
              this.addCards(title, toggle, cardData, order, id, 1, this.dashboard.find(entry => entry['id'] === id)?.['headings'] || [], 'custom-card-height');
            } else {
              if (cardData.length === 0) {
                existingItem['cards'] = [
                  {
                    title,
                    field1: 'No data found',
                  },
                ];
              } else
                existingItem['cards'] = cardData;
                existingItem['headings'] = headings;
                existingItem['cardTitle'] = title;
                existingItem['cardToggle'] = toggle;
            }
          }
        })
      );
    }
    else {
      return of(null);
    }
    return of(null);
  }

  isSelected(customer: any, widgetId: number): boolean {
    return this.widgetFilters[widgetId].customers.value.some((selectedCustomer: any) => selectedCustomer.code === customer.code);
  }

  clearSelectedCustomers(item: any): void {
    this.widgetFilters[item['id']].customers.setValue([]);
  }

  handleToggleChangeForCard(item: GridsterItem): void {
    const originalEntry = this.dashboardEntries.find(entry => entry.id === item['id']);

    if (!originalEntry) {
      return;
    }

    const updatedFilter = { ...originalEntry.filterData };
    updatedFilter['stackStatus'] = item['stackStatus'] ? ['Provisional'] : ['Firm'];
    item['cardToggle'] = item['stackStatus'] ? 'Provisional' : 'Firm';
    this.activeCardLoading[item['id']] = true;

    this.updateData(item['id'], updatedFilter, originalEntry.dateFrom, originalEntry.dateTo, item['cardTitle'], '', 'Schedules')
    .subscribe(updatedData => {
      if (updatedData && Array.isArray(updatedData) && updatedData.length > 0) {
        item['cards'] = updatedData.slice(0, 10).map((data: { voyageNumber: string; vesselName: string; stackStart: Date; stackEnd: Date; stackStatus: string }) => ({
          title: item['cardTitle'],
          field1: data.vesselName ?? 'N/A',
          field2: data.voyageNumber ?? 'N/A',
          field3: data.stackStart ? this.datePipe.transform(data.stackStart, 'dd MMM yyyy, HH:mm') ?? 'N/A' : 'N/A',
          field4: data.stackEnd ? this.datePipe.transform(data.stackEnd, 'dd MMM yyyy, HH:mm') ?? 'N/A' : 'N/A',
        }));
      } else {
        item['cards'] = [{
          title: item['cardTitle'],
          field1: 'No stacks found',
        }];
      }
      this.activeCardLoading[item['id']] = false;
    });
  }

  navigateWithDetails(url: string, filter: FilterData, dateFrom?: Date | null, dateTo?: Date | null): void {
    const normalizedFilter = {
      ...filter,
      dateFrom: dateFrom ? dateFrom.toISOString() : undefined,
      dateTo: dateTo ? dateTo.toISOString() : undefined,
    };

    const queryParams: any = {
      filter: JSON.stringify(normalizedFilter),
    };

    this.router.navigate([url], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  sortSelectedCustomers(customers: LookupModel[], selectedCustomers: LookupModel[]): LookupModel[] {
    const selectedCustomerIds = new Set(selectedCustomers.map(customer => customer.code));

    return customers.sort((a, b) => {
      const isSelectedA = selectedCustomerIds.has(a.code);
      const isSelectedB = selectedCustomerIds.has(b.code);

      if (isSelectedA && isSelectedB) {
        return a.name.localeCompare(b.name);
      }
      if (!isSelectedA && !isSelectedB) {
        return a.name.localeCompare(b.name);
      }
      return isSelectedA ? -1 : 1;
    });
  }

  navigate(url: string, queryParams?: { [key: string]: any }) {
    this.router.navigate([url], { queryParams });
  }

  addCards(
    cardTitle?: string,
    cardToggle?: string,
    cardData?: { title: string, field1: string, field2: string, field3: string, field4?: string, field5?: string, field6?: string }[],
    order: number = 0,
    id: number = 0,
    cols: number = 1,
    headings?: string[],
    cssClass: string = '',
    x: number = 0,
    y: number = 0,

  ) {
    const selectId = `mat-select${id}`;
    const item: GridsterItem = {
      cols: cols,
      rows: 2,
      y: y,
      x: x,
      type: 'cards',
      cardTitle,
      cardToggle,
      stackStatus: false,
      cards: cardData,
      id: id,
      headings: headings,
      cssClass,
      selectId
    };
    this.dashboard.push(item);
  }

  addProgress(id: number = 0, value: number, title: string, order: number = 0, onClick?: () => void, cssClass: string = '', dateFrom: Date | null | undefined = new Date(), dateTo: Date | null | undefined = new Date()) {
    const item: GridsterItem = {
      cols: 1,
      rows: 1,
      isInView: false,
      y: order,
      x: 0,
      id: id,
      type: 'progress',
      progressValue: value,
      cardTitle: title,
      onClick,
      cssClass,
      dateFrom,
      dateTo
    };
    this.dashboard.push(item);
  }

  addMap(id: number = 0, value: number, title: string, order: number = 0, onClick?: () => void, cssClass: string = '') {
    const item: GridsterItem = {
      cols: 1,
      rows: 2,
      y: order,
      x: 0,
      type: 'map',
      id: id,
      progressValue: value,
      cardTitle: title,
      onClick,
      cssClass
    };
    this.dashboard.push(item);
  }

}
