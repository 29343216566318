<div class="dv-content-wrap">
    <div class="dv-header-wrap">
        <div class="detail-header">
            <h3>Shipment - {{grRefContainerData.grReferenceNo}}</h3>
        </div>
    </div>
    <ng-container *ngIf="!isLoading && shipmentData.grReference; else noDataContainer">
        <div class="dv-summary-list">
            <div class="dv-summary-column">
                <div class="info-item">
                    <span class="info-label">Consignee</span>
                    <span>{{shipmentData.consignee}}</span>
                </div>

                <div class="info-item">
                    <span class="info-label">Load Port</span>
                    <span>{{shipmentData.loadPortName}}</span>
                </div>
            </div>

            <div class="dv-summary-column">
                <div class="info-item">
                    <span class="info-label">Carrier</span>
                    <span>{{shipmentData.carrierName}}</span>
                </div>

                <div class="info-item">
                    <span class="info-label">Vessel</span>
                    <span>{{shipmentData.vesselName}}</span>
                </div>
            </div>
            <div class="dv-summary-column">
                <div class="info-item">
                    <span class="info-label">Discharge Port</span>
                    <span>{{shipmentData.destination}}</span>
                </div>

                <div class="info-item">
                    <span class="info-label">Containers</span>
                    <span>{{ shipmentData.containerNumber.split(',').join(' | ') }}</span>
                </div>
            </div>

        </div>

        <ng-container *ngIf="!isLoading && shipmentData.grReference">
            <app-document-management [grRefNo]="shipmentData.grReference"
                [containerNo]="shipmentData.containerNumber"></app-document-management>
        </ng-container>
    </ng-container>
    <ng-template #noDataContainer>
        <ng-container *ngIf="isLoading" class="no-data-container">
            <div class="no-data-container">
                <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
            </div>
        </ng-container>
        <ng-container *ngIf="!isLoading" class="no-data-container">
            <div class="no-data-container">
                There is currently no information for shipment {{grRefContainerData.grReferenceNo}}.
            </div>
        </ng-container>
    </ng-template>
</div>