import { Component, inject, OnInit, EventEmitter, Output } from '@angular/core';
import { IUserInfoModel, UserInfoModel } from '../models/user-info-model';
import { ApiUserService } from '../service/user/api-user.service';
import { ChangeDetectorRef } from '@angular/core';
import { AppComponent } from '../app.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';


@Component({
  selector: 'app-header-icons',
  templateUrl: './header-icons.component.html',
  styleUrls: ['./header-icons.component.css']
})
export class HeaderIconsComponent implements OnInit {

  userInfoModel: IUserInfoModel = new UserInfoModel();
  private app = inject(AppComponent);
  private cd = inject(ChangeDetectorRef);
  websocketMessages: string[] = [];
  hidden = true;
  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @Output() openAlerts = new EventEmitter<void>();

  private apiUserService = inject(ApiUserService)

  ngOnInit(): void {
    this.apiUserService.userInfo.subscribe((userInfo) => { this.userInfoModel = userInfo });
    // this.app.wsMessage.subscribe(data => {
    //     this.openSuccessSnackBar(data.notification);
    //   this.websocketMessages.push(data.notification);
    //   this.cd.detectChanges();
    // });
  }

  onOpenAlertsClick() {
    this.openAlerts.emit();
    this.websocketMessages = [];
  }

  openSuccessSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 800,
      panelClass: ['snackbar-success']
    });
  }
}
