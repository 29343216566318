<div class="tracking-map-wrapper">
  <div class="tracking-map-container">
    <div class="container">
      <div class="map-container-wrapper">
        <div id="map" class="map-container"></div>
      </div>
    </div>
    <div class="legend">
      <div>
        <div class="legend-header">Road Legend:</div>
        <!-- <div class="legend-description">
          <span class="color-box completed"></span> Completed
          <span class="color-box stationary"></span> Stationary
          <span class="color-box moving"></span> Moving
        </div> -->
        <!-- <hr class="legend-separator" /> -->
        <div class="legend-item" *ngFor="let item of legendRoad">
          <div [ngClass]="item.iconClass"></div>
          <span class="legend-text">{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
