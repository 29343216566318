import { Component, OnInit } from '@angular/core';
import { ShipmentService } from '../service/shipment/shipment.service'; // Import your data service
import { DataSource } from '@angular/cdk/collections';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  dataSources = ['Shipments','Documents','Schedules','Bookings']; // Populate this with your available data sources
  availableFields = []; // Fields based on the selected data source
  selectedDataSource: any;

  constructor(private dataService: ShipmentService) { }

  ngOnInit() {
    // Load data sources from your service
    switch (this.selectedDataSource){
      case "Shipment" : {
        //statements; 
        break;
      } 
    }
 
  }

  //onDataSourceChange() {
  //  // Load available fields based on the selected data source
  //  this.availableFields = this.dataService.getFieldsForDataSource(this.selectedDataSource);
  //}

  addWidget() {
    // Create a new widget based on selected fields and the current metrics
    //const selectedFields = this.availableFields.filter(field => field.selected);
    // Emit an event or call a method to add the widget to the dashboard
  }
}
