
<div class="container">
  <div class="map-container-wrapper">
    <div id="mapId" class="map-container"></div>
  </div>
  <div class="route-points">
    <h4>Route Points:</h4>
    <ul>
      <li *ngFor="let point of routePoints">
        Latitude: {{ point[0] }}, Longitude: {{ point[1] }}
      </li>
    </ul>
  </div>
</div>
