import { Renderer2, AfterViewInit, ElementRef, Component, ViewChild, ChangeDetectorRef, OnInit, Output, EventEmitter, OnDestroy, Input, inject } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SchedulesService } from '../service/schedules/schedules.service';
import { IScheduleListModel, ScheduleListModel } from '../models/schedules/schedule-list-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from '../service/communication/communication.service';
import { FilterData } from '../models/list-model';
import { FilterEnum } from '../models/filter-enum';
import { ColumnSetup } from '../models/column-setup';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { Observable, of, Subject } from 'rxjs';
import { DataFilter, FilterTransferModel } from '../models/filter-models';
import { takeUntil, catchError, distinctUntilChanged, skip, map, startWith } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertNotificationModel } from '../models/alerts/alert-notification-model';
import { AlertSubscriptionModel } from '../models/alerts/alert-subscription-model';
import { AlertSubscriptionInputModel } from '../models/alerts/alert-subscription-input-model';
import { SubscriptionComponent } from '../dialog/subscription/subscription.component';
import { AlertService } from '../service/alerts/alert.service';
import { AppComponent } from '../app.component';
import { KeycloakService } from 'keycloak-angular';
import { Alert } from '../models/alerts/alerts';
import { LookupModel } from '../models/lookup-model';
import { LookupService } from '../service/lookup/lookup.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrl: './schedules.component.css'
})
export class SchedulesComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator = {} as MatPaginator;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.scheduleDataSource.sort = sort;
  }
  protected scheduleDataSource = new MatTableDataSource<ScheduleListModel>();
  @Output() openFilter = new EventEmitter<string>();
  protected filter!: FilterData;
  protected filterParameter!: FilterData;
  protected savedFilters: DataFilter[] = [];
  protected currentFilter: DataFilter = new DataFilter();
  protected filterData: FilterData = {};
  protected isCustUser: boolean = false;
  protected FilterEnum = FilterEnum;
  protected selectedButton = "";
  protected detailTabbedViewEnum = detailTabbedViewEnum;
  protected detailView = detailView;
  protected displayedColumns: ColumnSetup[] = [];
  protected extractedColumns: string[] = [];
  protected scheduleList: IScheduleListModel[] = []
  protected range = new FormGroup({
    dateFrom: new FormControl<Date | null>(new Date()),
    dateTo: new FormControl<Date | null>({
      value: this.addDays(new Date(), 14),
      disabled: false,
    }, Validators.required),
  });
  //protected dateFrom = new FormControl(new Date());
  //protected dateTo = new FormControl({
  //  value: null,
  //  disabled: false
  //});

  protected filterList = new FormControl();
  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  protected subscribedTemplatesMap = new Map<string, boolean>();
  protected hasUnreadAlert: boolean = false;
  isLoading = false;
  protected yesterday = new Date();
  protected setDate = new Date();
  public dateErrorMessage: string | null = null;
  countries = new FormControl<LookupModel[]>([]);
  countriesMultiFilter = new FormControl<string>('');
  scheduleDataCountries: LookupModel[] = [];
  selectedFilterId: number = 0;
  SavedFilterName: string = '';
  datafilter: DataFilter = new DataFilter();
  filteredCountries!: Observable<LookupModel[]>;
  dischargePorts = new FormControl<LookupModel[]>([]);
  dischargePortsMultiFilter = new FormControl<string>('');
  filteredDischargePorts!: Observable<LookupModel[]>;
  scheduleDataDischargePort: LookupModel[] = [];
  scheduleDataLoadPort: LookupModel[] = [];
  loadPorts = new FormControl<LookupModel[]>([]);
  loadPortsMultiFilter = new FormControl<string>('');
  filteredLoadPorts!: Observable<LookupModel[]>;
  carriers = new FormControl<LookupModel[]>([]);
  carriersMultiFilter = new FormControl<string>('');
  filteredCarriers!: Observable<LookupModel[]>;
  scheduleDataCarriers: LookupModel[] = [];
  stackStatus = new FormControl<LookupModel[]>([]);
  stackStatusMultiFilter = new FormControl<string>('');
  filteredStackStatus!: Observable<LookupModel[]>;
  scheduleDataStackStatus: LookupModel[] = [];
  loadingSearch: boolean = false;


  stackStatusData: LookupModel[] = [
    { name: 'Provisional', code: 'Provisional', isSelected: false },
    { name: 'Firm', code: 'Firm', isSelected: false },
    { name: 'Port Omitted', code: 'Port Omitted', isSelected: false }
  ]

  get dateFrom() {
    return this.range.get('dateFrom') as FormControl;
  }

  get dateTo() {
    return this.range.get('dateTo') as FormControl;
  }

  private addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }


  constructor(
    private lookupService: LookupService,
    private cdr: ChangeDetectorRef,
    private schedulesService: SchedulesService,
    // private cdr: ChangeDetectorRef,
    private communicationService: CommunicationService,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private alertDataService: AlertService,
    private appComponent: AppComponent,
    private apiUserService: ApiUserService,
    private renderer: Renderer2,
  ) {
    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'backspace',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
  }

  ngAfterViewChecked(): void {
    if (this.table) {
      this.table.updateStickyColumnStyles();
    }
  }

  ngOnInit(): void {

    this.configureColumns();

    this.scheduleDataSource.filterPredicate = (data: ScheduleListModel, filter: string) => {
      const normalizedFilter = filter.toLowerCase();
      return (
        (data.carrier?.toLowerCase().includes(normalizedFilter) || false) ||
        (data.vesselName?.toLowerCase().includes(normalizedFilter) || false) ||
        (data.voyageNo?.toLowerCase().includes(normalizedFilter) || false) ||
        (data.stackStatus?.toLowerCase().includes(normalizedFilter) || false) ||
        (data.loadPort?.toLowerCase().includes(normalizedFilter) || false) ||
        (data.dischargePort?.toLowerCase().includes(normalizedFilter) || false) ||
        (data.portCountry?.toLowerCase().includes(normalizedFilter) || false) ||
        (data.serviceName?.toLowerCase().includes(normalizedFilter) || false)
      );
    }

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.schedulesService.filterNameSaved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (t) => {
          this.SavedFilterName = t;
          this.filterData = {};
          this.loadSavedFilters();
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });
    this.schedulesService.filterData$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (value) => {
          this.filterData = value;
          this.selectedFilterId = 0;
          this.updateCurrentFilter(this.selectedFilterId, this.getSavedFilterNameFromId(this.selectedFilterId), this.filterData);

          this.schedulesService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
        },
        error: (error) => {
          console.error('Error receiving filter data', error);
          window.alert('Error receiving filter data');
        }
      });


    this.isCustUser = this.apiUserService.IsCustUser;
    this.loadScheduleData();

  }

  reset() {
    this.dateFrom.reset();
    this.dateTo.reset();
    this.loadPorts.reset();
    this.dischargePorts.reset();
    this.countries.reset();
    this.carriers.reset();
    this.stackStatus.reset();

    this.filterList.reset();
    startWith(''),
      this.runReportClick()
  }



  loadScheduleData(): void {
    this.lookupService.getDischargePorts(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.scheduleDataDischargePort = data;
          this.dischargePorts.setValue(this.scheduleDataDischargePort.filter(port => port.isSelected));
          this.filteredDischargePorts = this.dischargePortsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterDischargePorts(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Discharge Port data', error)
      });


    this.schedulesService.filterData$.subscribe((value) => {
      this.filter = value;

      this.loadSavedFilters();
      //this.updateData(this.filter);
    });

    this.schedulesService.sendActiveFilterToList$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (filterReceived) => {
          this.updateCurrentFilter(filterReceived.id, filterReceived.filterName, filterReceived.filter)
          this.updateData(this.currentFilter.filter);
        }
      });
    this.lookupService.getLoadPorts(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.scheduleDataLoadPort = data;
          this.loadPorts.setValue(this.scheduleDataLoadPort.filter(port => port.isSelected));
          this.filteredLoadPorts = this.loadPortsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterLoadPorts(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

    this.lookupService.getCarriers(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.scheduleDataCarriers = data;
          this.carriers.setValue(this.scheduleDataCarriers.filter(carrier => carrier.isSelected));
          this.filteredCarriers = this.carriersMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCarriers(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Carrier data', error)
      });

    of(this.stackStatusData)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          this.scheduleDataStackStatus = data;
          this.stackStatus.setValue(this.scheduleDataStackStatus.filter(stack => stack.isSelected));
          this.filteredStackStatus = this.stackStatusMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterStackStatus(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error handling stack status data', error)
      });

    this.lookupService.getCountries(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.scheduleDataCountries = data;
          this.countries.setValue(this.scheduleDataCountries.filter(data => data.isSelected));
          this.filteredCountries = this.countriesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCountries(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Country data', error)
      });

    this.schedulesService.filterNameSaved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (t) => {
          this.loadSavedFilters();
          this.updateCurrentFilter(0, t, {});
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });

    this.dateFrom.valueChanges.subscribe(startDate => {
      if (startDate) {
        this.setDate = startDate;
      } else {
        this.dateTo.clearValidators();
        this.dateErrorMessage = null; // Clear error message if dateFrom is empty
      }
      this.dateTo.updateValueAndValidity();
      this.checkDateValidity(); // Check validity after updating
    });

    this.detectCustUser();

  }


  configureColumns(): void {
    this.displayedColumns = [
      { name: 'carrier', header: 'Carrier', type: 'string', visible: true, toolTip: 'Name of the carrier.' },
      { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true, toolTip: 'Name of the vessel.' },
      { name: 'voyageNo', header: 'Voyage No', type: 'string', visible: true, toolTip: 'Number assigned to voyage.' },
      { name: 'stackStatus', header: 'Stack Status', type: 'string', visible: true, toolTip: 'Provisional stack - The date window to place loaded container in port might change.' + '\n' + 'Firm stack - The date window to place loaded container in port is fixed.' },
      { name: 'stackStart', header: 'Stack Start', type: 'date', visible: true, toolTip: 'Date and time that stacks open.' },
      { name: 'stackEnd', header: 'Stack End', type: 'date', visible: true, toolTip: 'Date and time that stacks close.' },
      { name: 'siCutoffDateTime', header: 'SI Cut-off', type: 'date', visible: true, toolTip: 'Deadline for the shipper to provide the carrier with required documentation.' },
      { name: 'loadPort', header: 'Load Port', type: 'string', visible: true, toolTip: 'Port where goods will be loaded on to vessel.' },
      { name: 'loadETD', header: 'Load ETD', type: 'date', visible: true },
      { name: 'dischargePort', header: 'Discharge Port', type: 'string', visible: true, toolTip: 'Port where the vessel discharges some or all of its cargo.' },
      { name: 'dischargeETA', header: 'Discharge ETA', type: 'date', visible: true, toolTip: 'The estimated time of arrival at the discharge port.' },
      { name: 'portCountry', header: 'Country', type: 'string', visible: true, toolTip: 'The destination country.' },
      { name: 'serviceName', header: 'Service Name', type: 'string', visible: true, toolTip: 'Service code which indicate the planned route of the vessel.' },
      { name: 'actions', header: 'Action', type: 'string', visible: true },
      //{ name: 'star', header: 'More', type: 'string', visible: true }
    ]
    this.extractedColumns = this.displayedColumns.map(col => col.name);

  }
  createFilter(): void {
    if (this.loadPorts.value != null) {
      const loadPortCodes = this.parseFilterSelections(this.loadPorts.value);
      this.processFilterData('loadPortCode', loadPortCodes);
    }

    if (this.dischargePorts.value != null) {
      const dischargePortCodes = this.parseFilterSelections(this.dischargePorts.value);
      this.processFilterData('dischargePortCode', dischargePortCodes);
    }

    if (this.countries.value != null) {
      const countryCodes = this.parseFilterSelections(this.countries.value);
      this.processFilterData('countryCode', countryCodes);
    }

    if (this.carriers.value != null) {
      const carrierCodes = this.parseFilterSelections(this.carriers.value);
      this.processFilterData('carrierCode', carrierCodes);
    }

    if (this.stackStatus.value != null) {
      const stackStatusCodes = this.parseFilterSelections(this.stackStatus.value);
      this.processFilterData('stackStatus', stackStatusCodes);
    }
  }

  parseFilterSelections(selections: LookupModel[]): string {
    const codes: string[] = selections.map(selection => selection.code);
    return codes.join(',');

  }


  applyFilterList(filter: string) {
    const filterGroups = filter.split('|').map(group => group.split('&').map(value => value.trim().toLowerCase()));

    this.scheduleDataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return filterGroups.some(group => group.every(value => dataStr.includes(value)));
    };

    this.scheduleDataSource.filter = filter; // Trigger the filter
  }

  private detectCustUser(): void {
    this.apiUserService.userInfo
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (_) => {
          this.isCustUser = this.apiUserService.IsCustUser;
        },
        error: (error) => {
          console.error('Error fetching user info:', error);
        }
      });
  }

  private updateData(filterData?: FilterData): void {
    this.isLoading = true;
    this.createFilter()


    const dateFromValue = this.dateFrom.value ? new Date(this.dateFrom.value) : undefined;
    const dateToValue = this.dateTo.value ? new Date(this.dateTo.value) : undefined;

    this.schedulesService.getScheduleList(this.currentFilter.id, filterData, dateFromValue, dateToValue).subscribe(data => {
      this.configureColumns();
      this.scheduleDataSource.data = data;
      this.scheduleDataSource.data.sort((a, b) => {
        const loadETDA = new Date(a.loadETD).getTime();
        const loadETDB = new Date(b.loadETD).getTime();
        return loadETDA - loadETDB; // Ascending order
      });

      this.scheduleDataSource.paginator = this.paginator;
      this.isLoading = false;
    });

    this.filterParameter = {};
  }

  onOpenFilter(openFiler: FilterEnum) {
    this.communicationService.toggleFilter(openFiler);
    this.sendingActiveFilter();
  }

  loadSavedFilters() {
    this.schedulesService.getFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedFilters = data;
          if (this.currentFilter.filterName !== "") {
            let filterId = this.getFilterIdByName(this.currentFilter.filterName);
            this.updateCurrentFilter(filterId, this.currentFilter.filterName, this.getSavedFilterFromId(filterId));
            this.updateData();
          }
        }
      });
  }

  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');

    this.filterData[key] = dataArray;
  }

  setupFilter(column: string) {
    this.scheduleDataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  applyFilter(filterId: number): void {
    this.filterData = {};

    if (this.currentFilter.id === filterId) {
      this.updateCurrentFilter(0, '', {});
    }
    else {
      this.updateCurrentFilter(filterId, this.getSavedFilterNameFromId(filterId), this.getSavedFilterFromId(filterId))
    }
    if (this.currentFilter.id === 0) {
      this.scheduleDataSource = new MatTableDataSource<ScheduleListModel>();
      this.clearCurrentFilter();
    } else {
      this.filterList.reset();
      this.updateData(this.currentFilter.filter)
    }
  }

  clearCurrentFilter() {
    this.currentFilter.filter = {};
    this.currentFilter.filterName = '';
    this.currentFilter.id = 0;
    this.datafilter.filter = {};
    this.carriers.setValue(this.scheduleDataCarriers.filter(x => !x));
    this.stackStatus.setValue(this.scheduleDataStackStatus.filter(x => !x));
    this.countries.setValue(this.scheduleDataCountries.filter(x => !x));
    this.loadPorts.setValue(this.scheduleDataLoadPort.filter(x => !x));
    this.dischargePorts.setValue(this.scheduleDataDischargePort.filter(x => !x));
    this.filterList.reset();
  }

  deleteFilter() {
    let filterName = this.currentFilter.filterName;

    this.schedulesService.deleteScheduleFilter(this.currentFilter.id).subscribe(result => {
      this.schedulesService.filterNameSavedSend('');
      this.clearCurrentFilter();
      this.openSnackBar('Your filter, ' + filterName + ' has been deleted');
    });

  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.currentFilter.id = id;
    this.currentFilter.filterName = name;
    this.currentFilter.filter = filter;
    this.datafilter.filter = filter;

    if (this.datafilter.filter['carrierCode'] != null) {
      const carriersFilter = this.datafilter?.filter['carrierCode'] ?? [];
      this.carriers.setValue(this.scheduleDataCarriers.filter(status => carriersFilter.includes(status.code)))
    }
    if (this.datafilter.filter['stackStatus'] != null) {
      const stackStatusFilter = this.datafilter?.filter['stackStatus'] ?? [];
      this.stackStatus.setValue(this.scheduleDataStackStatus.filter(status => stackStatusFilter.includes(status.name)))
    }
    if (this.datafilter.filter['countryCode'] != null) {
      const countryCodeFilter = this.datafilter?.filter['countryCode'] ?? [];
      this.countries.setValue(this.scheduleDataCountries.filter(status => countryCodeFilter.includes(status.code)))
    }
    if (this.datafilter.filter['loadPortCode'] != null) {
      const loadPortCodeFilter = this.datafilter?.filter['loadPortCode'] ?? [];
      this.loadPorts.setValue(this.scheduleDataLoadPort.filter(port => loadPortCodeFilter.includes(port.code)))
    }
    if (this.datafilter.filter['dischargePortCode'] != null) {
      const dischargePortCodeFilter = this.datafilter?.filter['dischargePortCode'] ?? [];
      this.dischargePorts.setValue(this.scheduleDataDischargePort.filter(status => dischargePortCodeFilter.includes(status.code)))
    }
  }


  getSavedFilterNameFromId(filterId: number): string {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  getSavedFilterFromId(filterId: number): FilterData {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  getFilterIdByName(filterName: string): number {
    const filter = this.savedFilters.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  sendingActiveFilter() {
    this.createFilter();

    this.updateCurrentFilter(this.currentFilter.id, this.currentFilter.filterName, this.filterData);
    this.schedulesService.sendingActiveFilterToFilter(this.currentFilter)
  }

  subscribeToAlert(row: any): void {
    const notificationModel = this.createNotificationModel(row);

    this.showLoading(true);

    this.alertDataService.getAlertSubscriptionTemplates(notificationModel)
      .pipe(
        catchError(error => {
          console.error('Error fetching subscription templates:', error);
          return of([]);
        })
      )
      .subscribe((subscriptionTemplates: AlertSubscriptionModel[]) => {

        this.showLoading(false);

        const subscriptionInput = new AlertSubscriptionInputModel();
        subscriptionInput.notificationModel = notificationModel;
        subscriptionInput.templates = subscriptionTemplates;

        const dialogRef = this.dialog.open(SubscriptionComponent, {
          data: { subscriptionList: subscriptionTemplates }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            subscriptionInput.templates = result;

            this.alertDataService.updateAlertSubscription(subscriptionInput)
              .pipe(
                catchError(error => {
                  console.error('Error updating subscriptions:', error);
                  return of(null);
                })
              )
              .subscribe(response => {
                if (response) {
                  this.updateData(this.filter);
                }
              });
          }
        });
      });
  }

  showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  private createNotificationModel(row: any): AlertNotificationModel {
    return {
      AlertCode: '',
      ScheduleCode: row.sailingScheduleHeaderCode,
      TemplateGroup: 'Schedule'
    } as AlertNotificationModel;
  }

  public openSnackBar(message: string, panelClass: string = 'snackbar-success') {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: [panelClass]
    });
  }

  viewDetails(row: any, detailViewToOpen: detailView, tab: detailTabbedViewEnum): void {
    var scheduleParameter = row['sailingScheduleHeaderCode'] + '|' + row['carrierCode'] + '|' + row['loadPortCode'] + '|' + row['dischargePortCode'];

    var siCutoff = row['siCutoffDateTime'];
    this.communicationService.setSiCutOffDate(siCutoff);

    this.communicationService.toggleDetailView(detailViewToOpen, tab);
    this.communicationService.getDetailViewParameter(scheduleParameter)
  }

  protected isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  protected getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  protected getExtractedColumns(columns: ColumnSetup[]) {
    return columns.map(col => col.name);

  }

  protected runReportClick(): void {
    if ((this.countries.value && this.countries.value.length === 0) && (this.dischargePorts.value && this.dischargePorts.value.length === 0)) {
      this.openSnackBar('You need to select either a Country or a Discharge Port before searching', 'snackbar-fail');
      return;
    }

    if (this.range.invalid) {
      this.range.markAllAsTouched();
      return;
    }
    else {
      this.clearSearchField(this.filterList);
      this.updateData(this.filterData);
    }

  }

  handleButtonClick(row: any): void {
    // Replace with your desired logic (e.g., open a dialog, navigate, etc.)
    console.log('Button clicked for row:', row);
    alert(row['voyageNo'])
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkDateValidity(): void {
    const dateFromValue = this.dateFrom.value ? new Date(this.dateFrom.value) : undefined;
    const dateToValue = this.dateTo.value ? new Date(this.dateTo.value) : undefined;

    if (dateToValue && dateFromValue && dateToValue <= dateFromValue) {
      this.dateErrorMessage = 'End date must be after the start date.'; // Set error message
    } else {
      this.dateErrorMessage = null; // Clear error message if valid
    }
  }

  private filterCountries(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.scheduleDataCountries;
    }
    return this.scheduleDataCountries.filter(country =>
      country.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCarriers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.scheduleDataCarriers;
    }
    return this.scheduleDataCarriers.filter(carrier =>
      carrier.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterStackStatus(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.scheduleDataStackStatus;
    }
    return this.scheduleDataStackStatus.filter(stackStatus =>
      stackStatus.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterDischargePorts(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.scheduleDataDischargePort;
    }
    return this.scheduleDataDischargePort.filter(dischargePort =>
      dischargePort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }


  private filterLoadPorts(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.scheduleDataLoadPort;
    }
    return this.scheduleDataLoadPort.filter(loadPort =>
      loadPort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }


  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
  }

  clearSearchField(control: FormControl): void {
    control.reset(); // Clear the search input field
    this.scheduleDataSource.filter = ''; // Reset the table filter
  }

  applySearch(filterValue: string | undefined) {
    const normalizedFilter = (filterValue || '').trim().toLowerCase();
    this.loadingSearch = true;
    setTimeout(() => {
      this.scheduleDataSource.filter = normalizedFilter;
      this.loadingSearch = false;
    }, 200);
  }

  checkNoFilter(): boolean {
    if (Object.values(this.filterData).some(value => value && value.length > 0)) {
      return true;
    }
    return false;
  }
}



