<div class="icon-container">
  <!-- <button 
    matBadge="{{websocketMessages.length!=0 ? websocketMessages.length : ''}}" [matBadgeHidden]="websocketMessages.length!=0 ? false : true"
     class="icon-button" (click)="onOpenAlertsClick()">      
      <img src="assets/BellRinging.svg">
    </button> -->
  <button class="icon-button " matTooltip="Update your account details." matTooltipPosition="below"
    matTooltipClass="custom-mat-tooltip">
    <a [href]="userInfoModel.accountUrl+'#/personal-info'">
      <img src="assets/User.svg">
    </a>

  </button>
</div>