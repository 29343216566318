<div class="container">
  <div class="header-title-wrapper">
    <h1>Booking Management</h1>
    <div class="save-wrapper">
      <div class="saved-views" *ngIf="selectedTab === bookingTabbedViewEnum.listTab">
        <div *ngFor="let savedFilter of savedFilters">
          <button mat-button class="Saved-filter-btn" [ngClass]="{'selected': currentFilter.id === savedFilter.id}"
                  (click)="applyFilter(savedFilter.id)">
            {{ savedFilter.filterName }}
          </button>
        </div>
      </div>
      <div class="action-buttons-wrapper" *ngIf="selectedTab === bookingTabbedViewEnum.listTab">
          <button mat-button class=" filter-btn" (click)="onOpenFilter(FilterEnum.bookingFilter)"
                  matTooltip="Save your current filters." matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
            <div class="filter-icon-wrap">
              <div class="filter-icon"></div>
              Save Filters
            </div>
          </button>
          <button mat-button class="filter-btn" (click)="clearCurrentFilter()" matTooltip="Clear data in input fields."
                  matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
            <div class="filter-icon-wrap">
              <div class="filter-clear-icon"></div>
              Reset Filters
            </div>
          </button>
          <button mat-button class="filter-btn-delete" (click)="deleteFilter()" *ngIf="currentFilter.id !== 0">
            <div class="filter-icon-wrap">
              <div class="filter-delete-icon"></div>
              Delete Saved View
            </div>
          </button>
      </div>
    </div>
  </div>

  <div class="mat-tab-row">
    <div class="col-md-4 booking-tabs">
      <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color" [hideSingleSelectionIndicator]="true"
        class="tab-menu" [(ngModel)]="selectedTab">
        <mat-button-toggle class="tab-menu-item" matTooltip="All your bookings processed by GoReefers."
          matTooltipPosition="above" matTooltipClass="custom-mat-tooltip"
          [value]="bookingTabbedViewEnum.listTab">Bookings
          Overview</mat-button-toggle>
        <mat-button-toggle class="tab-menu-item"
          matTooltip="All your newly submitted bookings not yet processed by GoReefers." matTooltipPosition="above"
          matTooltipClass="custom-mat-tooltip" [value]="bookingTabbedViewEnum.submittedTab">My Pending
          Bookings</mat-button-toggle>
      </mat-button-toggle-group>
    </div>


  </div>

  <div class="row" *ngIf="selectedTab === bookingTabbedViewEnum.listTab">
    <div class="col-md-2-bookings" *ngIf="selectedTab === bookingTabbedViewEnum.listTab">
      <mat-form-field>
        <mat-label>Enter a ETD date range</mat-label>
        <mat-date-range-input [rangePicker]="picker" required>
          <input matStartDate placeholder="ETD from" [formControl]="dateFrom">
          <input matEndDate placeholder="ETD to" [formControl]="dateTo">
        </mat-date-range-input>

        <!-- Clear button for the Start Date -->
        <ng-container *ngIf="dateFrom.value && dateTo.value">
          <button class="clear-search" (click)="clearSearchField(dateFrom,dateTo)">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </ng-container>

        <mat-datepicker-toggle matIconSuffix [for]="picker" matTooltip="Pick a date from the calendar."
          matTooltipPosition="above" matTooltipClass="custom-mat-tooltip"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="dateFrom.invalid && dateFrom.touched">Start date is required.</mat-error>
        <mat-error *ngIf="dateTo.invalid && dateTo.touched">End date is required.</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-2-bookings">
      <mat-form-field>
        <mat-label>GR Ref No.</mat-label>
        <input matInput [formControl]="grRefNos">
        <button matSuffix *ngIf="grRefNos.value" mat-icon-button (click)="clearSearchField(grRefNos)"
          aria-label="Clear selection" class="clear-filter-icon">
          <mat-icon svgIcon="backspace"></mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-md-2-bookings">
      <mat-form-field *ngIf="loadPorts.value">
        <mat-label>Load Ports </mat-label>
        <mat-select [formControl]="loadPorts" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="loadPortsMultiFilter" placeholderLabel="Search"
              noEntriesFoundLabel="Load port not found"
              [alwaysRestoreSelectedOptionsMulti]="true"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{loadPorts.value.length > 0 ? loadPorts.value[0].code : ''}}
            <span *ngIf="loadPorts.value[0]  && loadPorts.value.length > 1" class="example-additional-selection">
              (+{{(loadPorts.value.length || 0) - 1}} {{loadPorts.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(loadPorts)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let loadPort of filteredLoadPorts | async" [value]="loadPort">
            {{loadPort.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2-bookings">
      <mat-form-field *ngIf="markets.value">
        <mat-label>Market</mat-label>
        <mat-select [formControl]="markets" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="marketsMultiFilter" placeholderLabel="Search"
              noEntriesFoundLabel="Market not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{markets.value.length > 0 ? markets.value[0].code : ''}}
            <span *ngIf="markets.value[0]  && markets.value.length > 1" class="example-additional-selection">
              (+{{(markets.value.length || 0) - 1}} {{markets.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(markets)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let market of filteredMarkets | async" [value]="market">
            {{market.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2-bookings">
      <mat-form-field *ngIf="carriers.value">
        <mat-label>All Carriers</mat-label>
        <mat-select [formControl]="carriers" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="carriersMultiFilter" placeholderLabel="Search"
              noEntriesFoundLabel="Carrier Not Found"></ngx-mat-select-search>
          </mat-option>

          <mat-select-trigger>
            {{carriers.value.length > 0 ? carriers.value[0].code : ''}}
            <span *ngIf="carriers.value[0]  && carriers.value.length > 1" class="example-additional-selection">
              (+{{(carriers.value.length || 0) - 1}} {{carriers.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(carriers)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier">
            {{carrier.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2-bookings">
      <mat-form-field *ngIf="vesselNames.value">
        <mat-label>All Vessels</mat-label>
        <mat-select [formControl]="vesselNames" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="vesselsMultiFilter" placeholderLabel="Search"
              noEntriesFoundLabel="Vessel Not Found"></ngx-mat-select-search>
          </mat-option>

          <mat-select-trigger>
            {{vesselNames.value.length > 0 ? vesselNames.value[0] : ''}}
            <span *ngIf="vesselNames.value[0]  && vesselNames.value.length > 1" class="example-additional-selection">
              (+{{(vesselNames.value.length || 0) - 1}} {{vesselNames.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(vesselNames)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let vesselName of filteredVesselNames | async" [value]="vesselName">
            {{vesselName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <ng-container *ngIf="!isCustUser">
      <div class="col-md-2-bookings">
        <mat-form-field *ngIf="customers.value">
          <mat-label>Customer</mat-label>
          <mat-select [formControl]="customers" multiple #multiSelect>
            <mat-option>
              <ngx-mat-select-search [formControl]="customersMultiFilter" placeholderLabel="Search"
                noEntriesFoundLabel="Customer not found"></ngx-mat-select-search>
            </mat-option>
            <mat-select-trigger>
              {{customers.value.length > 0 ? customers.value[0].code : ''}}
              <span *ngIf="customers.value[0]  && customers.value.length > 1" class="example-additional-selection">
                (+{{(customers.value.length || 0) - 1}} {{customers.value.length === 2 ? 'other' : 'others'}})
              </span>
              <button class="clear-filter" (click)="clearLookupFilter(customers)">
                <mat-icon svgIcon="backspace"></mat-icon>
              </button>
            </mat-select-trigger>
            <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
              {{customer.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <div class="col-md-2-bookings" *ngIf="selectedTab === bookingTabbedViewEnum.listTab">
      <button class="runReportButton" matTooltip="Start your search." matTooltipPosition="above"
        matTooltipClass="custom-mat-tooltip"
        (click)="runReportClick(filterList.value)">Search<mat-icon>arrow_forward</mat-icon></button>
    </div>
  </div>


  <div class="filter-btn-wrapper" *ngIf="selectedTab === bookingTabbedViewEnum.listTab">
    <div class="legend-wrapper">
      <ng-container *ngIf="selectedTab === bookingTabbedViewEnum.listTab">
        <div class="btn-header" *ngIf="selectedTab === bookingTabbedViewEnum.listTab">
          <button class="add-booking-btn"
                  (click)="navigateToAddBooking()"
                  [disabled]="!authService.hasClaim(ClaimAction.Root2Go_AddBookings)"
                  [matTooltip]="
                                !authService.hasClaim(ClaimAction.Root2Go_AddBookings)
                                ? getPermissionTooltip(ClaimAction.Root2Go_AddBookings).toolTipMessage
                                : 'Click to proceed to schedule search to add new booking.'
                                "
                  matTooltipPosition="above"
                  matTooltipClass="custom-mat-tooltip"
                  [matTooltipShowDelay]="!authService.hasClaim(ClaimAction.Root2Go_AddBookings) ? getPermissionTooltip(ClaimAction.Root2Go_AddBookings).timeOut : 1000">
            <mat-icon>add_circle</mat-icon>
            New booking
          </button>
        </div>
      </ng-container>
      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'confirmed'}"
              (click)="selectButton('confirmed')">
        <img src="/assets/Confirmed.svg" alt="" class="icon">
        Confirmed
      </button>

      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'processing'}"
              (click)="selectButton('processing')">
        <img src="/assets/Draft.svg" alt="" class="icon">
        Processing
      </button>

      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'awaiting'}"
              (click)="selectButton('awaiting')">
        <img src="/assets/Awaiting.svg" alt="" class="icon">
        Awaiting approval
      </button>

      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'cancelled'}"
              (click)="selectButton('cancelled')">
        <img src="/assets/Cancelled.svg" alt="" class="icon">
        Booking Cancelled
      </button>
    </div>
  </div>



  <ng-container *ngIf="selectedTab === bookingTabbedViewEnum.listTab">
    <app-booking-list></app-booking-list>
  </ng-container>

  <ng-container *ngIf="selectedTab === bookingTabbedViewEnum.submittedTab">
    <app-booking-submitted-view></app-booking-submitted-view>
  </ng-container>
</div>
