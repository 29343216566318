<div class="tracking-map-wrapper">
  <div class="tracking-map-container">
    <ng-container [ngSwitch]="mode">
      <app-road-tracking *ngSwitchCase="TransportMode.Road"
                         [startLocation]="[51.505, -0.09]"
                         [endLocation]="[51.515, -0.1]"
                         [vehicles]="vehicles">
      </app-road-tracking>
      <ng-container *ngSwitchCase="TransportMode.Ocean">
        <app-ocean-tracking [vehicleLocation]="vehicleLocation"
                            [startLocation]="startLocation"
                            [endLocation]="endLocation"
                            [vesselDataList]="vesselDataList"
                            [shipmentDataList]="shipmentDataList"
                            [mapView]="mapView">
        </app-ocean-tracking>
      </ng-container>
      <div *ngSwitchDefault>
        <p>Invalid Transport Mode</p>
      </div>
    </ng-container>
    
    <div class="legend">
      <div *ngIf="mode === TransportMode.Road">
        <div class="legend-header">Road Legend:</div>
        <div class="legend-item" *ngFor="let item of legendRoad">
          <div [ngClass]="item.iconClass"></div>
          <span>{{ item.text }}</span>
        </div>
      </div>
      <div *ngIf="mode === TransportMode.Ocean">
        <div class="legend-header">Ocean Legend:</div>
        <div class="legend-item" *ngFor="let item of legend">
          <div [ngClass]="item.iconClass"></div>
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
