<div class="dialog-wrap">
    <div mat-dialog-content>
        <div mat-dialog-title class="dialog-title">Report Schedule</div>
        <mat-label>
            Report incorrect information on this sailing schedule to GoReefers.
        </mat-label>
    </div>

    <div mat-dialog-content class="dialog-reason">
        <mat-form-field appearance="fill">
            <textarea matInput [(ngModel)]="data.comment" placeholder="Enter your reason for reporting schedule..."></textarea>
        </mat-form-field>
    </div>

    <div mat-dialog-actions>
        <button class="dialog-cancel-button" mat-button (click)="onCancel()">Cancel</button>
        <button class="dialog-action-button" mat-button (click)="onReport()">Report now</button>
    </div>
</div>  
  