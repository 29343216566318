<div class="container">
  <!--<div>
    <h2>Recent Activities</h2>
    <div class="activity-container">
        <mat-card appearance="outlined" class="activity-card col-md-2 " *ngFor="let activity of activities$()">
          <div class="notification-column">
            <div class="activity-card-header">
              <img class="activity-logo" src="/assets/R2GIcon.svg" alt="Dashed line">
              <img class="activity-clock" src="/assets/HistoryClockIcon.svg" alt="Dashed line">
            </div>
            <mat-card-content class="activity-card-body">
              <div class="activity-heading"> {{ activity.action }}</div>
              <div class="activity-details"> {{ activity.timestamp | date:'short' }}</div>
            </mat-card-content>
          </div>
        </mat-card>
    </div>
   
  </div>-->
  <app-widget></app-widget>
  

</div>

