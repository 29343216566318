<div class="dv-timeline">
  <div *ngIf="isMinWidth(0)" class="dv-timeline-header-row">
    <ng-container *ngFor="let column of displayedColumns">
      <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
        <strong matTooltip="{{column[2]}}" matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
          {{
          column[1]
          }}
        </strong>
      </div>
    </ng-container>
  </div>
    <div *ngIf="hasTrackingEvents" style="width: 100%;">
      <div *ngFor="let row of events" class="dv-timeline-data-row" id="mobileCard">
        <ng-container *ngFor="let column of displayedColumns">
          <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
            <ng-container *ngIf="column[0] === 'date'">
              {{
            getValueForRowColumn(row, column[0]) && getValueForRowColumn(row, column[0]) !== '0001-01-01T00:00:00'
            ? (getValueForRowColumn(row, column[0]) | date: 'dd MMM yyyy, HH:mm') : ' '
              }}
            </ng-container>
            <ng-container *ngIf="column[0] === 'location'">
              <span style="font-weight: bold;">
                {{ getValueForRowColumn(row, column[0]) }}
              </span>
            </ng-container>
            <ng-container *ngIf="column[0] !== 'location'&& column[0] !== 'date'">
              {{getValueForRowColumn(row, column[0])}}
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="!hasTrackingEvents">No Tracking Data</div>
  </div>

