import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-late-stack-warning',
  templateUrl: './late-stack-warning.component.html',
  styleUrls: ['./late-stack-warning.component.css'] // Corrected from styleUrl to styleUrls
})
export class LateStackWarningComponent {

  constructor(
    public dialogRef: MatDialogRef<LateStackWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onOk(): void {
    this.dialogRef.close(true); // Close the dialog and return true for agreement
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onReject(): void {
    this.dialogRef.close(false); // Close the dialog and return false for decline
  }

}
